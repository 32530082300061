import { Box, Button, Card, CardActions, CardContent, CardHeader, CircularProgress, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { ProductBundle } from "../../model/ProductBundle";
import { ApiBackend } from "../../providers/apibackend";
import DirtyPageChecker from "../Common/DirtyPageChecker";
import EditBundleFormContent from "./EditBundleFormContent";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

const EditBundle = (props: IProps & WithStyles) => {
    const [loading, setLoading] = useState(true);
    const [bundle, setBundle] = useState(null as ProductBundle);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    // Run on load
    useEffect(() => {
        didMount.current = true;

        const initBundle = async (): Promise<void> => {
            const result = await backend.getProductBundle(props.match.params.id);

            if (didMount.current) {
                setBundle(result);
                setLoading(false);
            }
        };

        initBundle();

        // unmount
        return () => {didMount.current = false};
    }, []);

    const render = () => {
        const { classes } = props;
        const errorText = "Ett okänt fel har uppstått - ändringar är inte sparade.";
        const cardHeader = (): string => {
            if (bundle?.name) {
                return `Hantera ${bundle.name}`;
            }

            return `Hantera ${bundle?.id}`
        };

        return (<>
            {loading &&
                <CircularProgress color="primary" />
            }
            {bundle &&
                <Formik
                    initialValues={bundle}
                    onSubmit={async (values, { resetForm }) => {
                        const response = await backend.addOrUpdateProductBundle(values);

                        if (response) {
                            setBundle(response);
                            resetForm({ values: response });
                        } else {
                            alert(`${errorText}\n\nFick felkod från server`);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (
                            <DirtyPageChecker isDirty={dirty} getTitle={getTitle} {...props}>
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                        <CardContent className={classes.form}>
                                            <EditBundleFormContent {...formik} classes={classes} mode="edit" />
                                        </CardContent>
                                        <CardActions>
                                            <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} width={"100%"}>
                                                <Box>
                                                    <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                                    <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                                </Box>
                                                <Box>
                                                    <Typography variant="body2">Skapad: {vasaloppetDateTimeGetterFormatter(formik.values.created)}</Typography>
                                                    <Typography variant="body2">Uppdaterad: {vasaloppetDateTimeGetterFormatter(formik.values.updated)}</Typography>
                                                </Box>
                                            </Box>

                                        </CardActions>
                                    </Card>
                                </form>
                            </DirtyPageChecker>
                        )
                    }}
                </Formik>
            }
        </>);
    }


    const getTitle = (): string | undefined => {
        if (bundle?.name) {
            return `Produktbundle: ${bundle.name}`;
        }
    };

    return render();
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditBundle));
