import { PropTypes } from "@material-ui/core";
import { Children, PropsWithChildren, ReactNode } from "react";

type SplitButtonProps = { color?: PropTypes.Color; disabled?: boolean; } & PropsWithChildren<{}>;

const getPrimary = (children: ReactNode) => {
    if (Array.isArray(children)) {
        return Children.toArray(children)[0];
    }

    return children;
};

const getSecondaries = (children: ReactNode) => {
    if (!Array.isArray(children)) {
        return [];
    }

    const all = Children.toArray(children);
    all.shift();
    return all;
};

export { SplitButtonProps, getPrimary, getSecondaries };
