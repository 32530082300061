import IconButton from "@material-ui/core/IconButton/IconButton";
import Select from "@material-ui/core/Select/Select";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";

interface IProps {
    variant?: "filled" | "outlined" | "standard";
    value?: string | string[];
    disabled?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    style?: React.CSSProperties;
    onClear?: () => void;
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

class ClearableSelect extends React.Component<IProps> {

    render(): JSX.Element {
        const { value, variant, disabled, clearable, multiple, style, children, onChange } = this.props;

        const displayClear = this.hasValue() ? "" : "none";
        const inputProps = (clearable && !!value) ? { IconComponent: () => null as any } : undefined;

        return (
            <Select
                disabled={disabled}
                variant={variant}
                value={value}
                multiple={multiple}
                style={style}
                endAdornment={clearable ?
                    <IconButton
                        style={{ padding: 0, display: displayClear }}
                        onClick={this.handleClear}>
                        <ClearIcon />
                    </IconButton>
                    : null
                }
                inputProps={inputProps}
                onChange={onChange}
            >
                {children}
            </Select>
        );
    }

    private hasValue = (): boolean => {
        const { value, multiple } = this.props;

        if (multiple) {
            return value.length > 0;
        }

        return !!value;
    };

    private handleClear = (): void => {
        if (this.props.onClear) {
            this.props.onClear();
        }
    };
}

export default ClearableSelect;
