/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { Theme, createStyles, WithStyles, Button, withStyles, Dialog, DialogContent, DialogTitle, DialogActions, Grid, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { ApiBackend } from '../../../providers/apibackend';
import { Person } from '../../../model/Person';
import validationSchema from './PersonValidationSchema';
import EditPersonFormContent from './EditPersonFormContent';
import RenderPersonFields from './RenderPersonFields';
import { cloneDeep } from 'lodash';

interface IProps {
    personToBeMerged: Person;
    personTarget: Person;
    onSave: (person: Person) => void;
    onAbort: () => void;
}

interface IState {
    loading: boolean;
    mergedPerson: Person;
}

class MergeDialog extends React.Component<WithStyles & IProps, IState> {

    constructor(props: WithStyles & IProps) {
        super(props);
        this.state = { loading: false, mergedPerson: cloneDeep(this.props.personTarget) };
    }

    render() {
        return <div>
            {this.renderEditPerson()}
        </div>;
    }

    renderEditPerson() {
        const title = "Slå samman personer";
        const { loading, mergedPerson } = this.state;
        const { classes, personToBeMerged, personTarget, onAbort, onSave } = this.props;

        let submitForm: () => void = null;
        return <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-person-title"
            open={true}
        >
            <DialogTitle id="edit-person-title">{loading ? "Laddar" : title}</DialogTitle>
            <DialogContent dividers>

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant='h5'>Källa till sammanslagning</Typography>
                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                            <Formik initialValues={personToBeMerged} onSubmit={() => { }}>
                                {formik => {
                                    return <RenderPersonFields readonly={true} allowEditVasaId={true} {...formik} />
                                }}
                            </Formik>
                        </Grid>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant='h5'>Person att slå samman till</Typography>
                        <Formik initialValues={personTarget} onSubmit={() => { }}>
                            {formik => {
                                return <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                    <RenderPersonFields readonly={true} allowEditVasaId={true} {...formik} />
                                </Grid>
                            }}
                        </Formik>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant='h5'>Sammanslaget</Typography>
                        <Formik
                            initialValues={mergedPerson}
                            validationSchema={validationSchema}
                            onReset={(values): void => {
                                this.setState({ mergedPerson: values });
                                onAbort();
                            }}
                            onSubmit={async (values, { resetForm }) => {
                                const person = await this.save(values);
                                resetForm({ values: person });
                            }}
                        >
                            {formik => {
                                const { handleSubmit } = formik;
                                submitForm = formik.submitForm;

                                return (<form autoComplete="off" onSubmit={handleSubmit}>
                                    <EditPersonFormContent isEditMode={false} {...formik} classes={classes} ></EditPersonFormContent>
                                </form>)
                            }}
                        </Formik>
                    </Grid>
                </Grid>


            </DialogContent>
            <DialogActions>
                <Button type="submit" color="secondary" variant="contained" onClick={() => { submitForm() }}>Spara</Button>
                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={this.props.onAbort}>Avbryt</Button>
            </DialogActions>


        </Dialog>;
    }

    private save = async (person: Person): Promise<Person> => {
        let api = new ApiBackend();
        let saved = await api.savePerson(person)
        await api.mergePerson(this.props.personToBeMerged.id, this.state.mergedPerson.id);
        this.props.onSave(saved);
        return saved;
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(MergeDialog);
