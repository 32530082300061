import { Entry } from "../model/Entry";
import { Person } from "../model/Person";

export const copyValuesFromPersonToEntry = (person: Person,entry: Entry) => {        
        entry.firstName = person.firstName;
        entry.lastName = person.lastName;
        entry.email = person.email;
        entry.phone = person.mobileNumber;
        entry.personId = person.id;
        entry.address = person.address;
        entry.city = person.city;
        entry.country = person.country;
        entry.nationality = person.nationality;
        entry.sex = person.gender == "female" ? "W" : "M";
        entry.postalCode = person.postCode;
        if (person.birthDay)
        {
            entry.dateOfBirthYear = +(person.birthDay.split("-")[0]);
            entry.dateOfBirthMonth = +(person.birthDay.split("-")[1]);
            entry.dateOfBirthDay = +(person.birthDay.split("-")[2]);
        }
};

export const getDateOfBirthAsString = (entry: Entry) => {        
        if (entry.dateOfBirthYear && entry.dateOfBirthMonth && entry.dateOfBirthDay)
        {
            return entry.dateOfBirthYear + "-" + entry.dateOfBirthMonth.toString().padStart(2,'0') + "-" + entry.dateOfBirthDay.toString().padStart(2,'0');
        }
        return "";
};