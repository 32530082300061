import { getLocalStoragePosSetting } from "../contexts/ExpoContext";
import { Entry } from "./Entry";
import { OrderCode } from "./OrderCode";
import { OrderContact } from "./OrderContact";
import { OrderItem } from "./OrderItem";

export const OrderStatuses = [{ name: "Ny", value: "NEW" }, { name: "Ej Betald", value: 'PAYMENT_INITIALIZED' }, { name: "Klar", value: "Completed" }, { name: "Återbetald", value: "REVERSED" }, { name: "Avbruten", value: "Cancelled" }, { name: "Borttagen", value: "DELETED" }, { name: "SportsProOrder", value: "SPORTSPROSHADOW" }];
export class Order {
    static translateStatus(status: string) {
        let s = OrderStatuses.filter(x => x.value == status);
        return s.length > 0 ? s[0].name : "OKÄND";
    }

    id: string;
    publicId: string;
    items: OrderItem[];
    Status: string;
    contact: OrderContact;
    entries: Entry[];
    discountCodes: OrderCode[];
    totalSum: number;
    OrderDate: Date;
    LastUpdate: Date;
    locale: string;
    b2bId?: string;
    metadata?: OrderMetadata[];
    locked: boolean;
    totalAmountPaiedOnOrder: number;
    createdOnPosId?: string;
}

export const initNewOrder = (): Order => {
    const order = new Order();

    const userPosSetting = getLocalStoragePosSetting();
    if (!!userPosSetting) {
        order.createdOnPosId = userPosSetting.Id;
    }

    return order;
};

export class OrderMetadata {
    key: string;
    value: string;
}

export class ListOrder extends Order {
    Created: Date;
    LastChange: Date;
}

export class AddOnSummaryItem {
    productName: string;
    productId: string;
    numberOfSold: number;
    price: number;
}

export class DiplomaOrderItem {
    id: string;
    orderId: string;
    orderPublicId: string;
    productName: string;
    productVariantName: string;
    productVariantPriceGroupName: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
    participantId: string;
    contact: OrderContact;
    created: Date;
    RaceInfo?: {
        eventKey: string;
        year: number;
        bib: string;
        firstName: string;
        lastName: string;
        raceTime: string;
    }
}


export class EnervitOrderItem {
    id: string;
    orderId: string;
    orderPublicId: string;
    productName: string;
    productVariantName: string;
    productVariantPriceGroupName: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
    created: Date;
    RaceInfo?: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    }
}


export class FolksamOrderItem {
    id: string;
    orderId: string;
    orderPublicId: string;
    productName: string;
    productVariantName: string;
    productVariantPriceGroupName: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
    participantId: string;
    price: number;
    created: Date;
    Info: {
        firstName: string;
        lastName: string;
        address: string;
        postalCode: string;
        city: string;
        country: string;
        nationality: string;
        personalNumber: string;
        email: string;
        phone: string;
    }
}