/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 * 
 * This files holds the firebase setup
 */

import app from 'firebase/compat/app';
import 'firebase/compat/auth';

const prodConfig = {
    apiKey: "AIzaSyA7XrqenJlxp7W879oayhRqbhcrVEh3b98",
    authDomain: "vasaloppetauthtest.firebaseapp.com",
    databaseURL: "https://vasaloppetauthtest.firebaseio.com",
    projectId: "vasaloppetauthtest",
    storageBucket: "vasaloppetauthtest.appspot.com",
    messagingSenderId: "182019014958",
    appId: "1:182019014958:web:fb2a4df07489fd3c03babf"
  };
  const devConfig = {
    apiKey: "AIzaSyA7XrqenJlxp7W879oayhRqbhcrVEh3b98",
    authDomain: "vasaloppetauthtest.firebaseapp.com",
    databaseURL: "https://vasaloppetauthtest.firebaseio.com",
    projectId: "vasaloppetauthtest",
    storageBucket: "vasaloppetauthtest.appspot.com",
    messagingSenderId: "182019014958",
    appId: "1:182019014958:web:fb2a4df07489fd3c03babf"
  };

  const config =
  process.env.NODE_ENV === 'production' ? prodConfig : devConfig;

  //Integration against the Firebase Auth provider
  class Firebase {
      m_auth : app.auth.Auth;
      constructor() {
      app.initializeApp(config);
      this.m_auth = app.auth();
    }

    auth = () : app.auth.Auth => {
        return this.m_auth;
    }

    doCreateUserWithEmailAndPassword = (email:string, password: string) =>
    this.m_auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email:string, password: string) =>
    this.m_auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.m_auth.signOut();

    doPasswordReset = (email : string) => this.m_auth.sendPasswordResetEmail(email);

    doPasswordUpdate = (password:string) =>
    this.m_auth.currentUser.updatePassword(password);
  }
   
  export default Firebase;