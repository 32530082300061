import * as yup from "yup";

// Tillfälligt lånat från https://github.com/dockwa/simple-react-validator
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const validationSchema = yup.object().shape({
    email: yup.string()
        .email("Felaktig epost-adress")
        .notRequired(),
    mobileNumber: yup.string()
        .matches(phoneRegExp, "Felaktigt telefonnummer")
        .notRequired(),
});

export default validationSchema;
