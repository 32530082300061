import React from "react";
import { Card, CardContent, CardHeader, Grid, MenuItem, TextField, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { vasaloppetDateTimeGetterFormatter } from "../../../../utilities/date";
import { B2bCompanyProduct } from "../../../../model/B2bCompany";
import { Product } from "../../../../model/Product";

interface IProps {
    sourceProduct: Product;
    product: B2bCompanyProduct;
    onPriceGroupChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomProductInvoice = ({ sourceProduct, product, onPriceGroupChange, classes }: IProps & WithStyles) => {
    const hasB2bPriceGroup = !!product.b2bInvoicePriceGroupId;

    const priceGroups = sourceProduct.variants.find(v => v.Id === product.productVariantId)?.priceGroups ?? [];

    const render = () => {
        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Faktureringsinformation" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Faktura skapad"
                                        InputLabelProps={{ shrink: true }}
                                        value={vasaloppetDateTimeGetterFormatter(product.b2bInvoiceCreated)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Faktura skapad av"
                                        InputLabelProps={{ shrink: true }}
                                        value={product.b2bInvoiceCreatedBy ?? ""}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Märkning"
                                        InputLabelProps={{ shrink: true }}
                                        value={product.b2bInvoiceExternalRef ?? ""}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        select
                                        style={{ flex: 1, margin: '0 3px' }}
                                        label="Prisgrupp"
                                        InputLabelProps={{ shrink: true }}
                                        value={product.b2bInvoicePriceGroupId ?? ""}
                                        fullWidth
                                        helperText={!hasB2bPriceGroup ? "Vänligen välj en prisgrupp" : ""}
                                        error={!hasB2bPriceGroup}
                                        onChange={onPriceGroupChange}
                                    >
                                        {priceGroups.map((p, idx) => {
                                            return <MenuItem key={idx} value={p.id}>{p.Name}</MenuItem>;
                                        })}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(CustomProductInvoice);
