/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import React, { Fragment } from 'react';
import { CustomField, CustomFieldValidValue, hasCustomFieldStringValidValues, isChoiceType } from '../../model/CustomField';


interface Props {
    onPropChanged: (prop: keyof CustomField, newValue: CustomField[keyof CustomField]) => void;
    item: CustomField;
}

/** Edit  -- Edits an existing a new event
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */
class EditCustomField extends React.Component<Props, any> {

    private renderGenderPickerField(label: string, label_en: string, value: string, validValues: CustomFieldValidValue[]) {
        return <>
            <Grid item xs={6}>
                <TextField label={label} value={validValues.find(x => x.value == value).label}
                    onChange={(ev) => {
                        let cc = validValues.find(x => x.value == value);
                        cc.label = ev.target.value;
                        this.props.onPropChanged("validvalues", validValues);
                    }} fullWidth />
            </Grid>
            <Grid item xs={6}>
                <TextField label={label_en} value={validValues.find(x => x.value == value).label_en}
                    onChange={(ev) => {
                        let cc = validValues.find(x => x.value == value);
                        cc.label_en = ev.target.value;
                        this.props.onPropChanged("validvalues", validValues);
                    }} fullWidth />
            </Grid>
        </>;
    }

    private renderGenderPicker(item: CustomField) {
        const validValues = item.validvalues as CustomFieldValidValue[];
        return <>
            {this.renderGenderPickerField("Text för M (Men/Herrar)", "Text EN för M (Men/Herrar)", "M", validValues)}
            {this.renderGenderPickerField("Text för W (Women/Kvinnor)", "Text EN för W (Women/Kvinnor)", "W", validValues)}
            {this.renderGenderPickerField("Text för O (Mixed)", "Text EN för O (Mixed)", "O", validValues)}
        </>
    }

    private renderChoice(item: CustomField) {

        if (CONFIG.environment === "Prod" || hasCustomFieldStringValidValues(item.validvalues)) {
            // Legacy
            return (
                <Grid item xs={12}>
                    <TextField label="Giltiga värden (separera värden med ;)" value={item.validvalues ? item.validvalues.join(";") : ''} onChange={(ev) => {
                        this.props.onPropChanged("validvalues", ev.target.value.split(";"));
                    }} fullWidth />
                </Grid>
            );
        }

        const validValues = item.validvalues as CustomFieldValidValue[] ?? [];

        return (<>
            {validValues.map((x, idx) => {
                return (<Fragment key={idx}>
                    <Grid item xs={4}>
                        <TextField label="Värde" value={x.value}
                            onChange={(ev) => {
                                const match = validValues[idx];
                                match.value = ev.target.value;
                                this.props.onPropChanged("validvalues", validValues);
                            }} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField label="Svenska" value={x.label}
                            onChange={(ev) => {
                                const match = validValues[idx];
                                match.label = ev.target.value;
                                this.props.onPropChanged("validvalues", validValues);
                            }} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField label="Engelska" value={x.label_en}
                            onChange={(ev) => {
                                const match = validValues[idx];
                                match.label_en = ev.target.value;
                                this.props.onPropChanged("validvalues", validValues);
                            }} fullWidth />
                    </Grid>
                </Fragment>);
            })}

            <Grid item xs={12}>
                <Button variant="contained" color="primary"
                    disabled={validValues.some(x => x.value === "")}
                    onClick={() => {
                        validValues.push({
                            value: "",
                            label: "",
                            label_en: ""
                        });
                        this.props.onPropChanged("validvalues", validValues);
                    }}
                >
                    Lägg till alternativ
                </Button>
            </Grid>
        </>);
    }

    render() {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField label="Namn" value={this.props.item.name} onChange={(ev) => {
                        this.props.onPropChanged("name", ev.target.value);
                    }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Label" value={this.props.item.label} onChange={(ev) => {
                        this.props.onPropChanged("label", ev.target.value);
                    }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Label ENG" value={this.props.item.label_en} onChange={(ev) => {
                        this.props.onPropChanged("label_en", ev.target.value);
                    }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Beskrivning" value={this.props.item.description} onChange={(ev) => {
                        this.props.onPropChanged("description", ev.target.value);
                    }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <TextField label="Beskrivning ENG" value={this.props.item.description_en} onChange={(ev) => {
                        this.props.onPropChanged("description_en", ev.target.value);
                    }} fullWidth />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel label={"Hantera som klubbnamn"} control={<Checkbox checked={this.props.item.handleAsClubName} onChange={(ev) => {
                        this.props.onPropChanged('handleAsClubName', ev.target.checked);
                    }} />} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel label={"Föreslå standardklubbar"} control={<Checkbox checked={this.props.item.useClubNameSuggestion} onChange={(ev) => {
                        this.props.onPropChanged('useClubNameSuggestion', ev.target.checked);
                    }} />} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel label={"Visa enbart för tjejer"} control={<Checkbox checked={this.props.item.onlyForWomen} onChange={(ev) => {
                        this.props.onPropChanged('onlyForWomen', ev.target.checked);
                    }} />} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel label={"Visa enbart för killar"} control={<Checkbox checked={this.props.item.onlyForMen} onChange={(ev) => {
                        this.props.onPropChanged('onlyForMen', ev.target.checked);
                    }} />} />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel label={"Kräver samtycke"} control={<Checkbox checked={this.props.item.requiresConsent} onChange={(ev) => {
                        this.props.onPropChanged('requiresConsent', ev.target.checked);
                    }} />} />
                </Grid>
                <Grid item xs={12}>
                    <Select label="Typ" value={this.props.item.type} onChange={(ev) => {
                        this.props.onPropChanged("type", ev.target.value as string);
                    }} fullWidth>
                        <MenuItem value="text">Text</MenuItem>
                        <MenuItem value="number">Heltal</MenuItem>
                        <MenuItem value="ssn">Personnummer</MenuItem>
                        <MenuItem value="choice">Enkelval</MenuItem>
                        <MenuItem value="multichoice">Flerval</MenuItem>
                        <MenuItem value="genderpicker">Könsdrivande fält</MenuItem>
                    </Select>
                </Grid>
                {isChoiceType(this.props.item) && this.renderChoice(this.props.item)}
                {this.props.item.type == "genderpicker" && this.renderGenderPicker(this.props.item)}
            </Grid>
        );
    }
}


export default (EditCustomField);