import { Checkbox, FormControlLabel } from "@material-ui/core";
import React from "react";

interface IProps {
    disabled?: boolean;
    propName: string;
    label: string;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    valueGetter?: () => any;
    onChange?: (handler: any) => (e: any) => void;
}

class FormCbxField extends React.Component<IProps> {

    render(): JSX.Element {
        const { disabled, propName, label, values, handleChange, handleBlur, valueGetter, onChange } = this.props;
        
        const changeImpl = onChange ? onChange(handleChange) : handleChange;

        return (
            <FormControlLabel
                control={
                    <Checkbox color="primary"
                        id={propName}
                        name={propName}
                        disabled={disabled}
                        checked={valueGetter ? valueGetter() : values[propName]}
                        onChange={changeImpl}
                        onBlur={handleBlur(propName)}
                    />
                }
                label={label}
            />
        );
    }
}

export default FormCbxField;
