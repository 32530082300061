/**
 * Trim form values recursively (objects, arrays and strings)
 * 
 * @param {T} values - Form values
 * @returns {T} Returns form values with trimmed strings
 */
export const trimFormValues = <T extends { [key: string]: any } | string>(values: T): T => {
    if (typeof values === 'string') {
        return values.trim() as unknown as T;
    }

    if (Array.isArray(values)) {
        return values.map(item => trimFormValues(item)) as unknown as T;
    }

    if (typeof values === 'object' && values !== null) {
        return Object.keys(values).reduce<T>((acc, key) => {
            const value = values[key];

            if (typeof value === 'string') {
                (acc[key as keyof T] as any) = value.trim();
            } else if (typeof value === 'object') {
                (acc[key as keyof T] as any) = trimFormValues(value);
            } else {
                (acc[key as keyof T] as any) = value;
            }

            return acc;
        }, {} as T);
    }

    return values;
};
