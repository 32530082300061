import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";

interface IProps {
    metadata: {key: string, value: string}[];
    onChangeValue: (key : string,val : any) => void;
}

const EditInsuranceDetails = ({ metadata, onChangeValue }: IProps) => {

    const render = () => {
        
        const renderField = (label: string, key: string) =>
        {
            return <>
             <Grid item xs={3}>{label}</Grid>
             <Grid item xs={9}><TextField fullWidth value={metadata?.find(x=>x.key==key)?.value} onChange={(ev) => onChangeValue(key,ev.target.value)}/></Grid>
            </>
        }

        return (<Grid container spacing={1}>
                {renderField("Förnamn","firstName")}
                {renderField("Efternamn","lastName")}
                {renderField("E-post","email")}
                {renderField("Telefon","phone")}
                {renderField("Personnummer","socialSecurityNumber")}
                {renderField("Address","address")}
                {renderField("Postnummer","postalCode")}
                {renderField("Ort","city")}
                {renderField("Land","country")}
                {renderField("Nationalitet","nationality")}
            </Grid>);
    };

    return render();
};

export default EditInsuranceDetails;
