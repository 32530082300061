import React from "react";
import { RouteChildrenProps, withRouter } from "react-router-dom";
import { Button, Card, CardActions, CardContent, CardHeader, CircularProgress, createStyles, Theme, withStyles, WithStyles } from "@material-ui/core";
import { ApiBackend } from "../../providers/apibackend";
import { Formik } from "formik";
import DirtyPageChecker from "../Common/DirtyPageChecker";
import { ProductCatalogue } from "../../model/ProductCatalogue";
import EditCatalogueFormContent from "./EditCatalogueFormContent";

interface IProps extends RouteChildrenProps<{ id: string }> {
}

interface IState {
    loading: boolean;
    catalogue: ProductCatalogue;
}

class EditCatalogue extends React.Component<IProps & WithStyles, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps & WithStyles) {
        super(props);
        
        this.api = new ApiBackend();

        this.state = {
            catalogue: null,
            loading: true
        };
    }

    async componentDidMount(): Promise<void> {
        await this.loadCatalogue();
    }

    render() {
        const { classes } = this.props;
        const { catalogue, loading } = this.state;
        const errorText = "Ett okänt fel har uppstått - ändringar är inte sparade.";
        const cardHeader = (): string => {
            if (catalogue.name) {
                return `Hantera ${catalogue.name}`;
            }

            return `Hantera ${catalogue.id}`
        };

        return (<>
            {loading &&
                <CircularProgress color="primary" />
            }
            {catalogue &&
                <Formik
                    initialValues={catalogue}
                    onReset={(values): void => this.setState({ catalogue: values })}
                    onSubmit={async (values, { resetForm }) => {
                        const response = await this.api.addOrUpdateProductCatalogue(values);

                        if (response) {
                            this.setState({
                                catalogue: values
                            }, () => {
                                resetForm({
                                    values: values
                                });
                            });
                        } else {
                            alert(`${errorText}\n\nFick felkod från server`);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (
                            <DirtyPageChecker isDirty={dirty} getTitle={this.getTitle} {...this.props}>
                                <form autoComplete="off" onSubmit={handleSubmit}>
                                    <Card>
                                        <CardHeader className={classes.cardHeader} title={cardHeader()} />
                                        <CardContent className={classes.form}>
                                            <EditCatalogueFormContent {...formik} classes={classes} />
                                        </CardContent>
                                        <CardActions>
                                            <Button type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                            <Button style={{ marginLeft: 10 }} color="secondary" disabled={!dirty} variant="contained" onClick={handleReset}>Avbryt</Button>
                                        </CardActions>
                                    </Card>
                                </form>
                            </DirtyPageChecker>
                        )
                    }}
                </Formik>
            }
        </>);
    }

    private loadCatalogue = async (): Promise<void> => {
        const catalogue = await this.api.getProductCatalogue(this.props.match.params.id);
        this.setState({
            loading: false,
            catalogue: catalogue
        });
    };

    private getTitle = (): string | undefined => {
        const { catalogue } = this.state;

        if (catalogue?.name) {
            return `Katalog: ${catalogue.name}`;
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(EditCatalogue));
