import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { PointOfSale } from "../../model/PointOfSale";
import { ApiBackend } from "../../providers/apibackend";
import ClearableSelect from "../Common/ClearableSelect";

class Props {
    initialValue?: string;
    clearable?: boolean;
    onChange: (pointOfSaleId: string, pointOfSale?: PointOfSale) => void;
}

const SelectPointOfSale = ({ initialValue, clearable, onChange }: Props) => {
    const [pointOfSale, setPointOfSale] = useState(initialValue ?? "");
    const [pointOfSales, setPointOfSales] = useState(null as PointOfSale[]);

    useEffect(() => {
        new ApiBackend().listPointOfSales().then((res) => {
            res.sort((x, y) => x.name < y.name ? -1 : 1);
            setPointOfSales(res);
        });
    }, []);

    return (
        <ClearableSelect
            value={pointOfSale}
            clearable={clearable}
            onChange={(ev) => {
                const posId = ev.target.value as string;
                const pos = pointOfSales.find(x => x.id === posId);
                setPointOfSale(posId);
                onChange(posId, pos);
            }}
            onClear={() => {
                setPointOfSale("");
                onChange("", null);
            }}
        >
            {pointOfSales && pointOfSales.map(x => {
                return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
            })}
        </ClearableSelect>
    )
}

export default SelectPointOfSale;
