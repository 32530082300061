import * as yup from "yup";

// Tillfälligt lånat från https://github.com/dockwa/simple-react-validator
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const mandatoryMessage = "Obligatoriskt";
const invalidEmailMessage = "Felaktig epost-adress";

const editB2bCompanyAdminsValidationSchema = yup
    .object()
    .shape(
        {
            "firstName": yup
                .string()
                .required(mandatoryMessage),
            "lastName": yup
                .string()
                .required(mandatoryMessage),
            "email": yup
                .string()
                .email(invalidEmailMessage)
                .required(mandatoryMessage),
            "mobilePhone": yup
                .string()
                .notRequired()
                .nullable()
                .matches(phoneRegExp, "Felaktigt telefonnummer")
        }
    );

const editB2bCompanyValidationSchema = yup
    .object()
    .shape(
        {
            "name": yup
                .string()
                .required(mandatoryMessage),
            "description": yup
                .string()
                .notRequired()
                .nullable(),
            "isArchived": yup
                .boolean()
                .required(mandatoryMessage),
            "code": yup
                .string()
                .notRequired()
                .max(36, "Koden får max vara 36 tecken")
                .nullable(),
            "registrationLink": yup
                .string()
                .notRequired()
                .nullable(),
            "orgNumber": yup
                .string()
                .notRequired()
                .nullable(),
            "address": yup
                .string()
                .notRequired()
                .nullable(),
            "postCode": yup
                .string()
                .notRequired()
                .nullable(),
            "city": yup
                .string()
                .notRequired()
                .nullable(),
            "country": yup
                .string()
                .notRequired()
                .nullable(),
            "email": yup
                .string()
                .email(invalidEmailMessage)
                .notRequired()
                .nullable(),
            "reference": yup
                .string()
                .notRequired()
                .nullable(),
            "status": yup
                .string()
                .required(mandatoryMessage),
            "admins": yup
                .array()
                .of(editB2bCompanyAdminsValidationSchema)
                .nullable()
        });

export const createB2bCompanyValidationSchema = yup
    .object()
    .shape(
        {
            "name": yup
                .string()
                .required(mandatoryMessage),
            "description": yup
                .string()
                .notRequired()
                .nullable(),
            "orgNumber": yup
                .string()
                .required()
                .nullable(),
            "address": yup
                .string()
                .required()
                .nullable(),
            "postCode": yup
                .string()
                .required()
                .nullable(),
            "city": yup
                .string()
                .required()
                .nullable(),
            "country": yup
                .string()
                .required()
                .nullable(),
            "email": yup
                .string()
                .email(invalidEmailMessage)
                .required()
                .nullable(),
            "reference": yup
                .string()
                .required()
                .nullable()
        });

export default editB2bCompanyValidationSchema;
