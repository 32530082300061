/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogTitle, DialogActions, LinearProgress, DialogContent, IconButton } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import uuid from 'react-uuid';
import { CustomField } from '../../model/CustomField';
import EditCustomField from './EditCustomField';
import CheckCircle from '@material-ui/icons/CheckCircle';
import withConfirm, { IConfirmContext } from '../Common/dialog/Confirm';
import withToaster, { IToasterContext } from '../Common/Toaster';

interface State {
    loading: boolean;
    fields: CustomField[],
    addItem: CustomField;
    editItem: CustomField;
}

class ManageFields extends React.Component<RouteComponentProps & IConfirmContext & IToasterContext, State> {
    static contextType = FirebaseContext;
    constructor(props: RouteComponentProps & IConfirmContext & IToasterContext) {
        super(props);
        this.state = { loading: true, addItem: null, editItem: null, fields: null };
    }

    componentDidMount() {
        this.setState({ loading: true });
        const back = new ApiBackend();

        back.listCustomFields().then((fields) => {
            this.setState({ fields: fields, loading: false });
        });
    }

    private addField = () => {
        this.setState({
            addItem: {
                id: uuid(),
                name: 'Nytt fält',
                label: 'Fält 1',
                label_en: 'Field 1',
                description: '',
                description_en: '',
                type: 'text',
                validation_type: '',
                handleAsClubName: false,
                useClubNameSuggestion: false,
                validvalues: null,
                onlyForMen: false,
                onlyForWomen: false,
                requiresConsent: false
            }
        });
    };

    private editField = (field: CustomField) => () => {
        this.setState({ editItem: field });
    };

    private handleDeleteField = async (field: CustomField) => {
        const confirmResult = await this.props.showConfirm(`Du håller på att ta bort fältet: ${field.name}. Vill du fortsätta?`);

        if (!confirmResult) {
            return;
        }

        this.setState({ loading: true });
        try {
            const back = new ApiBackend();
            back.deleteCustomField(field.id).then((res) => {
                if (res) {
                    back.listCustomFields().then((fields) => {
                        this.setState({ fields: fields });
                    });
                }
                else {
                    this.props.showToast(`Kunde inte ta bort fältet: ${field.name}`, "error");
                }
            });
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes } = this.props as any;
        return <Fragment>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Fältdefinitioner" />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }

                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={3}>
                                    <Button variant="contained" onClick={this.addField}>Lägg till fält</Button>
                                </Grid>
                                <Grid item xs={5}>
                                </Grid>
                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" >
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Namn</StyledTableCell>
                                                    <StyledTableCell>Label</StyledTableCell>
                                                    <StyledTableCell>Label EN</StyledTableCell>
                                                    <StyledTableCell>Typ</StyledTableCell>
                                                    <StyledTableCell>Validering</StyledTableCell>
                                                    <StyledTableCell>Klubbnamn?</StyledTableCell>
                                                    <StyledTableCell>Klubblista?</StyledTableCell>
                                                    <StyledTableCell></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.state && this.state.fields && this.state.fields.map((x, idx) => {
                                                    return <TableRow key={idx}>
                                                        <StyledTableCell>{x.name}</StyledTableCell>
                                                        <StyledTableCell>{x.label}</StyledTableCell>
                                                        <StyledTableCell>{x.label_en}</StyledTableCell>
                                                        <StyledTableCell>{x.type}</StyledTableCell>
                                                        <StyledTableCell>{x.validation_type}</StyledTableCell>
                                                        <StyledTableCell>{x.handleAsClubName ? <CheckCircle /> : ""}</StyledTableCell>
                                                        <StyledTableCell>{x.useClubNameSuggestion ? <CheckCircle /> : ""}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <IconButton color="default" size="small"
                                                                onClick={this.editField(x)

                                                                }>
                                                                <EditIcon fontSize="medium" />
                                                            </IconButton>
                                                            <IconButton color="default" size="small"
                                                                onClick={async () => {
                                                                    await this.handleDeleteField(x)
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="medium" />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.addItem && this.renderAddFieldDialog()}
            {this.state && this.state.editItem && this.renderEditFieldDialog()}
        </Fragment>;
    }

    private renderAddFieldDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Lägg till fält</DialogTitle>
            <DialogContent dividers>
                <EditCustomField item={this.state.addItem}
                    onPropChanged={(prop, val) => {
                        let ai = this.state.addItem;
                        if (prop == "type" && val == "genderpicker") {
                            ai.validvalues = [
                                { value: "M", label: "Herrar", label_en: "Men" },
                                { value: "W", label: "Damer", label_en: "Women" },
                                { value: "O", label: "Mixed", label_en: "Mixed" }
                            ];
                        }
                        (ai as any)[prop] = val;

                        this.setState({ addItem: ai });
                    }}
                />
                {this.state.loading &&
                    <LinearProgress color="secondary" />
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleAddOk} color="primary">
                    Skapa
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private renderEditFieldDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="sm"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Editera fält</DialogTitle>
            <DialogContent dividers>
                <EditCustomField item={this.state.editItem}
                    onPropChanged={(prop, val) => {
                        let ai = this.state.editItem;
                        (ai as any)[prop] = val;
                        this.setState({ editItem: ai });
                    }}
                />
                {this.state.loading &&
                    <LinearProgress color="secondary" />
                }
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleAddCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleEditOk} color="primary">
                    Spara
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private handleAddCancel = () => {
        this.setState({ addItem: null, editItem: null });
    };

    private handleAddOk = () => {
        this.setState({ loading: true });
        const back = new ApiBackend();
        back.addCustomField(this.state.addItem).then((res) => {
            back.listCustomFields().then((res) => {
                this.setState({ fields: res, loading: false, addItem: null });
            })
        })
    };

    private handleEditOk = () => {
        this.setState({ loading: true });
        const back = new ApiBackend();
        back.updateCustomField(this.state.editItem).then((res) => {
            back.listCustomFields().then((res) => {
                this.setState({ fields: res, loading: false, editItem: null });
            })
        })
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withConfirm(withToaster(withStyles(useStyles)(ManageFields))));
