import React from "react";
import { TextField } from "@material-ui/core";
import { FormikErrors, FormikTouched } from "formik";

interface IProps {
    disabled?: boolean;
    propName: string;
    label: string;
    inputProps?: any;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
    valueFormatter?: () => any;
    onChange?: (handler: any) => (e: any) => void;
}

class FormDateField extends React.Component<IProps> {

    render(): JSX.Element {
        const { disabled, propName, label, inputProps, values, touched, errors, handleChange, handleBlur, valueFormatter, onChange } = this.props;
        const changeImpl = onChange ? onChange(handleChange) : handleChange;

        return (
            <TextField
                id={propName}
                disabled={disabled}
                style={{ flex: 1, margin: '0 3px' }}
                type="date"
                inputProps={inputProps}
                label={label}
                InputLabelProps={{ shrink: true }}
                value={valueFormatter ? valueFormatter() : values[propName]}
                fullWidth
                onChange={changeImpl}
                onBlur={handleBlur(propName)}
                error={touched[propName] && Boolean(errors[propName])}
                helperText={touched[propName] && errors[propName]}
            />
        )
    }
}

export default FormDateField;
