import { CustomField } from "../../../model/CustomField";
import { ProductVariantCustomField } from "../../../model/ProductVariantCustomField";

export type ValueType = {
    definition: CustomField;
    value: ProductVariantCustomField;
}

export const getInitialValue = (defs: CustomField[], values: ProductVariantCustomField[]): ValueType[] => {

    return values.map(v => {
        return {
            definition: defs.find(x => x.id === v.customFieldId),
            value: v
        }
    });
};

export const getAvailable = (defs: CustomField[], values: ProductVariantCustomField[]): CustomField[] => {
    return defs.filter(x => !values.some(v => v.customFieldId === x.id));
};
