import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { ExternalSeedingRace } from '../../model/ExternalSeedingRace';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Checkbox, FormControlLabel, LinearProgress, Link } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import { ExternalSeedingResult } from '../../model/ExternalSeedingResult';
import { SeedingTable } from '../../model/SeedingTable';
import { Product } from '../../model/Product';
import { parseString } from "xml2js";
import { isArray } from 'lodash';

class Props {
    externalSeedingRace : ExternalSeedingRace;
    close: () => void;
}


const ExternalSeedingRaceUploadResult = ({externalSeedingRace, close} : Props) => {

    const [fileType, setFileType] = useState('xml');

    const [step, setStep] = useState('loadfile' as 'loadfile'|'selectcolumns'|'selectclasses'|'confirm');
    const [loading, setLoading] = useState(false);
    const [columns, setColumns] = useState(null as string[]);
    const [rows, setRows] = useState(null as string[]);
    const [columnVasaId, setColumnVasaId] = useState(null as string);
    const [columnFirstName, setColumnFirstName] = useState(null as string);
    const [columnFisCode, setColumnFisCode] = useState(null as string);
    const [columnLastName, setColumnLastName] = useState(null as string);
    const [columnClass, setColumnClass] = useState(null as string);
    const [columnTime, setColumnTime] = useState(null as string);
    const [columnStatus, setColumnStatus] = useState(null as string);
    const [classes, setClasses] = useState(null as string[]);
    const [importClasses, setImportClasses] = useState(null as string[]);
    const [results, setResults] = useState(null as ExternalSeedingResult[]);
    const [showOnlyErrors, setShowOnlyErrors] = useState(false);
    const [seedingTables, setSeedingTables] = useState(null as SeedingTable[]);
    const [compareSeedingTable, setCompareSeedingTable] = useState(null as string);

    useEffect(() => {
        let backend = new ApiBackend();
        Promise.all([backend.listProducts(),backend.listSeedingTables()]).then((values: [Product[], SeedingTable[]]) => {
            let tablesToAdd = [] as SeedingTable[];
            const resTables = values[1];
            const resProducts = values[0];
            for (let i = 0; i < resTables.length; i++)
            {
                if (resTables[i].races.some(x=>x.raceId == externalSeedingRace.id))
                {
                    //Does this table have this race?
                    tablesToAdd.push(resTables[i]);
                }
            }
            setSeedingTables(tablesToAdd);
            if (tablesToAdd.length > 0)
            {
                setCompareSeedingTable(tablesToAdd[0].id);
            }
        });

    },[]);


    useEffect(() => {
        if (columns){
            trySetColumns();
        }
    },[columns]);

    const renderSelectColumns = () => {
        return <Fragment>
                <Grid item xs={6}>Kolumn för VASAID</Grid>
                <Grid item xs={6}><Select native value={columnVasaId} onChange={(ev) => {setColumnVasaId(ev.target.value as string);}}>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={6}>Kolumn för FIS-Kod</Grid>
                <Grid item xs={6}><Select native value={columnFisCode} onChange={(ev) => {setColumnFisCode(ev.target.value as string);}}>
                    <option value=""></option>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={6}>Kolumn för Förnamn</Grid>
                <Grid item xs={6}><Select native value={columnFirstName} onChange={(ev) => {setColumnFirstName(ev.target.value as string);}}>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={6}>Kolumn för Efternamn</Grid>
                <Grid item xs={6}><Select native value={columnLastName} onChange={(ev) => {setColumnLastName(ev.target.value as string);}}>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={6}>Kolumn för Klass</Grid>
                <Grid item xs={6}><Select native value={columnClass} onChange={(ev) => {setColumnClass(ev.target.value as string);}}>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={6}>Kolumn för Tid</Grid>
                <Grid item xs={6}><Select native value={columnTime} onChange={(ev) => {setColumnTime(ev.target.value as string);}}>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={6}>Kolumn för Status</Grid>
                <Grid item xs={6}><Select native value={columnStatus} onChange={(ev) => {setColumnStatus(ev.target.value as string);}}>
                    {columns.map((c) => {
                        return <option value={c}>{c}</option>
                    })}
                </Select></Grid>
                <Grid item xs={12}>

                <Button variant="contained" onClick={() => {
                        setStep("loadfile");
                    }}>Tillbaka</Button>

                    <Button variant="contained" onClick={() => {
                        setClassesFromRows(columns.indexOf(columnClass), rows);
                        setStep("selectclasses");
                    }}>Nästa</Button>
                </Grid>
            </Fragment>

    }

    
    const renderSelectClasses = () => {
        return <Fragment>
                <Grid item xs={6}>Klasser att importera</Grid>
                <Grid item xs={6}>
                    {classes.map((c) => {
                        return <FormControlLabel label={c} control={<Checkbox checked={importClasses.indexOf(c) >= 0}
                        onChange={(ev) => {
                            if (ev.target.checked)
                            {
                                importClasses.push(c);
                                setImportClasses([...importClasses]);
                            }
                            else 
                            {
                                const newimportClasses = importClasses.filter(x=>x != c);
                                setImportClasses(newimportClasses);
                            }
                        }}/>}/>
                    })}
                     </Grid>
                
                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => {
                        if (fileType == "xml")
                        {
                            setStep("loadfile");
                        }
                        else 
                        {
                            setStep("selectcolumns");
                        }
                    }}>Tillbaka</Button>
                    <Button variant="contained" onClick={() => {

                        let idxFirstName = columns.indexOf(columnFirstName);
                        let idxLastName = columns.indexOf(columnLastName);
                        let idxVasaId = columns.indexOf(columnVasaId);
                        let idxTime = columns.indexOf(columnTime);
                        let idxCLass = columns.indexOf(columnClass);
                        let idxStatus = columns.indexOf(columnStatus);
                        let idxFisCode = columnFisCode ? columns.indexOf(columnFisCode) : -1;
                        let results = [] as ExternalSeedingResult[];
                        for (let i = 0; i < rows.length; i++)
                        {
                            let parts = rows[i].split(/[,;]/);
                            let firstName = parts[idxFirstName];
                            let lastName = parts[idxLastName];
                            let vasaId = parts[idxVasaId];
                            let className = parts[idxCLass];
                            let status = parts[idxStatus];
                            let time = parts[idxTime];
                            let fisCode = idxFisCode >= 0 ? parts[idxFisCode] : null;

                            if (importClasses.indexOf(className) >= 0)
                            {                             
                                results.push({
                                firstName: firstName,
                                lastName: lastName,
                                firstNameVasa: null,
                                lastNameVasa: null,
                                vasaId: vasaId,
                                result: time,
                                resultMs: 0,
                                status: status,
                                fisCode: fisCode,
                                note: undefined,
                                warn: undefined,
                                error: undefined
                                });
                            }
                        }

                        setLoading(true);
                        let back = new ApiBackend();
                        back.prepareExternalSeedingResults(externalSeedingRace.year, externalSeedingRace.id, results).then((resp) => {
                            setResults(resp);
                            setLoading(false);
                            setStep("confirm");
                        });
                    }}>Nästa</Button>
                </Grid>
            </Fragment>

    }

    const renderConfirm = () => {

        const compareTable = compareSeedingTable ? seedingTables.find(x=>x.id == compareSeedingTable) : null;
        const compareRace = compareTable ? compareTable.races.find(x=>x.raceId == externalSeedingRace.id) : null;

        const getCalculatedGroup = (res : ExternalSeedingResult) => {

                if (!(compareRace && res.resultMs > 0))
                {
                    return null;
                }

                let group = null;
                for (let i = compareRace.startGroupLimits.length-1; i >= 0; i--)
                {
                    if (res.resultMs < compareRace.startGroupLimits[i])
                    {
                        group = i;
                    }
                }

                return group;
        };

        return <Fragment>
                <Grid item xs={12}><Checkbox checked={showOnlyErrors} onChange={(ev ) => {setShowOnlyErrors(ev.target.checked);}}/> Visa enbart fel och varningar</Grid>
                <Grid item xs={12}>Visa led i tabell <Select native value={compareSeedingTable} onChange={(ev) => {setCompareSeedingTable(ev.target.value as string)}}>
                    <option value=""></option>
                    {seedingTables && seedingTables.map((x) => {
                        return <option value={x.id}>{x.name}</option>
                    })}
                    </Select></Grid>
                <Grid item xs={12}>Resultat att importera</Grid>
                        <Grid item xs={1}><b>VASA-ID</b></Grid>
                        <Grid item xs={1}><b>FIS-kod</b></Grid>
                        <Grid item xs={2}><b>Namn i fil</b></Grid>
                        <Grid item xs={3}><b>Namn hos vasaloppet</b></Grid>
                        <Grid item xs={1}><b>Resultat</b></Grid>
                        <Grid item xs={2}><b>Fel?</b></Grid>
                        <Grid item xs={1}><b>Ger led i tabell</b></Grid>
                        <Grid item xs={1}></Grid>
                
                    {results && results.map((r,idx) => {

                        if (showOnlyErrors)
                        {
                            if (!r.error && !r.warn)
                            {
                                return;
                            }
                        }

                        let bg = "";
                        if (r.error)
                        {
                            bg = "#FF0000";
                        }
                        else if (r.warn) {
                            bg = "#FFFF00";
                        }
                        else if (r.note)
                        {
                            bg = "#EEEEEE";
                        }
                        
                        return <>
                        <Grid style={{background: bg}} item xs={1}>{r.vasaId}</Grid>
                        <Grid style={{background: bg}} item xs={1}>{r.fisCode}</Grid>
                        <Grid style={{background: bg}} item xs={2}>{r.firstName} {r.lastName}</Grid>
                        <Grid style={{background: bg}} item xs={3}>{r.firstNameVasa} {r.lastNameVasa}</Grid>
                        <Grid style={{background: bg}} item xs={1}>{r.result}</Grid>
                        <Grid style={{background: bg}} item xs={2}>{r.note}</Grid>
                        <Grid style={{background: bg}} item xs={1}>{getCalculatedGroup(r)}</Grid>
                        <Grid style={{background: bg}} item xs={1}><Link style={{textDecoration: 'underline', color:"#000000"}} onClick={() => {
                            results.splice(idx,1);
                            setResults([...results]);
                        }}>Ta bort</Link></Grid>
                        </>
                    })}
                
                <Grid item xs={12}>
                    <Button variant="contained" onClick={() => {
                        setStep("selectclasses");
                    }}>Tillbaka</Button>
                    <Button variant="contained" onClick={() => {
                         setLoading(true);
                         let back = new ApiBackend();
                         back.importExternalSeedingResults(externalSeedingRace.year, externalSeedingRace.id, results).then((resp) => {
                            setLoading(false);
                            if (!resp)
                            {
                                alert("Kunde inte importera resultat, ett fel uppstod!");
                            }
                            else 
                            {                             
                                alert("Resultat har lagrats!");
                                close();
                            }
                         });
                       
                    }}>Importera</Button>
                </Grid>
            </Fragment>

    }

    const parseCSV = (data : string) => {
        let rows = data.split("\r\n");
        let  header = rows[0];
        if (fileType=="csvallok")
        {
            header += ";status;klass";
        }

        setColumns(header.split(/[,;]/));
         rows.splice(0,1);
         rows = rows.filter((x)=>{return x.trim() != ""});

         if (fileType=="csvallok")
         {
            for (let i = 0; i < rows.length; i++)
            {
                rows[i] +=";OK;ImportKlass";
            }
         }

         setRows(rows);
        
        setLoading(false);
        setStep("selectcolumns");
    }

    const parseXMLResultNode = (resultNode: any)=>
    {
        let results : string[] = [];
        if (resultNode.CC_race && resultNode.CC_race.CC_classified)
        {
            const classified = resultNode.CC_race.CC_classified;
            for (let i = 0; i < classified.CC_ranked.length; i++)
            {
                const ranked = classified.CC_ranked[i];
                if (!ranked.Competitor)
                {
                    continue;
                }
                results.push(ranked.Competitor.VasaId + ";" + ranked.Competitor.Firstname +";" + ranked.Competitor.Lastname + ";OK;" + ranked.CC_result.Totaltime + ";" + resultNode.EntryClass._ + " ("  + resultNode.EntryDiscipline.$.Distance + ")");
            }   
        }
        return results;
    }

    const setClassesFromRows = (idxClassColumn : number, rows: string[]) => {
        const classMap = new Map<string,string>();
        for (let i = 0; i < rows.length; i++)
        {
            let parts = rows[i].split(/[,;]/);
            let cl = parts[idxClassColumn];
            if (!classMap.has(cl))
            {
                classMap.set(cl,cl);
            }
        }
        const allClasses = Array.from(classMap,([name,value])  => value);
        setClasses(allClasses);
        setImportClasses(allClasses);
    }
    
    const parseXML = (data : string) => {

        parseString(data, {explicitArray: false}, (error, xml) => {
            if (!xml.SSFResults)
            {
                alert("Kan inte tolka XML-fil!");
                setLoading(false);
                return;
            }
            let results : string[] = [];
            if (isArray(xml.SSFResults.Result))
            {
                for (let i = 0; i < xml.SSFResults.Result.length; i++)
                {
                    results.push(...parseXMLResultNode(xml.SSFResults.Result[i]));
                }
            }
            else 
            {
                results.push(...parseXMLResultNode(xml.SSFResults.Result));
            }
            setLoading(false);

            setColumns(["vasaid","firstname","lastname","status","time","klass"]);
            setRows(results);
            setClassesFromRows(5, results);
            
            setStep("selectclasses");

            
        });
    }

    const renderChooseFile = () => {
        return <>
        <Grid item xs={6}>
                Typ av fil
            </Grid>
            <Grid item xs={6}>
                <Select native value={fileType} onChange={(ev) => {setFileType(ev.target.value as string);}}>
                    <option value="xml">XML</option>
                    <option value="csv">CSV</option>
                    <option value="csvallok">CSV utan klass, alla OK</option>
                </Select>
            </Grid>

            <Grid item xs={6}>
                Välj fil
            </Grid>
            <Grid item xs={6}>
                        <input
            accept={fileType == "xml" ? "text/xml" : "text/csv"}
            style={{ display: 'none' }}
            id="raised-button-file"
            type="file"
            onChange={(ev) => {
                setLoading(true);
                let file = ev.target.files[0];
                const reader = new FileReader();
                reader.addEventListener('load', (event) => {
                    if (fileType == "xml")
                    {
                        parseXML(event.target.result as string);
                    }
                    else 
                    {
                        parseCSV(event.target.result as string);
                    }
                });
                reader.readAsText(file);
            }}
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                    Välj fil
                </Button>
            </label> 
            </Grid>
        </>;
    }

    const findCol = (names: string[]) =>{
        let name = null;
        for (let i = 0; i < names.length; i++)
        {
            let id = columns.findIndex(x=>x.toLowerCase() == names[i].toLowerCase());
            if (id >= 0)
            {
                
                name = columns[id];
                break;
            }
        }
        return name;
    }

    const trySetColumns = () => {
        setColumnVasaId(findCol(["vasaid","vasa-id"]));
        setColumnFirstName(findCol(["förnamn","fornavn","firstname"]));
        setColumnLastName(findCol(["efternamn","etternavn","lastname","familyname"]));
        setColumnStatus(findCol(["status"]));
        setColumnTime(findCol(["tid","time"]));
        setColumnClass(findCol(["bana","klass","klasse","class"]));
        setColumnFisCode(findCol(["fiscode","fiskod"]));
    }

    return <Fragment>
        <Grid container spacing={2}>
            

            {loading && <Grid item xs={12}>
                <LinearProgress color="secondary"/>
            </Grid>}
            {step == "loadfile" && renderChooseFile()}
            {step == "selectcolumns" && renderSelectColumns()}
            {step == "selectclasses" && renderSelectClasses()}
            {step == "confirm" && renderConfirm()}



        </Grid>
    </Fragment>;

}
export default ExternalSeedingRaceUploadResult;
