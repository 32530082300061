import React from "react";
import VasaloppetTooltip from "../../Common/VasaloppetTooltip";
import { Avatar, Box } from "@material-ui/core";
import { StartGroup } from "../../../model/StartGroup";
import { getAvailabilityTitle, getStartGroupSexAvailability } from "./utils";

interface Props {
    startGroup: StartGroup;
}

const StartGroupSexAvailabilityIcon = ({ startGroup }: Props) => {

    const sexAvailability = getStartGroupSexAvailability(startGroup);

    const getAvatarText = () => {
        switch (sexAvailability) {
            case "W":
                return "W";
            case "M":
                return "M";
            default:
                return "A";
        }
    };

    return (
        <VasaloppetTooltip title={getAvailabilityTitle(sexAvailability)}>
            <Box display="inline-flex" justifyContent="center" alignItems="center" style={{ verticalAlign: "middle", padding: 3 }}>
                <Avatar style={{ width: 16, height: 16, padding: 5 }}>{getAvatarText()}</Avatar>
            </Box>
        </VasaloppetTooltip>
    );
};

export default StartGroupSexAvailabilityIcon;
