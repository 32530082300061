import { createStyles, Grid, TextField, Theme, withStyles, WithStyles } from '@material-ui/core';
import React from 'react';

interface Props {
    onTextChanged: (text: string) => void;
    text: string;
}

class ManageOrderEditItemsRepay extends React.Component<Props & WithStyles> {
    constructor(props: Props & WithStyles) {
        super(props);
    }

    render() {
        return <Grid container spacing={2}>
            <Grid item xs={6}>Anledning till återbetalning</Grid>
            <Grid item xs={6}><TextField fullWidth value={this.props.text} onChange={(ev) => {
                this.props.onTextChanged(ev.target.value);
            }} />
            </Grid>
        </Grid>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ManageOrderEditItemsRepay);
