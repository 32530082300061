import { Box, Button, Card, CardHeader, IconButton } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { FieldArrayRenderProps, FormikProps } from "formik";
import React from "react";
import { B2bCompany, B2bCompanyProduct } from "../../../../model/B2bCompany";
import FormCbxField from "../../../Common/FormCbxField";
import EditB2bCompanyCustomProductDialog from "./EditB2bCompanyCustomProductDialog";
import EditIcon from '@material-ui/icons/Edit';
import { Product } from "../../../../model/Product";
import { vasaloppetDateGetterFormatter } from "../../../../utilities/date";
import AddCompanyEntriesDialog from "../../../Entries/Entry/AddEntryDialog/AddCompanyEntriesDialog";

interface IProps extends FormikProps<B2bCompany> {
    idx: number;
    product: B2bCompanyProduct;
    sourceProduct: Product;
    aggregationGroupValues: string[];
    arrayHelpers: FieldArrayRenderProps;
    onDelete: (idx: number) => void;
    onSave: (product: B2bCompanyProduct) => void;
}

interface IState {
    editProduct: B2bCompanyProduct;
    addEntries: boolean;
}

class EditB2bCompanyCustomProduct extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            editProduct: null,
            addEntries: false
        };
    }

    render(): JSX.Element {
        const { values, product, aggregationGroupValues, idx, arrayHelpers } = this.props;

        return (
            <Card style={{ marginTop: 10 }}>
                <CardHeader
                    title={this.cardTitle()}
                    subheader={this.cardSubHeader()}
                    action={<>
                        <FormCbxField {...this.props}
                            propName={`products[${idx}].isActive`}
                            label="Aktiv"
                            valueGetter={() => product.isActive} />
                        <IconButton
                            onClick={() => this.editProduct(product)}>
                            <EditIcon />
                        </IconButton>
                        <Button color="secondary" variant="text"
                            onClick={async () => {
                                this.setState({ addEntries: true })
                            }}>Lägg till anmälningar
                        </Button>
                        <Box style={{ display: "flex", justifyContent: "end" }}>
                            {idx > 0 &&
                                <ExpandLess style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        arrayHelpers.move(idx, idx - 1);
                                    }}
                                />
                            }
                            {idx < values.products.length - 1 &&
                                <ExpandMore style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        arrayHelpers.move(idx, idx + 1);
                                    }}
                                />
                            }
                        </Box>
                    </>}
                />
                {this.state.editProduct && <div>
                    <EditB2bCompanyCustomProductDialog {...this.props}
                        productIdx={this.props.idx}
                        product={this.state.editProduct}
                        aggregationGroupValues={aggregationGroupValues}
                        startGroups={this.props.sourceProduct?.startGroups}
                        onSave={(product: B2bCompanyProduct) => {
                            this.props.onSave(product);
                            this.resetEditProduct();
                        }}
                        onClose={() => {
                            this.resetEditProduct();
                        }}
                        onDelete={() => {
                            this.props.onDelete(idx);
                            this.resetEditProduct();
                        }}
                    />
                </div>
                }
                {this.state && this.state.addEntries &&
                    <AddCompanyEntriesDialog
                        company={values}
                        companyProduct={product}
                        onAbort={() => {
                            this.setState({ addEntries: false });
                        }}
                        onSave={() => {
                            this.setState({ addEntries: false });
                        }}
                    />
                }
            </Card>
        )
    }

    private editProduct = (product: B2bCompanyProduct): void => {
        this.setState({
            editProduct: product
        });
    }

    private resetEditProduct = (): void => {
        this.setState({
            editProduct: null
        });
    }

    private cardTitle = (): string => {
        const product = this.props.product;
        let desc = product.name ? product.name : "Produkt";

        if (product.description) {
            desc += " - ";
            desc += (product.description.length > 40) ? product.description.slice(0, 40 - 1) + '...' : product.description;
        }

        return desc;
    };

    private cardSubHeader = (): JSX.Element => {
        const { product, sourceProduct } = this.props;

        const startGroups = sourceProduct?.startGroups;
        const sourceProductVariant = sourceProduct?.variants?.find(x => x.Id === product.productVariantId);
        const sourceProductVariantPriceGroup = sourceProductVariant?.priceGroups?.find(x => x.id === product.productVariantPriceGroupId);

        const sourceProductName = sourceProduct?.name ?? "SAKNAS";
        const sourceProductVariantName = sourceProductVariant?.Name ?? "SAKNAS";
        const sourceProductVariantPriceGroupName = sourceProductVariantPriceGroup?.Name ?? "SAKNAS";

        let desc = `produkt: ${sourceProductName}, variant: ${sourceProductVariantName}, prisgrupp: ${sourceProductVariantPriceGroupName}`;

        const append = (str: string) => {
            if (desc) {
                desc += ", ";
            }

            desc += str;
        };

        if (product.validFrom) {
            append(`från: ${vasaloppetDateGetterFormatter(product.validFrom)}`);
        }

        if (product.validTo) {
            append(`till: ${vasaloppetDateGetterFormatter(product.validTo)}`);
        }

        if (product.maxNumRegistrations) {
            append(`max-antal: ${product.maxNumRegistrations}`);
        }

        if (product.enforcedStartGroupId && startGroups) {
            const startGroup = startGroups.find(x => x.id === product.enforcedStartGroupId);
            if (startGroup) {
                append(`startgrupp: ${startGroups.find(x => x.id === product.enforcedStartGroupId).name}`);
            }
        }

        if (product.extraFields && product.extraFields.length) {
            append(`extra-fält: ${product.extraFields.length} st`);
        }

        if (product.b2bInvoice) {
            append("Ska faktureras");
        }

        const numEntries = `Antal anmälda: ${product.numRegistred}, Anmälda Agg.grupp: ${product.aggregationGroupNumRegistered}`;

        return <p>{desc}<br />{numEntries}</p>;
    };
}

export default EditB2bCompanyCustomProduct;
