/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { svSE } from "@material-ui/core/locale";
import { MuiThemeProvider, createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import * as React from "react";
import * as ReactDOM from "react-dom";
import MainPage from "./components/MainPage";
import { ExpoContextProvider } from "./contexts/ExpoContext";

let theme = createTheme({
    palette: {
        primary: {
            light: '#E53037',
            main: '#FFFFFF',
            dark: '#2772b6',
            contrastText: '#000',
        },
        secondary: {
            light: '#FF0000',
            main: '#2772b6',
            dark: '#DDD700',
            contrastText: '#FFF',
        },
        type: "light"
    },
    overrides: {
        MuiIconButton: {
            colorPrimary: {
                color: '#2772b6',
                fill: '#2772b6',
            },
            colorSecondary: {
                color: '#000000',
                fill: '#000000'
            }
        },
        MuiCheckbox: {
            colorPrimary: {
                '&$checked': {
                    color: '#2772b6',
                    fill: '#2772b6'
                }
            },
            colorSecondary: {
                '&$checked': {
                    color: '#000000',
                    fill: '#000000'
                }
            }
        }, MuiTooltip: {
            tooltip: {
                backgroundColor: "#f5f5f9",
                color: "rgba(0, 0, 0, 0.87)",
                maxWidth: 220,
                fontSize: 12,
                border: "1px solid #dadde9",
            }

        }
    }
}, svSE);
theme = responsiveFontSizes(theme);

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <ExpoContextProvider>
            <MainPage />
        </ExpoContextProvider>
    </MuiThemeProvider>
    , document.getElementById("main")
);