/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent, Theme, withStyles, createStyles, Grid, LinearProgress, Typography, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { AddOnSummaryItem } from '../../model/Order';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import RefreshIcon from '@material-ui/icons/Refresh';
import ProductList from '../Common/ProductList/ProductList';
import { DATE_FORMAT, FILE_FORMAT, vasaloppetMoment } from '../../utilities/date';

interface State {
    loading: boolean;
    selectedProduct: string;
    summary: AddOnSummaryItem[];
}

class ListTShirtOrders extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: false, summary: null, selectedProduct: null };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        let back = new ApiBackend();
        this.setState({ loading: true });

        if (this.state.selectedProduct) {
            back.listTShirtSummary(this.state.selectedProduct).then((summary) => {
                this.setState({ summary: summary, loading: false });
            });
        }
        else {

            this.setState({ summary: null, loading: false });

        }
    }


    render() {
        let { classes } = this.props as any;

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Inställningar</Typography></Fragment>} />
                    <CardContent>

                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}><b>Lopp</b></Grid>
                            <Grid item xs={10}>
                                <ProductList
                                    initialValue={this.state.selectedProduct}
                                    productTypes={["race", "event"]}
                                    onChange={(ev) => {
                                        this.setState({ selectedProduct: ev.products[0].id }, () => { this.refresh() });
                                    }}
                                />
                            </Grid>

                            <Grid item xs={2}><Button variant="contained" disabled={this.state.loading} onClick={this.exportExcel}>Excelexportera</Button></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>

                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Beställningar T-Shirts</Typography>
                        <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} />
                    </Fragment>} />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        {this.state.summary && <Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={8}><b>Produkt</b></Grid>
                                <Grid item xs={4}><b>Antal</b></Grid>
                                {this.state.summary.map((x) => {
                                    return <>
                                        <Grid item xs={8}>{x.productName}</Grid>
                                        <Grid item xs={4}>{x.numberOfSold}st</Grid>
                                    </>
                                })}
                                <Grid item xs={6}><b>SUMMA:</b></Grid>
                                <Grid item xs={6}><b>{this.state.summary.reduce((prev, cur, idx) => prev + cur.numberOfSold, 0)}st</b></Grid>
                            </Grid>
                        </Fragment>
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </Fragment>;
    }

    exportExcel = () => {

        this.renderXLSXSummary();
    }

    renderXLSXSummary = async () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "TShirtsamsummering",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Utlägg");

        //let groupedPanels = await this.getGroupedPanels();

        let data = [['Vasaloppsföreningen Sälen-Mora'],
        [],
        ['TShirt-beställningar'],
        [],
        [],
        ["Produkt", "Antal sålda "]];
        let totalNum = 0;
        let totalSum = 0;
        for (let i = 0; i < this.state.summary.length; i++) {
            const row = this.state.summary[i];
            totalNum += row.numberOfSold;
            totalSum += (row.numberOfSold * row.price);
            data.push(
                [
                    row.productName,
                    row.numberOfSold + '',
                ]
            );
        }

        data.push(["Summa", totalNum + '']);

        var ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Utlägg"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'tshirtbeställingar_' + vasaloppetMoment().format(FILE_FORMAT) + '.xlsx');
    }


    s2ab(s: any) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {

        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ListTShirtOrders));