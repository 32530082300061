import {
	Box,
	Button,
	Card,
	CardContent,
	CardHeader,
	Grid,
	IconButton,
	LinearProgress,
	Theme,
	Typography,
	WithStyles,
	createStyles,
	withStyles,
} from "@material-ui/core";
import { ArrowForward, OpenInBrowser } from "@material-ui/icons";
import RefreshIcon from "@material-ui/icons/Refresh";
import GetAppIcon from "@material-ui/icons/GetApp";
import { debounce } from "lodash";
import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { B2bCompany, B2bCompanyCreate } from "../../../model/B2bCompany";
import FirebaseContext from "../../../providers/Firebase/context";
import { ApiBackend } from "../../../providers/apibackend";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";
import { IColumnDefinition, ISortModel } from "../../Common/EnhancedTable/models";
import SearchFilter, { Filters } from "../../Common/SearchFilter/SearchFilter";
import CreateCompanyDialog from "./Dialogs/CreateCompanyDialog/CreateCompanyDialog";
import { searchFilterToExportExcelFilter } from "../../Common/SearchFilter/utils";
import { IExportExcelRequest } from "../../../model/IExportExcelRequest";
import { vasaloppetDateGetterFormatter } from "../../../utilities/date";

interface ISearchFilter {
	companyName?: string;
	existsInExternalAccountingSystem?: boolean;
	category?: string[];
	includeArchived: boolean;
}

interface IState {
	loading: boolean;
	companies: B2bCompany[];
	isDialogOpen: boolean;
	searchFilter: ISearchFilter;
	sortModel: ISortModel<B2bCompany>;
}

type EditMode = "in-app" | "new-tab";

class ManageB2bCompanies extends React.Component<RouteComponentProps & WithStyles, IState> {
	static contextType = FirebaseContext;

	private readonly api: ApiBackend;

	constructor(props: RouteComponentProps & WithStyles) {
		super(props);
		this.state = {
			loading: true,
			companies: null,
			isDialogOpen: false,
			searchFilter: {
				companyName: "",
				includeArchived: false,
			},
			sortModel: {
				sortBy: "name",
				sortOrder: "asc",
			}
		};
		this.api = new ApiBackend();
	}

	componentDidMount() {
		this.refresh();
	}

	private export = async () => {
		const { searchFilter, sortModel } = this.state;

		const filter = searchFilterToExportExcelFilter<ISearchFilter>(searchFilter);

		const request: IExportExcelRequest = {
			entityType: "B2BCompany",
			filter: filter,
			sortProp: sortModel?.sortBy,
			sortDir: sortModel?.sortOrder
		};
		try {
			this.setState({ loading: true });
			await this.api.exportExcel(request);
		} finally {
			this.setState({ loading: false });
		}
	};

	private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
		this.setState({ searchFilter: searchFilter, loading: true }, async () => {
			await this.refresh();
		});
	};

	private refresh = async () => {
		this.setState({ loading: true });
		await this.performSearch();
	};

	private showCreateCompany = (e: any) => {
		this.setState({ isDialogOpen: true });
	};

	private onDialogClose = () => {
		this.setState({ isDialogOpen: false });
	};

	private performSearch = debounce(async () => {
		const { searchFilter } = this.state;

		const companies = await this.api.listB2bCompanies(searchFilter);
		this.setState({
			companies: companies,
			loading: false,
		});
	}, 500);

	private onCreateCompanySubmit = async (company: B2bCompanyCreate) => {
		try {
			this.setState({ loading: true });

			const response = await this.api.createB2bCompany(company);

			if (!response) {
				throw new Error("Failed to create company");
			}

			this.setState({ isDialogOpen: false }, () => {
				this.refresh();
			});
		} catch (error) {
			throw error;
		} finally {
			this.setState({ loading: false });
		}
	};

	private editCompany = (company: B2bCompany, mode: EditMode): void => {
		switch (mode) {
			case "in-app":
				this.props.history.push(`manage/${company.id}`);
				break;
			case "new-tab":
				window.open(`manage/${company.id}`);
				break;
		}
	};

	render(): JSX.Element {
		const { classes } = this.props;
		const { isDialogOpen, searchFilter } = this.state;

		const invoiceStatusFormatter = (status: string): string => {
			switch (status) {
				case "NOTAPPLIED":
					return "Ej ansökt";
				case "APPLIED":
					return "Ansökt";
				case "ACCEPTED":
					return "Godkänd";
				case "DENIED":
					return "Nekad";
				case "NOTINTERESTED":
					return "Inte intresserad";
				default:
					return "Ej ansökt";
			}
		};

		const columnDefinitions: IColumnDefinition<B2bCompany>[] = [
			{
				renderCell: (row) => {
					return (
						<>
							<IconButton
								color="default"
								onClick={() => {
									this.editCompany(row, "new-tab");
								}}
								size="small"
							>
								<OpenInBrowser />
							</IconButton>
							<IconButton
								color="default"
								onClick={() => {
									this.editCompany(row, "in-app");
								}}
								size="small"
							>
								<ArrowForward />
							</IconButton>
						</>
					);
				},
			},
			{
				propName: "name",
				label: "Namn",
			},
			{
				propName: "description",
				label: "Beskrivning",
				valueFormatter: (row: B2bCompany) => {
					return (row.description?.length > 40) ? row.description.slice(0, 50 - 1) + '...' : row.description;
				},
			},
			{
				propName: "category",
				label: "Kategori",
			},
			{
				propName: "status",
				label: "Fakturering",
				valueFormatter: (row: B2bCompany) => invoiceStatusFormatter(row.status),
				sortValueGetter: (row: B2bCompany) => invoiceStatusFormatter(row.status),
			},
			{
				propName: "orgNumber",
				label: "Organisationsnummer",
			},
			{
				propName: "existsInExternalAccountingSystem",
				label: "Finns i Agresso",
				valueFormatter: (row: B2bCompany) => row.existsInExternalAccountingSystem ? "Ja" : "Nej",
			},
			{
				propName: "createdBy",
				label: "Skapad av",
				valueFormatter: (row) => row.createdBy ?? "---"
			}, {
				propName: "created",
				label: "Skapad",
				valueFormatter: (row) => vasaloppetDateGetterFormatter(row.created, "---")
			}, {
				propName: "updatedBy",
				label: "Uppdaterad av",
				valueFormatter: (row) => row.updatedBy ?? "---"
			}, {
				propName: "updated",
				label: "Uppdaterad",
				valueFormatter: (row) => vasaloppetDateGetterFormatter(row.updated, "---")
			}
		];

		const defaultBoxStyle = {
			display: "flex",
			flexDirection: "row",
		};

		return (
			<>
				<SearchFilter
					id={"manage-b2bcompanies-filter"}
					filters={{
						companyName: {
							id: "filter-company-name",
							type: "DebouncedText",
							label: "Namn på företag",
							size: 4,
						},
						existsInExternalAccountingSystem: {
							id: "filter-exists-in-external-accounting-system",
							type: "GenericSelect",
							label: "Finns i Agresso",
							size: 3,
							itemDefinition: {
								itemValueType: "string",
								items: [
									{ name: "Ja", value: "true" },
									{ name: "Nej", value: "false" }
								]
							},
							clearable: true
						},
						"category": {
							id: "filter-code-category",
							type: "CompanyCategory",
							label: "Kategori",
							size: 3,
							defaultValue: searchFilter.category
						},
						includeArchived: {
							id: "filter-includearchived",
							type: "Checkbox",
							label: "Inkludera arkiverade företag",
							size: 2,
						},
					}}
					persist={true}
					onInit={async (filter: Filters<ISearchFilter>) => {
						await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
					}}
					onChange={async (filter: Filters<ISearchFilter>) => {
						await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
					}}
				/>
				<Grid container className={classes.root} spacing={2}>
					<Grid item xs={12}>
						<Card>
							<CardHeader
								className={classes.cardHeader}
								title={
									<Fragment>
										<Typography variant="h5" style={{ display: "inline" }}>
											Företag
										</Typography>
										<RefreshIcon style={{ display: "inline", verticalAlign: "middle" }} onClick={this.refresh} />
									</Fragment>
								}
								action={
									<Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
										<div style={{ marginTop: 12, marginRight: 12 }}>
											<GetAppIcon onClick={this.export} />
										</div>
									</Box>
								}
							/>
							<CardContent>
								<Box mb={1}>
									<Button variant="contained" onClick={this.showCreateCompany}>
										Lägg till företag
									</Button>
								</Box>

								{this.state.loading && <LinearProgress color="secondary" />}

								<EnhancedTable<B2bCompany>
									columnDefinitions={columnDefinitions}
									data={this.state.companies ?? []}
									pageSize={10}
									sortModel={this.state.sortModel}
									onSortModelChange={(nextSortModel: ISortModel<B2bCompany>) => {
										this.setState({ sortModel: nextSortModel });
									}}
									loading={this.state.loading}
									dense
								/>
							</CardContent>
						</Card>
					</Grid>
				</Grid>
				<CreateCompanyDialog open={isDialogOpen} onClose={this.onDialogClose} onSubmit={this.onCreateCompanySubmit} />
			</>
		);
	}
}

const useStyles = ({ palette, spacing }: Theme) =>
	createStyles({
		cardHeader: {
			background: palette.secondary.main,
			color: palette.secondary.contrastText,
			padding: 3,
		},
		photo: {
			height: "30px",
			verticalAlign: "middle",
			borderRadius: "10px",
		},
		root: {},
		form: {
			"& > *": {
				margin: spacing(1),
				width: "25ch",
			},
			"& label.Mui-focused": {
				color: palette.secondary.main,
			},
			"& .MuiInput-underline:after": {
				borderBottomColor: palette.secondary.main,
			},
		},
	});

export default withRouter(withStyles(useStyles)(ManageB2bCompanies));
