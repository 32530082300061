import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, LinearProgress, MenuItem, Popover, WithStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { Product } from "../../model/Product";
import { ProductBundleGroup, ProductBundleItem } from "../../model/ProductBundle";
import { ApiBackend } from "../../providers/apibackend";
import FormCbxField from "../Common/FormCbxField";
import FormSelectField from "../Common/FormSelectField";
import FormTextField from "../Common/FormTextField";
import SelectProductPopover from "./Popovers/SelectProductPopover";
import { Locale } from "./models";

type Props = FormikProps<ProductBundleGroup> & WithStyles;

const EditBundleGroupFormContent = (props: Props) => {
    const [locale, setLocale] = useState("SV" as Locale);
    const [loadingProducts, setLoadingProducts] = useState(true);
    const [addPopupOpen, setAddPopupOpen] = useState(false);
    const [editPopupOpen, setEditPopupOpen] = useState(false);
    const [addAnchorEl, setAddAnchorEl] = useState(null as HTMLButtonElement);
    const [editAnchorEl, setEditAnchorEl] = useState(null as HTMLButtonElement);
    const [sourceProducts, setSourceProducts] = useState(null as Product[]);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    // Run on load
    useEffect(() => {
        didMount.current = true;

        updateAllSourceProducts();

        // unmount
        return () => {didMount.current = false};
    }, []);

    const updateAllSourceProducts = async (): Promise<void> => {
        setLoadingProducts(true);
        const sourceProductIds = props.values.items.map((product) => product.productId);
        const uniqueProductIds = sourceProductIds.filter((v, i, a) => a.indexOf(v) === i);
        const uniqueSourceProducts: Product[] = await Promise.all(uniqueProductIds.map(async (productId): Promise<Product> => {
            return backend.getProduct(productId);
        }));

        if (didMount.current) {
            setSourceProducts(uniqueSourceProducts);
            setLoadingProducts(false);
        }
    };

    const addSourceProduct = async (productId: string): Promise<void> => {
        setLoadingProducts(true);
        const newSourceProduct = await backend.getProduct(productId);
        setSourceProducts([...sourceProducts, newSourceProduct]);
        setLoadingProducts(false);
    }

    const productName = (productId?: string): string => {
        if (!productId) {
            return "";
        }
        return sourceProducts?.find(x => x.id === productId)?.name ?? "";
    };

    const variantName = (productId?: string, variantId?: string): string => {
        if (!productId || !variantId) {
            return "";
        }
        const p = sourceProducts?.find(x => x.id === productId);
        if (!p) {
            return "";
        }

        return p.variants?.find(v => v.Id === variantId)?.Name ?? "";
    };

    const priceGroupName = (productId?: string, variantId?: string, priceGroupId?: string): string => {
        if (!productId || !variantId || !priceGroupId) {
            return "";
        }

        const p = sourceProducts?.find(x => x.id === productId);
        if (!p) {
            return "";
        }

        const v = p.variants?.find(v => v.Id === variantId);
        if (!v) {
            return "";
        }

        return v.priceGroups?.find(pg => pg.id === priceGroupId)?.Name ?? "";
    };

    const handleAddProduct = (e: any) => {
        setAddPopupOpen(true);
        setAddAnchorEl(e.currentTarget);
    };

    const handleAddProductClose = () => {
        setAddPopupOpen(false);
        setAddAnchorEl(null);
    };

    const handleEditProduct = (e: any) => {
        setEditPopupOpen(true);
        setEditAnchorEl(e.currentTarget);
    };

    const handleEditProductClose = () => {
        setEditPopupOpen(false);
        setEditAnchorEl(null);
    };

    const handleOnPopoverChange = async (product: ProductBundleItem, arrayHelpers: FieldArrayRenderProps, idx?: number) => {
        const sourceProductExists = sourceProducts.some((p) => p.id == product.productId);
        if (!sourceProductExists) {
            await addSourceProduct(product.productId);
        }

        handleAddProductClose();
        handleEditProductClose();

        if (idx !== null && idx !== undefined) {
            arrayHelpers.replace(idx, product);
        } else {
            arrayHelpers.push(product);
        }
    };

    const render = (): JSX.Element => {
        const { classes, values } = props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ToggleButtonGroup
                            value={locale}
                            exclusive
                            onChange={(_: React.MouseEvent<HTMLElement>, newLocale: Locale) => {
                                setLocale(newLocale);
                            }}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="SV" aria-label="left aligned">
                                SV
                            </ToggleButton>
                            <ToggleButton value="EN" aria-label="left aligned">
                                EN
                            </ToggleButton>
                        </ToggleButtonGroup>

                        <FormCbxField {...props}
                            propName={"required"}
                            label="Måste väljas"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {locale === "SV" ?
                        <FormTextField {...props}
                            propName="name"
                            label="Namn"
                        /> :
                        <FormTextField {...props}
                            propName="nameEn"
                            label="Namn (EN)"
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    {locale === "SV" ?
                        <FormTextField {...props}
                            multiline={true}
                            propName="description"
                            label="Beskrivning"
                        /> :
                        <FormTextField {...props}
                            multiline={true}
                            propName="descriptionEn"
                            label="Beskrivning (EN)"
                        />
                    }
                </Grid>
                <Grid item xs={6}>
                    <FormSelectField {...props}
                        propName="selectionMode"
                        label="Val av produkter"
                    >
                        <MenuItem value="single">Endast en produkt kan väljas</MenuItem>
                        <MenuItem value="multiple">Flera produkter kan väljas</MenuItem>
                    </FormSelectField>
                </Grid>

                <Grid item xs={12}>
                    <FieldArray
                        name="items"
                        render={arrayHelpers => (
                            <Card>
                                <CardHeader
                                    className={classes.cardHeader}
                                    title="Hantera produkter"
                                />
                                <CardContent>
                                    {loadingProducts &&
                                        <LinearProgress color="secondary"/>
                                    }
                                    {values.items.map((item, idx) => {
                                        return (
                                            <Grid key={idx} container className={classes.root} spacing={2} style={{ width: "100%" }}>
                                                <Grid item xs={3}>
                                                    <FormTextField {...props}
                                                        disabled={true}
                                                        propName={`items[${idx}].productId`}
                                                        valueGetter={() => productName(item.productId)}
                                                        label="Produkt"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormTextField {...props}
                                                        disabled={true}
                                                        propName={`items[${idx}].productVariantId`}
                                                        valueGetter={() => variantName(item.productId, item.productVariantId)}
                                                        label="Variant"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <FormTextField {...props}
                                                        disabled={true}
                                                        propName={`items[${idx}].productVariantPriceGroupId`}
                                                        valueGetter={() => priceGroupName(item.productId, item.productVariantId, item.productVariantPriceGroupId)}
                                                        label="Prisgrupp"
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Box display="flex" justifyContent="flex-end">
                                                        {idx !== 0 && <ExpandLess
                                                            className={classes.icon}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                arrayHelpers.move(idx, idx - 1);
                                                            }}
                                                        />
                                                        }
                                                        {idx !== values.items.length - 1 && <ExpandMore
                                                            className={classes.icon}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                arrayHelpers.move(idx, idx + 1);
                                                            }}
                                                        />
                                                        }

                                                        <IconButton
                                                            onClick={handleEditProduct}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <Popover
                                                            open={editPopupOpen}
                                                            anchorEl={editAnchorEl}
                                                            onClose={handleEditProductClose}
                                                            anchorOrigin={{
                                                                vertical: "top",
                                                                horizontal: "center",
                                                            }}
                                                            transformOrigin={{
                                                                vertical: "bottom",
                                                                horizontal: "center",
                                                            }}
                                                            PaperProps={{
                                                                style: {
                                                                    width: "500px"
                                                                }
                                                            }}>
                                                            <SelectProductPopover
                                                                onChange={(product) => handleOnPopoverChange(product, arrayHelpers, idx)}
                                                            />
                                                        </Popover>
                                                        <IconButton
                                                            onClick={() => { arrayHelpers.remove(idx); }}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                </CardContent>
                                <CardActions>
                                    <Box mb={1}>
                                        <Button
                                            variant="contained"
                                            onClick={handleAddProduct}
                                        >
                                            Lägg till produkt
                                        </Button>
                                        <Popover
                                            open={addPopupOpen}
                                            anchorEl={addAnchorEl}
                                            onClose={handleAddProductClose}
                                            anchorOrigin={{
                                                vertical: "top",
                                                horizontal: "center",
                                            }}
                                            transformOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center",
                                            }}
                                            PaperProps={{
                                                style: {
                                                    width: "500px"
                                                }
                                            }}>
                                            <SelectProductPopover
                                                onChange={(product) => handleOnPopoverChange(product, arrayHelpers)}
                                            />
                                        </Popover>
                                    </Box>
                                </CardActions>
                            </Card>
                        )}
                    />
                </Grid>
            </Grid>
        )
    }

    return render();
}

export default EditBundleGroupFormContent;
