const range = (start: number, end: number): string[] => {
    return Array(end - start + 1)
        .fill(0)
        .map((_, idx) => `${start + idx}`.padStart(2, "0"));
};

const getYears = (start: number, end: number): string[] => range(start, end);

const getMonths = (): string[] => ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

const getDaysInMonth = (year?: string, month?: string): string[] => {
    if (!year ||!month) {
        return [];
    }

    return range(1, new Date(+year, +month, 0).getDate());
};

const getDefaults = (value: string): { year?: string; month?: string; day?: string; } | undefined => {
    if (!value) {
        return;
    }

    let defaultYear = "";
    let defaultMonth = "";
    let defaultDay = "";

    const parts = value.split("-");

    if (parts[0]) {
        defaultYear = parts[0];

        if (parts[1]) {
            defaultMonth = parts[1];

            if (parts[2]) {
                defaultDay = parts[2];
            }
        }
    }

    return {
        year: defaultYear,
        month: defaultMonth,
        day: defaultDay
    };
};

const getNotificationValue = (year?: string, month?: string, day?: string): string => {
    let value = "";
    if (year) {
        value =  year;

        if (month) {
            value += `-${month}`;
        }

        if (day) {
            value += `-${day}`;
        }
    }

    return value;
};

export { getDaysInMonth, getDefaults, getMonths, getNotificationValue, getYears };

