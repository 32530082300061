import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import React from "react";
import { B2bCompany } from "../../model/B2bCompany";
import { ApiBackend } from "../../providers/apibackend";
import ClearableSelect from "./ClearableSelect";

interface IProps {
    id?: string;
    variant?: "filled" | "outlined" | "standard";
    initialValue?: string | string[];
    autoSelectFirst?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    includeArchived?: boolean;
    disabled?: boolean;
    style?: React.CSSProperties;
    onInit?: (value: string | string[]) => void;
    onClear?: () => void;
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
    onBeforeChange?: (reason: "change" | "clear") => Promise<boolean>;
}

interface IState {
    value: string | string[];
    listItems: JSX.Element[];
}

class CompanySelect extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();

        this.state = {
            value: this.props.initialValue ?? "",
            listItems: null
        };
    }

    async componentDidMount(): Promise<void> {
        const { multiple, autoSelectFirst, onInit } = this.props;

        const companies = await this.getCompanies();

        let value = autoSelectFirst ? companies[0].id : this.props.initialValue;
        if (multiple && !Array.isArray(value)) {
            if (value) {
                value = [value];
            }
            value = [];
        }

        this.setState({
            listItems: this.generateItems(companies),
            value: value
        }, () => {
            if (onInit) {
                this.props.onInit(this.state.value);
            }
        });
    }

    render(): JSX.Element {
        const { variant, clearable, multiple, style, disabled } = this.props;
        const { value, listItems } = this.state;

        const safeValue = !!listItems ? value : "";

        return (
            <ClearableSelect
                disabled={disabled}
                clearable={clearable}
                multiple={multiple}
                variant={variant}
                value={safeValue}
                style={style}
                onClear={this.handleClear}
                onChange={this.handleChange}
            >
                {listItems}
            </ClearableSelect>
        );
    }

    private generateItems = (companies: B2bCompany[]): JSX.Element[] => {
        if (!companies || companies.length === 0) {
            return null;
        }

        return companies.map(c => {
            return (
                <MenuItem key={c.id} value={c.id} style={{ marginLeft: 18 }}>
                    {c.name}
                </MenuItem>
            );
        });
    };

    private handleChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
        const { onBeforeChange } = this.props;

        if (onBeforeChange) {
            const canChange = await onBeforeChange("change");

            if (!canChange) return;
        }

        const value = event.target.value;
        this.setState({ value: value });
        this.props.onChange(event);
    };

    private handleClear = async (): Promise<void> => {
        const { multiple, onBeforeChange } = this.props;

        if (onBeforeChange) {
            const canChange = await onBeforeChange("clear");
            
            if (!canChange) return;
        }

        this.setState({ value: multiple ? [] : "" });

        if (this.props.onClear) {
            this.props.onClear();
        }
    };

    private getCompanies = async (): Promise<B2bCompany[]> => {
        const { includeArchived } = this.props;

        return this.api.listB2bCompanies({ includeArchived: includeArchived });
    };
}

export default CompanySelect;
