/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TableHead from '@material-ui/core/TableHead/TableHead';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Fragment } from 'react';
import uuid from 'react-uuid';
import { Product } from '../../../model/Product';
import { SeedingTable } from '../../../model/SeedingTable';
import { StartGroup } from '../../../model/StartGroup';
import { ApiBackend } from '../../../providers/apibackend';
import EditStartGroupDialog from './EditStartGroupDialog';
import { vasaloppetDayDiff, vasaloppetTimeGetterFormatter } from '../../../utilities/date';
import { getAvailabilityTitleFromGroup } from './utils';

interface Props {
    product: Product;
    groupsChanged: () => void;
    editLocale: string;
}

interface State {
    haveChanges: boolean;
    addEditGroup: StartGroup;
    showDeleteConfirmation: StartGroup;
    seedingTables: SeedingTable[];
}

class StartGroups extends React.Component<WithStyles & Props, State> {

    state = {
        haveChanges: false,
        addEditGroup: null as StartGroup,
        showDeleteConfirmation: null as StartGroup,
        seedingTables: null as SeedingTable[]
    };

    render() {
        const { classes } = this.props;
        let lastStartTime: Date = null;

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title="Tillgängliga startled" />
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Namn</StyledTableCell>
                                        <StyledTableCell>Max antal deltagare</StyledTableCell>
                                        <StyledTableCell>Antal säljbara platser</StyledTableCell>
                                        <StyledTableCell>Startledstilldelning</StyledTableCell>
                                        <StyledTableCell>Deltagarkategori</StyledTableCell>
                                        <StyledTableCell>Starttid</StyledTableCell>
                                        <StyledTableCell>Öppen för anmälan</StyledTableCell>
                                        <StyledTableCell>Tillgänglighet</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.product.startGroups && this.props.product.startGroups.map((group, idx) => {
                                        const availatility = getAvailabilityTitleFromGroup(group);

                                        let days = <></>;
                                        if (idx > 0 && lastStartTime) {
                                            const diff = vasaloppetDayDiff(group.startTime, lastStartTime);
                                            if (diff != 0) {
                                                days = <span style={{ color: 'red' }}>{(diff > 0 ? " +" : " ") + diff + "d"}</span>;
                                            }
                                        }

                                        lastStartTime = group.startTime;
                                        return <TableRow key={idx}>
                                            <StyledTableCell>{this.props.editLocale == "EN" ? group.nameEn : group.name}</StyledTableCell>
                                            <StyledTableCell>{group.maxParticipants}</StyledTableCell>
                                            <StyledTableCell>{group.numberOfSellablePlaces}</StyledTableCell>
                                            <StyledTableCell>{this.getStartGroupAllocationType(group)}</StyledTableCell>
                                            <StyledTableCell>{this.getStartGroupClass(group)}</StyledTableCell>
                                            <StyledTableCell>{vasaloppetTimeGetterFormatter(group.startTime)}{days}</StyledTableCell>
                                            <StyledTableCell><Checkbox checked={group.openForEntry} disabled /></StyledTableCell>
                                            <StyledTableCell>{availatility}</StyledTableCell>
                                            <StyledTableCell>
                                                <Box display="flex" justifyContent="flex-end">
                                                    {idx > 0 &&
                                                        <IconButton size="small" onClick={this.handleMoveUp(idx)}>
                                                            <ExpandLess />
                                                        </IconButton>
                                                    }
                                                    {idx < this.props.product.startGroups.length - 1 &&
                                                        <IconButton size="small" onClick={this.handleMoveDown(idx)}>
                                                            <ExpandMore />
                                                        </IconButton>
                                                    }
                                                    <IconButton size="small" onClick={this.editGroup(group)}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton size="small" onClick={this.removeGroup(group)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>

                        {this.state && this.state.addEditGroup &&
                            <EditStartGroupDialog {...this.props}
                                seedingTables={this.state.seedingTables}
                                initialValue={this.state.addEditGroup}
                                onAbort={this.handleAddEditCancel}
                                onChange={this.handleEditOnChange}
                            />
                        }
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="secondary" onClick={this.addGroup}>Lägg till startled</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.showDeleteConfirmation &&
                this.renderConfirmationDialog()
            }
        </Fragment>
    }

    private getStartGroupAllocationType = (group: StartGroup) => {
        switch (group.startGroupAllocationMethod) {
            case "seeded":
                return "Seedad tilldelning";
            case "userchoice":
                return "Startledsväljaren";
            case "extern":
                return "Extern tilldelning";
            case "none":
                return "Ingen tilldelning";
            default:
                return "Framifrån vid anmälan";
        }
    }

    private getStartGroupClass = (group: StartGroup) => {
        if (group.participantCategory && this.props.product.variants.some(x => x.Id == group.participantCategory)) {
            return this.props.product.variants.find(x => x.Id == group.participantCategory).Name;
        }
        return "-";
    }

    private handleMoveUp = (idx: number) => (): void => {
        const { product, groupsChanged } = this.props;

        if (idx > 0) {
            let startGroups = product.startGroups;
            const rem = startGroups.splice(idx, 1);
            startGroups.splice(idx - 1, 0, rem[0]);
            groupsChanged();
        }
    };

    private handleMoveDown = (idx: number) => (): void => {
        const { product, groupsChanged } = this.props;

        if (idx < product.startGroups.length - 1) {
            let startGroups = product.startGroups;
            const rem = startGroups.splice(idx, 1);
            startGroups.splice(idx + 1, 0, rem[0]);
            groupsChanged();
        }
    };

    private removeGroup = (group: StartGroup) => () => {
        this.setState({
            showDeleteConfirmation: group
        });
    };

    private editGroup = (group: StartGroup) => async () => {
        if (!this.state.seedingTables) {
            const back = new ApiBackend();
            const res = await back.listSeedingTables();
            this.setState({ seedingTables: res });
        }

        this.setState({
            addEditGroup: group
        });
    };

    private addGroup = async () => {
        if (!this.state.seedingTables) {
            const back = new ApiBackend();
            const res = await back.listSeedingTables();
            this.setState({ seedingTables: res });
        }

        const g = {
            name: "Nytt startled",
            nameEn: "",
            description: "",
            descriptionEn: "",
            startTime: new Date(),
            bibNumberFrom: 1,
            bibNumberTo: 1000,
            openForEntry: true
        } as StartGroup;
        this.setState({
            addEditGroup: g,
        });
    };

    private renderConfirmationDialog() {
        const handleDeleteCancel = () => {
            this.setState({ showDeleteConfirmation: null });
        };

        const handleDeleteOk = () => {
            const e = this.props.product;
            e.startGroups = e.startGroups.filter(x => x.id != this.state.showDeleteConfirmation.id);
            this.setState({ showDeleteConfirmation: null });
            this.props.groupsChanged();
            this.forceUpdate();
        };

        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort startledet?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    private handleAddEditCancel = () => {
        this.setState({ addEditGroup: null });
    };

    private handleEditOnChange = (value: StartGroup) => {
        const { product, groupsChanged } = this.props;

        if (!value.id) {
            if (!product.startGroups) {
                product.startGroups = [];
            }

            value.id = uuid();
            product.startGroups.push(value);
        } else {
            const idx = product.startGroups.findIndex(sg => sg.id === value.id);
            product.startGroups.splice(idx, 1, value);
        }

        this.setState({ addEditGroup: null });
        groupsChanged();
    };
}

const tableHeadStyles = ({ palette }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(StartGroups);
