import { Checkbox, createStyles, FormControl, FormControlLabel, FormGroup, Theme, withStyles } from "@material-ui/core";

import { InputLabel } from "@material-ui/core";
import React from "react";
import { CustomFieldValidValue } from "../../model/CustomField";

interface IProps {
	disabled?: boolean;
	label: string;
	validValues: CustomFieldValidValue[];
	valueGetter: () => any;
	onChange: (value: string) => void;
}

interface IState {
	selectedValues: string[];
}

class MultiChoiceField extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		const value = props.valueGetter();

		this.state = {
			selectedValues: value ? value.split(";") : [],
		};
	}

	handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { onChange } = this.props;
		const { selectedValues } = this.state;
		const value = e.target.value;
		const newSelectedValues = selectedValues.includes(value)
			? selectedValues.filter((v) => v !== value)
			: [...selectedValues, value];

		this.setState({ selectedValues: newSelectedValues });

		if (onChange) {
			onChange(newSelectedValues.join(";"));
		}
	};

	render() {
		const { validValues, label, disabled } = this.props;
		const { selectedValues } = this.state;

		return (
			<FormControl disabled={disabled} fullWidth>
				{label && <StyledInputLabel shrink>{label}</StyledInputLabel>}
				<StyledFormGroup>
					{validValues.map((val, idx) => {
						const checked = selectedValues.includes(val.value);

						return (
							<div key={idx}>
								<FormControlLabel
									label={val.label}
									className={checked ? "Mui-FormControlLabel-checked" : ""}
									control={<Checkbox checked={checked} value={val} color="primary" onChange={this.handleOnChange} />}
								/>
							</div>
						);
					})}
				</StyledFormGroup>
			</FormControl>
		);
	}
}

const StyledInputLabel = withStyles((theme: Theme) =>
	createStyles({
		root: {
			position: "relative",
		},
	})
)(InputLabel);

const StyledFormGroup = withStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			[theme.breakpoints.up("md")]: {
				display: "grid",
				gridTemplateColumns: "1fr 1fr",
				width: "100%",
			},
		},
	})
)(FormGroup);

export default MultiChoiceField;
