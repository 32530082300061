import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import uuidv4 from "react-uuid";
import { ProductBundleGroup } from "../../model/ProductBundle";
import EditBundleGroupFormContent from "./EditBundleGroupFormContent";
import { EditMode } from "./models";

interface IProps extends WithStyles {
    bundleGroup: ProductBundleGroup;
    mode: EditMode;
    onAbort: () => void;
    onSave: (bundleGroup: ProductBundleGroup) => void;
}

const EditBundleGroupDialog = (props: IProps) => {
    const [bundleGroup, setBundleGroup] = useState(null as ProductBundleGroup);
    const didMount = useRef<boolean>(false);
    const formId = `edit-group-form-${uuidv4()}`;

    // Run on load
    useEffect(() => {
        didMount.current = true;

        let grp = props.bundleGroup;
        if (!grp.id) {
            grp.id = uuidv4();
            grp.items = [];
        }
        setBundleGroup(grp);

        // unmount
        return () => {didMount.current = false};
    }, []);

    const render = () => {
        const { mode, onAbort, onSave, classes } = props;

        return <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-bundle-title"
            open={true}
        >
            <DialogTitle id="edit-bundle-title">{mode === "create" ? "Lägg till grupp" : "Redigera grupp"}</DialogTitle>

            {bundleGroup &&
                <Formik
                    initialValues={bundleGroup}
                    onReset={(values): void => {
                        setBundleGroup(values);
                        onAbort();
                    }}
                    onSubmit={async (values) => {
                        onSave(values);
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;
                        const isSaveDisabled = !dirty || !isValid || isSubmitting;

                        return (<>
                            <DialogContent dividers>
                                <form id={formId} autoComplete="off" onSubmit={handleSubmit}>
                                    <EditBundleGroupFormContent {...formik} classes={classes} />
                                </form>
                            </DialogContent>
                            <DialogActions>
                                <Button form={formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">
                                    OK
                                </Button>
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={handleReset}>
                                    {dirty ? "Avbryt" : "Stäng"}
                                </Button>
                            </DialogActions>
                        </>);
                    }}
                </Formik>
            }

        </Dialog>;
    }

    return render();
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(EditBundleGroupDialog);
