import React from "react";
import { Box, Button, TextField, Typography } from "@material-ui/core";
import { useState } from "react";

interface IProps {
    price: number;
    onComplete: (paymentComment: string) => void;
}

const CardPaymentStep = ({ price, onComplete }: IProps) => {
    const [cardStep, setCardStep] = useState<"1" | "2" | "3">("1");
    const [paymentComment, setPaymentComment] = useState("");

    const render = () => {
        let content: JSX.Element;

        switch (cardStep) {
            case "1":
                content = (<>
                    <Typography variant="h6">
                        {`1(3) - Ta betalt ${price} kr på kortterminalen`}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                            setCardStep("2");
                        }}
                    >
                        Nästa
                    </Button>
                </>);
                break;
            case "2":
                content = (<>
                    <Typography variant="h6">2(3) - Verifiera att köpet har gått igenom</Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                            setCardStep("3");
                        }}
                    >
                        Nästa
                    </Button>
                </>);
                break;
            case "3":
                content = (<>
                    <Typography variant="h6">3(3) - Klicka på slutför för att markera genomförd betalning</Typography>
                    <TextField label="Kommentar"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={paymentComment}
                        onChange={(ev) => {
                            setPaymentComment(ev.target.value);
                        }}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                            onComplete(paymentComment);
                        }}
                    >
                        Slutför
                    </Button>
                </>);
                break;
            default:
                content = null;
        }

        return (
            <Box display="flex" alignItems="center" justifyContent="center">
                <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" style={{ gap: 16, width: "80%" }}>
                    {content}
                </Box>
            </Box>
        );
    };

    return render();
};

export default CardPaymentStep
