import { Grid, Select, TextField } from '@material-ui/core';
import React from 'react';
import { OrderItem } from '../../../model/OrderItem';
import { getSortedOccations, getSortedPricegroups, getSortedVariants } from './utils';

interface Props {
    item: OrderItem;
    onTextChanged: (text: string) => void;
    onVariantChanged: (id: string) => void;
    onPriceGroupChanged: (id: string) => void;
    onOccationChanged: (id: string) => void;
    text: string;
}

const ManageOrderEditItemsChangeItem = ({ item, onTextChanged, onVariantChanged, onPriceGroupChanged, onOccationChanged, text }: Props) => {

    const render = () => {
        const curVariant = item.product.variants.find(x => x.Id == item.productVariantId);
        let priceGroups = null;
        if (curVariant) {
            priceGroups = getSortedPricegroups(curVariant.priceGroups);
        }

        const occations = getSortedOccations(curVariant.occations);

        return <Grid container spacing={2}>
            <Grid item xs={6}>Produkt</Grid>
            <Grid item xs={6}>{item.productName}</Grid>

            <Grid item xs={6}>Variant</Grid>
            <Grid item xs={6}>
                <Select fullWidth native value={item.productVariantId} onChange={(ev) => { onVariantChanged(ev.target.value as string); }}>
                    {getSortedVariants(item.product.variants).map(variant => {
                        return <option value={variant.Id}>{variant.Name}</option>
                    })}
                </Select>
            </Grid>

            <Grid item xs={6}>Prisgrupp</Grid>
            <Grid item xs={6}>
                <Select fullWidth native value={item.productVariantPriceGroupId} onChange={(ev) => { onPriceGroupChanged(ev.target.value as string); }}>
                    {priceGroups.map(pg => {
                        return <option value={pg.id}>{pg.Name} ({pg.price}kr)</option>
                    })}
                </Select>
            </Grid>
            {occations && <>
                <Grid item xs={6}>Tillfälle</Grid>
                <Grid item xs={6}>
                    <Select fullWidth native value={item.productVariantOccationId} onChange={(ev) => { onOccationChanged(ev.target.value as string); }}>
                        {occations.map(occ => {
                            return <option value={occ.id}>{occ.name}</option>
                        })}
                    </Select>
                </Grid>
            </>}

            <Grid item xs={6}>Anledning till ändring</Grid>
            <Grid item xs={6}><TextField fullWidth value={text} onChange={(ev) => {
                onTextChanged(ev.target.value);
            }} />
            </Grid>
        </Grid>;
    }
    return render();
};

export default ManageOrderEditItemsChangeItem;
