import React from "react";
import { Button, ButtonProps } from "@material-ui/core";
import FirebaseContext from "../../../providers/Firebase/context";
import { Profile } from "../../../model/Profile";

const AdminButton = (props: ButtonProps) => {

    const render = () => {
        return <FirebaseContext.Consumer>
            {(value: { profile: Profile }) => {
                const isAdmin = value.profile.isInRole("admin");
                const disabled = !isAdmin || props.disabled;

                return <Button {...props} disabled={disabled} />
            }}
        </FirebaseContext.Consumer>
    };

    return render();
};

export default AdminButton;
