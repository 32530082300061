import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, Select, Typography, useTheme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Alert from "@material-ui/lab/Alert/Alert";
import React, { useEffect, useRef, useState } from "react";
import { Entry, newEmptyEntry } from "../../../../model/Entry";
import { Order, initNewOrder } from "../../../../model/Order";
import { OrderItem } from "../../../../model/OrderItem";
import { Product } from "../../../../model/Product";
import { ApiBackend } from "../../../../providers/apibackend";
import ReadonlyTextField from "../../../Common/ReadonlyTextField";
import { vasaloppetTimeGetterFormatter } from "../../../../utilities/date";
import { B2bCompany, B2bCompanyProduct } from "../../../../model/B2bCompany";
import { OrderContact } from "../../../../model/OrderContact";
import { awaiter } from "./utils";
import withToaster, { IToasterContext } from "../../../Common/Toaster";

interface IProps {
    company: B2bCompany;
    companyProduct: B2bCompanyProduct
    onSave: () => void;
    onAbort: () => void;
}

const AddCompanyEntriesDialog = ({ showToast, onSave, onAbort, company, companyProduct }: IProps & IToasterContext) => {
    const [race, setRace] = useState(null as string);
    const [variant, setVariant] = useState(null as string);
    const [priceGroup, setPriceGroup] = useState(null as string);
    const [raceProduct, setRaceProduct] = useState(null as Product);

    const [maxNumItems, setMaxNumItems] = useState(30);
    const [numItems, setNumItems] = useState(1);
    const [orderContact, setOrderContact] = useState(null as Pick<OrderContact, "firstName" | "lastName" | "email" | "phone">)
    const [invoiceComment, setInvoiceComment] = useState("");
    const [entry, setEntry] = useState(null as Entry);
    const [step, setStep] = useState("entry");
    const [price, setPrice] = useState(0);
    const [estimating, setEstimating] = useState(false);
    const [isCreatingOrder, setIsCreatingOrder] = useState(false);
    const [createOrderStatus, setCreateOrderStatus] = useState("");
    const [hasOrderItems, setHasOrderItems] = useState(false);
    const [hasStartGroupRequiredError, setHasStartGroupRequiredError] = useState(false);
    const [hasFirstNameRequiredError, setHasFirstNameRequiredError] = useState(false);
    const [hasLastNameRequiredError, setHasLastNameRequiredError] = useState(false);
    const [hasEmailRequiredError, setHasEmailRequiredError] = useState(false);
    const [hasPhoneRequiredError, setHasPhoneRequiredError] = useState(false);
    const initialized = useRef<boolean>(false);

    const backend = new ApiBackend();
    const theme = useTheme();

    // Run on load
    useEffect(() => {
        const doWork = async (): Promise<void> => {
            const getProduct = async (): Promise<void> => {
                const product = await backend.getProduct(companyProduct.productId);
                setEntry(newEmptyEntry({
                    productId: companyProduct.productId,
                    variantId: companyProduct.productVariantId,
                    priceGroupId: companyProduct.productVariantPriceGroupId
                }));
                setRaceProduct(product);
                setRace(companyProduct.productId);
                setVariant(companyProduct.productVariantId);
                setPriceGroup(companyProduct.productVariantPriceGroupId);
                initialized.current = true;
            };

            await Promise.all([getProduct()]);
        };

        doWork();
    }, []);

    useEffect(() => {
        updatePrice();
    }, [priceGroup, numItems]);

    useEffect(() => {
        if (step === "order") {
            setIsCreatingOrder(false);
        }
    }, [step]);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }

        if (!raceProduct?.startGroupAllocationActive) {
            setHasStartGroupRequiredError(false);
        }

        setHasStartGroupRequiredError(!entry?.startgroup);
    }, [entry]);

    useEffect(() => {
        if (!initialized.current) {
            return;
        }

        setHasFirstNameRequiredError(!orderContact?.firstName);
        setHasLastNameRequiredError(!orderContact?.lastName);
        setHasEmailRequiredError(!orderContact?.email);
        setHasPhoneRequiredError(!orderContact?.phone);
    }, [orderContact]);

    const getEstimateOrder = (): Order => {
        const order = initNewOrder();

        order.items = [
            {
                productId: race,
                productVariantId: variant,
                productVariantPriceGroupId: priceGroup,
                numberOfProducts: numItems,
                entry: entry,
            } as OrderItem,
        ];

        return order;
    };

    const getCreateOrder = (): Order => {
        const order = initNewOrder();

        order.locale = "se";;
        order.b2bId = company.id;

        order.contact = orderContact as OrderContact;

        let orderItems: OrderItem[] = [];

        const metaDataItems: { key: string; value: any }[] = [
            { key: "b2b_id", value: company.id },
            {
                key: "b2b_profile", value: {
                    companyId: company.id,
                    contactFirstName: orderContact.firstName,
                    contactLastName: orderContact.lastName,
                    contactEmail: orderContact.email,
                    contactMobilePhone: orderContact.phone
                }
            },
            { key: "b2b_invoice_comment", value: invoiceComment.trim() }
        ];

        for (let i = 0; i < numItems; i++) {
            orderItems.push({
                productId: race,
                productVariantId: variant,
                productVariantPriceGroupId: priceGroup,
                numberOfProducts: 1,
                entry: newEmptyEntry(entry),
                metadata: metaDataItems,
            } as OrderItem);
        }

        order.items = orderItems;

        return order;
    };

    const updatePrice = async (): Promise<void> => {
        if (!race || !variant) {
            setPrice(0);
            return;
        }

        setEstimating(true);

        const res = await backend.adminEstimateOrder(getEstimateOrder());
        setPrice(res.totalPrice);

        if (!res.order.items || res.order.items.length === 0) {
            setHasOrderItems(false);
        } else {
            // order.items = res.order.items;
            setHasOrderItems(true);
        }

        setEstimating(false);
        // setOrder({ ...order });
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEntry({
            ...entry,
            [e.target.name]: e.target.value
        })
    };

    const handleNumItemsChange = (event: React.ChangeEvent<{ value: number }>): void => {
        const value = event.target.value;
        let nextMaxNumItems = maxNumItems;

        // If value is greater than maxNumItems, increase maxNumItems by 10…
        if (value == maxNumItems) {
            if (value % 10 === 0) {
                nextMaxNumItems = maxNumItems + 10;
            }
        }

        setNumItems(value);
        setMaxNumItems(nextMaxNumItems);
    };

    const handleOrderContactChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setOrderContact({
            ...orderContact,
            [e.target.name]: e.target.value
        })
    };

    const handleInvoiceCommentChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setInvoiceComment(e.target.value);
    };

    const handleCompanyAdminChange = (event: React.ChangeEvent<{ value: number }>): void => {
        const value = event.target.value;

        if (value in company.admins) {
            const admin = company.admins[value];
            setOrderContact({
                ...orderContact,
                firstName: admin.firstName,
                lastName: admin.lastName,
                email: admin.email,
                phone: admin.mobilePhone
            });
        }
    };

    const createEntry = async (): Promise<void> => {
        setIsCreatingOrder(true);
        setCreateOrderStatus("Skapar order");

        try {
            const res = await backend.adminCreateOrder(getCreateOrder());

            if (!res) {
                throw new Error("Någonting gick fel när order skulle skapas");
            }

            setIsCreatingOrder(false);
            setCreateOrderStatus("Klart!");
            await awaiter(3000);
            onSave();
        } catch (err) {
            showToast("Någonting gick fel när order skulle skapas", "error");
        } finally {
            setIsCreatingOrder(false);
        }
    };

    const renderOrderStep = () => {
        const defaultBoxStyle = {
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            style: { gap: "16px" },
        };

        return <>
            <Grid container spacing={2}>
                <Grid item xs={12}
                    style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                    Sammanställning
                </Grid>
                <Grid item xs={12}>
                    <Box {...defaultBoxStyle}>
                        <Box>
                            {companyProduct?.name}, {numItems} st för totalt <b>{price} kr</b>
                        </Box>
                        <Box textAlign={"left"}>
                            <Alert severity="info">
                                <Box display={"flex"} flexDirection={"column"} style={{ gap: 8 }}>
                                    <Typography variant="body2">
                                        <strong>Uppgifterna nedan kommer att användas vid fakturering</strong>
                                    </Typography>
                                    <Typography variant="body2">
                                        <ul>
                                            <li>
                                                <strong>Företagsnamn:</strong> {company.name}
                                            </li>
                                            <li>
                                                <strong>Adress:</strong> {company.address}
                                            </li>
                                            <li>
                                                <strong>Postnummer:</strong> {company.postCode}
                                            </li>
                                            <li>
                                                <strong>Postort:</strong> {company.city}
                                            </li>
                                            <li>
                                                <strong>Land:</strong> {company.country}
                                            </li>
                                            <li>
                                                <strong>E-post:</strong> {company.email}
                                            </li>
                                            <li>
                                                <strong>Kommentar på faktura:</strong> {invoiceComment ?? "-"}
                                            </li>
                                        </ul>
                                    </Typography>
                                </Box>
                            </Alert>
                        </Box>
                    </Box>
                </Grid>

                {isCreatingOrder && <>
                    <Grid item xs={12}
                        style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                        <CircularProgress />
                        {createOrderStatus}
                    </Grid>
                </>}
            </Grid>
        </>;
    };

    const renderEntryStep = () => {
        const selectedVariant = raceProduct?.variants?.find(x => x.Id === variant);
        const selectedPriceGroup = selectedVariant?.priceGroups?.find(x => x.id === priceGroup);

        return <Grid container spacing={2}>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={companyProduct?.name}
                    label="Lopp"
                />
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel shrink>Antal</InputLabel>
                    <Select
                        native
                        fullWidth
                        id={"entry_num_items"}
                        value={numItems ?? ""}
                        onChange={handleNumItemsChange}
                    >
                        {Array.from(Array(maxNumItems).keys()).map((i) => (
                            <option key={i + 1} value={i + 1}>
                                {i + 1}
                            </option>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={selectedVariant?.Name ?? ""}
                    label="Variant"
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={selectedPriceGroup?.Name ?? ""}
                    label="Prisgrupp"
                />
            </Grid>

            {variant && hasOrderItems && <>
                <Grid item xs={12}
                    style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                >
                    Personuppgifter (Orderkontakt)
                </Grid>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <InputLabel shrink>Företagsadmin</InputLabel>
                        <Select
                            native
                            fullWidth
                            id={"entry_num_items"}
                            defaultValue=""
                            onChange={handleCompanyAdminChange}
                        >
                            <option key="" value=""></option>
                            {company.admins?.map((x, idx) => (
                                <option key={idx} value={idx}>
                                    {x.firstName} {x.lastName}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Förnamn"
                        name="firstName"
                        fullWidth
                        error={hasFirstNameRequiredError}
                        InputLabelProps={{ shrink: true }}
                        value={orderContact?.firstName ?? ""}
                        onChange={handleOrderContactChange}
                    />
                    {hasFirstNameRequiredError && <FormHelperText error>Måste fyllas i.</FormHelperText>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Efternamn"
                        name="lastName"
                        fullWidth
                        error={hasLastNameRequiredError}
                        InputLabelProps={{ shrink: true }}
                        value={orderContact?.lastName ?? ""}
                        onChange={handleOrderContactChange}
                    />
                    {hasLastNameRequiredError && <FormHelperText error>Måste fyllas i.</FormHelperText>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="E-post"
                        name="email"
                        fullWidth
                        error={hasEmailRequiredError}
                        InputLabelProps={{ shrink: true }}
                        value={orderContact?.email ?? ""}
                        onChange={handleOrderContactChange}
                    />
                    {hasEmailRequiredError && <FormHelperText error>Måste fyllas i.</FormHelperText>}
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label="Tel."
                        name="phone"
                        fullWidth
                        error={hasPhoneRequiredError}
                        InputLabelProps={{ shrink: true }}
                        value={orderContact?.phone ?? ""}
                        onChange={handleOrderContactChange}
                    />
                    {hasPhoneRequiredError && <FormHelperText error>Måste fyllas i.</FormHelperText>}
                </Grid>

                <Grid item xs={12}>
                    <TextField
                        label="Kommentar på faktura"
                        name="invoiceComment"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={invoiceComment}
                        onChange={handleInvoiceCommentChange}
                    />
                </Grid>

                <Grid item xs={12}
                    style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                >
                    Loppspecifika uppgifter
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        name="prePackingGroup"
                        label="Packningsgrupp"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={entry.prePackingGroup || ""}
                        onChange={handleChange} />
                </Grid>
                <Grid item xs={6}>
                    {raceProduct && <FormControl fullWidth error={hasStartGroupRequiredError}>
                        <InputLabel shrink>Startgrupp</InputLabel>
                        <Select
                            native
                            name="startgroup"
                            value={entry.startgroup || ""}
                            label="Startled"
                            onChange={handleChange}
                        >
                            <option key="_" value=""></option>
                            {raceProduct.startGroups.map((gr) => {
                                if (!gr.participantCategory || gr.participantCategory == variant) {
                                    return <option key={gr.id} value={gr.id}>{gr.name} ({gr.maxParticipants - gr.numberOfOccupiedPlaces - gr.numberOfReservedPlaces} platser kvar), {vasaloppetTimeGetterFormatter(gr.startTime)}</option>
                                }
                            })}
                        </Select>
                        {hasStartGroupRequiredError && <FormHelperText>Startledstilldelning är aktiv. Vänligen välj en startgrupp.</FormHelperText>}
                    </FormControl>}
                </Grid>
            </>}
        </Grid>
    };

    const nextStepFn = (): string | null => {
        switch (step) {
            case "entry":
                return "order";
            default:
                return null;
        }
    };

    const prevStepFn = (): string | null => {
        switch (step) {
            case "entry":
                return null;
            case "order":
                return "entry";
            case "summary":
                return "order";
            default:
                return null;
        }
    };

    const prevStep = prevStepFn();
    const nextStep = nextStepFn();

    const isEntryValid = (): boolean => {
        let hasErrors = false;

        // Reset
        setHasStartGroupRequiredError(false);
        setHasFirstNameRequiredError(false);
        setHasLastNameRequiredError(false);
        setHasEmailRequiredError(false);
        setHasPhoneRequiredError(false);

        if (raceProduct.startGroupAllocationActive) {
            if (!entry?.startgroup) {
                setHasStartGroupRequiredError(true);
                hasErrors = true;
            }
        }

        if (!orderContact?.firstName) {
            setHasFirstNameRequiredError(true);
            hasErrors = true;
        }

        if (!orderContact?.lastName) {
            setHasLastNameRequiredError(true);
            hasErrors = true;
        }

        if (!orderContact?.email) {
            setHasEmailRequiredError(true);
            hasErrors = true;
        }

        if (!orderContact?.phone) {
            setHasPhoneRequiredError(true);
            hasErrors = true;
        }

        return !hasErrors;
    };

    const handleNextStepRequest = (next: string) => {
        if (next === "order" && !isEntryValid()) {
            return;
        }

        setStep(next);
    };

    const showCreateEntryBtn = () => price === 0;

    const renderEntryForm = () => (<>
        <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="add-relay-member-title"
            open={true}>
            <DialogTitle id="add-relay-member-title">Lägg till anmälningar</DialogTitle>
            <DialogContent dividers style={{ minHeight: 300 }}>
                {step == "entry" && renderEntryStep()}
                {step == "order" && renderOrderStep()}
            </DialogContent>
            <DialogActions>
                <Box style={{ display: "flex", lineHeight: "37px", width: "100%" }}>
                    <Box style={{ flex: 1 }}></Box>
                    <Box style={{ width: 200 }}>
                        Totalt pris: {estimating ?
                            <CircularProgress color="secondary" />
                            : <b>{price} kr</b>
                        }
                    </Box>
                    <Box style={{ display: "flex", flex: 1, gap: 8, justifyContent: "flex-end" }}>
                        {prevStep &&
                            <Button
                                variant="contained"
                                onClick={() => setStep(prevStep)}
                                color="secondary">Föregående</Button>
                        }
                        {nextStep && variant && hasOrderItems &&
                            <Button
                                variant="contained"
                                disabled={estimating}
                                onClick={() => handleNextStepRequest(nextStep)}
                                color="secondary">Nästa</Button>
                        }
                        {step == "order" && showCreateEntryBtn() &&
                            <Button
                                variant="contained"
                                disabled={estimating}
                                onClick={() => { createEntry() }}
                                color="secondary">Skapa anmälan</Button>
                        }

                        <Button
                            variant="contained"
                            onClick={() => onAbort()}
                            color="secondary">Avbryt</Button>
                    </Box>
                </Box>
            </DialogActions>
        </Dialog>
    </>);

    return renderEntryForm();
};

export default withToaster(AddCompanyEntriesDialog);
