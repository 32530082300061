import MenuItem from "@material-ui/core/MenuItem";
import React, { useEffect, useState } from "react";
import { ApiBackend } from "../../providers/apibackend";
import ClearableSelect from "../Common/ClearableSelect";
import { POSSetting } from "../../model/POSSetting";

class Props {
    initialValue?: string;
    clearable?: boolean;
    posSettingSelected: (pointOfSale?: POSSetting) => void;
}

const SelectPosSetting = ({ initialValue, clearable, posSettingSelected }: Props) => {
    const [posSetting, setPosSetting] = useState(initialValue ?? "");
    const [posSettings, setPosSettings] = useState(null as POSSetting[]);

    useEffect(() => {
        new ApiBackend().getGeneral("pos").then((res: POSSetting[]) => {
            res.sort((x, y) => x.Name < y.Name ? -1 : 1);
            setPosSettings(res);
        });
    }, []);

    return (
        <ClearableSelect
            value={posSetting}
            clearable={clearable}
            onChange={(ev) => {
                const posId = ev.target.value as string;
                const pos = posSettings.find(x => x.Id === posId);
                setPosSetting(posId);
                posSettingSelected(pos);
            }}
            onClear={() => {
                setPosSetting("");
                posSettingSelected(null);
            }}
        >
            {posSettings && posSettings.map(x => {
                return <MenuItem key={x.Id} value={x.Id}>{x.Name}</MenuItem>
            })}
        </ClearableSelect>
    )
}

export default SelectPosSetting;
