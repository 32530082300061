import { Box, Checkbox, Divider, FormControlLabel, Grid, TextField } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Fragment } from "react";
import { ProductVariantPriceGroup } from "../../model/ProductVariantPriceGroup";
import { vasaloppetDateGetterFormatter, vasaloppetIsoDateSetterFormatter, vasaloppetMoment } from "../../utilities/date";
import VasaloppetFieldset from "../Common/VasaloppetFieldset";

interface IProps {
    editLocale: string;
    forRace: boolean;
    priceGroups: ProductVariantPriceGroup[];
    onChange: (priceGroups: ProductVariantPriceGroup[]) => void;
}

const dividerStyle = { borderTop: "2px solid #2772b6", marginTop: 10, marginBottom: 10 };

class EditPriceGroups extends React.Component<IProps> {
    private readonly defaultDate = "";

    constructor(props: IProps) {
        super(props);
    }

    render() {
        const { forRace } = this.props;

        if (forRace) {
            return this.renderForRace();
        }

        return this.renderDefault();
    }

    private renderForRace(): JSX.Element {
        const { priceGroups } = this.props;

        return (<>
            {priceGroups.map((pg, idx) => {
                const hasDivider = idx > 0;

                return <Fragment key={pg.id}>
                    {hasDivider &&
                        <Grid item xs={12}>
                            <Divider style={dividerStyle} />
                        </Grid>
                    }
                    <Grid item xs={2}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Name_En : pg.Name) ?? ''} label="Namn" onChange={this.handleCulturePropValueChanged(idx, "Name")} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.LongName_En : pg.LongName) ?? ''} label={"Långt namn"} onChange={this.handleCulturePropValueChanged(idx, "LongName")} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Description_En : pg.Description) ?? ''} label={"Beskrivning"} onChange={this.handleCulturePropValueChanged(idx, "Description")} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="number" value={pg.price ?? ''} label={"Pris"} onChange={this.handlePropNumericValueChanged(idx, "price")} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" height="100%" alignItems="center">
                            <Box >
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={this.handleDelete(idx)} />
                                {idx > 0 &&
                                    <ExpandLess style={{ cursor: 'pointer' }} onClick={this.handleMoveUp(idx)} />
                                }
                                {idx < priceGroups.length - 1 &&
                                    <ExpandMore style={{ cursor: 'pointer' }} onClick={this.handleMoveDown(idx)} />
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validFrom && vasaloppetMoment(pg.validFrom).isValid() ? vasaloppetDateGetterFormatter(pg.validFrom) : this.defaultDate}
                            label={"Öppen från"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validFrom")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validTo && vasaloppetMoment(pg.validTo).isValid() ? vasaloppetDateGetterFormatter(pg.validTo) : this.defaultDate}
                            label={"Öppen till"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validTo")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="number" InputProps={{ inputProps: { min: 0, max: 200 } }} value={pg.maxAge ?? ''} label={"Max ålder"} onChange={this.handlePropNumericValueChanged(idx, "maxAge")} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel label="Publikt synlig"
                            control={
                                <Checkbox checked={pg.isPublic} onChange={this.handlePropCheckedChanged(idx, "isPublic")} />
                            }
                        />
                    </Grid>
                    <Grid item xs={2}></Grid>
                </Fragment>
            })}
        </>);
    }

    private renderDefault(): JSX.Element {
        const { priceGroups } = this.props;

        return (<>
            {priceGroups.map((pg, idx) => {
                const hasDivider = idx > 0;

                return <Fragment key={pg.id}>
                    {hasDivider &&
                        <Grid item xs={12}>
                            <Divider style={dividerStyle} />
                        </Grid>
                    }
                    <Grid item xs={2}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Name_En : pg.Name) ?? ''} label={"Namn"} onChange={this.handleCulturePropValueChanged(idx, "Name")} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.LongName_En : pg.LongName) ?? ''} label={"Långt namn"} onChange={this.handleCulturePropValueChanged(idx, "LongName")} fullWidth />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField value={(this.props.editLocale == "EN" ? pg.Description_En : pg.Description) ?? ''} label={"Beskrivning"} onChange={this.handleCulturePropValueChanged(idx, "Description")} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField value={pg.type ?? ''} label="Typ" onChange={this.handlePropValueChanged(idx, "type")} fullWidth />
                    </Grid>
                    <Grid item xs={1}>
                        <TextField type="number" value={pg.price ?? ''} label="Pris" onChange={this.handlePropNumericValueChanged(idx, "price")} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" justifyContent="flex-end" height="100%" alignItems="center">
                            <Box >
                                <DeleteIcon style={{ cursor: 'pointer' }} onClick={this.handleDelete(idx)} />
                                {idx > 0 &&
                                    <ExpandLess style={{ cursor: 'pointer' }} onClick={this.handleMoveUp(idx)} />
                                }
                                {idx < priceGroups.length - 1 &&
                                    <ExpandMore style={{ cursor: 'pointer' }} onClick={this.handleMoveDown(idx)} />
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validFrom && vasaloppetMoment(pg.validFrom).isValid() ? vasaloppetDateGetterFormatter(pg.validFrom) : this.defaultDate}
                            label={"Öppen från"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validFrom")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField type="date"
                            InputProps={{ inputProps: { min: "2020-05-01", max: "9999-12-31" } }}
                            value={pg.validTo && vasaloppetMoment(pg.validTo).isValid() ? vasaloppetDateGetterFormatter(pg.validTo) : this.defaultDate}
                            label={"Öppen till"}
                            InputLabelProps={{ shrink: true }}
                            onChange={this.handlePropValueChanged(idx, "validTo")}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <FormControlLabel label="Publikt synlig"
                            control={
                                <Checkbox checked={pg.isPublic} onChange={this.handlePropCheckedChanged(idx, "isPublic")} />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <VasaloppetFieldset title="Tillgänglighet" style={{ marginRight: 10 }}>
                            <FormControlLabel label={"Enbart online"}
                                control={
                                    <Checkbox checked={pg.validOnlineOnly} onChange={this.handlePropCheckedChangedToggleRelated(idx, "validOnlineOnly", "validPOSOnly", true)} />
                                }
                            />
                            <FormControlLabel label={"Enbart POS"}
                                control={
                                    <Checkbox checked={pg.validPOSOnly} onChange={this.handlePropCheckedChangedToggleRelated(idx, "validPOSOnly", "validOnlineOnly", true)} />
                                }
                            />
                            <FormControlLabel label="Vid köp som tillägg"
                                control={
                                    <Checkbox checked={pg.enabledForAddons} onChange={this.handlePropCheckedChangedToggleRelated(idx, "enabledForAddons", "enabledForAddonsNewRegistration", false)} />
                                }
                            />
                        </VasaloppetFieldset>

                        <VasaloppetFieldset title="Giltighet">
                            <FormControlLabel label="Enbart som köp av tillägg vid nyanmälan" disabled={!pg.enabledForAddons}
                                control={
                                    <Checkbox checked={pg.enabledForAddonsNewRegistration} onChange={this.handlePropCheckedChanged(idx, "enabledForAddonsNewRegistration")} />
                                }
                            />
                        </VasaloppetFieldset>
                    </Grid>
                </Fragment>
            })}
        </>);
    }

    private handlePropValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        let value = ev.target.value;

        if (propName === "validFrom") {
            value = vasaloppetIsoDateSetterFormatter(value);
        }

        if (propName === "validTo") {
            value = vasaloppetIsoDateSetterFormatter(value, true);
        }

        (priceGroups[idx] as any)[propName] = value;
        onChange(priceGroups);
    };

    private handlePropNumericValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        (priceGroups[idx] as any)[propName] = +ev.target.value;
        onChange(priceGroups);
    };

    private handlePropCheckedChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        (priceGroups[idx] as any)[propName] = ev.target.checked;

        onChange(priceGroups);
    };

    /**
     * 
     * @param idx 
     * @param propName 
     * @param relPropName Related propertyName to toggle to false when condition is met
     * @param whenChecked If toggle should occur when target is checked och unchecked
     * @returns 
     */
    private handlePropCheckedChangedToggleRelated = (idx: number, propName: string, relPropName: string, whenChecked: boolean) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;
        (priceGroups[idx] as any)[propName] = ev.target.checked;

        if (whenChecked && ev.target.checked) {
            (priceGroups[idx] as any)[relPropName] = false;
        } else if (!whenChecked && !ev.target.checked) {
            (priceGroups[idx] as any)[relPropName] = false;
        }

        onChange(priceGroups);
    };

    private handleCulturePropValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>): void => {
        const { priceGroups, onChange } = this.props;

        if (this.props.editLocale == "EN") {
            propName += "_En";
        }

        (priceGroups[idx] as any)[propName] = ev.target.value;
        onChange(priceGroups);
    };

    private handleDelete = (idx: number) => (): void => {
        const { priceGroups, onChange } = this.props;

        if (priceGroups.length === 1) {
            alert("Det måste finnas minst en prisgrupp!");
            return;
        }

        priceGroups.splice(idx, 1);
        onChange(priceGroups);
    };

    private handleMoveUp = (idx: number) => (): void => {
        const { priceGroups, onChange } = this.props;

        if (idx > 0) {
            const rem = priceGroups.splice(idx, 1);
            priceGroups.splice(idx - 1, 0, rem[0]);
            onChange(priceGroups);
        }
    };

    private handleMoveDown = (idx: number) => (): void => {
        const { priceGroups, onChange } = this.props;

        if (idx < priceGroups.length - 1) {
            const rem = priceGroups.splice(idx, 1);
            priceGroups.splice(idx + 1, 0, rem[0]);

            onChange(priceGroups);
        }
    };
}

export default EditPriceGroups;
