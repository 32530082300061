import { Sex } from "../../../model/CommonTypes";
import { StartGroup } from "../../../model/StartGroup";
import { Subset } from "../../../typings/GlobalExtensions";

export type SexAvailability = Subset<Sex, "W" | "M"> | "ALL";

const hasBibWomen = (sg: StartGroup): boolean => {
    return !!(sg.bibNumberFromWomen && sg.bibNumberToWomen);
};

const hasBibMen = (sg: StartGroup): boolean => {
    return !!(sg.bibNumberFrom && sg.bibNumberTo);
};

export const getStartGroupSexAvailability = (sg: StartGroup): SexAvailability => {
    const hasForWomen = hasBibWomen(sg);
    const hasForMen = hasBibMen(sg);

    if (!hasForWomen && !hasForMen) {
        return "ALL";
    }

    if (hasForWomen && hasForMen) {
        return "ALL";
    }

    if (!hasForWomen) {
        return "M";
    } else {
        return "W";
    }
};

export const getAvailabilityTitle = (sexAvailability: SexAvailability, short?: boolean) => {
    switch (sexAvailability) {
        case "W":
            return short ? "Kvinnor" : "Endast tillgängligt för kvinnor";
        case "M":
            return short ? "Män" : "Endast tillgängligt för män";
        default:
            return short ? "Alla" : "Tillgängligt för alla";
    }
};

export const getAvailabilityTitleFromGroup = (sg: StartGroup) => {
    const sexAvailability = getStartGroupSexAvailability(sg);

    return getAvailabilityTitle(sexAvailability, true);
};
