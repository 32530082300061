import React from "react";
import { Order } from "../../../model/Order";
import { getMetadataValue, hasCompanyMetadata } from "./utils";
import { Box, Card, CardContent, CardHeader, Grid, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";

interface Props {
    order: Order;
};


const ManageOrderCompanyMetadata = ({ order, classes }: Props & WithStyles) => {

    const render = () => {
        if (!hasCompanyMetadata(order)) {
            return null;
        }

        return (
            <Card>
                <CardHeader className={classes.cardHeader} title="Faktureringsinformation" />
                <CardContent>
                    <Box display={"flex"} flexDirection={"column"} style={{ gap: "0.25em" }}>
                        <Box padding={1}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <span>Märkning</span>
                                </Grid>
                                <Grid item xs={6}>
                                    {getMetadataValue(order, "b2b_invoice_tag")}
                                </Grid>
                            </Grid>
                        </Box>
                        <Box padding={1}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs={6}>
                                    <span>Kommentar</span>
                                </Grid>
                                <Grid item xs={6}>
                                    {getMetadataValue(order, "b2b_invoice_comment")}
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        );
    };

    return render();
};

const useStyles = ({ palette }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
});

export default withStyles(useStyles)(ManageOrderCompanyMetadata);
