import { Box, Button, Card, CardActions, CardContent, CardHeader, Grid, IconButton, WithStyles } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { FieldArray, FormikProps } from "formik";
import React, { useState } from "react";
import { ProductBundle, ProductBundleGroup } from "../../model/ProductBundle";
import FormTextField from "../Common/FormTextField";
import EditBundleGroupDialog from "./EditBundleGroupDialog";

type Props = FormikProps<ProductBundle> & WithStyles;

const EditBundleGroups = (props: Props) => {
    const [editGroup, setEditGroup] = useState(null as ProductBundleGroup);
    const [editGroupIdx, setEditGroupIdx] = useState(null as number);

    const render = (): JSX.Element => {
        const { classes, values } = props;

        if (!values) {
            return null;
        }

        return (
            <Card>
                <FieldArray
                    name="groups"
                    render={arrayHelpers => (
                        <>
                            <CardHeader
                                className={classes.cardHeader}
                                title="Hantera grupper"
                            />
                            <CardContent>
                                {values.groups.map((grp, idx) => {
                                    return (
                                        <Grid container key={idx} spacing={2}>
                                            <Grid item xs={4}>
                                                <FormTextField {...props}
                                                    disabled={true}
                                                    propName="name"
                                                    valueGetter={() => grp.name ?? ""}
                                                    label="Namn"
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <FormTextField {...props}
                                                    disabled={true}
                                                    propName="description"
                                                    valueGetter={() => grp.description ?? ""}
                                                    label="Beskrivning"
                                                />
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box display="flex" justifyContent="flex-end">
                                                    {idx !== 0 && <ExpandLess
                                                        className={classes.icon}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            arrayHelpers.move(idx, idx - 1);
                                                        }}
                                                    />
                                                    }
                                                    {idx !== values.groups.length - 1 && <ExpandMore
                                                        className={classes.icon}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            arrayHelpers.move(idx, idx + 1);
                                                        }}
                                                    />
                                                    }
                                                    <IconButton
                                                        onClick={() => { 
                                                            setEditGroup(grp);
                                                            setEditGroupIdx(idx);
                                                         }}>
                                                        <EditIcon />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => { arrayHelpers.remove(idx); }}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                })}
                            </CardContent>
                            <CardActions>
                                <Button variant="contained"
                                    onClick={() => {
                                        const grp = new ProductBundleGroup();
                                        grp.selectionMode = "single";
                                        setEditGroup(grp);
                                    }}>
                                    Lägg till grupp
                                </Button>
                            </CardActions>

                            {editGroup &&
                                <EditBundleGroupDialog {...props}
                                    bundleGroup={editGroup}
                                    mode={editGroup?.id ? "edit" : "create"}
                                    onAbort={() => {
                                        setEditGroup(null);
                                        setEditGroupIdx(null);
                                    }}
                                    onSave={(group: ProductBundleGroup) => {
                                        if (editGroupIdx !== null) {
                                            arrayHelpers.replace(editGroupIdx, group);
                                        } else {
                                            arrayHelpers.push(group);
                                        }

                                        setEditGroup(null);
                                        setEditGroupIdx(null);
                                    }}
                                />
                            }
                        </>)} />
            </Card>
        )
    }

    return render();
}

export default EditBundleGroups;