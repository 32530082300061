import { Order } from "../../../model/Order";

export const isEqualWithTolerance = (v1: number, v2: number) => {
    return Math.abs(v1 - v2) < 1e-5;
};

export const getTotalAmountPaid = (order: Order) => {
    return +(order.totalAmountPaiedOnOrder.toFixed(10));
};

export const getPaymentDiff = (order: Order) => {
    return order.totalSum - getTotalAmountPaid(order);
};
