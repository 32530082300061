/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent, Theme, withStyles, createStyles, Grid, LinearProgress, Typography, Button } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { DiplomaOrderItem } from '../../model/Order';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DataGrid, GridColDef, GridValueFormatterParams, GridRowData, GridComponentProps, GridSelectionModel } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ManageOrder from './ManageOrder';
import { DATE_FORMAT, vasaloppetDateTimeGetterFormatter, vasaloppetMoment } from '../../utilities/date';

interface State {
    loading: boolean;
    orders: DiplomaOrderItem[],
    viewOrder: string;
    selectedRows?: GridRowData[]
    numToUpdate?: number;
    numUpdated?: number;
    confirmMarkDelivered?: boolean;
}

class ListDiplomaOrders extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: false, orders: [], viewOrder: null };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        let back = new ApiBackend();
        this.setState({ loading: true });
        back.listDiplomaOrders().then((orders) => {
            this.setState({ orders: orders, loading: false });
        });
    }


    openOrder = (orderId: string) => () => {
        this.setState({ viewOrder: orderId });
    }

    render() {
        let { classes } = this.props as any;
        const columns: GridColDef[] = [
            {
                field: 'orderPublicId', headerName: 'OrderID', width: 120,

                renderCell: (params: GridValueFormatterParams) => {
                    return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.openOrder(params.row.orderId)}>{params.row.orderPublicId}</Typography>
                }
            },
            { field: 'RaceInfo.name', headerName: 'Lopp', width: 100, valueGetter: (params) => { return params.row.RaceInfo?.eventKey } },
            { field: 'RaceInfo.bib', headerName: 'Startnr', width: 100, valueGetter: (params) => { return params.row.RaceInfo?.bib } },
            { field: 'participant', headerName: 'Deltagare', width: 250, valueGetter: (params) => { return params.row.RaceInfo?.firstName + " " + params.row.RaceInfo?.lastName } },
            { field: 'productName', headerName: 'Produkt', width: 170, valueGetter: (params) => { return params.row.productName } },
            { field: 'variantName', headerName: 'Variant', width: 250, valueGetter: (params) => { return params.row.productVariantName } },
            { field: 'contact.name', headerName: 'Leverans Namn', width: 170, valueGetter: (params) => { return params.row.contact.firstName + " " + (params.row.contact.lastName ?? '') } },
            { field: 'contact.email', headerName: 'Leverans Email', width: 170, valueGetter: (params) => { return params.row.contact.email } },
            { field: 'contact.phone', headerName: 'Leverans Tel', width: 170, valueGetter: (params) => { return params.row.contact.phone } },
            { field: 'contact.address', headerName: 'Leverans address', width: 250, valueGetter: (params) => { return params.row.contact.address + " ," + params.row.contact.postalcode + " " + params.row.contact.city } },
            { field: 'contact.country', headerName: 'Leverans land', width: 100, valueGetter: (params) => { return params.row.contact.country } },
            { field: 'Created', headerName: 'Skapad', width: 170, valueGetter: (params) => { return vasaloppetDateTimeGetterFormatter(params.row.created) } }
        ];

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Hantera</Typography></Fragment>} />
                    <CardContent>

                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={6}><Button variant="contained" disabled={!this.state.selectedRows || this.state.selectedRows.length == 0} onClick={this.markAsDelivered}>Markera valda som levererade</Button></Grid>
                            <Grid item xs={6}><Button variant="contained" disabled={!this.state.selectedRows || this.state.selectedRows.length == 0} onClick={this.exportExcel}>Exportera valda till Excel</Button></Grid>
                            {this.state.confirmMarkDelivered && <Grid item xs={12}>
                                <Typography variant="body2">Vill du markera {this.state.selectedRows.length} rader som levererade?</Typography>
                                <Button variant="contained" onClick={this.runMarkDelivered}>Ja</Button>
                                <Button variant="contained" onClick={() => { this.setState({ confirmMarkDelivered: false }) }}>Avbryt</Button>
                            </Grid>}
                            {this.state.numToUpdate > 0 && <Grid item xs={12}>
                                <LinearProgress color="secondary" value={this.state.numUpdated / this.state.numToUpdate * 100} />
                            </Grid>}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>

                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Ej levererade diplombeställnignar</Typography>
                        <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} />
                    </Fragment>} />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        {this.state.orders && <DataGrid
                            onSelectionModelChange={(ev: GridSelectionModel) => {
                                const rows = this.state.orders.filter(x => ev.some(y => y == x.id));
                                this.setState({ selectedRows: rows });
                            }}
                            autoHeight
                            checkboxSelection loading={this.state.loading} rows={this.state.orders ?? []} columns={columns} pageSize={100} rowsPerPageOptions={[100]} />}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.viewOrder && <ManageOrder orderId={this.state.viewOrder} close={() => { this.setState({ viewOrder: null }); }} />}
        </Fragment>;
    }

    markAsDelivered = () => {
        this.setState({ confirmMarkDelivered: true });
    }

    exportExcel = () => {
        this.renderXLSX();
    }

    renderXLSX = async () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Diplombeställningar",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Beställningar");

        //let groupedPanels = await this.getGroupedPanels();

        let data = [['OrderId', "Lopp", "Startnr", "Deltagare", "Produkt", "Variant", "Leveransnamn", "Leverans Tel.", "Leverans epost", "Leverans address", "Leverans Postnr", "Leverans ort", "Levernas land", "Skapad"]];
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            const row = this.state.selectedRows[i];
            data.push(
                [
                    row.orderPublicId,
                    row.RaceInfo?.eventKey,
                    row.RaceInfo?.bib,
                    row.RaceInfo?.firstName + " " + row.RaceInfo?.lastName,
                    row.productName,
                    row.productVariantName,
                    row.contact?.firstName + " " + row.contact?.lastName,
                    row.contact?.phone,
                    row.contact?.email,
                    row.contact?.address,
                    row.contact?.postalcode,
                    row.contact?.city,
                    row.contact?.country,
                    vasaloppetDateTimeGetterFormatter(row.created, "", true)
                ]
            );
        }

        var ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Beställningar"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'diplombeställingar_' + vasaloppetMoment().format(DATE_FORMAT) + '.xlsx');
    }

    s2ab(s: any) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }


    runMarkDelivered = async () => {
        this.setState({ confirmMarkDelivered: false, numUpdated: 0, numToUpdate: this.state.selectedRows.length });
        let back = new ApiBackend();
        const rows = this.state.selectedRows;
        for (let i = 0; i < this.state.selectedRows.length; i++) {
            const diplomaItem = rows[i] as DiplomaOrderItem;
            let res = await back.deliverOrderItem(diplomaItem.orderId, diplomaItem.id);
            this.setState({ numUpdated: this.state.numUpdated + 1 });
        }
        this.setState({ numToUpdate: null, numUpdated: null, selectedRows: null }, () => {
            this.refresh();
        });
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {

        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ListDiplomaOrders));