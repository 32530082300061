import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, LinearProgress, Theme, Typography, createStyles, withStyles } from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';

import { debounce } from 'lodash';
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import FirebaseContext from '../../providers/Firebase/context';
import { ApiBackend } from '../../providers/apibackend';
import SearchFilter, { Filters } from '../Common/SearchFilter/SearchFilter';
import { vasaloppetDateGetterFormatter } from '../../utilities/date';
import { AccountingAccountItem } from '../../model/IGetAccountingAccountsResponse';
import OrderTransactionOverViewItem from './OrderTransactionOverViewItem';

interface ISearchFilter {
    fromDate: string; // Order
    toDate: string; // Order
    product?: string; // Product 
    account?: string;

}

interface State {
    loading: boolean;
    groupBy: "account" | "product"; 
    accounts: AccountingAccountItem[];
    searchFilter: ISearchFilter;
}

class OrderTransactionOverview extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps) {
        super(props);

        this.api = new ApiBackend();

        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 365);

        const toDate = new Date(2030, 11, 31);

        this.state = {
            loading: true,
            groupBy: "account",
            accounts: [],
            searchFilter: {
                fromDate: vasaloppetDateGetterFormatter(fromDate),
                toDate: vasaloppetDateGetterFormatter(toDate)
            }
        };
    }

    async componentDidMount(): Promise<void> {
        await this.init();

    }

    refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    }

    render() {
        const { classes } = this.props as any;
        const { searchFilter } = this.state;

        return <>
            <SearchFilter
                id={"ordertransactions-filter"}
                filters={{
                    "fromDate": {
                        id: "filter-fromdate",
                        type: "Date",
                        label: "Fråndatum",
                        size: 3,
                        defaultValue: searchFilter.fromDate
                    },
                    "toDate": {
                        id: "filter-todate",
                        type: "Date",
                        label: "Tilldatum",
                        size: 3,
                        defaultValue: searchFilter.toDate
                    }
                }}
                persist={true}
                onInit={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControlLabel label={"Gruppera per konto"} control={<Checkbox checked={this.state.groupBy == "account"} onChange={(ev) => { if (ev.target.checked){
                        this.setState({groupBy: 'account'}, () => {this.performSearch();});
                    }}}/>}/>
                    <FormControlLabel label={"Gruppera per produkt"} control={<Checkbox checked={this.state.groupBy == "product"} onChange={(ev) => { if (ev.target.checked){
                        this.setState({groupBy: 'product'},() => {this.performSearch();});
                    }}}/>}/>
                </Grid>
            </Grid>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Kontotransaktioner </Typography>
                            <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} /></Fragment>}/>
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                        </CardContent>
                        
                        <div style={{padding:3}}>
                        
                        {this.state && this.state.accounts && this.state.accounts.map(x=> {
                            return <OrderTransactionOverViewItem account={x} key={x.account} fromDateFilter={this.state.searchFilter.fromDate} toDateFilter={this.state.searchFilter.toDate}/>
                        })}
                        </div>

                    </Card>
                </Grid>
            </Grid>
        </>;
    }

    private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        this.setState({ searchFilter: searchFilter, loading: true }, async () => {
            await this.performSearch();
        });
    };

    private init = async (): Promise<void> => {
        const accounts = await this.api.getAccountingAccounts(this.state.searchFilter.fromDate, this.state.searchFilter.toDate, this.state.groupBy);
        this.setState({
            accounts : accounts, loading:false
        });
    };

    private performSearch = debounce(async () => {
        this.setState({loading: true});
        const accounts = await this.api.getAccountingAccounts(this.state.searchFilter.fromDate, this.state.searchFilter.toDate, this.state.groupBy);
        this.setState({
            accounts : accounts, loading:false
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(OrderTransactionOverview));
