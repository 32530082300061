import React from "react";
import debounce from "lodash/debounce";
import TextField from "@material-ui/core/TextField/TextField";

interface IProps {
	id: string;
	initialValue: string;
	label: string;
	style?: React.CSSProperties
	onChange: (value: string) => void;
}

interface IState {
	value: string;
}

class DebouncedTextField extends React.Component<IProps, IState> {
	state: IState;

	constructor(props: IProps) {
		super(props);
		
		this.state = {
			value: this.props.initialValue
		};
	}

	componentDidMount(): void {
		this.notifyChange = debounce(this.notifyChange, 500);
	}

	render(): JSX.Element {
		return (
			<TextField
				id={this.props.id}
				autoComplete="off"
				style={this.props.style}
				label={this.props.label}
				value={this.state.value}
				onChange={this.handleChange}
				fullWidth
				InputLabelProps={{
					shrink: true,
				}}
			/>
		)
	}

	private handleChange = (e: any) => {
		this.setState({value: e.target.value});
        this.notifyChange(e.target.value.trim());
	};

	private notifyChange = (value: string) => {
		this.props.onChange(value);
	};
}

export default DebouncedTextField;
