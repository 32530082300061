import React, { useEffect, useRef, useState } from "react";
import { Entry } from "../../../../model/Entry";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, TextField, useTheme } from "@material-ui/core";
import { ApiBackend } from "../../../../providers/apibackend";
import { EntryPersonalBibHandoutLocation, EntryPersonalBibSize } from "../../../../model/CommonTypes";
import { initNewOrder, Order } from "../../../../model/Order";
import SelectPointOfSale from "../../../Orders/SelectPointOfSale";
import ReadonlyTextField from "../../../Common/ReadonlyTextField";
import { getDateOfBirthAsString } from "../../../../utilities/EntryHelpers";
import { OrderContact } from "../../../../model/OrderContact";
import { OrderItem } from "../../../../model/OrderItem";
import { Product } from "../../../../model/Product";
import { Person } from "../../../../model/Person";
import withAlert, { IAlertContext } from "../../../Common/dialog/Alert";

interface IProps {
    entry: Entry;
    onComplete: () => void;
    onAbort: () => void;
}

type EmptyValueType = "EMPTY_VALUE";
const EMPTY_VALUE: EmptyValueType = "EMPTY_VALUE";

const OrderItemBibMetadataKeys = {
    profileEntryId: "profile_entry_id",
    profileEntryBibSize: "profile_entry_bib_size",
    profileEntryBibHandout: "profile_entry_bib_handoutlocation",
}

const AddEntryPersonalBibDialog = ({ entry, onComplete, onAbort, showAlert }: IProps & IAlertContext) => {
    const [price, setPrice] = useState(0);
    const [handoutLocation, setHandoutLocation] = useState(EMPTY_VALUE as EntryPersonalBibHandoutLocation | EmptyValueType);
    const [bibSize, setBibSize] = useState(EMPTY_VALUE as EntryPersonalBibSize | EmptyValueType);
    const [order, setOrder] = useState(null as Order);
    const [pointOfSale, setPointOfSale] = useState("");
    const [paymentComment, setPaymentComment] = useState("");
    const [isEstimating, setIsEstimating] = useState(false);
    const [isCreatingOrder, setIsCreatingOrder] = useState(false);
    const [createOrderStatus, setCreateOrderStatus] = useState("");

    const didMount = useRef<boolean>(false);
    const api = new ApiBackend();
    const theme = useTheme();

    useEffect(() => {
        const init = async (): Promise<void> => {
            try {
                const work = [
                    api.getProduct(CONFIG.bibProductId),
                    api.getPerson(entry.personId)
                ];
                const res = await Promise.all(work);
                const [product, person] = res as [Product, Person];

                const variant = product.variants[0];
                const priceGroup = variant.priceGroups[0];

                const nextOrder = initNewOrder();
                nextOrder.items = [{
                    productId: product.id,
                    productVariantId: variant.Id,
                    productVariantPriceGroupId: priceGroup.id,
                    numberOfProducts: 1
                } as OrderItem];

                nextOrder.contact = {
                    firstName: person.firstName,
                    lastName: person.lastName,
                    address: person.address,
                    postalcode: person.postCode,
                    city: person.city,
                    country: person.country,
                    email: person.email,
                    phone: person.mobileNumber,
                    loggedInUserId: person.id
                } as OrderContact;

                nextOrder.locale = person.nationality == "SWE" || person.nationality == "NOR" ? "se" : "en";

                if (didMount.current) {
                    setOrder(nextOrder);
                }
            } catch (ex) {
                showAlert("Fel", "Någonting gick fel när vi försökte hämta information om personlig nummerlapp.");
            }
        };

        didMount.current = true;
        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    useEffect(() => {
        estimateOrder();
    }, [order]);

    const handleSave = async () => {
        if (!didMount.current) {
            return;
        }

        try {
            setIsCreatingOrder(true);
            setCreateOrderStatus("Skapar order");

            let toCreate = order;
            const metadata: { key: string; value: string }[] = [{
                key: OrderItemBibMetadataKeys.profileEntryId,
                value: entry.id
            }, {
                key: OrderItemBibMetadataKeys.profileEntryBibSize,
                value: bibSize
            }, {
                key: OrderItemBibMetadataKeys.profileEntryBibHandout,
                value: handoutLocation
            }];

            toCreate.items[0].metadata = metadata;

            const createdOrder = await api.adminCreateOrder(toCreate);
            if (!createdOrder) {
                throw new Error("Någonting gick fel vid skapande av order.");
            }

            setCreateOrderStatus("Registrerar betalning");

            const paymentResult = await api.addOrderPayment({
                orderId: createdOrder.id,
                paymentType: "PAYMENT",
                amount: createdOrder.totalSum,
                pointOfSaleId: pointOfSale,
                description: paymentComment
            });

            if (!paymentResult) {
                throw new Error("Ordern skapades, men registrering av betalning misslyckades.");
            }

            onComplete();
        } catch (ex) {
            alert(ex);
        } finally {
            setIsCreatingOrder(false);
        }
    };

    const handleAbort = () => {
        onAbort();
    };

    const estimateOrder = async (): Promise<void> => {
        if (!didMount.current || !order || !order.items) {
            setPrice(0);
            return;
        }

        setIsEstimating(true);

        let toEstimate = order;

        const metadata: { key: string; value: string }[] = [{
            key: OrderItemBibMetadataKeys.profileEntryId,
            value: entry.id
        }, {
            key: OrderItemBibMetadataKeys.profileEntryBibSize,
            value: bibSize
        }, {
            key: OrderItemBibMetadataKeys.profileEntryBibHandout,
            value: handoutLocation
        }];

        toEstimate.items[0].metadata = metadata;

        const res = await api.adminEstimateOrder(toEstimate);
        if (!didMount.current) {
            return;
        }

        setPrice(res.totalPrice);
        setIsEstimating(false);
    };

    const renderEntryFields = () => {
        return (<>
            <Grid item xs={12}
                style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
            >
                Personuppgifter
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={entry.firstName}
                    label="Förnamn"
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={entry.lastName}
                    label="Efternamn"
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={entry.address}
                    label="Address"
                />
            </Grid>
            <Grid item xs={3}>
                <ReadonlyTextField
                    value={entry.postalCode}
                    label="postnummer"
                />
            </Grid>
            <Grid item xs={3}>
                <ReadonlyTextField
                    value={entry.city}
                    label="Ort"
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={entry.email}
                    label="E-post"
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={entry.phone}
                    label="Tel."
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={getDateOfBirthAsString(entry)}
                    label="Födelsedatum"
                />
            </Grid>
            <Grid item xs={6}>
                <ReadonlyTextField
                    value={entry.nationality}
                    label="Nationalitet"
                />
            </Grid>
        </>);
    };

    const render = () => {
        if (!order) {
            return null;
        }

        const isLoading = isCreatingOrder || isEstimating;
        const isSaveEnabled = !isLoading && pointOfSale && handoutLocation !== "EMPTY_VALUE" && bibSize !== "EMPTY_VALUE";;
        const contentStyle = isLoading ? { minHeight: 300, pointerEvents: "none" as React.CSSProperties["pointerEvents"] } : { minHeight: 300 };

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="personalbib-dialog-title"
                open={true}>
                <DialogTitle id="personalbib-dialog-title">
                    Lägg till personlig nummerlapp
                </DialogTitle>
                <DialogContent dividers style={contentStyle}>

                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor={"bib_handout"}>Utlämningsställe</InputLabel>
                                <Select
                                    fullWidth
                                    id={"bib_handout"}
                                    value={handoutLocation}
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        const nextValue = ev.target.value as EntryPersonalBibHandoutLocation;
                                        setHandoutLocation(nextValue);
                                    }}
                                >
                                    {handoutLocation === EMPTY_VALUE && <MenuItem key={EMPTY_VALUE} value={EMPTY_VALUE}>VÄLJ</MenuItem>}
                                    <MenuItem key="Mora" value="Mora">Mora</MenuItem>
                                    <MenuItem key="Sälen" value="Sälen">Sälen</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink htmlFor={"bib_size"}>Storlek</InputLabel>
                                <Select
                                    fullWidth
                                    id={"bib_size"}
                                    value={bibSize}
                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                        const nextValue = ev.target.value as EntryPersonalBibSize;
                                        setBibSize(nextValue);
                                    }}
                                >
                                    {bibSize === EMPTY_VALUE && <MenuItem key={EMPTY_VALUE} value={EMPTY_VALUE}>VÄLJ</MenuItem>}
                                    <MenuItem key="S-L" value="S-L">S-L</MenuItem>
                                    <MenuItem key="XL-XXL" value="XL-XXL">XL-XXL</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>


                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel shrink>POS</InputLabel>
                                <SelectPointOfSale
                                    onChange={setPointOfSale}
                                />
                            </FormControl>

                        </Grid>
                        <Grid item xs={6}>
                            <TextField label="Kommentar"
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={paymentComment}
                                onChange={(ev) => {
                                    setPaymentComment(ev.target.value);
                                }}
                            />
                        </Grid>

                        {renderEntryFields()}

                        {isCreatingOrder && <>
                            <Grid item xs={12}
                                style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}>
                                <CircularProgress />
                                {createOrderStatus}
                            </Grid>
                        </>}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: "100%" }}>
                        <Box></Box>
                        <Box>
                            Pris: {isEstimating ?
                                <CircularProgress color="secondary" />
                                : <b>{price} kr</b>
                            }
                        </Box>
                        <Box>
                            <Button variant="contained" color="secondary"
                                disabled={!isSaveEnabled}
                                onClick={handleSave}>
                                Spara
                            </Button>
                            <Button variant="contained" color="secondary" style={{ marginLeft: 5 }}
                                disabled={isLoading}
                                onClick={handleAbort}>
                                Avbryt
                            </Button>
                        </Box>
                    </Box>
                </DialogActions>
            </Dialog>

        );
    };

    return render();
};

export default withAlert(AddEntryPersonalBibDialog);
