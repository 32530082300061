import React from "react";
import uuidv4 from "react-uuid";
import Button from "@material-ui/core/Button/Button";
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import TextField from "@material-ui/core/TextField/TextField";
import { Theme } from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ApiBackend } from "../../providers/apibackend";
import { createRandomCode } from "../../utilities/codes";
import { Code } from "../../model/Code";
import { isArray } from "lodash";

interface IProps {
    codeHolderId: string;
    codesChanged: (codes: Code[]) => void;
}

interface IState {
    numCodes: number;
    maxUsages: number;
    code: string;
    prefix: string;
    numBlocks: number;
    numChars: number;
    loading: boolean;
}

class CodesCard extends React.Component<WithStyles & IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);
        
        this.api = new ApiBackend();
        this.state = {
            code: createRandomCode(),
            maxUsages: 10,
            numCodes: 1,
            prefix: "VL",
            numBlocks: 1,
            numChars: 5,
            loading: false
        };
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const { numCodes, maxUsages, code, prefix, numBlocks, numChars, loading } = this.state;

        return (<Card style={{ marginTop: 10 }}>
            <CardHeader className={classes.cardHeader} title={"Lägg till aktiveringskoder"} />
            <CardContent>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                    <Grid item xs={12} sm={numCodes == 1 ? 4 : 6}>
                        <TextField type="number" inputProps={{ min: 1 }} value={numCodes} label="Antal koder" onChange={this.handleNumCodesChange} fullWidth />
                    </Grid>
                    {numCodes == 1 &&
                        <Grid item xs={12} sm={4}>
                            <TextField
                                value={code}
                                label="Kodsträng (1-36)"
                                onChange={this.handleCodeChange}
                                fullWidth
                                inputProps={{ minLength: 1, maxLength: 36, style: { textTransform: "uppercase" } }}
                            />
                        </Grid>
                    }
                    <Grid item xs={12} sm={numCodes == 1 ? 4 : 6}>
                        <TextField
                            type="number"
                            inputProps={{ min: 1 }}
                            value={maxUsages}
                            onChange={this.handleMaxUsagesChange}
                            label="Giltig antal gånger"
                            fullWidth
                        />
                    </Grid>
                    {numCodes > 1 && <>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                value={prefix}
                                label="Prefix (1-6)"
                                onChange={this.handlePrefixChange}
                                fullWidth
                                inputProps={{ minLength: 1, maxLength: 6, style: { textTransform: "uppercase" } }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                type="number"
                                value={numBlocks}
                                label="Antal block (1-5)"
                                onChange={this.handleNumBlocksChange}
                                fullWidth
                                inputProps={{ min: 1, max: 5 }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                type="number"
                                value={numChars}
                                label="Antal tecken per block (1-5)"
                                onChange={this.handleNumCharsChange}
                                fullWidth
                                inputProps={{ min: 1, max: 5 }}
                            />
                        </Grid>
                    </>}
                </Grid>
            </CardContent>
            <CardActionArea component="a">
                {loading &&
                    <LinearProgress />
                }
                <Button onClick={this.createCodes}>{numCodes == 1 ? "Skapa kod" : "Skapa koder"}</Button>
            </CardActionArea>
        </Card>
        );
    }

    private handleNumCodesChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ numCodes: ev.target.value as any });
    };

    private handleMaxUsagesChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ maxUsages: ev.target.value as any });
    };

    private handleCodeChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ code: ev.target.value });
    };

    private handlePrefixChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ prefix: ev.target.value });
    };

    private handleNumBlocksChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ numBlocks: ev.target.value.replace(/[^0-9]+/g, "") as any });
    };

    private handleNumCharsChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ numChars: ev.target.value.replace(/[^0-9]+/g, "") as any });
    };

    private createCodes = async (): Promise<void> => {
        const { codeHolderId, codesChanged } = this.props;
        const { numCodes, code, maxUsages, prefix, numBlocks, numChars } = this.state;

        if (!this.isValid()) {
            return;
        }

        this.setState({ loading: true });

        let impl: Promise<boolean | Code[]>;
        let createdCode: Code;
        if (numCodes == 1) {
            createdCode = {
                id: uuidv4(),
                code: code.toUpperCase(),
                maxUsages: maxUsages,
                numUsages:0
            };
            impl = this.api.addCode(codeHolderId, createdCode);
        } else {
            impl = this.api.generateCodes(
                codeHolderId,
                numCodes,
                maxUsages,
                prefix.toUpperCase(),
                numBlocks,
                numChars
            );
        }

        const res = await impl;
        
        this.setState({ loading: false });

        if (res) {
            if (isArray(res)) {
                codesChanged(res as Code[]);
            } else {
                codesChanged([createdCode]);
            }
        } else {
            alert("Det gick inte att spara koden/koderna")
        }
    };

    private isValid = (): boolean => {
        const { numCodes, code, prefix } = this.state;

        const stringHasValue = (val: string): boolean => !!val;

        if (numCodes === 1) {
            if (stringHasValue(code)) {
                return true;
            }

            alert("Koden måste bestå av minst ett tecken");

            return false;
        }

        if (stringHasValue(prefix)) {
            return true;
        }

        alert("Prefix måste bestå av minst ett tecken");

        return false;
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    }
});

export default withStyles(useStyles)(CodesCard);
