import { EntryStatus } from "../../../../model/CommonTypes";
import { Entry, ListEntry } from "../../../../model/Entry";
import { BulkPatchEntriesRequest, PatchEntryDocumentProps } from "../../../../providers/models";

export type PatchablePropertiesWithId = PatchEntryDocumentProps & Pick<Entry, "id">;

export type PatchRestrictions = {
    status?: EntryStatus[];
};

// status, företag, startled, klubb, packningsgrupp
export const getPatchDocument = (ids: string[], item: PatchEntryDocumentProps): BulkPatchEntriesRequest => {
    const patchDocument: { propName: keyof PatchEntryDocumentProps; value: any; }[] = [];

    for (const prop in item) {
        if (item.hasOwnProperty(prop)) {
            const typedProp = prop as keyof PatchEntryDocumentProps;
            const value = item[typedProp];
            patchDocument.push({ propName: typedProp, value: value });
        }
    }

    return {
        entryIds: ids,
        patchDocument: patchDocument
    };
};

export const listEntriesToPatchEntryDocumentProps = (items: ListEntry[]): PatchablePropertiesWithId[] => {
    return items.map(p => {
        return {
            id: p.id,
            status: p.status,
            b2bId: p.b2bId,
            clubName: p.clubName,
            prePackingGroup: p.prePackingGroup,
            startgroup: p.startgroupId
        };
    });
};
