import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Popover from "@material-ui/core/Popover/Popover";
import TextField from "@material-ui/core/TextField/TextField";
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import React, { Fragment } from "react";
import { Product } from "../../model/Product";
import { ProductVariant } from "../../model/ProductVariant";
import { ProductVariantAgeCategory } from "../../model/ProductVariantAgeCategory";
import CopyCard from "./Popovers/CopyCard";
import YearTemplateCard from "./Popovers/YearTemplateCard";

interface IProps {
    product: Product;
    productVariant: ProductVariant;
    onChange: (ageCategories: ProductVariantAgeCategory[]) => void;
}

interface IState {
    popupOpen: boolean;
    anchorEl: HTMLButtonElement;
    popupMode: "template" | "copy";
}

class EditAgeCategories extends React.Component<IProps, IState> {
    state: IState;

    constructor(props: IProps) {
        super(props);

        this.state = {
            popupOpen: false,
            popupMode: undefined,
            anchorEl: undefined
        };
    }

    render(): JSX.Element {
        const { productVariant } = this.props;
        const { popupOpen, popupMode, anchorEl } = this.state;

        return (<>
            {productVariant.ageCategories && productVariant.ageCategories.map((ac, idx) => this.renderRow(ac, idx))}
            <Grid item xs={12}>
                <Button variant="contained" onClick={this.add}>
                    Lägg till
                </Button>
                <Button variant="contained" style={{ marginLeft: 10 }} onClick={this.showAddTemplate}>
                    Skapa från mall
                </Button>
                <Button variant="contained" style={{ marginLeft: 10 }} onClick={this.showCopy}>
                    Kopiera från lopp
                </Button>
                <Popover
                    open={popupOpen}
                    anchorEl={anchorEl}
                    onClose={this.handlePopoverClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                >
                    <>
                        {popupMode === "template" && (
                            <YearTemplateCard onChange={this.onPopoverChange} />
                        )}
                        {popupMode === "copy" && (
                            <CopyCard onChange={this.onPopoverChange} />
                        )}
                    </>
                </Popover>
            </Grid>
        </>);
    }

    private renderRow(ac: ProductVariantAgeCategory, idx: number) {
        const style = { borderTop: idx > 0 ? '3px solid #2772b6' : '', marginTop: idx > 0 ? 10 : 0 };

        return <Fragment key={idx}>
            <Grid item xs={3} style={style}>
                <TextField type="text" value={ac.category} label="Kategori" onChange={this.propValueChanged(idx, "category")} fullWidth />
            </Grid>
            <Grid item xs={3} style={style}>
                <TextField
                    select
                    style={{ flex: 1, margin: '0 3px' }}
                    label="Kön"
                    InputLabelProps={{ shrink: true }}
                    value={ac.gender}
                    fullWidth
                    onChange={this.propValueChanged(idx, "gender")}
                >
                    <MenuItem value="male">Man</MenuItem>
                    <MenuItem value="female">Kvinna</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={2} style={style}>
                <TextField type="number" value={ac.fromAge} label="Från-ålder" onChange={this.propValueNumericChanged(idx, "fromAge")} fullWidth />
            </Grid>
            <Grid item xs={2} style={style}>
                <TextField type="number" value={ac.toAge} label="Till-ålder" onChange={this.propValueNumericChanged(idx, "toAge")} fullWidth />
            </Grid>
            <Grid item xs={2} style={style}>
                <DeleteIcon style={{ cursor: 'pointer' }} onClick={this.delete(idx)} />
                <ExpandLess style={{ cursor: 'pointer' }} onClick={this.moveUp(idx)} />
                <ExpandMore style={{ cursor: 'pointer' }} onClick={this.moveDown(idx)} />
            </Grid>
        </Fragment>
    }

    private triggerChanges(ageCategories: ProductVariantAgeCategory[]) {
        const { onChange } = this.props;
        onChange(ageCategories);
    }

    private propValueChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { productVariant } = this.props;
        (productVariant.ageCategories[idx] as any)[propName] = ev.target.value;
        this.triggerChanges(productVariant.ageCategories);
    };

    private propValueNumericChanged = (idx: number, propName: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { productVariant } = this.props;
        (productVariant.ageCategories[idx] as any)[propName] = +ev.target.value;
        this.triggerChanges(productVariant.ageCategories);
    };

    private delete = (idx: number) => () => {
        const { productVariant } = this.props;
        productVariant.ageCategories.splice(idx, 1);
        this.triggerChanges(productVariant.ageCategories);
    };

    private add = () => {
        const { productVariant } = this.props;
        productVariant.ageCategories.push({
            id: undefined,
            gender: "male",
            category: "",
            fromAge: 0,
            toAge: 100
        });
        this.triggerChanges(productVariant.ageCategories);
    };

    private moveUp = (idx: number) => () => {
        if (idx > 0) {
            const { productVariant } = this.props;
            const rem = productVariant.ageCategories.splice(idx, 1);
            productVariant.ageCategories.splice(idx - 1, 0, rem[0]);
            this.triggerChanges(productVariant.ageCategories);
        }
    };

    private moveDown = (idx: number) => () => {
        const { productVariant } = this.props;
        if (idx < productVariant.ageCategories.length - 1) {
            const rem = productVariant.ageCategories.splice(idx, 1);
            productVariant.ageCategories.splice(idx + 1, 0, rem[0]);
            this.triggerChanges(productVariant.ageCategories);
        }
    };

    private showAddTemplate = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ popupOpen: true, popupMode: "template", anchorEl: e.currentTarget });
    };

    private showCopy = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ popupOpen: true, popupMode: "copy", anchorEl: e.currentTarget });
    };

    private handlePopoverClose = () => {
        this.setState({ popupOpen: false, popupMode: undefined, anchorEl: undefined });
    };

    private onPopoverChange = (newItems: ProductVariantAgeCategory[]) => {
        const { productVariant } = this.props;
        this.setState({ popupOpen: false, anchorEl: undefined }, () => {
            this.triggerChanges([...productVariant.ageCategories ?? [], ...newItems]);
        });
    };
}

export default EditAgeCategories;
