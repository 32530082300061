/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme, withStyles, WithStyles } from '@material-ui/core';
import FirebaseContext from '../providers/Firebase/context';
import Firebase from '../providers/Firebase/firebase';
import { withRouter, RouteComponentProps, Link} from 'react-router-dom';
import AccountMenu from './AccountMenu';
import withExpoContext from '../contexts/withExpoContext';
import { IExpoContext } from '../contexts/ExpoContext';

interface IProps {
    expoContext: IExpoContext;
}


/** 
 * TopBar of the application
 * Shows menu and account profile
 */
class Top extends React.Component<IProps & WithStyles & RouteComponentProps>
{

    render() {
        let { classes, expoContext } = this.props;
        let title = "Kontrollen";
        if (CONFIG.environment !== "Prod") {
            title += ` - ${CONFIG.environment}`;
        }
    
        const { isExpoMode, userPosSettings } = expoContext;

        return (
            <AppBar position="static" className="nav-header">
                <Toolbar>
                    <FirebaseContext.Consumer>
                        {(value: { firebase: Firebase, isSignedIn: boolean }) => {
                            return <Fragment>
                                <div style={{ display: 'inline', textAlign: 'center' }}>
                                    <a href="/" className="logotype asset asset-logotype" title="Mina sidor">{title}</a>
                                </div>
                                <Typography variant="h6" className={classes.title}>
                                    <Link to="/">{title}</Link>
                                </Typography>
                                {isExpoMode &&
                                    <Typography variant="h5" className={classes.title}>Agerar POS: {userPosSettings.Name}</Typography>
                                }
                                {value.isSignedIn ?
                                    <AccountMenu firebase={value.firebase} />
                                    : <Button color="inherit">Logga in</Button>
                                }
                            </Fragment>;
                        }}
                    </FirebaseContext.Consumer>
                </Toolbar>
            </AppBar>
        );
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    root: {
        flexGrow: 1
    },
    title: {
        flexGrow: 1
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    }
});

export default withRouter(withExpoContext(withStyles(useStyles)(Top)));
