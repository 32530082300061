/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { Theme, createStyles, Grid, WithStyles, Button, withStyles, Select, FormControl, MenuItem } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { Order } from '../../model/Order';
import LinearProgress from '@material-ui/core/LinearProgress';
import SelectPointOfSale from './SelectPointOfSale';

interface props {
    order: Order;
    close: () => void;
}
interface State {
    paymentType: "PAYMENT" | "REVERSAL";
    sum: number;
    loading: boolean;
    pointOfSale: string;
    comment: string;
}

/** Add Product -- Create a product
 */
class AddPaymentDialog extends React.Component<WithStyles & props, State> {
    constructor(props: any) {
        super(props);
        this.state = { paymentType: 'PAYMENT', sum: 0, pointOfSale: "payex", loading: true, comment: '' }
    }

    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var e = {} as any;
        e[prop] = ev.target.value;
        this.setState(e);
    }

    addPayment = () => {
        if (this.state.sum <= 0) {
            alert("Betald summa måste vara större än 0!");
            return;
        }
        else if (this.state.paymentType == "PAYMENT" && this.state.pointOfSale == "payex") {
            alert("Enbart återbetalningar stöds mot Swedbank Pay!");
            return;
        }
        else if (this.state.paymentType == "REVERSAL" && this.state.pointOfSale == "payex") {
            alert("Återbetalningar registreras automatiskt när den läggs in i Swedbank Pay!");
            return;
        }

        this.setState({ loading: true });
        let back = new ApiBackend();
        back.addOrderPayment({
            orderId: this.props.order.id,
            amount: this.state.sum,
            paymentType: this.state.paymentType,
            pointOfSaleId: this.state.pointOfSale,
            description: ApiBackend.getCurrentUserName() + ": " + this.state.comment
        }).then(res => {
            this.setState({ loading: false }, () => {
                this.props.close();
            });
        })


    }

    render() {
        let { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2} style={{ width: '100%', margin: 0, padding: 10, paddingTop: 0 }}>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="paymentType1">Typ av betalning</InputLabel>
                        <Select id="paymentType" value={this.state.paymentType} onChange={this.onChange("paymentType")}>
                            <MenuItem value="PAYMENT">Betalning</MenuItem>
                            <MenuItem value="REVERSAL">Återbetalning</MenuItem></Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="sum">Summa</InputLabel>
                        <Input value={this.state.sum} id="sum" onChange={this.onChange("sum")} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="pos">Försäljningsställe</InputLabel>
                        <SelectPointOfSale onChange={(pos) => { this.setState({ pointOfSale: pos }); }} />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>

                    <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="comment" shrink>Kommentar</InputLabel>
                        <Input value={this.state.comment} id="comment" onChange={this.onChange('comment')} />
                    </FormControl>
                </Grid>
                {this.state.loading &&
                    <Grid item xs={12}>
                        <LinearProgress />
                    </Grid>
                }
                <Button variant="contained" onClick={this.addPayment}>Lägg till</Button>
                <Button variant="contained" onClick={() => this.props.close()}>Avbryt</Button>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    },
    formControl: {
        margin: spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: spacing(2),
    },
}
);


export default withStyles(useStyles)(AddPaymentDialog);