import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, WithStyles } from "@material-ui/core";
import { Formik, FormikProps } from "formik";
import React from "react";
import uuidv4 from "react-uuid";
import { Product } from "../../../model/Product";
import { SeedingTable } from "../../../model/SeedingTable";
import { StartGroup } from "../../../model/StartGroup";
import FormCbxField from "../../Common/FormCbxField";
import FormSelectField from "../../Common/FormSelectField";
import FormTextField from "../../Common/FormTextField";
import { vasaloppetIsoDateTimeSetterFormatter, vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import SexAvailabilityIcon from "./StartGroupSexAvailabilityIcon";

interface IProps {
    product: Product;
    seedingTables: SeedingTable[];
    initialValue: StartGroup;
    onChange: (startGroup: StartGroup) => void;
    onAbort: () => void;
}

const EditStartGroupDialog = ({ product, seedingTables, initialValue, onChange, onAbort, classes }: IProps & WithStyles) => {
    const formId = `edit-startgroup-form-${uuidv4()}`;

    const renderSeedingGroups = () => {
        let ret = [];
        for (let i = 0; i < 17; i++) {
            ret.push(<MenuItem key={i} value={i}>Grupp {i}</MenuItem>);
        }
        return ret;
    };

    const render = () => {
        return (
            <Formik
                initialValues={initialValue}
                onSubmit={(values) => {
                    onChange(values);
                }}
            >
                {formik => {
                    return renderDialog(formik);
                }}
            </Formik>
        );
    };

    const renderDialog = (formik: FormikProps<StartGroup>) => {
        const { values } = formik;

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="edit-startgroup-title"
                open={true}
            >
                <DialogTitle id="edit-startgroup-title">
                    <Box {...defaultBoxStyle}>
                        {initialValue?.id ? "Redigera startled" : "Lägg till startled"}
                        <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                            <SexAvailabilityIcon startGroup={values} />
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <form id={formId} autoComplete="off" onSubmit={formik.handleSubmit}>
                        <Grid container className={classes.root} spacing={2} style={{ width: "100%", padding: 3 }}>
                            <Grid item xs={10}>
                                <FormTextField {...formik}
                                    propName="name"
                                    label="Namn"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    propName="externalKey"
                                    label="SportsProGroupKey"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField {...formik}
                                    propName="nameEn"
                                    label="Namn Engelska"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField {...formik}
                                    propName="description"
                                    label="Beskrivning"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormTextField {...formik}
                                    propName="descriptionEn"
                                    label="Beskrivning Engelska"
                                />
                            </Grid>

                            <Grid item xs={6}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="maxParticipants"
                                    label="Max deltagare"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="numberOfSellablePlaces"
                                    label="Säljbara platser"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormSelectField {...formik}
                                    propName="startGroupAllocationMethod"
                                    label="Startledstilldelning"
                                >
                                    <MenuItem value=""></MenuItem>
                                    <MenuItem value="default">Framifrån vid anmälan</MenuItem>
                                    <MenuItem value="seeded">Seedad tilldelning</MenuItem>
                                    <MenuItem value="userchoice">Startledsväljaren</MenuItem>
                                    <MenuItem value="extern">Extern tilldelning</MenuItem>
                                    <MenuItem value="none">Ingen tilldelning</MenuItem>
                                </FormSelectField>
                            </Grid>
                            <Grid item xs={3}>
                                <FormSelectField {...formik}
                                    propName="participantCategory"
                                    label="Deltagarkategori i ledet"
                                >
                                    <MenuItem key="" value=""></MenuItem>
                                    {product.variants.map((x, idx) => {
                                        return <MenuItem key={idx} value={x.Id}>{x.Name}</MenuItem>;
                                    })}
                                </FormSelectField>
                            </Grid>
                            <Grid item xs={6}>
                                <FormCbxField {...formik}
                                    propName="startTimeType"
                                    label="Fast starttid"
                                    valueGetter={() => formik.values.startTimeType !== "interval" ? true : false}
                                    onChange={() => (e: any) => {
                                        if (e.target.checked) {
                                            formik.setFieldValue("startTimeType", "fixed");
                                        }
                                    }}
                                />
                                <FormCbxField {...formik}
                                    propName="startTimeType"
                                    label="Start inom intervall"
                                    valueGetter={() => formik.values.startTimeType === "interval" ? true : false}
                                    onChange={() => (e: any) => {
                                        if (e.target.checked) {
                                            formik.setFieldValue("startTimeType", "interval");
                                        }
                                    }}
                                />
                            </Grid>

                            {formik.values.startGroupAllocationMethod === "seeded" && <>
                                <Grid item xs={3}>
                                    <FormSelectField {...formik}
                                        propName="seedingTable"
                                        label="Seedningstabell"
                                    >
                                        <MenuItem key="" value=""></MenuItem>
                                        {seedingTables.map((x, idx) => {
                                            return <MenuItem key={idx} value={x.id}>{x.name}</MenuItem>;
                                        })}
                                    </FormSelectField>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormSelectField {...formik}
                                        propName="seedingTableGroup"
                                        label="Seedningsgrupp i tabell"
                                        valueGetter={() => { return formik.values.seedingTableGroup; }}
                                    >
                                        <MenuItem key="" value=""></MenuItem>
                                        {renderSeedingGroups()}
                                    </FormSelectField>
                                </Grid>
                                <Grid item xs={6} />
                            </>}

                            <Grid item xs={3}>
                                <FormTextField {...formik}
                                    type="datetime-local"
                                    propName="startTime"
                                    label="Starttid"
                                    valueGetter={() => {
                                        return vasaloppetDateTimeGetterFormatter(formik.values.startTime);
                                    }}
                                    onChange={() => (e: any) => {
                                        formik.setFieldValue("startTime", vasaloppetIsoDateTimeSetterFormatter(e.target.value));
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                {formik.values.startTimeType == "interval" &&
                                    <FormTextField {...formik}
                                        type="datetime-local"
                                        propName="startTimeEnd"
                                        label="Starttid till"
                                        valueGetter={() => {
                                            return vasaloppetDateTimeGetterFormatter(formik.values.startTimeEnd);
                                        }}
                                        onChange={() => (e: any) => {
                                            formik.setFieldValue("startTimeEnd", vasaloppetIsoDateTimeSetterFormatter(e.target.value));
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={6}>
                                <FormCbxField {...formik}
                                    propName="openForEntry"
                                    label="Öppen för anmälan"
                                    valueGetter={() => formik.values.openForEntry ?? false}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                Startnummerserie herrar
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    propName="bibPrefix"
                                    label="Prefix"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibNumberFrom"
                                    label="Från nummer"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibNumberTo"
                                    label="Till nummer"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibAssignFrom"
                                    label="Tilldela från"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                Startnummerserie damer
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    propName="bibPrefixWomen"
                                    label="Prefix"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibNumberFromWomen"
                                    label="Från nummer"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibNumberToWomen"
                                    label="Till nummer"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibAssignFromWomen"
                                    label="Tilldela från"
                                />
                            </Grid>
                            <Grid item xs={4}>
                                Startnummerserie mixed
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    propName="bibPrefixMixed"
                                    label="Prefix"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibNumberFromMixed"
                                    label="Från nummer"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibNumberToMixed"
                                    label="Till nummer"
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <FormTextField {...formik}
                                    type="number"
                                    propName="bibAssignFromMixed"
                                    label="Tilldela från"
                                />
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary" autoFocus
                        onClick={() => {
                            formik.handleReset();
                            onAbort();
                        }}
                    >
                        Avbryt
                    </Button>
                    <Button form={formId} type="submit" variant="contained" color="primary"
                        disabled={!formik.dirty}
                    >
                        {initialValue?.id ? "Spara" : "Lägg till"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    return render();
};

export default EditStartGroupDialog;
