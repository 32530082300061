import { Button, Card, CardActions, CardContent, CardHeader, Grid, MenuItem, TextField } from "@material-ui/core";
import React from "react";
import { Event } from "../../model/Event";
import { Product } from "../../model/Product";
import { ProductVariant } from "../../model/ProductVariant";
import { ProductVariantPriceGroup } from "../../model/ProductVariantPriceGroup";
import { ApiBackend } from "../../providers/apibackend";

export interface IProductVariantPriceGroupSelectorResult {
    event: Event;
    product: Product;
    productVariant: ProductVariant;
    productVariantPriceGroup?: ProductVariantPriceGroup;
}

interface IProps {
    headerText: string;
    buttonText: string;
    products: Product[];
    includePriceGroups?: boolean;
    priceGroupOptional?: boolean;
    onChange: (result: IProductVariantPriceGroupSelectorResult) => void;
}

interface IState {
    events: Event[];
    products: Product[];
    productVariants: ProductVariant[];
    productVariantPriceGroups: ProductVariantPriceGroup[];
    eventId: string;
    productId: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
}

class ProductVariantPriceGroupSelector extends React.Component<IProps> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = {
            events: [],
            products: [],
            productVariants: [],
            productVariantPriceGroups: [],
            eventId: undefined,
            productId: undefined,
            productVariantId: undefined,
            productVariantPriceGroupId: undefined
        };

        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        const { products } = this.props;
        let events = await this.api.listEvents();
        events = events.filter(e => products.some(p => p.EventId === e.id))
            .sort((a, b) => a.name.localeCompare(b.name, "sv"));

        this.setState({ events: events });
    }

    render(): JSX.Element {
        const { includePriceGroups, priceGroupOptional, headerText, buttonText } = this.props;
        const { events, products, productVariants, productVariantPriceGroups, eventId, productId, productVariantId, productVariantPriceGroupId } = this.state;

        const priceGroupIsValid = !!(includePriceGroups ? !!(productVariantPriceGroupId || priceGroupOptional) : true)
        const commitEnabled = !!(productId && productVariantId && priceGroupIsValid);
        const eventDefault = eventId ?? "";
        const productDefault = productId ?? "";
        const productVariantDefault = productVariantId ?? "";
        const productVariantPriceGroupDefault = productVariantPriceGroupId ?? "";
        const colWidth = includePriceGroups ? 3 : 4;

        return (
            <Card>
                <CardHeader title={headerText} />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={colWidth}>
                            <TextField
                                select
                                style={{ flex: 1, margin: '0 3px' }}
                                label="Event"
                                InputLabelProps={{ shrink: true }}
                                value={eventDefault}
                                fullWidth
                                onChange={this.handleEventIdChanged}
                            >
                                {events.map((e, idx) => {
                                    return <MenuItem key={idx} value={e.id}>{e.name}</MenuItem>;
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={colWidth}>
                            <TextField
                                select
                                style={{ flex: 1, margin: '0 3px' }}
                                label="Produkt"
                                InputLabelProps={{ shrink: true }}
                                value={productDefault}
                                fullWidth
                                onChange={this.handleProductIdChanged}
                            >
                                {products.map((p, idx) => {
                                    return <MenuItem key={idx} value={p.id}>{p.name}</MenuItem>;
                                })}
                            </TextField>
                        </Grid>
                        <Grid item xs={colWidth}>
                            <TextField
                                select
                                style={{ flex: 1, margin: '0 3px' }}
                                label="Produktvariant"
                                InputLabelProps={{ shrink: true }}
                                value={productVariantDefault}
                                fullWidth
                                onChange={this.handleProductVariantIdChanged}
                            >
                                {productVariants.map((p, idx) => {
                                    return <MenuItem key={idx} value={p.Id}>{p.Name}</MenuItem>;
                                })}
                            </TextField>
                        </Grid>
                        {includePriceGroups &&
                            <Grid item xs={colWidth}>
                                <TextField
                                    select
                                    style={{ flex: 1, margin: '0 3px' }}
                                    label="Prisgrupp"
                                    InputLabelProps={{ shrink: true }}
                                    value={productVariantPriceGroupDefault}
                                    fullWidth
                                    onChange={this.handleProductVariantPriceGroupIdChanged}
                                >
                                    {productVariantPriceGroups.map((p, idx) => {
                                        return <MenuItem key={idx} value={p.id}>{p.Name}</MenuItem>;
                                    })}
                                </TextField>
                            </Grid>
                        }
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant="contained" disabled={!commitEnabled} onClick={this.commit}>{buttonText}</Button>
                </CardActions>
            </Card>
        );
    }

    private handleEventIdChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { products, includePriceGroups } = this.props;

        const filteredProducts = products.filter(x => x.EventId === ev.target.value);
        let productId: string | undefined = undefined;
        let productVariantId: string | undefined = undefined;
        let productVariantPriceGroupId: string | undefined = undefined;
        let variants: ProductVariant[] = [];
        let priceGroups: ProductVariantPriceGroup[] = [];

        if (filteredProducts && filteredProducts.length === 1) {
            const product = filteredProducts[0];
            productId = product.id;

            if (product.variants) {
                variants = product.variants.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));
            }

            if (variants && variants.length === 1) {
                const variant = variants[0];
                productVariantId = variant.Id;

                if (includePriceGroups) {
                    priceGroups = variant.priceGroups.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));

                    if (priceGroups && priceGroups.length === 0) {
                        productVariantPriceGroupId = variant.priceGroups[0].id;
                    }
                }
            }
        }
        
        this.setState({
            eventId: ev.target.value,
            products: filteredProducts,
            productVariants: variants,
            productVariantPriceGroups: priceGroups,
            productId: productId,
            productVariantId: productVariantId,
            productVariantPriceGroupId: productVariantPriceGroupId
        });
    };

    private handleProductIdChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { includePriceGroups } = this.props;
        const { products } = this.state;

        let productVariantId: string | undefined = undefined;
        let productVariantPriceGroupId: string | undefined = undefined;
        let variants: ProductVariant[] = [];
        let priceGroups: ProductVariantPriceGroup[] = [];
        
        const product = products.find(x => x.id === ev.target.value);

        if (product.variants) {
            variants = product.variants.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));

            if (variants && variants.length === 1) {
                const variant = variants[0];
                productVariantId = variant.Id;
    
                if (includePriceGroups) {
                    priceGroups = variant.priceGroups.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));
    
                    if (priceGroups && priceGroups.length === 0) {
                        productVariantPriceGroupId = variant.priceGroups[0].id;
                    }
                }
            }
        }

        this.setState({
            productVariants: variants,
            productVariantPriceGroups: priceGroups,
            productId: product.id,
            productVariantId: productVariantId,
            productVariantPriceGroupId: productVariantPriceGroupId
        });
    };

    private handleProductVariantIdChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        const { includePriceGroups } = this.props;
        const { productVariants } = this.state;

        let productVariantPriceGroupId: string | undefined = undefined;
        let priceGroups: ProductVariantPriceGroup[] = [];

        const variant = productVariants.find(x => x.Id === ev.target.value);

        if (includePriceGroups) {
            priceGroups = variant.priceGroups.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));

            if (priceGroups && priceGroups.length === 0) {
                productVariantPriceGroupId = variant.priceGroups[0].id;
            }
        }

        this.setState({
            productVariantPriceGroups: priceGroups,
            productVariantId: variant.Id,
            productVariantPriceGroupId: productVariantPriceGroupId
        });
    };

    private handleProductVariantPriceGroupIdChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ productVariantPriceGroupId: ev.target.value });
    };

    private commit = () => {
        const { includePriceGroups, onChange } = this.props;
        const { events, products, productVariants, productVariantPriceGroups, eventId, productId, productVariantId, productVariantPriceGroupId } = this.state;

        const event = events.find(x => x.id === eventId);
        const product = products.find(x => x.id === productId);
        const productVariant = productVariants.find(x => x.Id === productVariantId);
        let productVariantPriceGroup: ProductVariantPriceGroup;

        if (includePriceGroups) {
            productVariantPriceGroup = productVariantPriceGroups.find(x => x.id === productVariantPriceGroupId);
        }

        onChange({
            event: event,
            product: product,
            productVariant: productVariant,
            productVariantPriceGroup: productVariantPriceGroup
        });

        this.resetSelection();
    }

    private resetSelection = () => {
        this.setState({
            eventId: undefined,
            productId: undefined,
            products: [],
            productVariantId: undefined,
            productVariants: [],
            productVariantPriceGroupId: undefined,
            productVariantPriceGroups: []
        });
    }
}

export default ProductVariantPriceGroupSelector;
