/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import { Formik } from 'formik';
import React from 'react';
import uuidv4 from "react-uuid";
import { Person } from '../../../model/Person';
import { ApiBackend } from '../../../providers/apibackend';
import SplitButton from '../../Common/SplitButton/SplitButton';
import withAlert, { IAlertContext } from '../../Common/dialog/Alert';
import EditPersonFormContent from './EditPersonFormContent';
import MergePersonSearch from './MergePersonSearch';
import PersonEntries from './PersonEntries';
import validationSchema from './PersonValidationSchema';
import PersonOrders from './PersonOrders';
import TabPanel from '../../Common/TabPanel';
import EntrySeedingCard from '../Entry/EntrySeedingCard';

interface IProps {
    personId?: string;
    onSave?: (person: Person) => void;
    onAbortEdit: () => void;
}

interface IState {
    loading: boolean;
    person: Person;
    mergePerson: boolean;
    activeTab: number;
}

class EditPersonDialog extends React.Component<WithStyles & IProps & IAlertContext, IState> {
    private readonly api: ApiBackend;
    private readonly formId = `person-form-${uuidv4()}`;

    constructor(props: WithStyles & IProps & IAlertContext) {
        super(props);
        const person = this.props.personId ? null : { country: "SWE" } as Person;
        this.state = { person: person, loading: person ? false : true, mergePerson: false, activeTab: 0 };
        this.api = new ApiBackend();

        if (this.props.personId) {
            this.api.getPerson(this.props.personId).then((person) => {
                this.setState({ person: person, loading: false });
            });
        }
    }

    render() {
        return <div>
            {this.renderEditPerson()}
        </div>;
    }

    renderEditPerson() {
        const isEditPersonMode = !!this.state.person?.id;
        const title = this.state.person?.id ? "Hantera person" : "Lägg till person";
        const { loading, person, activeTab } = this.state;
        const { classes, onAbortEdit, onSave, showAlert } = this.props;

        return <Dialog
            disableEscapeKeyDown
            fullWidth={true}
            maxWidth="lg"
            aria-labelledby="edit-person-title"
            open={true}
        >
            <DialogTitle id="edit-person-title">{loading ? "Laddar" : title}</DialogTitle>

            {loading &&
                (<>
                    <DialogContent dividers>
                        <CircularProgress color='secondary' />
                    </DialogContent>
                </>)
            }
            {person &&
                <Formik
                    initialValues={person}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { resetForm }) => {
                        const person = await this.save(values);
                        resetForm({ values: person });

                        if (onSave) {
                            onSave(person);
                        }
                    }}
                >
                    {formik => {
                        const { isValid, dirty, handleSubmit, handleReset, isSubmitting } = formik;

                        const isFormDisabled = this.isInitialEmptyPerson();
                        const isSaveDisabled = !dirty || !isValid || isSubmitting || isFormDisabled;

                        return (<>
                            <DialogContent dividers >
                                <form id={this.formId} autoComplete="off" onSubmit={handleSubmit}>
                                    <EditPersonFormContent isEditMode={isEditPersonMode} {...formik} classes={classes} ></EditPersonFormContent>

                                    {isEditPersonMode && <>
                                        <Tabs
                                            value={activeTab}
                                            className={classes.tabs}
                                            onChange={this.handleTabChange}
                                        >
                                            <Tab label="Anmälningar" />
                                            <Tab label="Ordrar" />
                                            <Tab label="Resultat" />
                                        </Tabs>
                                        <TabPanel value={activeTab} index={0}>
                                            <PersonEntries
                                                isModalMode={true}
                                                isFormDisabled={isFormDisabled}
                                                isPersonDirty={dirty}
                                                personId={formik.values.id}
                                                personEmail={formik.values.email}
                                            />
                                        </TabPanel>
                                        <TabPanel value={activeTab} index={1}>
                                            <PersonOrders
                                                isPersonDirty={dirty}
                                                person={person}
                                            />
                                        </TabPanel>
                                        <TabPanel value={activeTab} index={2}>
                                            <EntrySeedingCard person={person} alwaysExpanded={true} title="Resultat" />
                                        </TabPanel>
                                        
                                    </>}

                                    {isEditPersonMode && !this.state.mergePerson && !isFormDisabled &&
                                        <Button color="secondary"
                                            onClick={() => {
                                                if (dirty) {
                                                    showAlert("Personen har osparade ändringar!", "Spara för att slå samman person.");
                                                    return;
                                                }
                                                this.setState({ mergePerson: true });
                                            }}>
                                            Slå samman person med...
                                        </Button>
                                    }
                                    {isEditPersonMode && this.state.mergePerson &&
                                        <MergePersonSearch
                                            sourcePerson={this.state.person}
                                            onClose={() => {
                                                this.setState({ mergePerson: null });
                                                onAbortEdit();
                                            }}
                                        />
                                    }
                                </form>
                            </DialogContent>
                            <DialogActions>
                                {!isFormDisabled &&
                                    <SplitButton disabled={isFormDisabled}>
                                        <Button form={this.formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                        <Button form={this.formId} type="submit" color="secondary" disabled={isFormDisabled} variant="text">Forcera Spara</Button>
                                    </SplitButton>
                                }
                                <Button style={{ marginLeft: 10 }} color="secondary" variant="contained"
                                    onClick={() => {
                                        handleReset();
                                        onAbortEdit();
                                    }}
                                >
                                    {dirty ? "Avbryt" : "Stäng"}
                                </Button>
                            </DialogActions>
                        </>);
                    }}
                </Formik>
            }

        </Dialog>;
    }

    private handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ activeTab: newValue });
    };

    private isInitialEmptyPerson = () => {
        const { person } = this.state;

        return person.id && (!person.firstName || !person.lastName);
    };

    private save = (values: Person): Promise<Person> => {
        let savePromise: Promise<Person>;
        if (this.state.person.id) {
            savePromise = this.api.savePerson(values);
        } else {
            savePromise = this.api.addPerson(values);
        }

        return savePromise;
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withAlert(withStyles(useStyles)(EditPersonDialog));
