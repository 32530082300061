import React, { useEffect, useRef, useState } from "react";
import { Box, Button, CardContent, CardHeader, Grid, LinearProgress, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { Theme } from "@material-ui/core/styles";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";
import { ApiBackend } from "../../../providers/apibackend";
import { IColumnDefinition } from "../../Common/EnhancedTable/models";
import { ListOrder, Order, initNewOrder } from "../../../model/Order";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";
import { OrderContact } from "../../../model/OrderContact";
import ManageOrder from "../../Orders/ManageOrder";
import { Person } from "../../../model/Person";

interface IProps {
    isPersonDirty: boolean;
    person: Person;
}

const defaultBoxStyle = {
    display: "flex",
    flexDirection: "row",
};

const PersonOrders = ({ classes, isPersonDirty, person, showAlert }: WithStyles & IAlertContext & IProps) => {
    const [loading, setLoading] = useState(false);
    const [orders, setOrders] = useState(null as ListOrder[]);
    const [displayOrder, setDisplayOrder] = useState(null as string);
    const [rowCount, setRowCount] = useState(0);

    const busyLoading = useRef<boolean>(false);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useEffect(() => {
        didMount.current = true;

        performSearch();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const performSearch = async (): Promise<void> => {
        if (!didMount.current || busyLoading.current) {
            return;
        }

        setBusyLoading(true);

        const ordersResponse = await backend.getOrdersForPerson(person.id);

        if (didMount.current) {
            setRowCount(ordersResponse.length);
            setOrders(ordersResponse);
            setBusyLoading(false);
        }
    };

    const handleOpenOrder = (order: ListOrder) => (): void => {
        setDisplayOrder(order.id);
    };

    const nameFormatter = (contact: OrderContact): string => {
        return `${contact.firstName} ${contact.lastName ?? ""}`;
    };

    const columnDefinitions: IColumnDefinition<ListOrder>[] = [
        {
            propName: "publicId",
            label: "ID",
            renderCell: (row) => {
                return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={handleOpenOrder(row)}>{row.publicId}
                </Typography>
            }
        },
        {
            propName: "OrderDate",
            label: "Skapad",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.OrderDate)
        },
        {
            propName: "contact",
            label: "Namn",
            valueFormatter: (row) => nameFormatter(row.contact),
            sortValueGetter: (row) => nameFormatter(row.contact)
        },
        {
            propName: "contact",
            label: "Email",
            valueFormatter: (row) => row.contact.email,
            sortValueGetter: (row) => row.contact.email
        },
        {
            propName: "totalSum",
            label: "Ordersumma",
            valueFormatter: (row) => `${row.totalSum} kr`
        },
        {
            propName: "Status",
            label: "Status",
            valueFormatter: (row) => Order.translateStatus(row.Status),
            sortValueGetter: (row) => Order.translateStatus(row.Status)
        },
        {
            propName: "LastUpdate",
            label: "Ändrad",
            valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.LastUpdate)
        },
    ];

    const handleCreateNewOrder = async (): Promise<void> => {
        setLoading(true);
        const newOrder = initNewOrder();
        newOrder.contact = {
            firstName: person.firstName,
            lastName: person.lastName,
            address: person.address,
            postalcode: person.postCode,
            city: person.city,
            country: person.country,
            email: person.email,
            phone: person.mobileNumber,
            loggedInUserId: person.id,
        } as OrderContact;
        newOrder.items = [];
        newOrder.Status = "NEW";

        const createdOrder = await backend.createOrder(newOrder);

        setDisplayOrder(createdOrder.id);
        setLoading(false);
    }

    const renderOrderDialog = (): JSX.Element => {
        return <ManageOrder
            orderId={displayOrder}
            close={async () => {
                setDisplayOrder(null);
                await performSearch();
            }}
        />
    };

    const render = (): JSX.Element => {
        return (
            <>
                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                    <Grid item xs={12}>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Typography variant="h5" style={{ display: 'inline' }}>
                                    Ordrar
                                </Typography>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto", marginTop: 8, marginRight: 8 }}>
                                    <Button color="primary" variant="text"
                                        onClick={async () => {
                                            if (isPersonDirty) {
                                                showAlert("Personen har osparade ändringar!", "Spara för att lägga till en order.");
                                                return;
                                            }

                                            handleCreateNewOrder();
                                        }}>Lägg till order
                                    </Button>
                                </Box>
                            }
                        />
                        <CardContent>
                            {loading &&
                                <LinearProgress color="secondary" />
                            }
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={12}>
                                    <EnhancedTable<ListOrder>
                                        columnDefinitions={columnDefinitions}
                                        data={orders ?? []}
                                        pageSize={10}
                                        page={0}
                                        rowCount={rowCount}
                                        sortModel={{
                                            sortBy: "OrderDate",
                                            sortOrder: "desc"
                                        }}
                                        loading={loading}
                                        dense
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Grid>
                </Grid>
                {!!displayOrder && renderOrderDialog()}
            </>);
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withAlert(withStyles(useStyles)(PersonOrders));
