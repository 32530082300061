import React from "react";
import { Box, Card, CardActions, CardContent, CardHeader, WithStyles, Grid, IconButton, TextField, Button, Popover } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import { SupportTicket, SupportTicketNote } from "../../model/SupportTicket";
import NotePopover from "./Popovers/NotePopover";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

interface IState {
	popupOpen: boolean;
	anchorEl: HTMLButtonElement;
}

class EditSupportTicketNotes extends React.Component<WithStyles & FormikProps<SupportTicket>, IState> {
	state: IState;

	constructor(props: WithStyles & FormikProps<SupportTicket>) {
		super(props);

		this.state = {
			popupOpen: false,
			anchorEl: undefined
		};
	}

	render(): JSX.Element {
		const { classes } = this.props;
		const notes = this.props.values.notes;

		const { popupOpen, anchorEl } = this.state;

		return (
			<Card>
				<FieldArray
					name="notes"
					render={arrayHelpers => (
						<>
							<CardHeader
								className={classes.cardHeader}
								title="Anteckningar"
							/>
							<CardContent>
								{notes.map((note, idx) => {
									return (
										<Grid container key={idx} spacing={2}>
											<Grid item xs={2}>
												<TextField
													fullWidth
													disabled={true}
													label="Skapad"
													value={vasaloppetDateTimeGetterFormatter(note.created, "---")}
												/>
											</Grid>
											<Grid item xs={9}>
												<TextField
													fullWidth
													disabled={true}
													label="Anteckning"
													value={note.message}
												/>
											</Grid>
											<Grid item xs={1}>
												<Box display="flex" justifyContent="flex-end">
													<IconButton
														disabled={!!note.id}
														onClick={() => { arrayHelpers.remove(idx); }}
													>
														<DeleteIcon />
													</IconButton>
												</Box>
											</Grid>
										</Grid>
									)
								})}
							</CardContent>
							<CardActions>
								<Box mb={1}>
									<Button variant="contained" onClick={this.showCreateCompany}>Lägg till anteckning</Button>
									<Popover
										open={popupOpen}
										anchorEl={anchorEl}
										onClose={this.handlePopoverClose}
										anchorOrigin={{
											vertical: 'center',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										PaperProps={{
											style: {
												width: "500px"
											}
										}}
									>
										<NotePopover
											onChange={(note: SupportTicketNote) => { this.handleOnPopoverChange(note, arrayHelpers); }}
										/>
									</Popover>
								</Box>
							</CardActions>
						</>
					)} />
			</Card>
		)
	}

	private showCreateCompany = (e: any) => {
		this.setState({ popupOpen: true, anchorEl: e.currentTarget });
	};

	private handlePopoverClose = () => {
		this.setState({ popupOpen: false, anchorEl: undefined });
	};

	private handleOnPopoverChange = async (note: SupportTicketNote, arrayHelpers: FieldArrayRenderProps) => {
		this.handlePopoverClose();
		arrayHelpers.insert(0, note);
	};
}

export default EditSupportTicketNotes;
