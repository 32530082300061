/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Checkbox, FormControlLabel, Grid, TextField, Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { Code } from '../../model/Code';
import { CodeHolder } from '../../model/CodeHolder';
import { CodeProductImplementation } from '../../model/CodeProductImplementation';
import { ApiBackend } from '../../providers/apibackend';
import withToaster, { IToasterContext } from '../Common/Toaster';
import { vasaloppetDateGetterFormatter, vasaloppetIsoDateSetterFormatter, vasaloppetMoment } from '../../utilities/date';
import CodeHolderTypePicker from '../Common/CodeHolderTypePicker';
import withConfirm, { IConfirmContext } from '../Common/dialog/Confirm';

type IProps = WithStyles & RouteChildrenProps & IToasterContext & IConfirmContext;

interface State {
    codeHolder: CodeHolder;
}

class AddCode extends React.Component<IProps, State> {
    state: State;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();
        this.state = {
            codeHolder: {
                id: uuid(),
                name: '',
                description: '',
                category: null,
                ValidFrom: vasaloppetMoment().startOf('day').toDate(),
                ValidTo: vasaloppetMoment().add(1, "y").startOf('day').toDate(),
                Codes: [] as Code[],
                Implementations: [] as CodeProductImplementation[],
                IsArchived: false,
                b2bCompany: null,
                reuseExistingEntries: false,
                b2bInvoice: false,
                type: "ORDER_ITEM"
            }
        };
    }

    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let value = ev.target.value;
        let e = this.state.codeHolder as any;

        if (prop === "ValidFrom") {
            value = vasaloppetIsoDateSetterFormatter(value);
        }

        if (prop === "ValidTo") {
            value = vasaloppetIsoDateSetterFormatter(value, true);
        }

        e[prop] = value;
        this.setState({ codeHolder: e });
    }

    createCode = async () => {
        const { codeHolder } = this.state;

        if (!codeHolder.b2bInvoice) {
            const result = await this.props.showConfirm("Är du säker på att du vill skapa en kodhållare som inte ska faktureras?");

            if (!result) {
                return;
            }
        }

        const res = await this.api.createOrUpdateCode(this.state.codeHolder);
        if (res) {
            this.props.history.replace(`/codes/manage/${this.state.codeHolder.id}`);
        }
        else {
            this.props.showToast("Kunde inte skapa kodhållare!");
        }
    }

    render() {
        const { classes } = this.props;
        return <div>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Skapa ny kodhållare" />
                        <CardContent>
                            <form className={classes.form} noValidate autoComplete="off">
                                <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                    <Grid item xs={12}>
                                        <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn" value={this.state.codeHolder.name} onChange={this.onChange('name')} fullWidth />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField type="date"
                                            id="standard-basic"
                                            style={{ flex: 1, margin: '0 3px' }}
                                            value={vasaloppetDateGetterFormatter(this.state.codeHolder.ValidFrom)}
                                            onChange={this.onChange('ValidFrom')}
                                            label="Giltig från"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} >
                                        <TextField type="date"
                                            id="standard-basic"
                                            style={{ flex: 1, margin: '0 3px' }}
                                            value={vasaloppetDateGetterFormatter(this.state.codeHolder.ValidTo)}
                                            onChange={this.onChange('ValidTo')}
                                            label="Giltig till"
                                            InputLabelProps={{ shrink: true }}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CodeHolderTypePicker
                                            type={this.state.codeHolder.type}
                                            label="Typ av kod"
                                            clearable={false}
                                            onChange={(nextValue) => {
                                                const { codeHolder } = this.state;
                                                codeHolder.type = nextValue;
                                                this.setState({ codeHolder });
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox name="b2bInvoice"
                                                    checked={!!this.state.codeHolder.b2bInvoice}
                                                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                                        const { codeHolder } = this.state;
                                                        codeHolder.b2bInvoice = ev.target.checked;
                                                        this.setState({ codeHolder });
                                                    }}
                                                />
                                            }
                                            label="Ska faktureras"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                        <CardActions>
                            <Button onClick={this.createCode}>Skapa kod</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid></div>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(withToaster(withConfirm(AddCode))));
