import React from "react";
import { FormControl, InputLabel, MenuItem } from "@material-ui/core";

import ClearableSelect from "./ClearableSelect";
import { CodeHolderType } from "../../model/CodeHolder";

interface IProps {
    type: CodeHolderType;
    label: string;
    clearable: boolean;
    onChange: (newValue: CodeHolderType) => void;
}

const CodeHolderTypePicker = ({ type, label, clearable, onChange }: IProps) => {
    const types = {
        Order: "ORDER" as CodeHolderType,
        OrderItem: "ORDER_ITEM" as CodeHolderType
    };

    const render = (): JSX.Element => {
        const safeValue = type ?? types.Order;

        return (
            <FormControl fullWidth>
                <InputLabel shrink>{label}</InputLabel>
                <ClearableSelect
                    clearable={clearable}
                    value={safeValue}
                    onClear={() => {
                        onChange(null);
                    }}
                    onChange={(evt: any) => {
                        const nextValue = evt.target.value === types.OrderItem ? types.OrderItem : null;
                        onChange(nextValue);
                    }}
                >
                    <MenuItem key={1} value={types.OrderItem}>Anmälningskod</MenuItem>
                    <MenuItem key={0} value={types.Order}>Orderkod</MenuItem>
                </ClearableSelect>
            </FormControl>
        );
    };

    return render();
};

export default CodeHolderTypePicker;
