import { Moment } from "moment";
import moment from "moment-timezone";

export const FILE_FORMAT = "YYMMDD";
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DATE_TIME_S_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const TIME_FORMAT = "HH:mm";
export const HOUR_FORMAT = "HH";
export const MINUTE_FORMAT = "mm";
const TIMEZONE = "Europe/Stockholm";
/**
 * Treats incoming value as UTC Date/string and converts to Vasaloppet Date Europe/Stockholm.
 * @param value source UTC date/string
 * @returns Moment date in Europe/Stockholm timezone
 */
export const vasaloppetMoment = (value?: string | Date): Moment => {
    if (!value) {
        return moment().tz(TIMEZONE);
    }

    return moment(value).tz(TIMEZONE);
};

/**
 * Formats incoming UTC value as vasaloppet local date string (YYYY-MM-DD).
 * @param value 
 * @param emptyValue 
 * @returns 
 */
export const vasaloppetDateGetterFormatter = (value: string | Date, emptyValue = ""): string => {
    if (!value) {
        return emptyValue;
    }

    return vasaloppetMoment(value).startOf("day").format(DATE_FORMAT);
};

/**
 * Formats incoming UTC value as vasaloppet local date time string (YYYY-MM-DD HH:mm).
 * @param value 
 * @param emptyValue Optional empty value
 * @param includeSeconds Optional
 * @returns 
 */
export const vasaloppetDateTimeGetterFormatter = (value: string | Date, emptyValue = "", includeSeconds = false): string => {
    if (!value) {
        return emptyValue;
    }

    if (includeSeconds) {
        return vasaloppetMoment(value).startOf("second").format(DATE_TIME_S_FORMAT);
    }

    return vasaloppetMoment(value).startOf("minute").format(DATE_TIME_FORMAT);
};

/**
 * Formats incoming UTC value as vasaloppet local time string (HH:mm).
 * @param value 
 * @param emptyValue 
 * @returns 
 */
export const vasaloppetTimeGetterFormatter = (value: string | Date, emptyValue = ""): string => {
    if (!value) {
        return emptyValue;
    }

    return vasaloppetMoment(value).startOf("minute").format(TIME_FORMAT);
};

/**
 * Formats incoming local date time value as UTC ISOString. Sets hours/minutes to zero if not endOfDay. Keeping YYYY-MM-DD.
 * @param value 
 * @param endOfDay Optional set time part to 23:59
 * @returns formatted value or null
 */
export const vasaloppetIsoDateSetterFormatter = (value: string, endOfDay = false): string | null => {
    if (!value) {
        return null;
    }

    if (endOfDay) {
        return vasaloppetMoment(value).endOf("day").set("ms", 0).utc().toISOString();
    }

    return vasaloppetMoment(value).startOf("day").utc().toISOString();
};

/**
 * Formats incoming local date value as UTC ISOString. Sets seconds to zero. Keeping YYYY-MM-DD HH:mm.
 * @param value 
 * @returns formatted value or null
 */
export const vasaloppetIsoDateTimeSetterFormatter = (value: string): string | null => {
    if (!value) {
        return null;
    }

    return vasaloppetMoment(value).startOf("minute").utc().toISOString();
};

/**
 * Calculates difference in days between two dates (startOfDay).
 * @param value1 Date/String
 * @param value2 Date/String
 * @returns Number of days between days since start of day
 */
export const vasaloppetDayDiff = (value1: string | Date, value2: string | Date): number => {
    if (!value1 || !value2) {
        return 0;
    }

    const d1 = vasaloppetMoment(value1).startOf("day");
    const d2 = vasaloppetMoment(value2).startOf("day");

    return d1.diff(d2, "days");
};

/**
 * Checks if value1 is same day or the day after value2
 * @param value1 Actual date
 * @param value2 Comparing date
 * @returns If same day or after
 */
export const isSameDayOrAfter = (value1: string | Date, value2: string | Date): boolean => {
    return vasaloppetMoment(value1).startOf("day").isSameOrAfter(vasaloppetMoment(value2).startOf("day"));
};

/**
 * Checks if value is between comparing dates
 * @param value Actual date
 * @param fromDate lower bound date
 * @param toDate upper bound date
 * @returns If is between
 */
export const isBetween = (value: string | Date, fromDate: string | Date, toDate: string | Date): boolean => {
    const actual = vasaloppetMoment(value);

    return actual.isAfter(vasaloppetMoment(fromDate).startOf("day")) &&
        actual.isBefore(vasaloppetMoment(toDate).endOf("day"));
};

export const isTodayOrAfter = (value: string | Date) => {
    return vasaloppetMoment(value).startOf("day").isSameOrAfter(vasaloppetMoment().startOf("day"));
};

export const isTodayOrBefore = (value: string | Date) => {
    return vasaloppetMoment(value).startOf("day").isSameOrBefore(vasaloppetMoment().startOf("day"));
};
