import React, { Fragment } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, CardContent, CardHeader, Grid, IconButton, LinearProgress, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import { GridColDef as ColDef, DataGrid, GridValueFormatterParams as ValueFormatterParams } from "@material-ui/data-grid";
import { OpenInBrowser, ArrowForward } from "@material-ui/icons";
import { B2bCompany } from "../../../model/B2bCompany";
import { ApiBackend } from "../../../providers/apibackend";
import SupportTicketsCard from "../../Support/SupportTicketsCard";


interface IState {
    loading: boolean;
    allCompanies: B2bCompany[];
    companies: B2bCompany[];
}

type EditMode = "in-app" | "new-tab";

class ManageCompanyInvoiceRequests extends React.Component<RouteComponentProps & WithStyles, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps & WithStyles) {
        super(props);

        this.state = {
            loading: true,
            allCompanies: null,
            companies: null
        };

        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        await this.refresh();
    }

    render(): JSX.Element {
        const { classes } = this.props;
        const { loading, companies, allCompanies } = this.state;

        const columns: ColDef[] = [
            {
                field: 'id',
                headerName: '',
                width: 150,
                renderHeader: () => " ",
                renderCell: (params: ValueFormatterParams) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editCompany(params.row as B2bCompany, "new-tab") }}>
                            <OpenInBrowser fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editCompany(params.row as B2bCompany, "in-app") }}>
                            <ArrowForward fontSize="medium" />
                        </IconButton>
                    </>
                }
            },
            {
                field: 'name',
                headerName: 'Namn',
                width: 250
            },
            {
                field: 'description',
                headerName: 'Beskrivning',
                minWidth: 350,
                flex: 1
            },
            {
                field: 'orgNumber',
                headerName: 'Organisationsnummer',
                width: 250
            }
        ];

        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography
                                        variant="h5"
                                        style={{ display: 'inline' }}>
                                        Fakturaförfrågningar
                                    </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refresh}
                                    />
                                </Fragment>
                            }
                        />
                        <CardContent>
                            {loading &&
                                <LinearProgress color="secondary" />
                            }
                            <DataGrid
                                autoHeight={true}
                                loading={loading}
                                rows={companies ?? []}
                                columns={columns}
                                pageSize={10}
                                rowsPerPageOptions={[10]}
                            />
                        </CardContent>
                        {allCompanies &&
                            <SupportTicketsCard {...this.props}
                                typeFilter={["company_account_change_request"]}
                                statusFilter={["new", "started"]}
                                companies={allCompanies}
                            />
                        }
                    </Card>
                </Grid>
            </Grid>
        );
    }

    private refresh = async (): Promise<void> => {
        this.setState({ loading: true });
        await this.listCompanies();
    };

    private listCompanies = async (): Promise<void> => {
        const companies = await this.api.listB2bCompanies({ includeArchived: false });
        const matches = companies?.filter(x => x.status === "APPLIED");

        this.setState({
            allCompanies: companies,
            companies: matches,
            loading: false
        });
    };

    private editCompany = (company: B2bCompany, mode: EditMode): void => {
        switch (mode) {
            case "in-app":
                this.props.history.push(`/companies/manage/${company.id}`);
                break;
            case "new-tab":
                window.open(`/companies/manage/${company.id}`);
                break;
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(ManageCompanyInvoiceRequests));
