import React, { useEffect, useRef } from "react";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { useState } from "react";
import { Order } from "../../../model/Order";
import { ApiBackend } from "../../../providers/apibackend";
import { Alert } from "@material-ui/lab";

interface IProps {
    phone: string;
    initializePaymentDisabled: boolean;

    onPhoneChange?: (value: string) => void;
    onComplete: () => void;
    onAbort: () => void;

    onRequestCreateOrder?: () => Promise<Order>;
    onProgress?: (message: string) => void;
}

const awaiter = (ms: number): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

const isSwishInputValid = (phone: string): boolean => {
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    return new RegExp(phoneRegExp).test(phone);
};

const SwishPaymentStep = (props: IProps) => {
    const [swishStep, setSwishStep] = useState<"1" | "2" | "3">("1");
    const [swishError, setSwishError] = useState(null as string);
    const [swishCancelled, setSwishCancelled] = useState(false);

    const stateRef = useRef<boolean>();
    stateRef.current = swishCancelled;
    const backend = new ApiBackend();

    useEffect(() => {
        if (swishCancelled) {
            setSwishStep("1");
            setSwishError(null);
            props.onAbort();
        }

    }, [swishCancelled]);

    const initSwish = async (orderId: string): Promise<boolean> => {
        setSwishCancelled(false);
        setSwishError(null);
        reportProgress("Initierar Swish-betalning");

        const res = await backend.initializeSwishPayment(orderId);
        if (!res) {
            throw ("Kunde inte initiera Swish-betalning, kolla mobilnumret!");
        }

        setSwishStep("2");
        reportProgress("Swish-betalning initierad, väntar på kund");

        const exitStatuses = ["PAID", "DECLINED", "ERROR"] as const;
        type StatusType = typeof exitStatuses[number];
        const work = async (): Promise<StatusType> => {
            let result = await backend.checkSwishPaymentStatus(res);
            while (!stateRef.current && !exitStatuses.some(x => x === result)) {
                await awaiter(500);
                result = await backend.checkSwishPaymentStatus(res);
            }

            return result as unknown as StatusType;
        };

        const result = await work();

        if (stateRef.current) {
            return false;
        }

        if (result === "PAID") {
            return true;
        }

        if (result === "DECLINED") {
            throw ("Betalning avbruten av användaren");
        }

        throw ("Ett fel uppstod vid betalning");
    };

    const handleInitialize = async () => {
        try {
            if (!isSwishInputValid(props.phone)) {
                setSwishError("Felaktigt telefonnummer");
                return;
            }

            const order = await props.onRequestCreateOrder();
            const orderId = order.id;

            const res = await initSwish(orderId);
            if (res)
            {
                props.onComplete();
            }
            else 
            {
                props.onAbort();
            }
            
        } catch (err) {
            setSwishError(err);
            props.onAbort();
        }
    };

    const reportProgress = (message: string) => {
        if (props.onProgress) {
            props.onProgress(message);
        }
    };

    const render = () => {
        let content: JSX.Element;

        switch (swishStep) {
            case "1":
                content = (<>
                    <Typography variant="h6">1(3) - Swishbetalning</Typography>
                    {swishError &&
                        <Alert severity="error">{swishError}</Alert>
                    }
                    <TextField label="Mobilnummer"
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        value={props.phone}
                        onChange={(ev) => {
                            if (props.onPhoneChange) {
                                props.onPhoneChange(ev.target.value);
                            }
                        }}
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={props.initializePaymentDisabled}
                        onClick={handleInitialize}
                    >
                        Initiera betalning
                    </Button>
                </>);
                break;
            case "2":
                content = (<>
                    <Typography variant="h6">2(3) - Betalning initierad, be kund öppna Swish-appen och godkänna betalning</Typography>
                    {swishError &&
                        <Alert severity="error">{swishError}</Alert>
                    }
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        disabled={swishCancelled}
                        onClick={() => {
                            setSwishCancelled(true);
                        }}
                    >
                        Avbryt
                    </Button>
                </>);
                break;
            case "3":
                content = (<>
                    <Typography variant="h6">3(3) - Swishbetalning slutförd</Typography>
                    <Alert>Klart!</Alert>
                </>);
                break;
        }
        return (
            <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="center">
                    <Box display="flex" alignItems="center" justifyContent="space-between" flexDirection="column" style={{ gap: 16, width: "80%" }}>
                        {content}
                    </Box>
                </Box>
            </Grid>
        );
    };

    return render();
};

export default SwishPaymentStep
