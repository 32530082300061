import { Grid, MenuItem, Typography, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import FormTextField from "../Common/FormTextField";
import { SupportTicket, SupportTicketStatusLookup, SupportTicketStatuses } from "../../model/SupportTicket";
import { B2bCompany } from "../../model/B2bCompany";
import FormSelectField from "../Common/FormSelectField";
import EditSupportTicketNotes from "./EditSupportTicketNotes";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

interface IProps extends FormikProps<SupportTicket>, WithStyles {
    company?: B2bCompany;
}

class EditSupportTicketFormContent extends React.Component<IProps> {

    render(): JSX.Element {
        const { classes, values } = this.props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="subject"
                        label="Ärende"
                        disabled
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormTextField {...this.props}
                        propName="created"
                        label="Skapad"
                        disabled
                        valueGetter={() => vasaloppetDateTimeGetterFormatter(values.created, "---")}
                    />
                </Grid>
                <Grid item xs={3}>
                    <FormSelectField {...this.props}
                        propName={"status"}
                        label="Status"
                    >
                        {SupportTicketStatuses.map((x) => {
                            return <MenuItem key={x} value={x}>{SupportTicketStatusLookup[x]}</MenuItem>;
                        })}
                    </FormSelectField>
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="message"
                        label="Meddelande"
                        disabled
                        minRows={4}
                        multiline={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">Kontakt</Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="contactFirstName"
                        label="Förnamn"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="contactLastName"
                        label="Efternamn"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="contactEmail"
                        label="E-post"
                        disabled
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...this.props}
                        propName="contactPhone"
                        label="Telefonnummer"
                        disabled
                    />
                </Grid>
                <Grid item xs={12}>
                    <EditSupportTicketNotes {...this.props} />
                </Grid>
            </Grid>
        )
    }
}

export default EditSupportTicketFormContent;
