export const isConcreteType = <T extends unknown>(source: any, propName: keyof T): source is T => {
    try {
        return (source as T)[propName] !== undefined;
    } catch (e) {
        return false;
    }
};

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;
export type OptionalPartial<T, K extends keyof T> = Pick<Partial<T>, K>;
