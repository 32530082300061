/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React from 'react';
 import { withRouter, RouteChildrenProps } from 'react-router-dom';
 import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import { CodeHolder } from '../../model/CodeHolder';
import { Product } from '../../model/Product';
 
 interface Props extends RouteChildrenProps<{id: string}> {
     item : CodeHolder;
     products: Product[];
     save : (reservation: CodeHolder) => void;
     abortEdit: () => void;
 }
 
 interface State {
     item : CodeHolder;
 }
 
 class ReservationFormEditItem extends React.Component<WithStyles & Props, State> {
  
     constructor(props: WithStyles & Props) {
         super(props);
         this.state={item: cloneDeep(props.item)};
     }
 
     onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
     {
         var e = this.state.item as any;
         e[prop] = ev.target.value;
         this.setState({item: e});
     }
 
     render() {
              return <div>
                 {this.state.item &&
                     this.renderEditReservationItem()
                 }
             </div>;
     }

     save = () => {
        this.props.save(this.state.item);
     }

     getRaceName(id: string) {
         let p = this.props.products.filter(x=>x.id==id);
         if (p && p.length > 0)
         {
             return p[0].name;
         }
         return "";
     }
    

     renderEditReservationItem() {
         let { classes } = this.props;
         return <Card>
                 <CardHeader className={classes.cardHeader} title={"Reservation: " + this.getRaceName(this.state.item.name)} />
                 <CardContent>
                     <form className={classes.form} noValidate autoComplete="off">
                         <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                             <Grid item xs={4}>
                             <FormControl>
                                 <InputLabel>Lopp</InputLabel>
                                 <Select style={{minWidth:'100px'}} label="Lopp" value={this.state.item.name} onChange={this.onChange('name')}>
                                    {this.props.products.map((product) => {
                                        return <MenuItem value={product.id}>{product.name}</MenuItem>
                                    })}
                                  </Select>
                                </FormControl>
                             </Grid>
                             <Grid item xs={4}>
                                    <TextField placeholder="Giltig till (YYYY-MM-DD)" label="Giltig till (YYYY-MM-DD)" variant="outlined" value={this.state.item.ValidTo} onChange={this.onChange('ValidTo')}/>
                             </Grid>
                        </Grid>
                     </form>
                 </CardContent>
                 <CardActionArea component="a">
                     <Button color="secondary" variant="contained" onClick={this.save}>OK</Button>
                     <Button style={{marginLeft: 10}} color="secondary" variant="contained" onClick={this.props.abortEdit}>Avbryt</Button>
                 </CardActionArea>
             </Card>;
     }
    }
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 
 export default withStyles(useStyles)(withRouter(ReservationFormEditItem));