import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, CircularProgress, Grid, IconButton } from "@material-ui/core";
import { Product } from '../../../model/Product';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { ApiBackend } from '../../../providers/apibackend';
import { Person } from '../../../model/Person';
import { PersonResult } from '../../../model/PersonResult';
import { SeedingTable } from '../../../model/SeedingTable';

class Props {
    entryProduct?: Product;
    person: Person;
    alwaysExpanded?: boolean;
    title: string;
}

const EntrySeedingCard = ({ entryProduct, person, alwaysExpanded = false, title }: Props) => {

    const [expanded, setExpanded] = useState(alwaysExpanded);
    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [results, setResults] = useState(null as PersonResult[]);
    const [seedingTables, setSeedingTables] = useState(null as SeedingTable[]);

    useEffect(() => {
        if (expanded && !loaded)
        {
            setLoading(true);
            let api = new ApiBackend();
            Promise.all([api.listSeedingTables(),api.getPersonResults(person.id)]).then((value: [SeedingTable[], PersonResult[]]) => {
                setSeedingTables(value[0]);
                setLoaded(true);
                setLoading(false);
                setResults(value[1]);
            });
        }
    },[expanded]);

    const calculateSeeding = (result : PersonResult) => {
        if (!entryProduct)
            return;

        if (!seedingTables || result.raceStatus != "F" || !entryProduct.startGroups)
        {
            return null;
        }

        let bestStartGroup = -1;

        for (let i = 0; i < entryProduct.startGroups.length; i++)
        {
            const sg =  entryProduct.startGroups[i];
            if (!sg.seedingTable)
            {
                continue;
            }

            const seedingTable = seedingTables.find(x=>x.id == sg.seedingTable);
            if (!seedingTable)
            {
                continue;
            }



            for (let r = 0; r < seedingTable.races.length; r++)
            {
                const race = seedingTable.races[r];
                if (race.raceId != result.eventKey)
                    continue;
                const resInRace = results.find(c=>c.eventKey == race.raceId);
                if (resInRace)
                {
                    for (let g = 0; g < race.startGroupLimits.length; g++)
                    {
                        if (resInRace.raceTimeMs < race.startGroupLimits[g])
                        {
                            bestStartGroup=g;
                            break;
                        }
                    }
                }

            }

        }

        return bestStartGroup >= 0 ? entryProduct.startGroups.find(x=>x.seedingTableGroup == bestStartGroup)?.name : "";

    }

    return (
        <Card>
            <CardHeader
                action={<>
                    <IconButton onClick={() => { setExpanded(!expanded) }}>
                        {expanded ? (
                            <KeyboardArrowUp />
                        ) : (
                            <KeyboardArrowDown />
                        )}
                    </IconButton>
                </>

                }
                title={title}
            />
            {expanded && (
                <CardContent>
                    <Grid container spacing={2} style={{ width: '100%' }}>
                        {loading && <CircularProgress color='secondary'/>}
                        {!loading && results && <>
                            <Grid item xs={entryProduct ? 3 : 6}><b>Lopp</b></Grid>
                            <Grid item xs={2}><b>Nr. lapp</b></Grid>
                            <Grid item xs={2}><b>Åktid</b></Grid>
                            <Grid item xs={1}><b>Startgr.</b></Grid>
                            <Grid item xs={1}><b>Status</b></Grid>
                            {entryProduct && <Grid item xs={3}><b>Ger seedning till led</b></Grid>}
                        {results.map((res, idx) => {
                            const s = {background : idx % 2 == 0 ? "#ffffff" : "#eeeeee"};
                            return <>
                            <Grid style={s} item xs={entryProduct ? 3 : 6}>{res.raceName}</Grid>
                            <Grid style={s} item xs={2}>{res.bib}</Grid>
                            <Grid style={s} item xs={2}>{res.raceTime}</Grid>
                            <Grid style={s} item xs={1}>{res.startGroup}</Grid>
                            <Grid style={s} item xs={1}>{res.raceStatus}</Grid>
                            {entryProduct &&<Grid  style={s} item xs={3}>{calculateSeeding(res)}</Grid>}
                            </>
                        })}
                        </>}
                    </Grid>
                </CardContent>
            )}
        </Card>
    )
}

export default EntrySeedingCard;