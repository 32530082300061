/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Box, Button, Card, CardContent, CardHeader, Grid, IconButton, LinearProgress, Theme, Typography, createStyles, withStyles } from '@material-ui/core';
import { ArrowForward, Edit, OpenInBrowser } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { debounce } from 'lodash';
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { IExportExcelRequest } from '../../../model/IExportExcelRequest';
import { Person } from '../../../model/Person';
import FirebaseContext from '../../../providers/Firebase/context';
import { ApiBackend } from '../../../providers/apibackend';
import EnhancedTable from '../../Common/EnhancedTable/EnhancedTable';
import { IColumnDefinition, ISortModel } from '../../Common/EnhancedTable/models';
import SearchFilter, { Filters } from '../../Common/SearchFilter/SearchFilter';
import { searchFilterToExportExcelFilter } from '../../Common/SearchFilter/utils';
import withConfirm, { IConfirmContext } from '../../Common/dialog/Confirm';
import EditPersonDialog from './EditPersonDialog';

interface ISearchFilter {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    vasaId?: string;
    birthDate?: string;
}

interface State {
    loading: boolean;
    persons: Person[];
    editPerson: Person;
    maxRows: number;
    searchFilter: ISearchFilter;
    sortModel: ISortModel<Person>;
}

type editPersonMode = "dialog" | "in-app" | "new-tab";

type IProps = RouteComponentProps & IConfirmContext;

class ManagePersons extends React.Component<IProps, State> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = {
            editPerson: null,
            loading: true,
            persons: null,
            searchFilter: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                vasaId: "",
            },
            maxRows: 500,
            sortModel: null
        };
        this.api = new ApiBackend();
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    };

    createPerson = () => {
        this.setState({ editPerson: new Person() });
    }

    editPerson = (person: Person, mode: editPersonMode): void => {
        switch (mode) {
            case "dialog":
                this.setState({ editPerson: person });
                break;
            case "in-app":
                this.props.history.push(`manage/${person.id}`);
                break;
            case "new-tab":
                window.open(`manage/${person.id}`);
                break;
        }
    };

    private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        this.setState({ searchFilter: searchFilter, loading: true }, async () => {
            await this.performSearch();
        });
    };

    private export = async () => {
        const { searchFilter, sortModel } = this.state;

        const filter = searchFilterToExportExcelFilter<ISearchFilter>(searchFilter);

        if (!filter || filter.length === 0) {
            const result = await this.props.showConfirm("Du håller på att exportera data utan något filter, vill du fortsätta?");
            if (!result) {
                return;
            }
        }

        const request: IExportExcelRequest = {
            entityType: "Person",
            filter: filter,
            sortProp: sortModel?.sortBy,
            sortDir: sortModel?.sortOrder
        };
        try {
            this.setState({ loading: true });
            await this.api.exportExcel(request);
        } finally {
            this.setState({ loading: false });
        }
    };

    render() {
        const { classes } = this.props as any;

        const columnDefinitions: IColumnDefinition<Person>[] = [
            {
                renderCell: (row) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editPerson(row, "dialog") }} size="small">
                            <Edit fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editPerson(row, "new-tab") }} size="small">
                            <OpenInBrowser fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editPerson(row, "in-app") }} size="small">
                            <ArrowForward fontSize="medium" />
                        </IconButton>
                    </>
                }
            },
            { propName: "lastName", label: "Efternamn" },
            { propName: "firstName", label: "Förnamn" },
            { propName: "vasaId", label: "VASA-ID" },
            { propName: "birthDay", label: "Födelsedatum" },
            { propName: "email", label: "E-post" },
            { propName: "mobileNumber", label: "Telefon" },
        ];

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return <Fragment>
            <SearchFilter
                id={"manage-persons-filter"}
                filters={{
                    "lastName": {
                        id: "filter-last-name",
                        type: "DebouncedText",
                        label: "Efternamn",
                        size: 4
                    },
                    "firstName": {
                        id: "filter-first-name",
                        type: "DebouncedText",
                        label: "Förnamn",
                        size: 4
                    },
                    "vasaId": {
                        id: "filter-vasaId",
                        type: "DebouncedText",
                        label: "VASA-ID",
                        size: 4
                    },
                    "birthDate": {
                        id: "filter-birthDate",
                        type: "BirthDate",
                        label: "Födelsedatum",
                        size: 4
                    },
                    "email": {
                        id: "filter-email",
                        type: "DebouncedText",
                        label: "E-post",
                        size: 4
                    },
                    "phone": {
                        id: "filter-phone",
                        type: "DebouncedText",
                        label: "Telefon",
                        size: 4
                    },
                }}
                persist={true}
                onInit={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography
                                        variant="h5"
                                        style={{ display: 'inline' }}>
                                        Personer
                                    </Typography>
                                    <RefreshIcon
                                        style={{ display: 'inline', verticalAlign: 'middle' }}
                                        onClick={this.refresh}
                                    />
                                </Fragment>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                    <div style={{ marginTop: 12, marginRight: 12 }}>
                                        <GetAppIcon onClick={this.export} />
                                    </div>
                                </Box>
                            }
                        />
                        <CardContent>
                            <Box mb={1}>
                                <Button variant="contained" onClick={this.createPerson}>Lägg till person</Button>
                            </Box>

                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <EnhancedTable<Person>
                                columnDefinitions={columnDefinitions}
                                data={this.state.persons ?? []}
                                pageSize={10}
                                maxRows={this.state.maxRows}
                                paginationMode="client"
                                sortingMode="server"
                                loading={this.state.loading}
                                dense
                                onRequestSort={this.setSortModel}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.editPerson &&
                <EditPersonDialog
                    personId={this.state.editPerson.id}
                    onAbortEdit={() => {
                        this.setState({ editPerson: null });
                        this.refresh();
                    }}
                />
            }
        </Fragment>;
    }

    private setSortModel = (nextSortModel: ISortModel<Person>): void => {
        this.setState({ sortModel: nextSortModel, loading: true }, () => {
            this.performSearch();
        });
    };

    private performSearch = debounce(async () => {
        const { searchFilter, maxRows, sortModel } = this.state;
        const { firstName, lastName, email, phone, vasaId, birthDate } = searchFilter;

        const response = await this.api.searchPersons({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            vasaid: vasaId,
            birthDate: birthDate,
            noCount: true,
            sortProp: sortModel?.sortBy as string,
            sortDir: sortModel?.sortOrder,
            begin: 0,
            limit: maxRows
        });
        this.setState({
            persons: response?.persons,
            loading: false
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withConfirm(withStyles(useStyles)(ManagePersons)));
