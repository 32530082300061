import { IFilterItem } from "../../../model/IExportExcelRequest";
import { ApiBackend } from "../../../providers/apibackend";

const searchFilterToExportExcelFilter = <T>(searchFilter: T): IFilterItem[] | undefined => {
    let property: keyof typeof searchFilter;

    let filter: IFilterItem[];
    for (property in searchFilter) {
        if (!searchFilter[property]) {
            continue;
        }

        if (!filter) {
            filter = [];
        }

        let value;
        if (Array.isArray(searchFilter[property])){
            value = (searchFilter[property] as unknown as string[]).join(ApiBackend.arraySeparator);
        } else {
            value = searchFilter[property];
        }

        filter.push({ propertyName: property, propertyValue: value });    
    }

    return filter;
};

export { searchFilterToExportExcelFilter };

