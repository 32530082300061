import React, { useState } from "react";
import { Person } from "../../../../model/Person";
import PersonSearchDialog from "../../Person/PersonSearchDialog";
import { Entry } from "../../../../model/Entry";
import AddEntryDialog, { IDefaults } from "../AddEntryDialog/AddEntryDialog";
import { ApiBackend } from "../../../../providers/apibackend";
import { Button } from "@material-ui/core";
import EditPersonDialog from "../../Person/EditPersonDialog";

interface IProps {
    entry: Entry;
    onComplete: () => void;
    onAbort: () => void;
}

const PersonChangeDialog = (props: IProps) => {
    const [showSearchChangePerson, setShowSearchChangePerson] = useState(true);
    const [showCreateChangePerson, setShowCreateChangePerson] = useState(false);
    const [targetPerson, setTargetPerson] = useState(null as Person);
    const [defaults, setDefaults] = useState(null as IDefaults);

    const backend = new ApiBackend();

    const handleOnPersonSelect = async (value: Person) => {
        const { entry } = props;
        const product = await backend.getProduct(props.entry.productId);
        const variant = product.variants.find(x => x.Id === entry.variantId)
        const priceGroup = variant?.priceGroups.find(x => x.Name === "Personbyte");

        setDefaults({
            raceProduct: product,
            productVariantId: entry.variantId,
            priceGroup: priceGroup?.id
        });

        setShowSearchChangePerson(false);
        setShowCreateChangePerson(false);
        setTargetPerson(value);
    };

    const handleOnSave = async () => {
        const toSave = props.entry;
        toSave.status = "SOLD";

        await backend.saveEntry(toSave);
        props.onComplete();
    };

    const render = () => {
        if (!targetPerson && showSearchChangePerson) {
            return (
                <PersonSearchDialog
                    title="Välj person att byta till"
                    action={
                        <Button variant="text" color="secondary"
                            onClick={() => {
                                setShowSearchChangePerson(false);
                                setShowCreateChangePerson(true);
                            }}
                        >
                            Skapa ny person
                        </Button>
                    }
                    onAbort={props.onAbort}
                    onPersonSelect={handleOnPersonSelect}
                />
            );
        }

        if (!targetPerson && showCreateChangePerson) {
            return (
                <EditPersonDialog
                    onAbortEdit={() => {
                        setShowCreateChangePerson(false);
                    }}
                    onSave={handleOnPersonSelect}
                />
            );
        }

        if (targetPerson && defaults) {
            return (
                <AddEntryDialog
                    personId={targetPerson.id}
                    defaults={defaults}
                    onAbort={props.onAbort}
                    onSave={handleOnSave}
                />
            )
        }

        return null;
    };

    return render();
};

export default PersonChangeDialog;
