import { IResponseBase } from "./IResponseBase";

export const BundleGroupSelectionModes = ["single", "multiple"] as const;
export type BundleGroupSelectionMode = typeof BundleGroupSelectionModes[number];

export class ProductBundle {
    id: string;
    publicId?: string;
    name: string;
    nameEn?: string;
    description?: string;
    descriptionEn?: string;
    validFrom?: Date;
    validTo?: Date;
    created: Date;
    updated: Date;
    isArchived: boolean;
    sortIndex?: number;
    groups: ProductBundleGroup[];
}

export class ProductBundleGroup {
    id: string;
    productBundleId: string;
    name: string;
    nameEn?: string;
    description?: string;
    descriptionEn?: string;
    selectionMode: BundleGroupSelectionMode;
    required: boolean;
    items: ProductBundleItem[];
}

export class ProductBundleItem {
    id: string;
    productBundleId: string;
    productBundleGroupId: string;
    productId: string;
    productVariantId?: string;
    productVariantPriceGroupId?: string;
}

export interface IListProductBundlesResponse extends IResponseBase {
    bundles: ProductBundle[];
}

export interface IGetProductBundleResponse extends IResponseBase {
    bundle: ProductBundle;
}

export interface IAddOrUpdateProductBundleResponse extends IResponseBase {
    bundle: ProductBundle;
}