import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import React from "react";
import { Entry } from "../../../model/Entry";
import { ApiBackend } from "../../../providers/apibackend";
import CompanySelect from "../../Common/CompanySelect";
import withConfirm, { IConfirmContext } from "../../Common/dialog/Confirm";

interface IProps {
	entry: Entry;
	disabled: boolean;
	onChange: () => void;
}

interface IState {
	loading: boolean;
	b2bId: string | null;
}

class SetEntryB2b extends React.Component<IProps & IConfirmContext, IState> {
	private readonly api: ApiBackend;

	constructor(props: IProps & IConfirmContext) {
		super(props);
		this.state = { b2bId: this.props.entry.b2bId, loading: false };
		this.api = new ApiBackend();
	}

	render(): JSX.Element {
		const { b2bId } = this.state;

		const { disabled } = this.props;
		const value = b2bId ?? "";

		return (
			<FormControl fullWidth style={{ flex: 1, margin: "0 3px", minWidth: "150px" }}>
				<InputLabel shrink style={{ lineHeight: "0" }}>
					Företagskoppling
				</InputLabel>
				<CompanySelect
					clearable={true}
					includeArchived={false}
					disabled={disabled}
					initialValue={value}
					style={{ height: "36px" }}
					onClear={() => {
						this.handleChange(null);
					}}
					onChange={(e: any) => {
						this.handleChange(e.target.value);
					}}
					onBeforeChange={async (reason) => {
						const { showConfirm } = this.props;

						const message = {
							change: "Är du säker på att du vill ändra företagskopplingen?",
							clear: "Är du säker på att du vill ta bort företagskopplingen?",
						};

						return showConfirm(message[reason]);
					}}
				/>
			</FormControl>
		);
	}

	private handleChange = async (value: string | null) => {
		const { entry, onChange } = this.props;

		this.setState({ b2bId: value, loading: true });
		const res = await this.api.setEntryB2b(entry.id, value);
		if (res) {
			onChange();
		}

		this.setState({ loading: false });
	};
}

export default withConfirm(SetEntryB2b);
