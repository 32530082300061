import Box from "@material-ui/core/Box/Box";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import { getDaysInMonth, getDefaults, getMonths, getNotificationValue, getYears } from "./utils";

interface IProps {
    initialValue?: string;
    onChange: (value?: string) => void;
}

const BirthDatePicker = ({initialValue, onChange}: IProps) => {
    const defaults = getDefaults(initialValue);

    const [year, setYear] = useState(defaults?.year as string);
    const [month, setMonth] = useState(defaults?.month as string);
    const [day, setDay] = useState(defaults?.day as string);
    const [years, setYears] = useState([] as string[]);
    const [months, setMonths] = useState([] as string[]);
    const [days, setDays] = useState([] as string[]);
    const didMount = useRef<boolean>(false);

    useEffect(() => {
        setYears(getYears(1900, new Date().getFullYear()));
        setMonths(getMonths());
        setDays(getDaysInMonth(year, month)); 

        didMount.current = true;

        // unmount
        return () => {didMount.current = false};
    }, []);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        if (!year) {
            setMonth(null);
        }

        notifyChange();
    }, [year]);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        const daysInMonth = getDaysInMonth(year, month);
        setDays(daysInMonth);

        if (!!day && !daysInMonth.some(x => x === day)) {
            setDay(null);
        }

        notifyChange();
    }, [month]);

    useEffect(() => {
        if (!didMount.current) {
            return;
        }

        notifyChange();
    }, [day]);
    
    const notifyChange = () => {
        if (!didMount.current) {
            return;
        }

        onChange(getNotificationValue(year, month, day));
    };

    return (
        <Box display="grid" gridTemplateColumns={"repeat(3,1fr)"} gridGap={{ xs: 16, sm: 24 }}>
            <Box>
                <Autocomplete
                    fullWidth
                    disablePortal
                    id="year"
                    options={years}
                    value={year ?? null}
                    renderInput={(params) => (
                        <TextField {...params} value={year} />
                    )}
                    onChange={(event: any, newValue: string | null) => {
                        setYear(newValue);
                    }}
                />
            </Box>
            <Box>
                <Autocomplete
                    fullWidth
                    disabled={!year}
                    disablePortal
                    id="month"
                    options={months}
                    value={month ?? null}
                    renderInput={(params) => (
                        <TextField {...params} value={month} />
                    )}
                    onChange={(event: any, newValue: string | null) => {
                        setMonth(newValue);
                    }}
                />
            </Box>
            <Box>
                <Autocomplete
                    fullWidth
                    disabled={!month}
                    disablePortal
                    id="day"
                    options={days}
                    value={day ?? null}
                    renderInput={(params) => (
                        <TextField {...params} value={day} />
                    )}
                    onChange={(event: any, newValue: string | null) => {
                        setDay(newValue);
                    }}
                />
            </Box>
        </Box>
    );
};

export default BirthDatePicker;