/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, Card, CardActionArea, CardContent, CardHeader, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead/TableHead';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import WarningIcon from '@material-ui/icons/Warning';
import React, { Fragment } from 'react';
import { CodeHolder, isOrderCode } from '../../model/CodeHolder';
import { CodeProductImplementation } from '../../model/CodeProductImplementation';
import { Product } from '../../model/Product';
import { ApiBackend } from '../../providers/apibackend';
import ProductList from '../Common/ProductList/ProductList';
import withConfirm, { IConfirmContext } from '../Common/dialog/Confirm';
import { Alert } from '@material-ui/lab';
import VasaloppetTooltip from '../Common/VasaloppetTooltip';
import { ProductListChangeEvent } from '../Common/ProductList/utils';

interface Props {
    code: CodeHolder;
    products: Product[];
    save: (implementation: CodeProductImplementation) => void;
    delete: (implementation: CodeProductImplementation) => void;
}

interface State {
    loading: boolean;
    haveChanges: boolean;
    addEdit: CodeProductImplementation;
    curProduct: Product;
}

type CodeProductImplementationBooleanProps = Pick<CodeProductImplementation, "allowsEntryToFullRace" | "startGroupEnforced">;

class CodeProducts extends React.Component<WithStyles & Props & IConfirmContext, State> {

    state = {
        loading: true,
        haveChanges: false,
        addEdit: null as CodeProductImplementation,
        curProduct: null as Product
    };

    constructor(props: WithStyles & Props & IConfirmContext) {
        super(props);
    }

    private hasTypeWarning = (impl: CodeProductImplementation) => {
        let { code } = this.props;
        return isOrderCode(code) && impl.type == "pricegroup";
    };

    onChangeStateProp = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = {} as any;
        o[prop] = ev.target.value;
        this.setState(o);
    };

    onChangeProductId = (value: string) => {
        let o = this.state.addEdit;

        let p = this.props.products.find(x => x.id == value);
        o.productId = value;
        o.productVariantId = p.variants[0].Id;
        o.startGroup = null;
        o.startGroupEnforced = false;

        if (this.state.addEdit.type == "pricegroup") {
            o.productVariantPriceGroupId = p.variants[0].priceGroups[0].id;
        }

        this.setState({ addEdit: o, curProduct: null, loading: p.type == "race" });
        if (p.type == "race") {
            let back = new ApiBackend();
            back.getProduct(value).then((x) => {
                this.setState({ loading: false, curProduct: x });
            })
        }
    };

    changeEditField = <IProp extends keyof CodeProductImplementation>(prop: IProp) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = this.state.addEdit;
        o[prop] = ev.target.value as any;

        // Om vi byter implementation till prisgrupp, töm ev. värde i value-fältet
        if (prop == "type" && ev.target.value == "pricegroup") {
            o.value = null;
        } else if (prop == "type" && ev.target.value != "pricegroup") //Om vi byter från prisgrupp, töm kopplad prisgrupp
        {
            o.productVariantPriceGroupId = null;
        }

        if (prop === "startGroup") {
            // Om vi byter startgrupp -> Sätt om enforced till false
            o.startGroupEnforced = false;
        }

        if (prop == "productVariantId") {
            let p = this.props.products.filter(x => x.id == this.state.addEdit.productId)[0];
            let variant = p.variants.filter(x => x.Id == ev.target.value)[0];
            o["productVariantPriceGroupId"] = variant.priceGroups[0].id;
            this.setState({ addEdit: o });
        } else {
            this.setState({ addEdit: o });
        }
    };

    changeChkEditField = <IProp extends keyof CodeProductImplementationBooleanProps>(prop: IProp) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = this.state.addEdit;
        o[prop] = ev.target.checked;
        this.setState({ addEdit: o });
    };

    getProductName(id: string) {
        let p = this.props.products.filter(x => x.id == id);
        if (p && p.length > 0) {
            return p[0].name + (p[0].IsArchived ? " (Arkiverad)" : "");
        }
        return "";
    }

    isProductArchived = (id: string) => {
        const p = this.props.products.find(x => x.id == id);
        return p?.IsArchived;
    };

    getProductVariantName(productId: string, id: string) {
        let p = this.props.products.filter(x => x.id == productId);
        if (p && p.length > 0) {
            let pv = p[0].variants.filter(x => x.Id == id);
            if (pv && pv.length > 0) {
                return pv[0].Name;
            }
        }
        return "";
    }

    getProductVariantPriceGroupName(productId: string, variantId: string, id: string) {
        let p = this.props.products.filter(x => x.id == productId);
        if (p && p.length > 0) {
            let pv = p[0].variants.filter(x => x.Id == variantId);
            if (pv && pv.length > 0) {
                let pg = pv[0].priceGroups.filter(x => x.id == id);
                if (pg && pg.length > 0) {
                    return pg[0].Name;
                }
            }
        }
        return "";
    }

    getImplementationValue(impl: CodeProductImplementation) {
        if (impl.type == "pricegroup") {
            return this.getProductVariantPriceGroupName(impl.productId, impl.productVariantId, impl.productVariantPriceGroupId);
        } else if (impl.type == "percent") {
            return impl.value + "%";
        } else {
            return impl.value + "kr";
        }
    }

    edit = (impl: CodeProductImplementation) => () => {
        let ps = this.props.products.filter(x => x.id == impl.productId);
        if (ps.length > 0 && ps[0].type == "race") {
            this.setState({ addEdit: impl, loading: true, curProduct: null });
            let api = new ApiBackend();
            api.getProduct(impl.productId).then((x) => {
                this.setState({ curProduct: x, loading: false });
            });
        } else {
            this.setState({ addEdit: impl, curProduct: null });
        }
    };

    remove = (impl: CodeProductImplementation) => async (): Promise<void> => {
        const productName = this.getProductName(impl.productId);
        const variantName = this.getProductVariantName(impl.productId, impl.productVariantId)
        const result = await this.props.showConfirm(`Är du säker på att du vill ta bort implementationen (${productName} - ${variantName})?`);

        if (result) {
            this.props.delete(impl);
        }
    };

    render() {
        let { classes, code } = this.props;
        const addDisabled = !!code.reuseExistingEntries;

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title="Giltig för produkter" />
                    <CardContent>
                        <TableContainer component={Paper}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Produkt</StyledTableCell>
                                        <StyledTableCell>Produktvariant</StyledTableCell>
                                        <StyledTableCell>Typ</StyledTableCell>
                                        <StyledTableCell>Värde</StyledTableCell>
                                        <StyledTableCell>Funkar i fulla lopp</StyledTableCell>
                                        <StyledTableCell>Reserverade platser</StyledTableCell>
                                        <StyledTableCell></StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.code.Implementations && this.props.code.Implementations.map((implementation, idx) => {
                                        const hasWarning = this.hasTypeWarning(implementation);
                                        return <TableRow key={idx}>
                                            <StyledTableCell>{this.getProductName(implementation.productId)}</StyledTableCell>
                                            <StyledTableCell>{this.getProductVariantName(implementation.productId, implementation.productVariantId)}</StyledTableCell>
                                            <StyledTableCell>{implementation.type}</StyledTableCell>
                                            <StyledTableCell>{this.getImplementationValue(implementation)}</StyledTableCell>
                                            <StyledTableCell>{implementation.allowsEntryToFullRace ? "Ja" : "Nej"}</StyledTableCell>
                                            <StyledTableCell>{implementation.reservedPlaces ? implementation.reservedPlaces : ''}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                {!this.isProductArchived(implementation.productId) &&
                                                    <EditIcon onClick={this.edit(implementation)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                }
                                                <DeleteIcon onClick={this.remove(implementation)} className={classes.icon} style={{ cursor: 'pointer' }} />
                                                {hasWarning &&
                                                    <VasaloppetTooltip title="Typ av kod: Prisgrupp är inte giltig för en kodhållare med typen Orderkod.">
                                                        <WarningIcon className={classes.icon} style={{ color: 'orange', cursor: 'initial' }} />
                                                    </VasaloppetTooltip>
                                                }
                                            </StyledTableCell>
                                        </TableRow>
                                    })}
                                </TableBody>
                            </Table></TableContainer>

                    </CardContent>
                    <CardActionArea component="a">
                        <Button disabled={addDisabled}
                            onClick={() => {
                                let impl = {} as CodeProductImplementation;
                                impl.productId = this.props.products[0].id;
                                impl.productVariantId = this.props.products[0].variants[0].Id;
                                impl.productVariantPriceGroupId = this.props.products[0].variants[0].priceGroups[0].id;
                                impl.startGroup = null;
                                impl.startGroupEnforced = false;
                                impl.type = "pricegroup";
                                this.edit(impl)();
                            }}>
                            Lägg till implementation
                        </Button>
                    </CardActionArea>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.addEdit &&
                this.renderEditImplementation()
            }
        </Fragment>

    }

    handleEditCancel = () => {
        this.setState({ addEdit: null });
    };

    handleEditOk = () => {
        let ae = this.state.addEdit;
        this.props.save(ae);
        this.setState({ addEdit: null });
    };

    renderEditImplementation() {
        let { code } = this.props;

        let impl = this.state.addEdit;
        let product = null;
        let variant = null;
        if (impl.productId) {
            let prs = this.props.products.filter(x => x.id == impl.productId);
            if (prs && prs.length > 0) {
                product = prs[0];
            }
        }
        if (impl.productVariantId && product) {
            let vars = product.variants.filter(x => x.Id == impl.productVariantId);
            if (vars && vars.length > 0) {
                variant = vars[0];
            }
        }

        const hasWarning = this.hasTypeWarning(impl);

        return <Dialog
            disableEscapeKeyDown
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">{impl.id != null ? "Editera Implementation" : "Lägg till Implementation"}</DialogTitle>
            <DialogContent dividers>
                <Grid container>
                    {hasWarning &&
                        <Grid item xs={12}>
                            <Alert variant="filled" severity="warning">
                                <Typography variant="body2">
                                    Typ av kod: Prisgrupp är inte giltig för en Kodhållare med typen Orderkod.
                                </Typography>
                            </Alert>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Typography variant="body2"><b>Gäller för:</b></Typography>
                    </Grid>
                    <Grid item xs={product.type == "race" ? 4 : 6}>
                        <FormControl>
                            <InputLabel>Produkt</InputLabel>
                            <ProductList
                                initialValue={impl.productId}
                                onChange={(evt: ProductListChangeEvent) => {
                                    this.onChangeProductId(evt.products[0]?.id);
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={product.type == "race" ? 4 : 6}>
                        {product &&
                            <FormControl>
                                <InputLabel>Produktvariant</InputLabel>
                                <Select label="Produktvariant" value={impl.productVariantId} onChange={this.changeEditField('productVariantId')}>
                                    {product.variants.map((v, idx) => {
                                        return <MenuItem key={idx} value={v.Id}>{v.Name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    {product.type == "race" && this.state.curProduct &&
                        <Grid item xs={2}>
                            <FormControl>
                                <InputLabel shrink>Startled</InputLabel>
                                <Select displayEmpty label="Startled" value={impl.startGroup || ''} onChange={this.changeEditField('startGroup')}>
                                    <MenuItem value=''>Alla</MenuItem>
                                    {this.state.curProduct && this.state.curProduct.startGroups && this.state.curProduct.startGroups.map((sg, idx) => {
                                        return <MenuItem key={idx} value={sg.id}>{sg.name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    }
                    {product.type == "race" && this.state.curProduct &&
                        <Grid item xs={2}>
                            <FormControlLabel label={"Forcerat"}
                                control={
                                    <Checkbox
                                        checked={impl.startGroupEnforced}
                                        onChange={this.changeChkEditField('startGroupEnforced')}
                                    />
                                }
                            />
                        </Grid>
                    }
                    <Grid item xs={12} style={{ marginTop: 30, borderTop: '1px solid grey' }}>
                        <Typography variant="body2"><b>Värde:</b></Typography>
                    </Grid>
                    <Grid item xs={6}>

                        <FormControl>
                            <InputLabel>Typ av kod</InputLabel>
                            <Select label="Typ" value={impl.type} onChange={this.changeEditField('type')}>
                                <MenuItem value="value">Värde (kr)</MenuItem>
                                <MenuItem value="percent">Procent</MenuItem>
                                <MenuItem value="pricegroup">Prisgrupp</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        {impl.type == "value" &&
                            <TextField fullWidth value={impl.value} label="Värde (kr)" placeholder="Värde (kr)" onChange={this.changeEditField('value')} />
                        }
                        {impl.type == "percent" &&
                            <TextField fullWidth value={impl.value} label="Värde (%)" placeholder="Värde (%)" onChange={this.changeEditField('value')} />
                        }
                        {impl.type == "pricegroup" &&
                            <FormControl>
                                <InputLabel>Prisgrupp</InputLabel>
                                <Select label="Prisgrupp" value={impl.productVariantPriceGroupId || ''} onChange={this.changeEditField('productVariantPriceGroupId')}>
                                    {variant && variant.priceGroups && variant.priceGroups.map((pg, idx) => {
                                        return <MenuItem key={idx} value={pg.id}>{pg.Name}</MenuItem>
                                    })}
                                </Select>
                            </FormControl>
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel label={"Kan användas i fulla lopp"}
                            control={<Checkbox checked={impl.allowsEntryToFullRace} onChange={this.changeChkEditField('allowsEntryToFullRace')} />
                            } />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField fullWidth value={impl.reservedPlaces ? impl.reservedPlaces : ''} onChange={this.changeEditField('reservedPlaces')} label="Reservera antal platser" placeholder="Reservera antal platser" />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleEditCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleEditOk} color="primary">
                    {impl.id != null ? "Ok" : "Lägg till"}
                </Button>
            </DialogActions>
        </Dialog>;
    }

    createRandomCode() {
        let randowCodes = [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
            '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
        ];
        let code = "";
        for (let i = 0; i < 5; i++) {
            code += randowCodes[Math.floor(Math.random() * randowCodes.length)];

        }

        return "VL-" + code;
    }
}

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withConfirm(withStyles(useStyles)(CodeProducts));
