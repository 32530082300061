import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import OrderRowMetadata from './OrderRowMetadata';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { createStyles, Theme } from '@material-ui/core/styles';

class Props {
    order: Order;
}

class OrderSummary extends React.Component<Props & WithStyles, any>
{


    render() {
        let { classes } = this.props;
        let totalSum = this.props.order.totalSum;
        return <Fragment>
            {this.props.order.items && this.props.order.items.map((item, idx) => {

                let orderedVariant = item.product.variants.filter(x => x.Id == item.productVariantId)[0];
                let orderedPriceGroup = orderedVariant.priceGroups.filter(x => x.id == item.productVariantPriceGroupId)[0];

                let name = item.product.name + " (" + orderedVariant.Name + " / " + orderedPriceGroup.Name + ")";
                if (item.occationName) {
                    name = item.product.name;
                }

                return <Grid key={"oi_" + idx} container className={classes.root} spacing={2}>
                    <Grid item xs={8}>
                        <h3>{name}</h3>
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <h3>{item.numberOfProducts > 1 ? item.numberOfProducts + " x " : ""}{item.itemPrice} kr</h3>
                    </Grid>
                    {(item.metadata || item.occationName) &&
                        <OrderRowMetadata item={item} />
                    }
                </Grid>;
            })}

            <hr className="thin black" style={{ margin: '15px 0' }} />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ textAlign: 'left', margin: 0 }}>Deltotal</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="body2" style={{ textAlign: 'right', margin: 0 }}>{totalSum} kr</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" style={{ textAlign: 'left', margin: 0 }}><b>Totalsumma</b></Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="h6" style={{ textAlign: 'right', margin: 0 }}><b>{totalSum} kr</b></Typography>
                </Grid>
            </Grid>
        </Fragment>;
    }

}

const useStyles = (theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1
        }
    });


export default withStyles(useStyles)(OrderSummary);