import { Grid, WithStyles } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { ProductCatalogue } from "../../model/ProductCatalogue";
import FormTextField from "../Common/FormTextField";
import EditCatalogueProducts from "./EditCatalogueProducts";

interface IProps extends FormikProps<ProductCatalogue> {
}

class EditCatalogueFormContent extends React.Component<WithStyles & IProps> {

    render(): JSX.Element {
        const { classes } = this.props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="name"
                        label="Namn"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormTextField {...this.props}
                        propName="description"
                        label="Beskrivning"
                    />
                </Grid>
                <Grid item xs={12}>
                    <EditCatalogueProducts {...this.props} />
                </Grid>
            </Grid>
        )
    }
}

export default EditCatalogueFormContent;
