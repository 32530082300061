import React from "react";
import { Card, CardContent, CardHeader, Grid, TextField, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import { CodeHolder } from "../../model/CodeHolder";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

interface IProps {
    codeHolder: CodeHolder;
    onDescriptionChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const CodeInvoices = ({ codeHolder, onDescriptionChange, classes }: IProps & WithStyles) => {
    const hasDescription = !!codeHolder.b2bInvoiceDescription;

    const render = () => {
        return (
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Faktureringsinformation" />
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Faktura skapad"
                                        InputLabelProps={{ shrink: true }}
                                        value={vasaloppetDateTimeGetterFormatter(codeHolder.b2bInvoiceCreated)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Faktura skapad av"
                                        InputLabelProps={{ shrink: true }}
                                        value={codeHolder.b2bInvoiceCreatedBy ?? ""}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        disabled
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Märkning"
                                        InputLabelProps={{ shrink: true }}
                                        value={codeHolder.b2bInvoiceExternalRef ?? ""}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        style={{ flex: 1, margin: '0 3px' }}
                                        type="text"
                                        label="Beskrivning"
                                        InputLabelProps={{ shrink: true }}
                                        value={codeHolder.b2bInvoiceDescription ?? ""}
                                        fullWidth
                                        helperText={!hasDescription ? "Vänligen ange en beskrivning" : ""}
                                        error={!hasDescription}
                                        onChange={onDescriptionChange}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    icon: {
        verticalAlign: 'middle',
        cursor: 'pointer'
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(CodeInvoices);
