const createRandomCode = () => {
    const randowCodes = [
        'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
        '1', '2', '3', '4', '5', '6', '7', '8', '9', '0',
    ];
    let code = "";
    for (let i = 0; i < 5; i++) {
        code += randowCodes[Math.floor(Math.random() * randowCodes.length)];

    }

    return "VL-" + code;
};

export {
    createRandomCode
}
