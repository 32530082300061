import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, useEffect, useState } from 'react';
import { Checkbox, CircularProgress, LinearProgress } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import { Product } from '../../model/Product';
import { StartGroup } from '../../model/StartGroup';
import { Entry } from '../../model/Entry';

class props {
    race: string;
    close: () => void;
}

type CalculatedGroupChange = {entry: Entry, newGroup: string};

const ManageSeedingRunSeedingForRace = ({race, close} : props)  => {
    const [loading, setLoading] = useState(true);
    const [dryRun, setDryRun] = useState(true);
    const [notifyChanges, setNotifyChanges] = useState(true);
    const [raceProduct, setRaceProduct] = useState(null as Product);
    const [selectedGroups, setSelectecGroups] = useState([] as StartGroup[]);
    const [calculationResults, setCalculationResults] = useState(null as CalculatedGroupChange[])
    const [jobid, setJobid] = useState(null as string);
    const [jobStatus, setJobStatus] = useState(null as {id: string, status: string, progress: number,request: string, response:string});

    useEffect(() => {
        let back = new ApiBackend();
        back.getProduct(race).then((res) => {
            setRaceProduct(res);
            setLoading(false);
        });

    },[])

    const seededGroups = [] as StartGroup[];
    if (raceProduct && raceProduct.startGroups)
    {
        for (let i = 0; i < raceProduct.startGroups.length; i++)
        {
            if (raceProduct.startGroups[i].startGroupAllocationMethod == "seeded")
            {
                seededGroups.push(raceProduct.startGroups[i]);
            }
        }
    }

    const markAllGroups = () => {
        setSelectecGroups(seededGroups);
    }
    const markNoGroups = () => {
        setSelectecGroups([]);
    }

    useEffect(() => {
        if (jobid)
        {
            pollJobResult();
        }
    },[jobid])

    const pollJobResult = () => {
        let back = new ApiBackend();
        if (jobid)
        {
            back.getSeedingCalculationForRaceAndGroupsStatus(jobid).then((res) => {
                setJobStatus(res);
                if (res.status=="DONE")
                {
                    setLoading(false);
                    setCalculationResults(JSON.parse(res.response));
                }
                else 
                {
                    setTimeout(() => {pollJobResult();},1000);
                }
            });        
        }
        else 
        {
            console.log("No jobid, stop polling");
        }
    }

    const renderCalculate = () => {
        return <><Grid item xs={12}><b>Välj startled att köra seedning för</b></Grid>    
        <Grid item xs={12}><Checkbox checked={dryRun} onChange={(ev) => {setDryRun(ev.target.checked);}}/> Simulera enbart</Grid>    
        <Grid item xs={12}><Checkbox checked={notifyChanges} onChange={(ev) => {setNotifyChanges(ev.target.checked);}}/> Skicka mail till deltagare om ändrad startgrupp</Grid>    
        {seededGroups.length == 0 && <Grid item xs={12}>Inga startled med seedad tilldelning i det här loppet</Grid>}
        {seededGroups.length > 0 && <>
            <Grid item xs={12}>
                <Button onClick={()=>{markAllGroups()}}>Markera alla</Button>
                <Button onClick={()=>{markNoGroups()}}>Markera ingen</Button>
            </Grid>
            {seededGroups.map((group) => {
                return <Grid item xs={12}><Checkbox checked={selectedGroups.some(x=>x.id == group.id)} onChange={ (ev) => {
                    if (ev.target.checked)
                    {
                        selectedGroups.push(group);
                        setSelectecGroups([...selectedGroups]);
                    }
                    else 
                    {
                        const newGroups = selectedGroups.filter(x=>x.id != group.id);
                        setSelectecGroups(newGroups);
                    }
                }}/> {group.name}</Grid>
    
            })}
        </>}
        <Grid item xs={6}>
            <Button variant="contained" autoFocus onClick={async () => {
                setLoading(true);
                setCalculationResults(null);
                let back = new ApiBackend();
                let res = await back.runSeedingCalculationForRaceAndGroups(race,selectedGroups.map(x=>x.id),dryRun, notifyChanges);
                setJobid(res.jobid);
                
                }} color="primary">
                        Beräkna valda led
            </Button>
            </Grid></>
    }

    const renderCalculating = () => {
        return <><Grid item xs={12}><b>Beräknar {jobStatus?.progress}%...</b></Grid>    
        <Grid item xs={12}><CircularProgress color='secondary'/></Grid>
        <Grid item xs={6}>
            <Button variant="contained" autoFocus onClick={async () => {
                setJobid(null);                
                }} color="primary">
                       Avbryt
            </Button>
            </Grid></>
    }

    const renderResponse = () => {
        return <><Grid item xs={12}><b>{dryRun ? "Beräknade" : "Applicerade"} startledsförändringar</b></Grid>    
        
            <Grid item xs={6}><b>Deltagare</b></Grid>
            <Grid item xs={3}><b>Nuvarande led</b></Grid>
            <Grid item xs={3}><b>Nytt led</b></Grid>
            {calculationResults && calculationResults.map((res) => {
                return <>
                    <Grid item xs={6}>{res.entry.firstName + " " + res.entry.lastName}</Grid>
                    <Grid item xs={3}>{res.entry.startgroup ? raceProduct.startGroups.find(x=>x.id==res.entry.startgroup)?.name : ""}</Grid>
                    <Grid item xs={3}>{res.newGroup ? raceProduct.startGroups.find(x=>x.id==res.newGroup)?.name : ""}</Grid>
                </>;
            })}
        
            <Grid item xs={6}>
                <Button variant="contained" autoFocus onClick={async () => {     
                    setJobid(null);
                    setCalculationResults(null);          
                    }} color="primary">
                            Tillbaka
                </Button>
            </Grid>
        </>
    }

return <Fragment>
    <Grid container spacing={2}>
    {loading && <Grid item xs={12}><LinearProgress color="primary"/></Grid>}
        {calculationResults == null && jobid==null && renderCalculate()}
        {calculationResults == null && jobid!=null && renderCalculating()}
        {calculationResults != null && renderResponse()}
        <Grid item xs={6} style={{textAlign:'right'}}>
            <Button variant="contained" autoFocus onClick={() => {
                setJobid(null);
                setCalculationResults(null);
                close()}} color="primary">
                    Stäng
        </Button>
      </Grid>
</Grid>
</Fragment>
}
export default ManageSeedingRunSeedingForRace;
