import React from "react";
import { TextField } from "@material-ui/core";
import { FormikProps } from "formik";

interface IProps<T> extends FormikProps<T> {
    propName: keyof T;
    label?: string;
    valueFormatter?: (value: any) => string;
}

class FormReadonlyField<T> extends React.Component<IProps<T>> {

    render(): JSX.Element {
        const { propName, label, values, valueFormatter } = this.props;
        const safeValue = values[propName] || "";
        return (
            <TextField
                id={propName as string}
                disabled
                style={{ flex: 1, margin: '0 3px' }}
                type="text"
                label={label}
                InputLabelProps={{ shrink: true }}
                value={valueFormatter ? valueFormatter(values[propName]) : safeValue}
                fullWidth
            />
        )
    }
}

export default FormReadonlyField;
