import React, { useState } from "react";
import { Box, Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, Grid, IconButton, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, WithStyles, createStyles, withStyles } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import { getAvailable, getInitialValue } from "./utils";
import useDidMountEffect from "../../Common/UseDidMountEffect";
import { CustomField } from "../../../model/CustomField";
import { ProductVariantCustomField } from "../../../model/ProductVariantCustomField";

interface IProps extends WithStyles {
    customFields: CustomField[];
    defaultValue: ProductVariantCustomField[];
    onChange: (nextValue: ProductVariantCustomField[]) => void;
}

const tableHeadStyles = ({ palette }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

const ProductVariantEntryFieldsCard = ({ customFields, defaultValue, onChange, classes }: IProps) => {
    const [value, setValue] = useState(getInitialValue(customFields, defaultValue));
    const [available, setAvailable] = useState(getAvailable(customFields, defaultValue));

    useDidMountEffect(() => {
        setAvailable(getAvailable(customFields, value.map(x => x.value)));
        onChange(value.map(x => x.value));

    }, [value]);

    const handleMoveUp = (idx: number) => () => {
        if (idx > 0) {
            let nextValue = value;
            var rem = nextValue.splice(idx, 1);
            nextValue.splice(idx - 1, 0, rem[0]);

            setValue([...nextValue]);
        }
    };

    const handleMoveDown = (idx: number) => () => {
        if (idx < value.length - 1) {
            let nextValue = value;
            const rem = nextValue.splice(idx, 1);
            nextValue.splice(idx + 1, 0, rem[0]);

            setValue([...nextValue]);
        }
    };

    const handleAdd = (e: React.ChangeEvent<HTMLInputElement>) => {
        const def = available.find(x => x.id === e.target.value);
        if (!def) {
            return;
        }

        let nextValue = value;
        nextValue.push({
            definition: def,
            value: { customFieldId: def.id, mandatory: false, kontrollenInternal: false, sortIndex: value.length }
        });
        setValue([...nextValue]);
    };

    const handleDelete = (idx: number) => () => {
        let nextValue = value;
        nextValue.splice(idx, 1);
        setValue([...nextValue]);
    };

    const handleMandatoryCheckChanged = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let nextValue = value;
        nextValue[idx].value.mandatory = e.target.checked;
        setValue([...nextValue]);
    };

    const handleKontrollenInternalCheckChanged = (idx: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let nextValue = value;
        nextValue[idx].value.kontrollenInternal = e.target.checked;
        setValue([...nextValue]);
    };

    const render = () => {
        return (
            <Card style={{ marginTop: 10, width: "100%" }}>
                <CardHeader className={classes.cardHeader} title="Anmälningsfält" />
                <CardContent>
                    <Grid container style={{ marginBottom: 8 }}>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Tillgängliga fält</InputLabel>
                                <Select
                                    value=""
                                    onChange={handleAdd}
                                >
                                    {available.map((x, idx) => {
                                        return <MenuItem key={idx} value={x.id}>{x.name}</MenuItem>;
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Fält</StyledTableCell>
                                    <StyledTableCell>Obligatorisk</StyledTableCell>
                                    <StyledTableCell>Internt för Kontrollen</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {value && value.map((item, idx) => {
                                    return <TableRow key={idx}>
                                        <StyledTableCell>{item.definition.name}</StyledTableCell>
                                        <StyledTableCell>{item.value.mandatory}
                                            <FormControlLabel label=""
                                                control={
                                                    <Checkbox color="primary" checked={item.value.mandatory} onChange={handleMandatoryCheckChanged(idx)} />
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell>{item.value.kontrollenInternal}
                                            <FormControlLabel label=""
                                                control={
                                                    <Checkbox color="primary" checked={item.value.kontrollenInternal} onChange={handleKontrollenInternalCheckChanged(idx)} />
                                                }
                                            />
                                        </StyledTableCell>
                                        <StyledTableCell align="right">
                                            <Box display="flex" justifyContent="flex-end">
                                                <IconButton onClick={handleMoveUp(idx)}>
                                                    <ExpandLess />
                                                </IconButton>
                                                <IconButton onClick={handleMoveDown(idx)}>
                                                    <ExpandMore />
                                                </IconButton>
                                                <IconButton onClick={handleDelete(idx)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        </StyledTableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
        );
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    icon: {
        verticalAlign: "middle",
        cursor: "pointer"
    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(ProductVariantEntryFieldsCard);
