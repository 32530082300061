import { Card, CardHeader, IconButton } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { B2bCompany, B2bCompanySurvey, CompanySurveyTypesLookup } from "../../../../model/B2bCompany";
import FormCbxField from "../../../Common/FormCbxField";
import EditIcon from '@material-ui/icons/Edit';
import EditB2bCompanySurveyDialog from "./EditB2bCompanySurveyDialog";
import { RaceRelayProduct } from "./EditB2bCompanySurveys";
import { vasaloppetDateGetterFormatter } from "../../../../utilities/date";

interface IProps extends FormikProps<B2bCompany> {
    idx: number;
    survey: B2bCompanySurvey;
    sourceRelayProducts: RaceRelayProduct[];
    onDelete: (idx: number) => void;
    onSave: (survey: B2bCompanySurvey) => void;
}

interface IState {
    editSurvey: B2bCompanySurvey;
}

class EditB2bCompanySurvey extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            editSurvey: null
        };
    }

    render(): JSX.Element {
        const { survey, idx } = this.props;

        return (
            <Card style={{ marginTop: 10 }}>
                <CardHeader
                    title={this.cardTitle()}
                    subheader={this.cardSubHeader()}
                    action={<>
                        <FormCbxField {...this.props}
                            propName={`surveys[${idx}].published`}
                            label="Publicerad"
                            valueGetter={() => survey.published} />
                        <FormCbxField {...this.props}
                            propName={`surveys[${idx}].isArchived`}
                            label="Arkiverad"
                            valueGetter={() => survey.isArchived} />
                        <IconButton
                            onClick={() => this.editSurvey(survey)}>
                            <EditIcon />
                        </IconButton>
                    </>}
                />
                {this.state.editSurvey && <div>
                    <EditB2bCompanySurveyDialog {...this.props}
                        surveyIdx={this.props.idx}
                        survey={this.state.editSurvey}
                        onSave={(survey: B2bCompanySurvey) => {
                            this.props.onSave(survey);
                            this.resetEditSurvey();
                        }}
                        onClose={() => {
                            this.resetEditSurvey();
                        }}
                        onDelete={() => {
                            this.props.onDelete(idx);
                            this.resetEditSurvey();
                        }}
                    />
                </div>
                }
            </Card>
        )
    }

    private editSurvey = (survey: B2bCompanySurvey): void => {
        this.setState({
            editSurvey: survey
        });
    }

    private resetEditSurvey = (): void => {
        this.setState({
            editSurvey: null
        });
    }

    private cardTitle = (): string => {
        const { survey } = this.props;

        let desc = survey.name ? survey.name : "Enkät";

        if (survey.description) {
            desc += " - ";
            desc += (survey.description.length > 40) ? survey.description.slice(0, 40 - 1) + '...' : survey.description;
        }

        return desc;
    };

    private cardSubHeader = (): string => {
        const { values, survey } = this.props;

        let desc = `Typ: ${CompanySurveyTypesLookup[survey.type]}`;
        const append = (str: string) => {
            if (desc) {
                desc += ", ";
            }

            desc += str;
        };

        if (survey.companyProductId) {
            const productMatch = values.products?.find(x => x.id === survey.companyProductId);

            if (productMatch) {
                append(`För produkt: ${productMatch.name}`);
            }
        }

        if (survey.validTo) {
            append(`Öppen till: ${vasaloppetDateGetterFormatter(survey.validTo)}`);
        }

        if (survey.questions && survey.questions.length) {
            append(`Antal frågor: ${survey.questions.length} st`);
        }

        return desc;
    };
}

export default EditB2bCompanySurvey;
