import uuid from "react-uuid";
import { ProductVariantAgeCategory } from "./ProductVariantAgeCategory";
import { ProductOccation } from "./ProductVariantOccation";
import { ProductVariantPriceGroup } from "./ProductVariantPriceGroup";
import { ProductVariantRaceData } from "./ProductVariantRaceData";
import { ProductVariantTimingPartner } from "./ProductVariantTimingPartner";

export class ProductVariant {
    Id: string;
    Name: string;
    Description: string;
    Name_En: string;
    Description_En: string;
    itemsAvailable?: number;
    priceGroups: ProductVariantPriceGroup[];
    ageCategories?: ProductVariantAgeCategory[];
    occations?: ProductOccation[];
    productVariantTiming?: ProductVariantTimingPartner;
    productVariantRaceData?: ProductVariantRaceData;
    firstValidPriceGroupOnly: boolean;

    static createNew(): ProductVariant {
        return {
            Id: uuid(),
            Name: "",
            Description: "",
            Name_En: "",
            Description_En: "",
            itemsAvailable: null,
            firstValidPriceGroupOnly: false,
            priceGroups: [
                ProductVariantPriceGroup.createNew("Normal", "Normal", null, null, false)
            ]
        };
    }
}
