import { Component } from "react";
import * as d3 from 'd3';
import React from "react";
import { OrderStatistics } from "../../model/OrderStatistics";

class props {
    id : string;
    data : OrderStatistics;
}
class BarChart extends Component<props, any> {
    
    componentDidMount() {
      this.drawChart();
    }
    drawChart() {
        //const data = [12, 5, 6, 6, 9, 10];
        let data : {date: string, count: number, totalSum: number}[] = [] ;
        let maxC = 0;
        for(var p in this.props.data)
        {
            let o = (this.props.data as any)[p];
            if (o.count > maxC)
            {
                maxC = o.count;
            }
            data.push({...o, ...{date:p}});
        }
        data.sort((a,b) => { return a.date.localeCompare(b.date);});
        let h = 140;
        let sy = 110/maxC;
        const svg = d3.select("#"+this.props.id).append("svg").attr("width", '100%').attr("height", h);
        svg.selectAll("rect")
            .data(data)
            .enter()
            .append("rect")
            .attr("x", (d, i) => i * 50)
            .attr("y", (d,i) =>  h-d.count*sy-15)
            .attr("width", 45)
            .attr("height", (d, i) => 5+d.count*sy)
            .attr("fill", "#2772b6");
        var texts = svg.selectAll("text")
            .data(data)
            .enter();

        texts.append("text")
            .text((d) => d.count + " / " + d.totalSum + "kr")
            .attr("x", (d, i) => i * 50)
            .attr("font-size",10)
            .attr("y", (d, i) => h - (sy * d.count) - 3-15);
        texts.append("text")
            .text((d) => d.date)
            .attr("x", (d, i) => i * 50)
            .attr("font-size",8)
            .attr("y", (d, i) => h);
        
            
        
      }
      render(){
        return <div id={this.props.id}></div>
      }
  }

  export default BarChart;