import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { Formik, FormikState } from "formik";
import React from "react";
import uuidv4 from "react-uuid";
import { B2bCompanyCreate } from "../../../../../model/B2bCompany";
import { trimFormValues } from "../../../../../utilities/Form";
import { createB2bCompanyValidationSchema } from "../../EditB2bCompanyValidationSchema";
import CreateCompanyFormContent from "./CreateCompanyFormContent";

interface IProps {
	open: boolean;
	onClose: () => void;
	onSubmit: (company: B2bCompanyCreate) => Promise<void>;
}

interface IState {
	error: boolean;
}

class CreateCompanyDialog extends React.Component<IProps, IState> {
	private readonly formId: string = `create-company-dialog-${uuidv4()}`;

	private readonly intialValues: B2bCompanyCreate = {
		name: "",
		description: "",
		orgNumber: "",
		address: "",
		postCode: "",
		city: "",
		country: "",
		email: "",
		reference: "",
	};

	constructor(props: IProps) {
		super(props);

		this.state = { error: false };
	}

	private handleClose = (resetForm: (nextState?: Partial<FormikState<B2bCompanyCreate>>) => void) => {
		const { onClose } = this.props;

		resetForm({ values: this.intialValues });
		
		onClose();
	};

	render(): React.ReactNode {
		const { open } = this.props;
		const { error } = this.state;

		return (
			<Formik
				initialValues={this.intialValues}
				validationSchema={createB2bCompanyValidationSchema}
				onSubmit={async (values, { resetForm }) => {
					const { onSubmit } = this.props;

					if (!onSubmit) return;

					try {
						this.setState({ error: false });

						await onSubmit(trimFormValues(values));

						this.handleClose(resetForm);
					} catch (error) {
						console.error(error);
					} finally {
						this.setState({ error: true });
					}
				}}
			>
				{(formik) => {
					const { isValid, dirty, isSubmitting, handleSubmit, resetForm } = formik;
					const isSaveDisabled = !dirty || !isValid || isSubmitting;

					return (
						<Dialog
							open={open}
							onClose={(_e, reason) => {
								if (isSubmitting) return;

								if (reason && reason === "backdropClick") return;

								this.handleClose(resetForm);
							}}
						>
							<DialogTitle>Lägg till företag</DialogTitle>
							<DialogContent>
								<Grid container spacing={4}>
									{error && (
										<Grid item xs={12}>
											<Alert severity="error">
												Ett fel uppstod vid försök att lägga till företaget. Kontrollera att alla fält är korrekt
												ifyllda och försök igen.
											</Alert>
										</Grid>
									)}
									<Grid item xs={12}>
										<form id={this.formId} autoComplete="off" onSubmit={handleSubmit}>
											<fieldset className="MuiFormControl-root" disabled={isSubmitting}>
												<CreateCompanyFormContent {...formik} />
											</fieldset>
										</form>
									</Grid>
								</Grid>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										this.handleClose(resetForm);
									}}
									variant="text"
									disabled={isSubmitting}
								>
									Avbryt
								</Button>
								<Button form={this.formId} type="submit" variant="contained" disabled={isSaveDisabled}>
									<Box
										display="flex"
										alignItems="center"
										style={{
											gap: "0.5rem",
										}}
									>
										{isSubmitting && <CircularProgress color="inherit" size={16} />}
										<span>Lägg till företag</span>
									</Box>
								</Button>
							</DialogActions>
						</Dialog>
					);
				}}
			</Formik>
		);
	}
}

export default CreateCompanyDialog;
