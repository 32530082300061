import * as XLSX from "xlsx";
import { OrderItemOverview, Orderoverview } from "../../../model/Orderoverview";
import { Product } from "../../../model/Product";
import { ProductVariant } from "../../../model/ProductVariant";
import { ProductOccation } from "../../../model/ProductVariantOccation";
import { ProductVariantPriceGroup } from "../../../model/ProductVariantPriceGroup";
import { DATE_FORMAT, isBetween, vasaloppetMoment } from "../../../utilities/date";

type SumType = { item: OrderItemOverview; occ: ProductOccation; };
type SumItemsResultType = { seatsAvailable: number; numPurchased: number; numDelivered: number; };
type OverviewGridItemsType = { item: OrderItemOverview, variant: ProductVariant, occ: ProductOccation, pg: ProductVariantPriceGroup };
export type Mode = "OCCATION" | "ORDER";

export const sumItems = (items: SumType[], fromDate: string, toDate: string): SumItemsResultType => {
    const filterFunc = (item: SumType) => !item.occ || isBetween(item.occ.time, fromDate, toDate);

    const filteredItems = items.filter(filterFunc);
    const seatsAvailable = filteredItems.map(x => x.item?.seatsAvailable).reduce((acc, v) => acc + (v || 0), 0);
    const numPurchased = filteredItems.map(x => x.item?.numPurchased).reduce((acc, v) => acc + (v || 0), 0);
    const numDelivered = filteredItems.map(x => x.item?.numDelivered).reduce((acc, v) => acc + (v || 0), 0);

    return {
        seatsAvailable,
        numPurchased,
        numDelivered
    };
};

export const getOverviewGridItems = (product: Product, orderOverview: Orderoverview): OverviewGridItemsType[] => {
    if (!product || !orderOverview) {
        return [];
    }

    const overviewGridItems: OverviewGridItemsType[] = [];
    product.variants.forEach((variant) => {
        variant.priceGroups.forEach((pg) => {
            if (variant.occations) {
                variant.occations.forEach((occ) => {
                    const items = orderOverview.items.filter(x => x.variantId == variant.Id && x.priceGroupId == pg.id && x.occationId == occ.id);
                    const item = items != null && items.length > 0 ? items[0] : null;
                    overviewGridItems.push({ item: item, variant: variant, pg: pg, occ: occ });
                });
            }
            else {
                const items = orderOverview.items.filter(x => x.variantId == variant.Id && x.priceGroupId == pg.id);
                const item = items != null && items.length > 0 ? items[0] : null;
                overviewGridItems.push({ item: item, variant: variant, pg: pg, occ: null });
            }
        });

    });

    overviewGridItems.sort((x, y) => {
        if (x.occ && y.occ) {
            return x.occ.time < y.occ.time ? -1 : 1;
        } else {
            return x.variant.Name < y.variant.Name ? -1 : 1;
        }
    });

    return overviewGridItems;
};

const getWorkBookData = (data: OverviewGridItemsType[], mode: Mode): string[][] => {
    let workBookData: string[][] = [];

    const toOccationRow = (item: OverviewGridItemsType): string[] => {
        return [
            `${item.variant.Name}-${item.pg.Name}`,
            !!item.occ ? item.occ.name : "",
            item.item && item.item.seatsAvailable ? `${item.item.seatsAvailable}` : "0",
            item.item && item.item.numPurchased ? `${item.item.numPurchased}` : "0",
            item.item && item.item.numDelivered ? `${item.item.numDelivered}` : "0"
        ];
    };

    const toOrderRow = (item: OverviewGridItemsType): string[] => {
        return [
            item.variant.Name,
            item.pg.Name,
            item.item && item.item.numPurchased ? `${item.item.numPurchased}` : "0"
        ];
    };

    // Headers
    if (mode === "OCCATION") {
        workBookData.push([
            "Variant",
            "Tillfälle",
            "Totalt",
            "Köpta",
            "Incheckade"
        ]);
        workBookData.push(...data.map(toOccationRow));
    } else {
        workBookData.push([
            "Variant",
            "Prisgrupp",
            "Köpta"
        ]);
        workBookData.push(...data.map(toOrderRow));
    }

    return workBookData;
};

export const toBinaryExcelString = (data: OverviewGridItemsType[], mode: Mode): string => {
    const wb = XLSX.utils.book_new();
    wb.Props = {
        Title: "Orderöversikt",
        Subject: vasaloppetMoment().format(DATE_FORMAT),
        Author: "Vasaloppet",
        CreatedDate: new Date()
    };

    wb.SheetNames.push("Orderöversikt");

    const workBookData = getWorkBookData(data, mode);
    const ws = XLSX.utils.aoa_to_sheet(workBookData);
    wb.Sheets["Orderöversikt"] = ws;
    return XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
};

export const s2ab = (s: any) => {
    const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    const view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i = 0; i < s.length; i++) {
        view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
    }

    return buf;
}
