/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from "react"
import FirebaseContext from "../providers/Firebase/context";
import { Card, CardHeader, CardContent, Theme, WithStyles, withStyles, Button, createStyles, Grid, TableContainer, Paper, Table, TableHead, TableRow, TableBody, TableCell, Dialog, DialogActions, LinearProgress, Typography, MenuItem, Select, FormControlLabel, FormControl, InputLabel, Checkbox, CardActions, IconButton, DialogTitle, DialogContent, Box } from "@material-ui/core";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { ApiBackend } from "../providers/apibackend";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import TextField from "@material-ui/core/TextField";
import { Product } from "../model/Product";
import { PointOfSale } from "../model/PointOfSale";
import { POSSetting } from "../model/POSSetting";
import withConfirm, { IConfirmContext } from "./Common/dialog/Confirm";
import withAlert, { IAlertContext } from "./Common/dialog/Alert";

interface State {
    loading: boolean;
    products: Product[];
    settings: POSSetting[],
    posPlaces: PointOfSale[];
    editSetting: POSSetting,
    selProdAdd: string;
    includeArchived: boolean;
}

class ManagePOSPlaces extends React.Component<RouteComponentProps & WithStyles & IConfirmContext & IAlertContext, State> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps & WithStyles & IConfirmContext & IAlertContext) {
        super(props);

        this.api = new ApiBackend();
        this.state = {
            selProdAdd: null,
            posPlaces: null,
            loading: true,
            settings: null,
            editSetting: null,
            products: null,
            includeArchived: false
        };
    }

    componentDidMount = async () => {
        this.setState({ loading: true });

        const work = [
            this.getPosSettings(),
            this.api.listProducts(),
            this.api.listPointOfSales()
        ];

        type ResType = [POSSetting[], Product[], PointOfSale[]];
        const [setts, prods, posPlaces]: ResType = await Promise.all(work) as any;

        prods.sort((x, y) => x.name > y.name ? 1 : -1);
        posPlaces.sort((x, y) => x.name > y.name ? 1 : -1);

        this.setState({ settings: setts, products: prods, posPlaces: posPlaces, loading: false });
    }

    render() {
        const { classes } = this.props as any;

        const filteredSettings = this.getFilteredSettings();

        return (<>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="POS Terminaler" />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={8}>
                                    <Typography variant="h6">
                                        Hantering av POS-platser. Konfigurerar POS-klienten med värdet i ID-fältet
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox color="primary"
                                                value={false}
                                                onChange={(ev) => {
                                                    this.setState({ includeArchived: ev.target.checked });
                                                }}
                                            />
                                        }
                                        label="Inkludera arkiverade" />
                                </Grid>

                                <Grid item xs={12}>
                                    <TableContainer component={Paper}>
                                        <Table size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow>
                                                    <StyledTableCell>Namn</StyledTableCell>
                                                    <StyledTableCell>ID</StyledTableCell>
                                                    <StyledTableCell>Betalsätt (Kort/Kassa)</StyledTableCell>
                                                    <StyledTableCell>Arkiverad</StyledTableCell>
                                                    <StyledTableCell></StyledTableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {filteredSettings.map((setting) => {
                                                    let posName = "Swedbank Pay";
                                                    if (setting.pointOfSale) {
                                                        let pos = this.state.posPlaces.filter(x => x.id == setting.pointOfSale);
                                                        if (pos && pos.length > 0) {
                                                            posName = pos[0].name;
                                                        }
                                                    }
                                                    return <TableRow key={setting.Id}>
                                                        <StyledTableCell>{setting.Name}</StyledTableCell>
                                                        <StyledTableCell>{setting.Id}</StyledTableCell>
                                                        <StyledTableCell>{posName}</StyledTableCell>
                                                        <StyledTableCell>{setting.isArchived ? "Ja" : "Nej"}</StyledTableCell>
                                                        <StyledTableCell align="right">
                                                            <IconButton color="default" size="small"
                                                                onClick={this.editSetting(setting)

                                                                }>
                                                                <EditIcon fontSize="medium" />
                                                            </IconButton>
                                                            <IconButton color="default" size="small"
                                                                onClick={async () => {
                                                                    await this.deleteSetting(setting)
                                                                }}
                                                            >
                                                                <DeleteIcon fontSize="medium" />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </TableRow>
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>


                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary" onClick={this.addSetting}>Lägg till POS</Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.editSetting && this.renderEditSetting()}
        </>);
    }

    private addSetting = () => {
        this.setState({ editSetting: { Id: null, Name: "" } as POSSetting });
    };

    private editSetting = (setting: POSSetting) => () => {
        this.setState({ editSetting: setting });
    };

    private handleChange = (field: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let o = this.state.editSetting;

        if (field.indexOf(".") > 0) {
            const p = field.split(".");
            (o as any)[p[0]][p[1]] = ev.target.value;
        } else {
            (o as any)[field] = ev.target.value;
        }

        this.setState({ editSetting: o });
    };

    private renderEditSetting = () => {
        const { classes } = this.props;
        const { editSetting } = this.state;

        const boxStyle = { display: "flex", justifyContent: "space-between", padding: "2px 10px", lineHeight: "30px", background: "#efefef", marginBottom: 2 };

        return <Dialog
            disableEscapeKeyDown
            maxWidth="md"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle>
                {editSetting.Id ? "Redigera POS" : "Skapa POS"}
            </DialogTitle>
            <DialogContent dividers>
                <form className={classes.form} noValidate autoComplete="off">
                    <Grid container className={classes.root} spacing={2} style={{ width: "100%" }}>
                        <Grid item xs={12}>
                            <TextField style={{ flex: 1, margin: "0 3px" }} id="standard-basic" value={editSetting.Name} onChange={this.handleChange("Name")} label="Namn" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField style={{ flex: 1, margin: "0 3px" }} id="standard-basic" value={editSetting.VAT} onChange={this.handleChange("VAT")} label="Momssats (%)" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel label="Visa checkin fönster"
                                control={<Checkbox color="primary" checked={editSetting.showCheckin} onChange={(ev) => {
                                    let o = this.state.editSetting;
                                    o.showCheckin = ev.target.checked;
                                    this.setState({ editSetting: o });
                                }} />} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel label="Visa snabbkassa"
                                control={<Checkbox color="primary" checked={editSetting.showCheckout} onChange={(ev) => {
                                    let o = this.state.editSetting;
                                    o.showCheckout = ev.target.checked;
                                    this.setState({ editSetting: o });
                                }} />} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel label="Arkiverad"
                                control={<Checkbox color="primary" checked={editSetting.isArchived} onChange={(ev) => {
                                    let o = this.state.editSetting;
                                    o.isArchived = ev.target.checked;
                                    this.setState({ editSetting: o });
                                }} />} />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel shrink>Betalsätt (Kort/Kassa)</InputLabel>
                                <Select fullWidth displayEmpty value={editSetting.pointOfSale ?? ""} onChange={this.handleChange("pointOfSale")}>
                                    <MenuItem value={""}>Swedbank Pay</MenuItem>
                                    {this.state.posPlaces.map((pos, idx) => {
                                        return <MenuItem key={idx} value={pos.id}>{pos.name}</MenuItem>;
                                    })}
                                </Select>

                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <b>Produkter i snabbkassa</b>
                        </Grid>
                        <Grid item xs={12}>
                            {editSetting.ProductList && editSetting.ProductList.map((p, idx) => {
                                const prod = this.state.products.find(x => x.id == p);
                                const prodName = !!prod ? prod.name : `Okänd produkt ${p}`;

                                return (
                                    <Box key={idx} style={boxStyle}>
                                        <Box>
                                            {prodName}
                                        </Box>
                                        <Box>
                                            <IconButton color="default" size="small"
                                                onClick={() => {
                                                    const p = this.state.editSetting;
                                                    p.ProductList.splice(idx, 1);
                                                    this.setState({ editSetting: p });
                                                }}
                                            >
                                                <DeleteIcon fontSize="medium" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Grid>
                        <Grid item xs={12}>
                            <Select fullWidth
                                value={this.state.selProdAdd ?? ""}
                                onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                                    this.setState({ selProdAdd: ev.target.value });
                                }}
                            >
                                {this.state.products.map((prod, idx) => {
                                    return <MenuItem key={idx} value={prod.id}>{prod.name}</MenuItem>;
                                })}
                            </Select>
                            <Button color="secondary"
                                onClick={() => {
                                    const p = this.state.editSetting;
                                    if (!p.ProductList) {
                                        p.ProductList = [];
                                    }

                                    p.ProductList.push(this.state.selProdAdd);
                                    this.setState({ editSetting: p });

                                }}>
                                Lägg till
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color="primary" autoFocus
                    onClick={() => {
                        this.setState({ editSetting: null, selProdAdd: null });
                    }}
                >
                    Avbryt
                </Button>
                <Button variant="contained" color="primary" onClick={this.updateSetting} >
                    {editSetting.Id ? "Spara" : "Skapa"}
                </Button>
            </DialogActions>
        </Dialog>;
    };

    private updateSetting = async () => {
        this.setState({ loading: true });

        try {
            const saveResult = await this.api.putGeneral("pos", this.state.editSetting);
            if (!saveResult) {
                throw Error();
            }

            this.setState({ editSetting: null });
            const nextSettings = await this.getPosSettings();
            this.setState({ settings: nextSettings });
        } catch {
            this.props.showAlert("Fel", "Inställningen kunde inte sparas!");
        } finally {
            this.setState({ loading: false });
        }
    };

    private deleteSetting = async (setting: POSSetting) => {
        const result = await this.props.showConfirm(`Är du säker på att du vill ta bort inställningen ${setting.Name}?`);
        if (!result) {
            return;
        }

        this.setState({ loading: true });

        try {
            var deleteResult = await this.api.deleteGeneral("pos/" + setting.Id);
            if (!deleteResult) {
                throw new Error();
            }

            const nextSettings = await this.getPosSettings();
            this.setState({ settings: nextSettings });
        } catch {
            this.props.showAlert("Fel", "Inställningen kunde inte ta bort!");
        } finally {
            this.setState({ loading: false });
        }
    };

    private getPosSettings = async (): Promise<POSSetting[]> => {
        const settings = await this.api.getGeneral<POSSetting[]>("pos") as POSSetting[];
        return settings.sort((x, y) => x.Name > y.Name ? 1 : -1);
    };

    private getFilteredSettings = () => {
        const { includeArchived, settings: allSettings } = this.state;

        if (!allSettings) {
            return [];
        }

        if (includeArchived) {
            return allSettings;
        }

        return allSettings.filter(x => !x.isArchived);
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

const tableHeadStyles = ({ palette, spacing }: Theme) => createStyles({
    head: {
        background: palette.primary.main,
        color: palette.primary.contrastText,
    }
});

const StyledTableCell = withStyles(tableHeadStyles)(TableCell);

export default withRouter(withAlert(withConfirm(withStyles(useStyles)(ManagePOSPlaces))));
