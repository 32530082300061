import { useEffect, useRef } from "react";

const useDidMountEffect = (callback: React.EffectCallback, deps: React.DependencyList) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (!didMount.current) {
            didMount.current = true;
            return;
        }

        callback();
    }, deps);
};

export default useDidMountEffect;
