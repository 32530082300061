import React, { useEffect, useRef, useState } from "react";
import { B2bCompany } from "../../model/B2bCompany";
import { Typography, TypographyProps } from "@material-ui/core";
import { ApiBackend } from "../../providers/apibackend";


interface IProps extends TypographyProps {
    b2bId?: string;
}

const CompanyTypography = ({ b2bId, ...otherProps }: IProps) => {
    const [companies, setCompanies] = useState(null as B2bCompany[]);

    const didMount = useRef(false);
    const backend = new ApiBackend();

    useEffect(() => {
        didMount.current = true;

        const init = async (): Promise<void> => {
            const res = await backend.listB2bCompanies({ includeArchived: true });
            if (didMount.current) {
                setCompanies(res)
            }
        };

        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const render = (): JSX.Element => {
        if (!b2bId || !companies) {
            return null;
        }

        return <Typography {...otherProps}>{companies.find(x => x.id === b2bId)?.name}</Typography>
    };

    return render();
};

export default CompanyTypography;
