import { Box, Button, Grid, IconButton } from "@material-ui/core";
import { FieldArray, FormikProps } from "formik";
import React from "react";
import { B2bCompany } from "../../../model/B2bCompany";
import FormTextField from "../../Common/FormTextField";
import DeleteIcon from '@material-ui/icons/Delete';
import { ApiBackend } from "../../../providers/apibackend";

class EditB2bCompanyAdmins extends React.Component<FormikProps<B2bCompany>> {

	private readonly api: ApiBackend;

	constructor(props: FormikProps<B2bCompany>) {
		super(props);

		this.api = new ApiBackend();
	}

	render(): JSX.Element {
		const rows = this.props.values.admins || [];

		return (
			<FieldArray
				name="admins"
				render={arrayHelpers => (
					<>
						{rows.map((row, idx) => {
							return (
								<Grid container key={idx} spacing={2}>
									<Grid item xs={2}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].firstName`}
											label="Förnamn"
											valueGetter={() => row.firstName} />
									</Grid>
									<Grid item xs={3}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].lastName`}
											label="Efternamn"
											valueGetter={() => row.lastName} />
									</Grid>
									<Grid item xs={3}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].email`}
											label="E-post"
											valueGetter={() => row.email} />
									</Grid>
									<Grid item xs={2}>
										<FormTextField {...this.props}
											propName={`admins[${idx}].mobilePhone`}
											label="Telefon"
											valueGetter={() => row.mobilePhone ?? ""} />
									</Grid>
									<Grid item xs={1}>
										<Button variant="text"
											onClick={() => {
												this.handleLogin(row.email);
											}}>
											Logga in som...
										</Button>
									</Grid>
									<Grid item xs={1}>
										<Box display="flex" justifyContent="flex-end">
											<IconButton
												onClick={() => { arrayHelpers.remove(idx); }}>
												<DeleteIcon />
											</IconButton>
										</Box>
									</Grid>
								</Grid>
							)
						})}
					</>
				)} />
		);
	}

	private handleLogin = async (email: string) => {
        const token = await this.api.createTransferTokenForCompayAdmin(email);
        window.open(`${CONFIG.b2bUrl}?token=${token.id}&company=${this.props.values.id}&isadmin=true`);
    };
}

export default EditB2bCompanyAdmins;