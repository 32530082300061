import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { FormikErrors, FormikTouched, getIn } from "formik";
import { debounce } from "lodash";
import React from "react";
import { Club } from "../../model/Club";

interface IProps {
    validClubs: Club[];
    disabled?: boolean;
    propName: string;
    label?: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    onChange: (value: string) => void;
}

interface IState {
    defaultValue: string;
    clubNames: string[];
}

class FormClubField extends React.Component<IProps, IState> {
    state: IState;

    constructor(props: IProps) {
		super(props);
		
		this.state = {
            defaultValue: this.props.values[this.props.propName] ?? "",
            clubNames: this.props.validClubs.map(x => x.name)
		};
	}

    render(): JSX.Element {
        const { disabled, propName, label, values, touched, errors, handleBlur } = this.props;
        const { clubNames, defaultValue } = this.state;

        const nestedProp = propName.indexOf(".") > -1;
        const errorFn = () => {
            if (!nestedProp) {
                return Boolean(touched[propName] && errors[propName]);
            }

            return Boolean(
                getIn(touched, propName) &&
                getIn(errors, propName)
            )
        };

        const helperFn = () => {
            if (!nestedProp) {
                return touched[propName] && errors[propName];
            }

            return (
                getIn(touched, propName) &&
                getIn(errors, propName)
            )
        };

        return (
            <Autocomplete
                id="club-autocomplete"
                disabled={disabled}
                freeSolo
                defaultValue={defaultValue}
                options={clubNames}
                onInputChange={(e: any, val: string) => {
                    if (!e) {
                        return;
                    }
                    this.handleInputChange(val);
                }}
                onOpen={handleBlur}
                renderInput={(params) => (
                    <TextField {...params}
                        id={propName}
                        name={propName}    
                        error={errorFn()}
                        fullWidth
                        helperText={helperFn()}
                        label={label}
                        InputLabelProps={{ shrink: true }}
                        style={{ flex: 1, margin: '0 3px' }}
                    />
                )}
            />
        )
    }

    private handleInputChange = debounce((value: string) => {
        let nextValue = value ?? "";
        this.notifyChange(nextValue.trim());
    }, 500);

	private notifyChange = (value: string) => {
		this.props.onChange(value);
	};
}

export default FormClubField;
