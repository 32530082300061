import { Order } from "../../../model/Order";

const NO_VALUE = "-";
const B2B_PROFILE_KEY = "b2b_profile";
export const B2B_INVOICE_KEY = "b2b_invoice_tag";
export const B2B_INVOICE_COMMENT_KEY = "b2b_invoice_comment";

const Keys = [B2B_PROFILE_KEY, B2B_INVOICE_KEY, B2B_INVOICE_COMMENT_KEY] as const;
type FindableKey = typeof Keys[number];

type Metadata = { key: string, value: string };

const hasMetadataKey = (key: FindableKey, metadataItems?: Metadata[]) => {
    return metadataItems?.some(k => k.key === key);
};

const getMetadataValueInternal = (key: FindableKey, metadataItems?: Metadata[]) => {
    const match = metadataItems?.find(k => k.key === key);
    return match?.value ?? NO_VALUE;
}

export const hasCompanyMetadata = (order: Order) => {
    return order.items.some(oi => hasMetadataKey(B2B_PROFILE_KEY, oi.metadata));
};

export const getMetadataValue = (order: Order, key: FindableKey) => {
    const orderItemMatch = order.items.find(oi => hasMetadataKey(key, oi.metadata));

    if (!orderItemMatch) {
        return NO_VALUE;
    }

    return getMetadataValueInternal(key, orderItemMatch.metadata);
};
