import { Box, Grid, WithStyles } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { ProductBundle } from "../../model/ProductBundle";
import FormCbxField from "../Common/FormCbxField";
import FormDateField from "../Common/FormDateField";
import FormTextField from "../Common/FormTextField";
import EditBundleGroups from "./EditBundleGroups";
import { EditMode, Locale } from "./models";
import { vasaloppetDateGetterFormatter, vasaloppetIsoDateSetterFormatter } from "../../utilities/date";

interface IProps extends FormikProps<ProductBundle> {
    mode: EditMode;
}

const EditBundleFormContent = (props: IProps & WithStyles) => {
    const [locale, setLocale] = useState("SV" as Locale);

    const render = (): JSX.Element => {
        const { classes, setFieldValue } = props;

        return (
            <Grid container className={classes.root} spacing={2} style={{ width: "100%" }}>
                <Grid item xs={12}>
                    <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <ToggleButtonGroup
                            value={locale}
                            exclusive
                            onChange={(_: React.MouseEvent<HTMLElement>, newLocale: Locale) => {
                                setLocale(newLocale);
                            }}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="SV" aria-label="left aligned">
                                SV
                            </ToggleButton>
                            <ToggleButton value="EN" aria-label="left aligned">
                                EN
                            </ToggleButton>
                        </ToggleButtonGroup>

                        {props.mode === "edit" &&
                            <FormCbxField {...props}
                                propName={"isArchived"}
                                label="Arkiverad"
                            />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {locale === "SV" ?
                        <FormTextField {...props}
                            propName="name"
                            label="Namn"
                        /> :
                        <FormTextField {...props}
                            propName="nameEn"
                            label="Namn (EN)"
                        />
                    }
                </Grid>
                <Grid item xs={12}>
                    {locale === "SV" ?
                        <FormTextField {...props}
                            multiline={true}
                            propName="description"
                            label="Beskrivning"
                        /> :
                        <FormTextField {...props}
                            multiline={true}
                            propName="descriptionEn"
                            label="Beskrivning (EN)"
                        />
                    }
                </Grid>
                <Grid item xs={6}>
                    <FormDateField {...props}
                        propName="validFrom"
                        label="Giltig från"
                        valueFormatter={() => vasaloppetDateGetterFormatter(props.values.validFrom)}
                        onChange={() => (e: any) => {
                            setFieldValue("validFrom", vasaloppetIsoDateSetterFormatter(e.target.value));
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormDateField {...props}
                        propName="validTo"
                        label="Giltig till"
                        valueFormatter={() => vasaloppetDateGetterFormatter(props.values.validTo)}
                        onChange={() => (e: any) => {
                            setFieldValue("validTo", vasaloppetIsoDateSetterFormatter(e.target.value, true));
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...props}
                        propName="publicId"
                        label="Publikt ID"
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormTextField {...props}
                        type="number"
                        propName="sortIndex"
                        label="Sorteringsordning"
                    />
                </Grid>

                {props.mode === "edit" &&
                    <Grid item xs={12}>
                        <EditBundleGroups {...props} />
                    </Grid>
                }
            </Grid>
        )
    }

    return render();
}

export default EditBundleFormContent;
