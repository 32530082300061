import { Box, CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Product } from "../../../model/Product";
import { ProductBundleItem } from "../../../model/ProductBundle";
import { ApiBackend } from "../../../providers/apibackend";
import ProductVariantPriceGroupSelector, { IProductVariantPriceGroupSelectorResult } from "../../Common/ProductVariantPriceGroupSelector";

interface IProps {
    onChange: (item: ProductBundleItem) => void;
}

const SelectProductPopover = (props: IProps) => {
    const [loading, setLoading] = useState(true);
    const [products, setProducts] = useState(null as Product[]);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();
    
    // Run on load
    useEffect(() => {
        didMount.current = true;

        const fetchData = async (): Promise<void> => {
            const allProducts = await backend.listProducts();
            const sortedProducts = allProducts
                .filter((product: Product) => product.type === "race" || product.type === "other")
                .sort((product1: Product, product2: Product) => product1.name.localeCompare(product2.name, "sv"));

            setProducts(sortedProducts);
            setLoading(false);
        };

        fetchData();

        // unmount
        return () => {didMount.current = false};
    }, []);

    const handleChange = (res: IProductVariantPriceGroupSelectorResult) => {
        props.onChange({
            productId: res.product.id,
            productVariantId: res.productVariant.Id,
            productVariantPriceGroupId: res.productVariantPriceGroup?.id
        } as unknown as ProductBundleItem);
    };

    const render = () => {

        if (loading || !products) {
            return (<Box display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ height: 200 }}>
                <CircularProgress size={60} color="secondary" />
            </Box>);
        }

        return (
            <ProductVariantPriceGroupSelector
                headerText="Välj produkt"
                buttonText="Ok"
                products={products}
                includePriceGroups={true}
                priceGroupOptional={true}
                onChange={handleChange} 
            />
        )
    };

    return render();
}

export default SelectProductPopover;
