import { FormControlLabel, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import { createStyles, WithStyles, withStyles } from "@material-ui/core/styles";
import { Theme } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Typography from "@material-ui/core/Typography";
import React, { Fragment } from "react";
import { Orderoverview } from "../../../model/Orderoverview";
import { Product } from "../../../model/Product";
import { ApiBackend } from "../../../providers/apibackend";
import { DATE_FORMAT, FILE_FORMAT, isBetween, vasaloppetMoment } from "../../../utilities/date";
import ProductList from "../../Common/ProductList/ProductList";
import { Mode, getOverviewGridItems, s2ab, sumItems, toBinaryExcelString } from "./utils";
import saveAs from "file-saver";
import { ProductListChangeEvent } from "../../Common/ProductList/utils";

interface IState {
    products: Product[];
    mode: Mode | null;
    selectedProduct: Product;
    selectedProductId: string;
    orderoverview: Orderoverview;
    loading: boolean;
    autoUpdate: boolean;
    filterFromDate: string;
    filterToDate: string;
}

class OrderOverview extends React.Component<WithStyles, IState> {
    state: IState;

    private readonly api: ApiBackend = new ApiBackend();;
    private timeout: NodeJS.Timeout;

    constructor(props: WithStyles) {
        super(props);

        this.state = {
            loading: false,
            products: null,
            mode: null,
            selectedProduct: null,
            selectedProductId: null,
            orderoverview: null,
            autoUpdate: false,
            filterFromDate: vasaloppetMoment().format(DATE_FORMAT),
            filterToDate: vasaloppetMoment().add(1, "M").format(DATE_FORMAT)
        };
    }

    componentDidMount() {
        this.timeout = setTimeout(() => { this.handleRefresh(); }, 30000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }

    private handleRefresh = () => {
        if (this.state.autoUpdate) {
            this.refresh();
        }

        this.timeout = setTimeout(() => { this.handleRefresh(); }, 30000);
    };

    private setProduct = async (productId: string) => {
        this.setState({ loading: true });

        const product = await this.api.getProduct(productId);
        const mode = ["skiwax", "busticket"].includes(product.type) ? "OCCATION" : "ORDER";

        this.setState({ selectedProduct: product, selectedProductId: productId, mode: mode, loading: false }, () => {
            this.refresh();
        });
    };

    private refresh = async () => {
        const { mode, filterFromDate, filterToDate } = this.state;

        this.setState({ loading: true });

        let fromDate, toDate: string;
        if (mode === "ORDER") {
            fromDate = filterFromDate;
            toDate = filterToDate;
        }

        let overview = await this.api.getOrderOverview(this.state.selectedProductId, fromDate, toDate);
        this.setState({ orderoverview: overview, loading: false });
    };

    private toggleAutoUpdate = () => {
        this.setState({ autoUpdate: !this.state.autoUpdate });
    };

    private handleFilterFromChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterFromDate: ev.target.value }, () => {
            if (this.state.mode === "ORDER") {
                this.refresh();
            }
        });
    };

    private handleFilterToChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ filterToDate: ev.target.value }, () => {
            if (this.state.mode === "ORDER") {
                this.refresh();
            }
        });
    };

    private handleExportExcel = () => {
        const overviewGridItems = getOverviewGridItems(this.state.selectedProduct, this.state.orderoverview);
        const reportData = toBinaryExcelString(overviewGridItems, this.state.mode);
        saveAs(new Blob([s2ab(reportData)], { type: "application/octet-stream" }), 'orderöversikt_' + vasaloppetMoment(this.state.filterFromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.filterToDate).format(FILE_FORMAT) + '.xlsx');
    };

    render() {
        const { classes } = this.props;

        return (
            <Fragment>
                <Grid container className={classes.root} spacing={2}>
                    <Grid item xs={12}>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        <Card>
                            <CardHeader
                                className={classes.cardHeader}
                                title={
                                    <Fragment>
                                        <Typography variant="h5" style={{ display: "inline" }}>Översikt försäljning [{this.state.mode}]</Typography>
                                    </Fragment>
                                }
                            />
                            <CardContent>
                                <Grid container className={classes.root} spacing={2}>
                                    <Grid item xs={5}>
                                        <ProductList
                                            autoSelectFirst={true}
                                            onInit={(evt: ProductListChangeEvent) => {
                                                this.setProduct(evt.products[0]?.id);
                                            }}
                                            onChange={(evt: ProductListChangeEvent) => {
                                                this.setProduct(evt.products[0]?.id);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Button variant="contained" onClick={this.refresh} style={{ marginLeft: 1 }}>
                                            Uppdatera
                                        </Button>
                                        <FormControlLabel
                                            control={
                                                <Switch style={{ marginLeft: 5 }}
                                                    checked={this.state.autoUpdate}
                                                    onChange={this.toggleAutoUpdate}
                                                    name="autoupdate"
                                                    color="secondary"
                                                />
                                            }
                                            label="Auto uppdatering"
                                        />
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button variant="contained" onClick={this.handleExportExcel} style={{ marginLeft: 1 }}>
                                            Excelexportera
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {this.state.mode === "OCCATION" && this.renderForOccation()}
                                        {this.state.mode === "ORDER" && this.renderForOrder()}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    private renderForOrder = () => {
        const overviewGridItems = getOverviewGridItems(this.state.selectedProduct, this.state.orderoverview);
        const summary = sumItems(overviewGridItems, this.state.filterFromDate, this.state.filterToDate);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}><Typography variant="body2"><b>Filtrera order</b></Typography>
                    <TextField type="date" value={this.state.filterFromDate} onChange={this.handleFilterFromChange} />
                    <TextField type="date" value={this.state.filterToDate} onChange={this.handleFilterToChange} />
                </Grid>
                <Grid item xs={5}><Typography variant="body2"><b>Variant</b></Typography></Grid>
                <Grid item xs={5}><Typography variant="body2"><b>Prisgrupp</b></Typography></Grid>
                <Grid item xs={2}><Typography variant="body2"><b>Köpta</b></Typography></Grid>
                {overviewGridItems.map((item, idx) => {
                    return <Fragment key={idx}>
                        <Grid item xs={5}><Typography variant="body2">{item.variant.Name}</Typography></Grid>
                        <Grid item xs={5}><Typography variant="body2">{item.pg.Name}</Typography></Grid>
                        <Grid item xs={2}><Typography variant="body2">{item.item && item.item.numPurchased ? item.item.numPurchased : 0}</Typography></Grid>
                    </Fragment>;
                })}
                <Grid item xs={10}>
                    <Typography variant="body2"><b>Summa</b></Typography>
                </Grid>
                <Grid item xs={2}>
                    <Typography variant="body2"><b>{summary.numPurchased}</b></Typography>
                </Grid>
            </Grid>
        );
    };

    private renderForOccation = () => {
        const overviewGridItems = getOverviewGridItems(this.state.selectedProduct, this.state.orderoverview);
        const summary = sumItems(overviewGridItems, this.state.filterFromDate, this.state.filterToDate);

        return (
            <Grid container spacing={2}>
                <Grid item xs={12}><Typography variant="body2"><b>Filtrera tillfälle</b></Typography>
                    <TextField type="date" value={this.state.filterFromDate} onChange={this.handleFilterFromChange} />
                    <TextField type="date" value={this.state.filterToDate} onChange={this.handleFilterToChange} />
                </Grid>
                <Grid item xs={5}><Typography variant="body2"><b>Variant</b></Typography></Grid>
                <Grid item xs={4}><Typography variant="body2"><b>Tillfälle</b></Typography></Grid>
                <Grid item xs={1}><Typography variant="body2"><b>Totalt</b></Typography></Grid>
                <Grid item xs={1}><Typography variant="body2"><b>Köpta</b></Typography></Grid>
                <Grid item xs={1}><Typography variant="body2"><b>Incheckade</b></Typography></Grid>
                {overviewGridItems.map((item, idx) => {
                    if (!item.occ ||
                        isBetween(item.occ.time, this.state.filterFromDate, this.state.filterToDate)
                    ) {
                        return <Fragment key={idx}>
                            <Grid item xs={5}><Typography variant="body2">{item.variant.Name}-{item.pg.Name}</Typography></Grid>
                            <Grid item xs={4}><Typography variant="body2">{item.occ ? item.occ.name : ""}</Typography></Grid>
                            <Grid item xs={1}><Typography variant="body2">{item.item && item.item.seatsAvailable ? item.item.seatsAvailable : 0}</Typography></Grid>
                            <Grid item xs={1}><Typography variant="body2">{item.item && item.item.numPurchased ? item.item.numPurchased : 0}</Typography></Grid>
                            <Grid item xs={1}><Typography variant="body2">{item.item && item.item.numDelivered ? item.item.numDelivered : 0}</Typography></Grid>
                        </Fragment>;
                    }
                })}
                <Grid item xs={9}>
                    <Typography variant="body2"><b>Summa</b></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="body2"><b>{summary.seatsAvailable}</b></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="body2"><b>{summary.numPurchased}</b></Typography>
                </Grid>
                <Grid item xs={1}>
                    <Typography variant="body2"><b>{summary.numDelivered}</b></Typography>
                </Grid>
            </Grid>
        );
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    root: {

    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(OrderOverview);
