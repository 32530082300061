import { Button, Card, CardContent, CardHeader, Grid, LinearProgress, Theme, Typography, WithStyles, createStyles, debounce, withStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Person } from '../../../model/Person';
import { ApiBackend } from '../../../providers/apibackend';
import SearchFilter, { Filters } from '../../Common/SearchFilter/SearchFilter';
import MergeDialog from './MergeDialog';
import { IColumnDefinition } from '../../Common/EnhancedTable/models';
import EnhancedTable from '../../Common/EnhancedTable/EnhancedTable';

interface ISearchFilter {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    vasaId?: string;
}

interface IProps {
    sourcePerson: Person;
    onClose: () => void;
}

interface IState {
    loading: boolean;
    persons: Person[];
    searchFilter: ISearchFilter;
    mergeToPerson: Person;
    maxRows: number;
}

class MergePersonSearch extends React.Component<WithStyles & IProps, IState> {
    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps) {
        super(props);

        this.api = new ApiBackend();
        this.state = {
            persons: [],
            loading: false,
            maxRows: 100,
            searchFilter: {
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                vasaId: "",
            },
            mergeToPerson: null
        };
    }

    private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        this.setState({ searchFilter: searchFilter, loading: true }, async () => {
            await this.performSearch();
        });
    };

    private performSearch = debounce(async () => {
        const { searchFilter, maxRows } = this.state;
        const { firstName, lastName, email, phone, vasaId } = searchFilter;

        const response = await this.api.searchPersons({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            vasaid: vasaId,
            noCount: true,
            begin: 0,
            limit: maxRows
        });
        this.setState({
            persons: response?.persons.filter(x => x.id != this.props.sourcePerson.id),
            loading: false
        });
    }, 500);

    render() {
        const columnDefinitions: IColumnDefinition<Person>[] = [
            {
                renderCell: (row) => {
                    return (
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={async () => {
                                const person = await this.api.getPerson(row.id);
                                this.setState({ mergeToPerson: person });
                            }}
                        >
                            Slå samman med
                        </Button>
                    );
                }
            },
            { propName: "lastName", label: "Efternamn" },
            { propName: "firstName", label: "Förnamn" },
            { propName: "vasaId", label: "VASA-ID" },
            { propName: "birthDay", label: "Födelsedatum" },
            { propName: "email", label: "E-post" },
            { propName: "mobileNumber", label: "Telefon" },
        ];

        let { classes } = this.props;

        return <>
            <SearchFilter
                id={"manage-persons-filter"}
                filters={{
                    "firstName": {
                        id: "filter-first-name",
                        type: "DebouncedText",
                        label: "Förnamn",
                        size: 4
                    },
                    "lastName": {
                        id: "filter-last-name",
                        type: "DebouncedText",
                        label: "Efternamn",
                        size: 4
                    },
                    "email": {
                        id: "filter-email",
                        type: "DebouncedText",
                        label: "E-post",
                        size: 4
                    },
                    "phone": {
                        id: "filter-phone",
                        type: "DebouncedText",
                        label: "Telefon",
                        size: 4
                    },
                    "vasaId": {
                        id: "filter-vasaId",
                        type: "DebouncedText",
                        label: "VASA-ID",
                        size: 4
                    }
                }}
                persist={true}
                onInit={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Fragment>
                                    <Typography
                                        variant="h5"
                                        style={{ display: 'inline' }}>
                                        Slå samman med person
                                    </Typography>
                                </Fragment>
                            }
                        />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <EnhancedTable<Person>
                                columnDefinitions={columnDefinitions}
                                data={this.state.persons ?? []}
                                pageSize={10}
                                maxRows={this.state.maxRows}
                                paginationMode="client"
                                sortingMode="client"
                                loading={this.state.loading}
                                dense
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state.mergeToPerson &&
                <MergeDialog personTarget={this.state.mergeToPerson} personToBeMerged={this.props.sourcePerson} onAbort={() => {
                    this.setState({ mergeToPerson: null });
                }}
                    onSave={() => {
                        this.setState({ mergeToPerson: null });
                        this.props.onClose();
                    }}
                />
            }
        </>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(MergePersonSearch);
