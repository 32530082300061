import { FormControl, Grid, InputLabel, MenuItem, Select, useTheme } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { Club } from "../../../../model/Club";
import { Sex } from "../../../../model/CommonTypes";
import { CustomField, CustomFieldValidValue, hasCustomFieldStringValidValues, isChoiceType, toCustomFieldValidValues } from "../../../../model/CustomField";
import { Entry } from "../../../../model/Entry";
import { Product } from "../../../../model/Product";
import { ApiBackend } from "../../../../providers/apibackend";
import { hasEntryFieldDefinitions, mandatoryFieldDefinitions, optionalFieldDefinitions } from "../../../../model/ProductVariantRaceData";
import ClearableSelect from "../../../Common/ClearableSelect";

interface IProps {
    entry: Entry;
    raceProduct: Product;
    variant: string;
    updateEntry: (entry: Entry) => void;
}

const AddEntryCustomFields = ({ entry, raceProduct, variant, updateEntry }: IProps) => {
    const [clubs, setClubs] = useState(null as Club[]);
    const backend = new ApiBackend();

    const theme = useTheme();

    //Run on load
    useEffect(() => {
        let mounted = true;
        backend.listClubs(false).then((clubs) => {
            if (mounted) {
                setClubs(clubs);
            }
        });

        return () => { mounted = false };
    }, []);

    const handleCustomFieldChange = (field: CustomField) => (e: React.ChangeEvent<HTMLInputElement>) => {
        handleCustomFieldChangeInternal(field, e.target.name, e.target.value);
    };

    const handleCustomFieldChangeInternal = (field: CustomField, name: string, val: any) => {
        let vals = entry.customFieldValues;
        if (!vals) {
            vals = [];
        }

        let typedValue = val;

        if (field.type === "multichoice") {
            typedValue = typedValue?.filter(Boolean)?.join(";");
        }

        let curIdx = vals.findIndex(x => x.key == name);
        if (curIdx >= 0) {
            vals[curIdx].value = typedValue;
        } else {
            vals.push({ key: name, value: typedValue });
        }
        entry.customFieldValues = vals;

        if (field.handleAsClubName) {
            entry.clubName = typedValue;
        }

        if (field.type == "genderpicker") {
            entry.sex = typedValue as Sex;
        }

        updateEntry({ ...entry });
    };

    const getCustomFieldValue = (field: CustomField, defaultVal: string): any => {
        const vals = entry.customFieldValues;

        if (vals) {
            const val = vals.find(x => x.key == field.id);
            if (val) {
                if (field.type === "multichoice") {
                    return (val.value as string)?.split(";");
                } else {
                    return val.value;
                }
            }
        }

        if (field.type === "multichoice") {
            return [defaultVal]
        } else {
            return defaultVal;
        }
    };

    const renderCustomField = (field: CustomField) => {

        if (field.useClubNameSuggestion && clubs) {
            return (
                <Autocomplete
                    key={field.id}
                    id={field.id}
                    fullWidth
                    freeSolo
                    disableClearable
                    autoComplete
                    autoHighlight
                    options={clubs.map((c) => c.name)}
                    onInputChange={(event, newval) => {
                        if (event != null) {
                            handleCustomFieldChangeInternal(field, field.id, newval);
                        }
                    }}
                    renderInput={params => {
                        return (
                            <TextField {...params}
                                variant="standard"
                                label={field.label}
                                InputLabelProps={{ shrink: true }}
                            />
                        );
                    }}
                />
            );
        }

        let validValues = field.validvalues as CustomFieldValidValue[];
        if (isChoiceType(field) && hasCustomFieldStringValidValues(field.validvalues)) {
            validValues = toCustomFieldValidValues(field.validvalues);
        }

        switch (field.type) {
            case "text":
                return (
                    <TextField
                        key={field.id}
                        fullWidth
                        name={field.id}
                        value={getCustomFieldValue(field, "")}
                        label={field.label}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleCustomFieldChange(field)}
                    />
                );
            case "number":
                return (
                    <TextField
                        key={field.id}
                        value={getCustomFieldValue(field, "")}
                        fullWidth type="number"
                        name={field.id}
                        label={field.label}
                        InputLabelProps={{ shrink: true }}
                        onChange={handleCustomFieldChange(field)}
                    />
                );
            case "choice":
                return (
                    <FormControl fullWidth key={field.id}>
                        <InputLabel shrink>{field.label}</InputLabel>
                        <ClearableSelect
                            clearable={true}
                            value={getCustomFieldValue(field, "")}
                            onClear={() => {
                                handleCustomFieldChangeInternal(field, field.id, "");
                            }}
                            onChange={(evt: any) => {
                                handleCustomFieldChangeInternal(field, field.id, evt.target.value);
                            }}
                        >
                            {validValues.map((x, idx) =>
                                <MenuItem key={`${field.id}_${idx}`} value={x.value}>
                                    {x.label}
                                </MenuItem>
                            )}
                        </ClearableSelect>
                    </FormControl>
                );
            case "multichoice":
                return (
                    <FormControl fullWidth key={field.id}>
                        <InputLabel shrink>{field.label}</InputLabel>
                        <ClearableSelect
                            clearable={true}
                            multiple={true}
                            value={getCustomFieldValue(field, "")}
                            onClear={() => {
                                handleCustomFieldChangeInternal(field, field.id, [""]);
                            }}
                            onChange={(evt: any) => {
                                handleCustomFieldChangeInternal(field, field.id, evt.target.value);
                            }}
                        >
                            {validValues.map((x, idx) =>
                                <MenuItem key={`${field.id}_${idx}`} value={x.value}>
                                    {x.label}
                                </MenuItem>
                            )}
                        </ClearableSelect>
                    </FormControl>
                );
            case "genderpicker":
                return (
                    <FormControl fullWidth key={field.id}>
                        <InputLabel shrink>{field.label}</InputLabel>
                        <Select
                            native
                            value={getCustomFieldValue(field, "")}
                            name={field.id}
                            onChange={handleCustomFieldChange(field)}
                        >
                            <option key={`${field.id}_`} value=""></option>
                            {field.validvalues.map((x, idx) =>
                                <option key={`${field.id}_${idx}`} value={(x as CustomFieldValidValue).value}>
                                    {(x as CustomFieldValidValue).label}
                                </option>
                            )}
                        </Select>
                    </FormControl>
                );
        }
    };

    const renderCustomFields = () => {
        if (!raceProduct || !variant) {
            return null;
        }

        const selectedVariant = raceProduct.variants.find(x => x.Id == variant);

        if (!selectedVariant || !hasEntryFieldDefinitions(selectedVariant.productVariantRaceData)) {
            return null;
        }

        const mandatoryDefs = mandatoryFieldDefinitions(selectedVariant.productVariantRaceData);
        const optionalDefs = optionalFieldDefinitions(selectedVariant.productVariantRaceData);

        let ret = [];
        if (raceProduct && variant) {
            if (mandatoryDefs.length > 0) {
                ret.push(
                    <Grid item xs={12} key="man"
                        style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                    >
                        Obligatoriska uppgifter
                    </Grid>
                );

                for (let i = 0; i < mandatoryDefs.length; i++) {
                    const f = mandatoryDefs[i];
                    if (f) {
                        ret.push(
                            <Grid key={"man_" + f.id} item xs={6}>
                                {renderCustomField(f)}
                            </Grid>
                        );
                    }
                }
            }

            if (optionalDefs.length > 0) {
                ret.push(
                    <Grid item xs={12} key="opt"
                        style={{ background: theme.palette.primary.dark, color: theme.palette.primary.main }}
                    >
                        Frivilliga uppgifter
                    </Grid>
                );

                for (let i = 0; i < optionalDefs.length; i++) {
                    const f = optionalDefs[i];
                    if (f) {
                        ret.push(
                            <Grid key={"opt_" + f.id} item xs={6}>
                                {renderCustomField(f)}
                            </Grid>
                        );
                    }
                }
            }
        }

        return ret.length > 0 ? ret : null;
    };

    return <>{renderCustomFields()}</>;
}

export default AddEntryCustomFields;
