import { Theme, Tooltip, withStyles } from "@material-ui/core";

const VasaloppetTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: 12,
        border: "1px solid #dadde9",
    },
}))(Tooltip);

export default VasaloppetTooltip;