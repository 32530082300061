import { Card, CardContent, CardHeader, createStyles, Grid, Theme, withStyles, WithStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Order } from '../../model/Order';
import { ApiBackend } from '../../providers/apibackend';
import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import { Message } from '../../model/Message';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorIcon from '@material-ui/icons/Error';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EmailIcon from '@material-ui/icons/Email';
import { vasaloppetDateTimeGetterFormatter } from '../../utilities/date';
import VasaloppetTooltip from '../Common/VasaloppetTooltip';

interface Props {
    orderId: string;
    order: Order;
}
interface State {
    isLoadingMessageHistory: boolean;
    orderMessages: Message[];
}

class OrderMessages extends React.Component<Props & WithStyles, State> {
    constructor(props: Props & WithStyles) {
        super(props);
        this.state = { isLoadingMessageHistory: true, orderMessages: null }
    }

    componentDidMount() {
        this.updateData();
    }

    updateData = () => {
        let backend = new ApiBackend();
        backend.getOrderCommunicationHistory(this.props.orderId).then((messages) => {
            this.setState({ orderMessages: messages, isLoadingMessageHistory: false });
        });
    };

    render() {
        let { classes } = this.props;
        return <Card>
            <CardHeader className={classes.cardHeader} title={"Meddelanden till " + (this.props.order && this.props.order.contact ? this.props.order.contact.firstName : '[Laddar..]')} />
            <CardContent style={{ overflowY: 'scroll', maxHeight: '135px', fontSize: '12px' }}>
                <Grid container spacing={2}>
                    <Grid item xs={1}></Grid>
                    <Grid item xs={4}><b>Mall</b></Grid>
                    <Grid item xs={3}><b>Skickat</b></Grid>
                    <Grid item xs={3}><b>Läst</b></Grid>
                    <Grid item xs={1}></Grid>
                    {this.state.isLoadingMessageHistory &&

                        <CircularProgress color="secondary" />
                    }
                    {this.state && this.state.orderMessages && this.state.orderMessages.map((x, idx) => {
                        return <Fragment key={"mess_" + idx}>
                            <Grid item xs={1}>{x.type == "SMS" ? <VasaloppetTooltip title="SMS" aria-label="SMS"><TextsmsIcon /></VasaloppetTooltip> : null}{x.type == "EMAIL" ? <VasaloppetTooltip title="E-Post" aria-label="E-Post"><EmailIcon /></VasaloppetTooltip> : null}</Grid>
                            <Grid item xs={4} style={{ overflow: 'hidden' }}>{x.msgTypeId}</Grid>
                            <Grid item xs={3}>{x.sent ? vasaloppetDateTimeGetterFormatter(x.sent, "", true) : null}</Grid>
                            <Grid item xs={3}>{x.read ? vasaloppetDateTimeGetterFormatter(x.read, "", true) : null}</Grid>
                            <Grid item xs={1}>{x.isSoftBounce &&
                                <VasaloppetTooltip title="Meddelandet har inte kunnat levereras än (softbounce)" aria-label="Meddelandet har inte kunnat levereras än (softbounce)"><WarningIcon style={{ color: 'orange' }} /></VasaloppetTooltip>
                            }
                                {x.isHardBounce &&
                                    <VasaloppetTooltip title="Meddelandet kan inte levereras (hardbounce)" aria-label="Meddelandet kan inte levereras (hardbounce)"><ErrorIcon style={{ color: 'darkred' }} /></VasaloppetTooltip>
                                }
                            </Grid>
                        </Fragment>;
                    })}
                </Grid>
            </CardContent>
        </Card>
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(OrderMessages);
