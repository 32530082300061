import { Product } from "../../../model/Product";
import { Event } from "../../../model/Event";
import { ProductVariant } from "../../../model/ProductVariant";
import { ProductOccation } from "../../../model/ProductVariantOccation";
import { ProductVariantPriceGroup } from "../../../model/ProductVariantPriceGroup";

export const getSortedEvents = (source: Event[]): Event[] => {
    if (!source) {
        return source;
    }

    return source.sort((a, b) => a.name.localeCompare(b.name, "sv"));
};

export const getSortedProducts = (source: Product[]): Product[] => {
    if (!source) {
        return source;
    }

    return source.sort((a, b) => a.name.localeCompare(b.name, "sv"));
};

export const getSortedVariants = (source: ProductVariant[]): ProductVariant[] => {
    if (!source) {
        return source;
    }

    return source.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));
};

export const getSortedPricegroups = (source: ProductVariantPriceGroup[]): ProductVariantPriceGroup[] => {
    if (!source) {
        return source;
    }

    return source.sort((a, b) => a.Name.localeCompare(b.Name, "sv"));
};

export const getSortedOccations = (source: ProductOccation[]): ProductOccation[] => {
    if (!source) {
        return source;
    }

    return source.sort((a, b) => a.name.localeCompare(b.name, "sv"));
};
