import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { OrderItem } from '../../model/OrderItem';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import EditInsuranceDetails from './EditInsuranceDetails';
import DialogActions from '@material-ui/core/DialogActions';
import { cloneDeep } from 'lodash';
import { getSkiPassType } from '../../model/ProductVariantPriceGroup';

class Props {
    item: OrderItem;
    rowStyle?: React.CSSProperties;
    onUpdateMetadata?: (metadata: { key: string; value: string; }[]) => Promise<void>;
}

class State {
    insuranceEdit: { key: string; value: string; }[];
}

class OrderRowMetadata extends React.Component<Props, State>
{

    getSkiPassNamesOnItem(item: OrderItem) {
        let names: string[] = [];
        for (let i = 0; i < item.numberOfProducts; i++) {
            if (item.metadata.filter(x => x.key == "skipass_name_" + i).length > 0) {
                names.push(item.metadata.filter(x => x.key == "skipass_name_" + i)[0].value);
            }
        }
        return names;
    }

    render() {
        const orderedVariant = this.props.item.product.variants.filter(x => x.Id == this.props.item.productVariantId)[0];
        const orderedPriceGroup = orderedVariant.priceGroups.filter(x => x.id == this.props.item.productVariantPriceGroupId)[0];
        const { day: isDaySkiPass, season: isSeasonSkiPass, isRange: isRangeSkiPass } = getSkiPassType(orderedPriceGroup, this.props.item.product.type)

        const item = this.props.item;
        const rowStyle = this.props.rowStyle;
        return <Fragment>
            {(item.product.type == "busticket" || item.occationName) && <>
                <Grid style={this.props.rowStyle} item xs={1}></Grid>
                <Grid item xs={11} style={{ ...{ paddingTop: 0, marginTop: -5 }, ...rowStyle }}>
                    <Typography variant="body2" style={{ ...{ margin: 0 }, ...rowStyle }}><i>{item.variantName}<br />
                        {item.occationName}</i></Typography>
                </Grid></>
            }
            {item.product.type == "skipass" && item.metadata && isDaySkiPass && <>
                <Grid style={this.props.rowStyle} item xs={1}></Grid>
                <Grid item xs={11} style={{ ...{ paddingTop: 0, marginTop: -5 }, ...rowStyle }}>
                    <Typography variant="body2" style={{ ...{ margin: 0 }, ...rowStyle }}><b>Giltigt</b>: {item.metadata.filter(x => x.key == "skipassvalidfrom")[0].value}</Typography>
                </Grid></>
            }
            {item.product.type == "skipass" && item.metadata && isRangeSkiPass && <>
                <Grid item xs={1}></Grid>
                <Grid item xs={11} style={{ ...{ paddingTop: 0, marginTop: -5 }, ...rowStyle }}>
                    <Typography variant="body2" style={{ ...{ margin: 0 }, ...rowStyle }}><b>Giltigt</b>: {item.metadata.filter(x => x.key == "skipassvalidfrom")[0].value} - {item.metadata.filter(x => x.key == "skipassvalidto")[0].value}</Typography>
                </Grid></>
            }
            {item.product.type == "skipass" && item.metadata && (isRangeSkiPass || isSeasonSkiPass) && <>
                <Grid style={this.props.rowStyle} item xs={1}></Grid>
                <Grid item xs={11} style={{ ...{ paddingTop: 0, marginTop: -5 }, ...rowStyle }}>
                    <Typography variant="body2" style={{ ...{ margin: 0 }, ...rowStyle }}>
                        <b>Registrerat på</b>: {this.getSkiPassNamesOnItem(item).join(", ")}
                    </Typography>
                </Grid>
            </>
            }
            {(item.product.name.startsWith("Enervit") || item.product.type== "insurance") && <>
                <Grid style={this.props.rowStyle} item xs={1}></Grid>
                <Grid style={this.props.rowStyle} item xs={2}></Grid>
                <Grid item xs={9} style={{ ...{ paddingTop: 0, marginTop: -5 }, ...rowStyle }}>
                    <Typography variant="body2" style={{ ...{ margin: 0 }, ...rowStyle }}>
                        <EditIcon onClick={() => { this.setState({ insuranceEdit: item.metadata ? cloneDeep(item.metadata) : [] }) }} style={{ cursor: 'pointer', height: 17, verticalAlign: 'middle' }} /> <b>Registrerad på</b>: {item.metadata?.find(x => x.key == "firstName")?.value} {item.metadata?.find(x => x.key == "lastName")?.value}
                    </Typography>
                </Grid>
                {this.state && this.state.insuranceEdit && this.showEditInsurance()}
            </>

            }
            {item.metadata && (item.metadata.some(x => x.key == "giftcard_code")) && <>
                <Grid style={this.props.rowStyle} item xs={1}></Grid>
                <Grid style={this.props.rowStyle} item xs={2}></Grid>
                <Grid item xs={9} style={{ ...{ paddingTop: 0, marginTop: -5 }, ...rowStyle }}>
                    <Typography variant="body2" style={{ ...{ margin: 0 }, ...rowStyle }}>
                        <b>Kod presentkort</b>: {item.metadata.find(x => x.key == "giftcard_code")?.value}
                    </Typography>
                </Grid>
            </>
            }
        </Fragment>;
    }

    showEditInsurance = () => {
        return <Dialog disableEnforceFocus
            maxWidth="lg"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Personuppgifter</DialogTitle>
            <DialogContent dividers>
                <EditInsuranceDetails metadata={this.state.insuranceEdit} onChangeValue={(key, val) => {
                    const item = this.state.insuranceEdit;
                    const kvp = item.find(x => x.key == key);
                    if (!kvp) {
                        item.push({ key: key, value: val });
                    }
                    else {
                        kvp.value = val;
                    }
                    this.setState({ insuranceEdit: item });
                }} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => { this.setState({ insuranceEdit: null }) }}>Avbryt</Button>
                {this.props.onUpdateMetadata && <Button onClick={async () => {
                    await this.props.onUpdateMetadata(this.state.insuranceEdit);
                    this.setState({ insuranceEdit: null });
                }}>Spara</Button>}
            </DialogActions>
        </Dialog>
    };
}

export default OrderRowMetadata;
