import React, { useState } from "react";
import { PatchRestrictions, PatchablePropertiesWithId } from "./utils";
import { Grid } from "@material-ui/core";
import StatusRenderer from "./PropertyRenderers/StatusRenderer";
import useDidMountEffect from "../../../Common/UseDidMountEffect";
import { EntryStatus } from "../../../../model/CommonTypes";
import { PatchEntryDocumentProps } from "../../../../providers/models";

interface IProps {
    items: PatchablePropertiesWithId[];
    patchProperties: (keyof PatchEntryDocumentProps)[];

    restrictions?: PatchRestrictions;

    onChange: (nextValue: Partial<PatchEntryDocumentProps>) => void;
}

const PatchEntriesDialogContent = (props: IProps) => {
    const [patchEntry, setPatchEntry] = useState({} as PatchEntryDocumentProps);

    useDidMountEffect(() => {
        props.onChange(patchEntry);
    }, [patchEntry]);

    const handleChange = (propName: keyof PatchEntryDocumentProps, nextValue: any) => {
        const nextEntry = { ...patchEntry };
        nextEntry[propName] = nextValue;
        setPatchEntry(nextEntry);
    };

    const getRenderer = (propName: keyof PatchEntryDocumentProps, key: number) => {

        switch (propName) {
            case "status":
                return (
                    <StatusRenderer
                        key={key}
                        defaultValues={props.items.map(x => x.status)}
                        restrictedStatuses={props.restrictions?.status}
                        onChange={(nextValue: EntryStatus) => {
                            handleChange(propName, nextValue);
                        }}
                    />
                );

            default:
                return null;
        }
    };

    const render = () => {

        return (
            <Grid container spacing={2}>
                {props.patchProperties.map(getRenderer)}
            </Grid>
        );
    }

    return render();
}

export default PatchEntriesDialogContent;
