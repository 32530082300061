/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent, Theme, withStyles, createStyles, Grid, LinearProgress, Typography, Button, TextField, FormControlLabel, Checkbox } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import { AddOnSummaryItem, EnervitOrderItem } from '../../model/Order';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DataGrid, GridColDef as ColDef, GridValueFormatterParams as ValueFormatterParams } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import ManageOrder from './ManageOrder';
import ProductList from '../Common/ProductList/ProductList';
import { DATE_FORMAT, FILE_FORMAT, vasaloppetDateGetterFormatter, vasaloppetDateTimeGetterFormatter, vasaloppetMoment } from '../../utilities/date';
import { IExportExcelRequest } from '../../model/IExportExcelRequest';

interface State {
    loading: boolean;
    showSummary: boolean;
    orders: EnervitOrderItem[],
    viewOrder: string;
    selectedRace: string;
    fromDate: Date;
    toDate: Date;
    summary: AddOnSummaryItem[];
}

class ListEnervitOrders extends React.Component<RouteComponentProps, State> {
    private readonly api: ApiBackend = new ApiBackend();

    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: false, orders: [], viewOrder: null, selectedRace: null as string, fromDate: new Date(new Date().getFullYear(), 0, 1), toDate: new Date(9999, 11, 31), showSummary: true, summary: null };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        this.setState({ loading: true });
        if (this.state.showSummary) {
            this.api.listEnervitSummary(vasaloppetDateGetterFormatter(this.state.fromDate), vasaloppetDateGetterFormatter(this.state.toDate)).then((summary) => {
                this.setState({ summary: summary, loading: false });
            });
        }
        else {
            if (this.state.selectedRace) {
                this.api.listEnervitOrders(this.state.selectedRace, vasaloppetDateGetterFormatter(this.state.fromDate), vasaloppetDateGetterFormatter(this.state.toDate)).then((orders) => {
                    this.setState({ orders: orders, loading: false });
                });
            }
            else {
                this.setState({ orders: [], loading: false });

            }
        }
    };

    openOrder = (orderId: string) => () => {
        this.setState({ viewOrder: orderId });
    };

    render() {
        let { classes } = this.props as any;
        const columns: ColDef[] = [
            {
                field: 'orderPublicId', headerName: 'OrderID', width: 150,

                renderCell: (params: ValueFormatterParams) => {
                    return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={this.openOrder(params.row.orderId)}>{params.row.orderPublicId}</Typography>
                }
            },
            { field: 'participant', headerName: 'Deltagare', width: 250, valueGetter: (params) => { return params.row.RaceInfo?.firstName + " " + params.row.RaceInfo?.lastName } },
            { field: 'email', headerName: 'Epost', width: 250, valueGetter: (params) => { return params.row.RaceInfo?.email } },
            { field: 'phone', headerName: 'Tel', width: 250, valueGetter: (params) => { return params.row.RaceInfo?.phone } },
            { field: 'productName', headerName: 'Produkt', minWidth: 270, flex: 1, valueGetter: (params) => { return params.row.productName } },
            { field: 'created', headerName: 'Skapad', width: 170, valueGetter: (params) => { return vasaloppetDateTimeGetterFormatter(params.row.created) } }
        ];

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Inställningar</Typography></Fragment>} />
                    <CardContent>

                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}><b>Visa</b></Grid>
                            <Grid item xs={12}>
                                <FormControlLabel label="Sammanställning" control={
                                    <Checkbox checked={this.state.showSummary} onChange={(ev) => { this.setState({ showSummary: ev.target.checked }, () => { this.refresh() }) }} />
                                }
                                />
                                <FormControlLabel label="Enskilda orders" control={
                                    <Checkbox checked={!this.state.showSummary} onChange={(ev) => { this.setState({ showSummary: !ev.target.checked }, () => { this.refresh() }) }} />
                                }
                                />
                            </Grid>
                            <Grid item xs={3}><b>Från datum</b></Grid>
                            <Grid item xs={3}><b>Till datum</b></Grid>
                            <Grid item xs={4}>{!this.state.showSummary && <b>Lopp</b>}</Grid>
                            <Grid item xs={2}><b></b></Grid>

                            <Grid item xs={3}><TextField type='date' value={vasaloppetDateGetterFormatter(this.state.fromDate)} onChange={(ev) => {
                                this.setState({ fromDate: vasaloppetMoment(ev.target.value).toDate() }, () => { this.refresh() });
                            }} /></Grid>
                            <Grid item xs={3}><TextField type='date' value={vasaloppetDateGetterFormatter(this.state.toDate)} onChange={(ev) => {
                                this.setState({ toDate: vasaloppetMoment(ev.target.value).toDate() }, () => { this.refresh() });
                            }} /></Grid>
                            <Grid item xs={4}>
                                {!this.state.showSummary && <ProductList
                                    initialValue={this.state.selectedRace}
                                    productTypes={["race", "event"]}
                                    onChange={(evt) => {
                                        this.setState({ selectedRace: evt.products[0].id }, () => {
                                            this.refresh();
                                        });
                                    }}
                                />}
                            </Grid>

                            <Grid item xs={2}><Button variant="contained" disabled={this.state.loading} onClick={this.exportExcel}>Excelexportera</Button></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>

                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Beställningar enervit</Typography>
                        <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} />
                    </Fragment>} />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        {this.state.showSummary && this.state.summary && <Fragment>
                            <Grid container spacing={2}>
                                <Grid item xs={6}><b>Produkt</b></Grid>
                                <Grid item xs={2}><b>Antal sålda</b></Grid>
                                <Grid item xs={4}><b>Totalt</b></Grid>
                                {this.state.summary.map((x, idx) => {
                                    return <Fragment key={idx}>
                                        <Grid item xs={6}>{x.productName}</Grid>
                                        <Grid item xs={2}>{x.numberOfSold}st</Grid>
                                        <Grid item xs={4}>{x.price * x.numberOfSold}kr</Grid>
                                    </Fragment>
                                })}
                                <Grid item xs={6}><b>SUMMA:</b></Grid>
                                <Grid item xs={2}><b>{this.state.summary.reduce((prev, cur, idx) => prev + cur.numberOfSold, 0)}st</b></Grid>
                                <Grid item xs={4}><b>{this.state.summary.reduce((prev, cur, idx) => prev + cur.numberOfSold * cur.price, 0)}kr</b></Grid>
                            </Grid>
                        </Fragment>
                        }

                        {!this.state.showSummary && this.state.orders &&
                            <DataGrid
                                checkboxSelection
                                autoHeight={true}
                                loading={this.state.loading}
                                rows={this.state.orders ?? []}
                                columns={columns}
                                pageSize={100}
                                rowsPerPageOptions={[100]}
                            />
                        }
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
            {this.state && this.state.viewOrder && <ManageOrder orderId={this.state.viewOrder} close={() => { this.setState({ viewOrder: null }); }} />}
        </Fragment>;
    }

    exportExcel = () => {
        if (this.state.showSummary) {
            this.renderXLSXSummary();
        }
        else {
            this.renderXLSX();
        }
    };

    renderXLSXSummary = async () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Enervitsummering",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };

        wb.SheetNames.push("Utlägg");

        let data = [['Vasaloppsföreningen Sälen-Mora'],
        [],
        ['Enervit ' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT)],
        [],
        ['Redovisning till:'],
        ['Kostnad Enervit support Vasaloppet 600kr, Öppet Spår 90K 650kr, Vasaloppet 45/Öppet Spår måndag 45 300kr'],
        ['Från: Vasaloppet, Rasmus Ericson, rasmus.ericson@vasaloppet.se'],
        [],
        [],
        ["Lopp", "Antal sålda tjänster", "Värde"]];
        let totalNum = 0;
        let totalSum = 0;
        for (let i = 0; i < this.state.summary.length; i++) {
            const row = this.state.summary[i];
            totalNum += row.numberOfSold;
            totalSum += (row.numberOfSold * row.price);
            data.push(
                [
                    row.productName,
                    row.numberOfSold + '',
                    (row.price * row.numberOfSold) + 'kr'
                ]
            );
        }

        data.push(["Summa att betala till Enervit", totalNum + '', totalSum + "kr"]);
        data.push(["konto 8431-9,53 063 191-0"]);

        var ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets["Utlägg"] = ws;

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'enervitbeställingar_' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT) + '.xlsx');
    }

    renderXLSX = async () => {
        const request: IExportExcelRequest = {
            entityType: "EnervitOrder",
            filter: [
                {
                    propertyName: "fromDate",
                    propertyValue: vasaloppetDateGetterFormatter(this.state.fromDate)
                }, {
                    propertyName: "toDate",
                    propertyValue: vasaloppetDateGetterFormatter(this.state.toDate)
                }, {
                    propertyName: "raceProducts",
                    propertyValue: [
                        this.state.selectedRace
                    ]
                }
            ]
        };
        const fileName = 'enervitbeställingar_' + vasaloppetMoment(this.state.fromDate).format(FILE_FORMAT) + "-" + vasaloppetMoment(this.state.toDate).format(FILE_FORMAT) + '.xlsx';
        await this.api.exportExcel(request, fileName);
    };

    s2ab(s: any) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {

        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ListEnervitOrders));
