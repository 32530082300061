import React from "react";
import { Product } from "../../../model/Product";
import { Event } from "../../../model/Event";
import { ApiBackend } from "../../../providers/apibackend";
import { groupBy } from "lodash";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import Menu from "@material-ui/core/Menu/Menu";
import Button from "@material-ui/core/Button/Button";


interface IProps {
    id?: string;
    buttonText: string;
    products: Product[];
    disabledItems: string[];
    onChange: (value: Product) => void;
}

interface IState {
    groupedProducts: { name: string; products: Product[]; }[];
    anchorEl: HTMLElement | null;
}

class ProductMenu  extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;
    
    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();

        this.state = {
            groupedProducts: null,
            anchorEl: null
        };
    }

    async componentDidMount(): Promise<void> {
        const { products } = this.props;

        const events = await this.api.listEvents();
        const groupedProducts = this.getGroupedProducts(products, events);
        this.setState({
            groupedProducts: groupedProducts
        });
    }

    render(): JSX.Element {
        const { buttonText } = this.props;
        const { groupedProducts, anchorEl } = this.state;

        if (!groupedProducts) {
            return null;
        }

        return (<>
            <Button
                variant="contained"
                aria-controls="product-menu"
                aria-haspopup="true"
                onClick={this.handleClick}>
                {buttonText}
            </Button>
            <Menu
                id="product-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
            >
                {this.generateItemsFromProducts(groupedProducts)}
            </Menu>
            </>);
    }

    private generateItemsFromProducts = (products: { name: string; products: Product[]; }[]): (JSX.Element | JSX.Element[])[][] => {
        const { disabledItems } = this.props;

        const listItems = products.map(s => {
            const options = s.products.map(p => {
                return (
                    <MenuItem 
                        key={p.id}
                        value={p.id}
                        style={{marginLeft: 18}}
                        disabled={disabledItems.some(x => x === p.id)}
                        onClick={(event) => this.handleSelect(p)}
                    >
                        {p.name}
                    </MenuItem>
                );
            });
            return [<ListSubheader disableSticky>{s.name}</ListSubheader>, options];
        });

        return listItems;
    };

    private handleClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: evt.currentTarget });
    };

    private handleSelect = (product: Product) => {
        const { onChange } = this.props;

        this.setState({ anchorEl: null });
        onChange(product);
    };

    private handleClose = () => {
        this.setState({ anchorEl: null });
    };

    private getGroupedProducts = (products: Product[], events: Event[]): { name: string; products: Product[]; }[] => {
        const map = new Map(events.map((x) => [x.id, x.name]));
        const groups = groupBy(products, (x) => (!!x.EventId && map.has(x.EventId)) ? map.get(x.EventId) : "Övriga");
        const subHeaders = Object.keys(groups).sort((a, b) => {
            if (b === "Övriga") {
                return -1;
            }

            return a.localeCompare(b, "sv");
        });

        return subHeaders.map(s => {
            return {
                name: s,
                products: groups[s].sort((a, b) => a.name.localeCompare(b.name, "sv"))
            }
        });
    };
}

export default ProductMenu;
