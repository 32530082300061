import { isConcreteType } from "../utilities/generics";

const CustomFieldTypes = ["text", "number", "ssn", "choice", "multichoice", "genderpicker"] as const;
export type CustomFieldType = typeof CustomFieldTypes[number];

export class CustomField {
    id: string;
    name: string;
    label: string;
    label_en: string;
    description: string;
    description_en: string;
    type: CustomFieldType;
    validation_type: string;
    validvalues: string[] | CustomFieldValidValue[];
    handleAsClubName: boolean;
    useClubNameSuggestion: boolean;
    onlyForWomen: boolean;
    onlyForMen: boolean;
    requiresConsent: boolean;
}

export class CustomFieldValidValue {
    value: string;
    label: string;
    label_en: string;
}

export const isChoiceType = (customField: CustomField): boolean => {
    return ["choice", "multichoice"].some(x => x === customField.type);
};

export const hasCustomFieldValidValues = (validValues: string[] | CustomFieldValidValue[]): validValues is CustomFieldValidValue[] => {
    if (!validValues || validValues.length === 0) {
        return false;
    }

    const firstItem = validValues[0];
    return isConcreteType<CustomFieldValidValue>(firstItem, "value");
};

export const hasCustomFieldStringValidValues = (validValues: string[] | CustomFieldValidValue[]): validValues is string[] => {
    if (!validValues || validValues.length === 0) {
        return false;
    }

    const firstItem = validValues[0];
    return typeof firstItem === "string";
};

export const toCustomFieldValidValues = (validValues: string[]): CustomFieldValidValue[] => {
    return validValues.map(x => {
        return {
            label: x,
            label_en: x,
            value: x
        }
    });
};
