import { Grid, Typography } from "@material-ui/core";
import { FormikProps } from "formik";
import React from "react";
import { B2bCompanyCreate } from "../../../../../model/B2bCompany";
import FormCountryPickerDropdown from "../../../../Common/FormCountryPickerDropDown";
import FormTextField from "../../../../Common/FormTextField";

class CreateCompanyFormContent extends React.Component<FormikProps<B2bCompanyCreate>> {
	render(): React.ReactNode {
		return (
			<>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<FormTextField {...this.props} propName="name" label="Företagsnamn" />
							</Grid>
							<Grid item xs={12}>
								<FormTextField {...this.props} propName="description" label="Beskrivning" />
							</Grid>
							<Grid item xs={12}>
								<FormTextField {...this.props} propName="orgNumber" label="Organisationsnummer" />
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">Fakturering</Typography>
							</Grid>
							<Grid item xs={12}>
								<FormTextField {...this.props} propName="address" label="Fakturaadress" />
							</Grid>
							<Grid item xs={4}>
								<FormTextField {...this.props} propName="postCode" label="Postnummer" />
							</Grid>
							<Grid item xs={8}>
								<FormTextField {...this.props} propName="city" label="Postort" />
							</Grid>
							<Grid item xs={12}>
								<FormCountryPickerDropdown {...this.props} propName="country" label="Land" />
							</Grid>
							<Grid item xs={12}>
								<FormTextField {...this.props} propName="email" label="E-post faktura" />
							</Grid>
							<Grid item xs={12}>
								<FormTextField {...this.props} propName="reference" label="Referens på faktura" />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}
}

export default CreateCompanyFormContent;
