import { Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { EntryAddonProduct } from "../../../../model/Entry";
import { Product } from "../../../../model/Product";
import { ProductVariant } from "../../../../model/ProductVariant";
import { ProductVariantPriceGroup } from "../../../../model/ProductVariantPriceGroup";

interface IProps {
    addon: Product;
    entryAddon?: EntryAddonProduct;
    onChange: (productId: string, e: EntryAddonProduct) => void;
    onRemove: (productId: string) => void;
}

const variantFromEntryAddon = (addon: Product, entryAddon?: EntryAddonProduct): ProductVariant | null => {
    if (!entryAddon) {
        return null;
    }

    return addon.variants.find(x => x.Id === entryAddon.productVariantId);
};

const priceGroupFromEntryAddon = (addon: Product, entryAddon?: EntryAddonProduct): ProductVariantPriceGroup | null => {
    if (!entryAddon) {
        return null;
    }

    const variant = addon.variants.find(x => x.Id === entryAddon.productVariantId);

    return variant.priceGroups.find(x => x.id === entryAddon.productVariantPriceGroupId);
};

const EntryAddon = ({ addon, entryAddon, onChange, onRemove }: IProps) => {
    const [checked, setChecked] = useState(!!entryAddon);
    const [variant, setVariant] = useState(variantFromEntryAddon(addon, entryAddon));
    const [priceGroup, setPriceGroup] = useState(priceGroupFromEntryAddon(addon, entryAddon));
    const prev = useRef({ checked, variant, priceGroup }).current;

    useEffect(() => {
        if (prev.checked !== checked) {
            notifyChange();
            prev.checked = checked;
        }
    }, [checked]);

    useEffect(() => {
        if (checked && prev.priceGroup !== priceGroup) {
            notifyChange();
            prev.priceGroup = priceGroup;
        }
    }, [priceGroup])

    const handleCheckedChange = (value: boolean): void => {
        setChecked(value);
    };

    const handleVariantChange = (value: string): void => {
        setVariant(addon.variants.find(v => v.Id === value));
        setPriceGroup(null);
        setChecked(false);
    };

    const handlePriceGroupChange = (value: string): void => {
        setPriceGroup(variant.priceGroups.find(pg => pg.id === value));
    };

    const checkEnabled = (): boolean => {
        return !!checked || (!!variant && !!priceGroup);
    };

    const formatPrice = (): string | null => {
        if (!priceGroup) {
            return null;
        }

        return `${priceGroup.price} kr`;
    };

    const notifyChange = debounce((): void => {
        if (!checked) {
            onRemove(addon.id);
            return;
        }

        onChange(addon.id, {
            productId: addon.id,
            productVariantId: variant.Id,
            productVariantPriceGroupId: priceGroup.id
        } as EntryAddonProduct);
    }, 200);

    return (<>
            <Grid item xs={4}>
                <FormControlLabel
                    label={<Typography variant="body2">{addon.name}</Typography>}
                    control={
                        <Checkbox
                            checked={checked}
                            disabled={!checkEnabled()}
                            onChange={(ev) => {
                                handleCheckedChange(ev.target.checked);
                            }} />
                    } />
            </Grid>
            <Grid item xs={3}>
                <FormControl fullWidth>
                    <InputLabel shrink>Variant</InputLabel>
                    <Select
                        value={variant?.Id ?? ""}
                        onChange={(ev) => {
                            handleVariantChange(ev.target.value as string);
                        }}
                    >
                        {addon.variants.map((v) => {
                            return <MenuItem key={v.Id} value={v.Id}>{v.Name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <InputLabel shrink>Prisgrupp</InputLabel>
                    <Select
                        disabled={!variant}
                        value={priceGroup?.id ?? ""}
                        onChange={(ev) => {
                            handlePriceGroupChange(ev.target.value as string);
                        }}
                    >
                        {variant && variant.priceGroups.map((pg) => {
                            return <MenuItem key={pg.id} value={pg.id}>{pg.Name}</MenuItem>
                        })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={3}>
                <Typography variant="body2" style={{padding: 12}}>{formatPrice()}</Typography>
            </Grid>
        </>);
};

export default EntryAddon;
