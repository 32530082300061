import React, { useEffect, useRef, useState } from "react";
import { CodeHolder } from "../../model/CodeHolder";
import { Grid, Card, CardHeader, CardContent, createStyles, Theme, withStyles, WithStyles, LinearProgress, Box, IconButton, FormControl, InputLabel, MenuItem } from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import GetAppIcon from "@material-ui/icons/GetApp";
import DeleteIcon from "@material-ui/icons/Delete";
import { ApiBackend } from "../../providers/apibackend";
import { ListEntry } from "../../model/Entry";
import EnhancedTable from "../Common/EnhancedTable/EnhancedTable";
import { IColumnDefinition, ISortModel, PageSize } from "../Common/EnhancedTable/models";
import { IExportExcelRequest } from "../../model/IExportExcelRequest";
import EditEntryDialog from "../Entries/Entry/EditEntryDialog";
import withConfirm, { IConfirmContext } from "../Common/dialog/Confirm";
import ClearableSelect from "../Common/ClearableSelect";
import { EntryStatus, EntryStatusTypes } from "../../model/CommonTypes";

interface IProps {
    codeHolder: CodeHolder;
    onChange: () => void;
}

const defaultBoxStyle = {
    display: "flex",
    flexDirection: "row",
};

interface IPageModel {
    pageSize: PageSize;
    page: number;
}

const ALL_ITEMS = "--ALL--";
const ENTRY_ACTIVE_STATUS = "ACTIVE";

const CodeEntries = ({ classes, codeHolder, showConfirm, onChange }: WithStyles & IProps & IConfirmContext) => {
    const [loading, setLoading] = useState(false);
    const [entries, setEntries] = useState(null as ListEntry[]);
    const [editEntry, setEditEntry] = useState(null as ListEntry);
    const [selectedCode, setSelectedCode] = useState(ALL_ITEMS);
    const [selectedEntryStatus, setSelectedEntryStatus] = useState(ENTRY_ACTIVE_STATUS);
    const [sortModel, setSortModel] = useState({ sortBy: "lastName", sortOrder: "desc" } as ISortModel<ListEntry>);
    const [pageModel, setPageModel] = useState({ page: 0, pageSize: 10 } as IPageModel);
    const [rowCount, setRowCount] = useState(0);

    const busyLoading = useRef<boolean>(false);
    const didMount = useRef<boolean>(false);
    const backend = new ApiBackend();

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    const codeHolderHasCodes = () => {
        if (!codeHolder.Codes) {
            return false;
        }

        return codeHolder.Codes.length > 0;
    };

    useEffect(() => {
        didMount.current = true;

        performSearch();

        // unmount
        return () => { didMount.current = false };
    }, []);

    useEffect(() => {
        performSearch();
    }, [pageModel, sortModel, selectedCode, selectedEntryStatus, codeHolder]);

    const performSearch = async (): Promise<void> => {
        if (!didMount.current || busyLoading.current || !codeHolderHasCodes()) {
            return;
        }

        setBusyLoading(true);

        const entriesResponse = await backend.listEntries({
            usedEntryCode: selectedCode !== ALL_ITEMS ? [selectedCode] : codeHolder.Codes.map(x => x.code),
            status: selectedEntryStatus !== ALL_ITEMS ? selectedEntryStatus as EntryStatus : EntryStatusTypes.map(x => x),
            sortProp: sortModel?.sortBy,
            sortDir: sortModel?.sortOrder,
            begin: pageModel.page * pageModel.pageSize,
            limit: pageModel.pageSize
        });

        if (didMount.current) {
            setRowCount(entriesResponse.count);
            setEntries(entriesResponse.entries);
            setBusyLoading(false);
        }
    };

    const exportExcel = async (): Promise<void> => {
        if (busyLoading.current) {
            return;
        }

        setBusyLoading(true);

        const codes = selectedCode !== ALL_ITEMS ? [selectedCode] : codeHolder.Codes.map(x => x.code);
        const entryStatus = (selectedEntryStatus !== ALL_ITEMS ? [selectedEntryStatus] : EntryStatusTypes) as EntryStatus[];
        const filter = [{
            propertyName: "usedEntryCode",
            propertyValue: codes.join(ApiBackend.arraySeparator)
        }, {
            propertyName: "status",
            propertyValue: entryStatus.join(ApiBackend.arraySeparator)
        }];

        const request: IExportExcelRequest = {
            entityType: "Entry",
            filter: filter,
            sortProp: sortModel?.sortBy,
            sortDir: sortModel?.sortOrder
        };
        try {
            await backend.exportExcel(request);
        } finally {
            setBusyLoading(false);
        }
    };

    const handleRequestSetPage = (nextPage: number, pageSize?: PageSize): void => {
        setPageModel({
            page: nextPage,
            pageSize: pageSize
        });
    };

    const handleRequestSetSort = (nextSortModel: ISortModel<ListEntry>): void => {
        setSortModel(nextSortModel);
    };

    const handleEntryStatusSelectChange = (nextValue: string) => {
        setSelectedEntryStatus(nextValue);
    };

    const handleCodeSelectChange = (nextValue: string) => {
        setSelectedCode(nextValue);
    };

    const handleSetNotActive = async (row: ListEntry): Promise<void> => {
        const result = await showConfirm(`Vill du avanmäla deltagaren (${row.firstName} ${row.lastName}) och lämna tillbaka koden?`)
        if (!result) {
            return;
        }

        try {
            setBusyLoading(true);
            const entry = await backend.getEntry(row.id);
            const currentCodeHolder = await backend.getCode(codeHolder.id);
            const currentCode = currentCodeHolder.Codes.find(x => x.code === row.usedEntryCode);
            currentCode.numUsages = Math.max(0, currentCode.numUsages - 1);

            entry.status = "NOTACTIVE";
            await backend.saveEntry(entry);
            await backend.updateCode(codeHolder.id, currentCode);

            onChange();
        } finally {
            setBusyLoading(false);
        }
    };

    const handleSetEditEntry = (entry: ListEntry): void => {
        setEditEntry(entry);
    };

    const columnDefinitions: IColumnDefinition<ListEntry>[] = [
        {
            propName: "usedEntryCode",
            label: "Kod"
        },
        {
            propName: "lastName",
            label: "Efternamn"
        },
        {
            propName: "firstName",
            label: "Förnamn"
        },
        {
            propName: "eventName",
            label: "Lopp"
        },
        {
            propName: "bibtext",
            label: "Startnummer"
        },
        {
            propName: "startgroupName",
            label: "Startled"
        },
        {
            propName: "vasaId",
            label: "VASA-ID"
        },
        {
            propName: "birthDay",
            label: "Födelsedatum"
        },
        {
            propName: "itemPrice",
            label: "Pris"
        },
        {
            propName: "clubName",
            label: "Klubb"
        },
        {
            propName: "email",
            label: "E-post"
        },
        {
            propName: "phone",
            label: "Telefon"
        },
        {
            propName: "orderDate",
            label: "Orderdatum"
        },
        {
            propName: "status",
            label: "Status"
        },
        {
            propName: "b2bId",
            label: "Företag"
        },
        {
            propName: "raceStatus",
            label: "Racestatus"
        },
        {
            renderCell: (row) => {
                return <>
                    <IconButton color="default" onClick={() => { handleSetEditEntry(row) }} size="small">
                        <Edit fontSize="medium" />
                    </IconButton>
                    <IconButton color="default" onClick={() => { handleSetNotActive(row) }} size="small">
                        <DeleteIcon fontSize="medium" />
                    </IconButton>
                </>
            }
        }
    ];

    const render = (): JSX.Element => {
        return <>
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader}
                            title="Anmälningar"
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                    <div style={{ marginTop: 12, marginRight: 12 }}>
                                        <GetAppIcon onClick={exportExcel} />
                                    </div>
                                </Box>
                            }
                        />
                        <CardContent>
                            {loading &&
                                <LinearProgress color="secondary" />
                            }
                            <Grid container className={classes.root} spacing={2}>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>Status</InputLabel>
                                        <ClearableSelect
                                            clearable={false}
                                            value={selectedEntryStatus}
                                            onClear={() => {
                                                handleEntryStatusSelectChange("");
                                            }}
                                            onChange={(evt: any) => {
                                                handleEntryStatusSelectChange(evt.target.value);
                                            }}
                                        >
                                            <MenuItem key={""} value={"--ALL--"}>Alla</MenuItem>
                                            {EntryStatusTypes.map((x, idx) => {
                                                return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                                            })}
                                        </ClearableSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>Kod</InputLabel>
                                        <ClearableSelect
                                            clearable={false}
                                            value={selectedCode}
                                            onClear={() => {
                                                handleCodeSelectChange("");
                                            }}
                                            onChange={(evt: any) => {
                                                handleCodeSelectChange(evt.target.value);
                                            }}
                                        >
                                            <MenuItem key={""} value={"--ALL--"}>Alla</MenuItem>
                                            {codeHolder.Codes.map((x, idx) => {
                                                return <MenuItem key={idx} value={x.code}>{x.code}</MenuItem>;
                                            })}
                                        </ClearableSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    <EnhancedTable<ListEntry>
                                        columnDefinitions={columnDefinitions}
                                        data={entries ?? []}
                                        pageSize={pageModel.pageSize}
                                        page={pageModel.page}
                                        rowCount={rowCount}
                                        paginationMode="server"
                                        sortingMode="server"
                                        loading={loading}
                                        dense
                                        sticky="right"
                                        onRequestPageChange={handleRequestSetPage}
                                        onRequestSort={handleRequestSetSort}
                                    />
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {editEntry &&
                <EditEntryDialog
                    entryId={editEntry.id}
                    onAbortEdit={() => {
                        setEditEntry(null)
                    }}
                />
            }
        </>;
    };

    return render();
};

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: "30px",
        verticalAlign: "middle",
        borderRadius: "10px"
    },
    icon: {
        verticalAlign: "middle",
        cursor: "pointer"
    },
    form: {
        "& > *": {
            margin: spacing(1),
            width: "25ch",
        },
        "& label.Mui-focused": {
            color: palette.secondary.main,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withConfirm(withStyles(useStyles)(CodeEntries));
