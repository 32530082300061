import React, { useEffect, useRef } from "react";
import { Box, IconButton, Popover, TextField } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { useState } from "react";
import { Product, ProductType } from "../../../model/Product";
import { Event } from "../../../model/Event";
import ProductTypeSelect, { ProductTypeSelectProductType } from "../ProductTypeSelect";
import useDidMountEffect from "../UseDidMountEffect";
import { ApiBackend } from "../../../providers/apibackend";
import { GroupedProducts, ProductListChangeEvent, buildChangeEvent, buildPresentationValue, getGroupedProducts, getInitialSafeValue } from "./utils";
import ReadonlyTextField from "../ReadonlyTextField";
import ProductListPopoverItems from "./ProductListPopoverItems";

interface ProductListPopoverProps {
    label: string;
    initialValue?: string | string[];
    showArchived?: boolean;
    productTypes?: ProductType[];
    filterEventId?: string;
    useVariants?: boolean;
    autoSelectFirst?: boolean;
    clearable?: boolean;
    multiple?: boolean;

    onChange: (evt: ProductListChangeEvent) => void;
    onInit?: (evt: ProductListChangeEvent) => void;
    onClear?: () => void;
}

type InitData = {
    products: Product[];
    events: Event[];
};

const ProductListPopover = (props: ProductListPopoverProps) => {
    const [initData, setInitData] = useState(null as InitData);
    const [value, setValue] = useState(getInitialSafeValue(props.initialValue));
    const [groupedProducts, setGroupedProducts] = useState([] as GroupedProducts[]);
    const [presentationValue, setPresentationValue] = useState("");
    const [selectedProductType, setSelectedProductType] = useState("ALL" as ProductTypeSelectProductType);

    const [anchorEl, setAnchorEl] = useState(null as HTMLInputElement);

    const initialized = useRef<boolean>(false);
    const api = new ApiBackend();

    useEffect(() => {
        const init = async (): Promise<void> => {
            const work: (Promise<Product[]> | Promise<Event[]>)[] = [
                api.listProducts(props.showArchived),
                api.listEvents()
            ];

            const [products, events] = await Promise.all(work);
            setInitData({
                products: products as Product[],
                events: events as Event[]
            });

            initialized.current = true;
        };

        init();
    }, []);

    useDidMountEffect(() => {
        setValue([]);
        setPresentationValue("");
        const evt = buildChangeEvent(props.useVariants, [], value);
        props.onChange(evt);
    }, [props.filterEventId]);

    useDidMountEffect(() => {
        const groups = getGroupedProducts(initData.products, initData.events, getFilteredProductTypes(), props.filterEventId);
        const products = groups.map(g => g.products).flat();

        const evt = buildChangeEvent(props.useVariants, products, value);
        setPresentationValue(buildPresentationValue(props.useVariants, initData.products, evt));

        if (!!props.onInit) {
            props.onInit(evt);
        }

    }, [initData]);

    useDidMountEffect(() => {
        const groups = getGroupedProducts(initData.products, initData.events, getFilteredProductTypes(), props.filterEventId);
        setGroupedProducts(groups);
    }, [props.filterEventId, props.productTypes, selectedProductType, initData]);

    const getFilteredProductTypes = (): ProductType[] | undefined => {
        if (selectedProductType === "ALL") {
            return props.productTypes;
        }

        return props.productTypes?.filter(p => p === selectedProductType);
    };

    const handlePopupOpen = (e: any) => {
        setAnchorEl(e.currentTarget);
    };

    const handlePopupClose = () => {
        setAnchorEl(null);
    };

    const handleChange = (evt: ProductListChangeEvent) => {
        setValue(evt.selectedIds);
        setPresentationValue(buildPresentationValue(props.useVariants, initData.products, evt))

        props.onChange(evt);
    };

    const handleClear = (ev: React.MouseEvent<HTMLElement>) => {
        setValue([]);
        setPresentationValue("");

        // Stop popover from being shown
        ev.stopPropagation();
        ev.preventDefault();

        if (!!props.onClear) {
            props.onClear();
        }
    };

    const render = () => {
        if (!initialized.current) {
            return (
                <ReadonlyTextField
                    value=""
                    label={props.label}
                />
            );
        }

        const isPopupOpen = Boolean(anchorEl);
        const listKey = `${props.filterEventId}_${selectedProductType}`;
        const displayClear = !!value ? "" : "none";
        const showProductTypeSelect = !props.productTypes || props.productTypes.length > 1;

        const endAdornment = props.clearable ?
            <IconButton
                style={{ padding: 0, display: displayClear }}
                onClick={handleClear}>
                <ClearIcon />
            </IconButton>
            : null;

        return (<>
            <TextField
                autoComplete="off"
                label={props.label}
                value={presentationValue}
                fullWidth
                onClick={handlePopupOpen}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    endAdornment: endAdornment
                }}
            />

            <Popover
                open={isPopupOpen}
                anchorEl={anchorEl}
                onClose={handlePopupClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                PaperProps={{
                    style: {
                        width: "500px",
                        maxHeight: "90vh"
                    }
                }}>

                <Box style={{ display: "flex", flexDirection: "column" }}>
                    {showProductTypeSelect &&
                        <Box style={{ display: "flex", flexDirection: "column", position: "sticky", top: 0, padding: "1em", zIndex: 1, backgroundColor: "#fff" }}>
                            <ProductTypeSelect
                                useFormWrapper={true}
                                wildCardSelect="ALL"
                                label="Produktkategori (filter)"
                                restrictedTypes={props.productTypes}
                                defaultValue={selectedProductType}
                                onChange={(nextValue: ProductTypeSelectProductType) => {
                                    setSelectedProductType(nextValue)
                                }}
                            />
                        </Box>
                    }

                    <Box style={{ padding: "1em" }}>
                        <ProductListPopoverItems
                            key={listKey}
                            groupedProducts={groupedProducts}
                            useVariants={props.useVariants}
                            autoSelectFirst={props.autoSelectFirst}
                            multiple={props.multiple}
                            initialValue={value}
                            onChange={handleChange}
                        />
                    </Box>
                </Box>

            </Popover>
        </>);
    };

    return render();
}

export default ProductListPopover;
