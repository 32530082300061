import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, MenuItem } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import { FieldArray, FieldArrayRenderProps, Formik, FormikProps } from "formik";
import React from "react";
import { B2bCompany, B2bCompanyProduct, B2bCompanyProductExtraField, B2bCompanySurvey, B2bCompanySurveyQuestion, CompanySurveyTypes, CompanySurveyTypesLookup } from "../../../../model/B2bCompany";
import FormCbxField from "../../../Common/FormCbxField";
import FormDateField from "../../../Common/FormDateField";
import FormReadonlyField from "../../../Common/FormReadOnlyField";
import FormSelectField from "../../../Common/FormSelectField";
import FormTextField from "../../../Common/FormTextField";
import editB2bCompanySurveysValidationSchema from "./EditB2bCompanySurveyValidationSchema";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { RaceRelayProduct } from "./EditB2bCompanySurveys";
import { vasaloppetDateGetterFormatter, vasaloppetIsoDateSetterFormatter } from "../../../../utilities/date";

interface IProps extends FormikProps<B2bCompany> {
    surveyIdx: number;
    survey: B2bCompanySurvey;
    sourceRelayProducts: RaceRelayProduct[];

    onSave: (survey: B2bCompanySurvey) => void;
    onClose: () => void;
    onDelete: () => void;
}

interface IState {
    companyProducts?: B2bCompanyProduct[];
    extraFields?: B2bCompanyProductExtraField[];
}

class EditB2bCompanySurveyDialog extends React.Component<IProps, IState> {
    state: IState;

    private readonly fieldTypes = ["choice", "text"];

    constructor(props: IProps) {
        super(props);

        this.state = {
            companyProducts: props.values.products,
            extraFields: props.values.products?.find(x => x.id === props.survey.companyProductId)?.extraFields ?? []
        };
    }

    render(): JSX.Element {
        const { survey } = this.props;

        return (<Formik
            initialValues={survey}
            validationSchema={editB2bCompanySurveysValidationSchema}
            onReset={() => {
                this.props.onClose();
            }}
            onSubmit={(survey: B2bCompanySurvey) => {
                this.props.onSave(survey);
            }}
        >
            {formik => {
                return this.renderDialog(formik);
            }}
        </Formik>)
    }

    private renderDialog = (formik: FormikProps<B2bCompanySurvey>): JSX.Element => {
        const { values, isValid, dirty, handleSubmit, handleReset, isSubmitting, setFieldValue, setFieldTouched } = formik;
        const { values: company, sourceRelayProducts } = this.props;
        const isSaveDisabled = !dirty || !isValid || isSubmitting;
        const companyProducts = company.products ?? [];

        const isPersisted = !!values.id;
        const isLocked = !!values.participants && values.participants.length > 0 && isPersisted;
        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        let productList = companyProducts;
        if (values.type === "RELAY_POOL") {
            const isRaceRelay = (companyProduct: B2bCompanyProduct): boolean => {
                const pMatch = sourceRelayProducts.find(p => p.productId === companyProduct.productId);
                if (!pMatch) {
                    return false;
                }

                return pMatch.variantIds.some(v => v === companyProduct.productVariantId);
            };

            productList = productList.filter(isRaceRelay);
        }

        return (
            <Dialog
                open={true}
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>
                    <Box {...defaultBoxStyle}>
                        {this.cardTitle()}
                        <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                            <Button variant="text"
                                onClick={() => {
                                    this.handleOpenSurvey(values);
                                }}>
                                Öppna enkät...
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Card style={{ marginTop: 10 }}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4} >
                                    <FormTextField {...formik}
                                        propName="name"
                                        label="Namn"
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <FormTextField {...formik}
                                        propName="description"
                                        label="Beskrivning"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormSelectField {...formik}
                                        propName="type"
                                        label="Typ"
                                        valueGetter={() => values.type ?? ""}
                                        onChange={() => (e: any) => {
                                            setFieldValue(`type`, e.target.value);
                                            setFieldTouched("companyProductId", true);

                                            if (!!e.target.value && e.target.value === "RELAY_POOL") {
                                                setFieldValue("requiresLogin", true);
                                                setFieldValue("companyProductId", undefined);
                                            }
                                        }}
                                    >
                                        {CompanySurveyTypes.map((x, sIdx) => {
                                            return <MenuItem key={sIdx} value={x}>{CompanySurveyTypesLookup[x]}</MenuItem>;
                                        })}
                                    </FormSelectField>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormSelectField {...formik}
                                        propName="companyProductId"
                                        label="Produktkoppling"
                                        isClearable={true}
                                        valueGetter={() => values.companyProductId ?? ""}
                                    >
                                        {productList.map((x, sIdx) => {
                                            return <MenuItem key={sIdx} value={x.id}>{x.name}</MenuItem>;
                                        })}
                                    </FormSelectField>
                                </Grid>
                                <Grid item xs={4}>
                                    <FormDateField {...this.props}
                                        propName="validTo"
                                        label="Giltig till"
                                        valueFormatter={() => vasaloppetDateGetterFormatter(values.validTo)}
                                        onChange={() => (e: any) => {
                                            setFieldValue("validTo", vasaloppetIsoDateSetterFormatter(e.target.value, true));
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormCbxField {...formik}
                                        propName="requiresLogin"
                                        label="Kräver inloggning"
                                        disabled={values.type === "RELAY_POOL"}
                                        valueGetter={() => values.requiresLogin} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormCbxField {...formik}
                                        propName="canParticipantsUnregister"
                                        label="Möjligt att avregistrera"
                                        valueGetter={() => values.canParticipantsUnregister} />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormReadonlyField {...formik}
                                        propName="id"
                                        label="Antal registrerade"
                                        valueFormatter={() => values.participants ? `${values.participants.length}` : "0"}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <Card>
                                        <CardHeader title="Frågor"></CardHeader>
                                        <FieldArray
                                            name="questions"
                                            render={arrayHelpers => (
                                                <>
                                                    {!!(values.questions && values.questions.length) && <CardContent>
                                                        {values.questions && values.questions.map((question, eIdx) => {
                                                            return this.renderQuestion(formik, question, eIdx, arrayHelpers);
                                                        })}
                                                    </CardContent>
                                                    }
                                                    <CardActions>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => { this.addQuestion(arrayHelpers); }}
                                                        >
                                                            Lägg till fråga
                                                        </Button>
                                                        <Button
                                                            variant="contained"
                                                            disabled={!values.companyProductId}
                                                            onClick={() => { this.addQuestionsFromCompanyProduct(values, arrayHelpers); }}
                                                        >
                                                            Hämta frågor från kopplad produkt
                                                        </Button>
                                                    </CardActions>
                                                </>
                                            )} />
                                    </Card>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        disabled={isLocked}
                        onClick={() => this.props.onDelete()}
                    >Ta bort enkät</Button>
                    <Button
                        variant="contained"
                        onClick={handleReset}
                    >Avbryt</Button>
                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={isSaveDisabled}
                        onClick={() => handleSubmit()}
                    >Stäng</Button>
                </DialogActions>
            </Dialog>
        )
    }

    private renderQuestion(
        formik: FormikProps<B2bCompanySurvey>,
        question: B2bCompanySurveyQuestion,
        questionIdx: number,
        arrayHelpers: FieldArrayRenderProps): JSX.Element {

        const { setFieldValue } = formik;

        const validValuesGetter = (): string => {
            if (!question.validValues || question.validValues.length === 0) {
                return "";
            }

            return question.validValues.join("\n");
        };

        const validValuesSetter = (str: string): void => {
            const validValues = str.split("\n");
            setFieldValue(`questions[${questionIdx}].validValues`, validValues);
        };

        const isPersisted = !!question.id;
        const isLocked = !!formik.values.participants && formik.values.participants.length > 0 && isPersisted;

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (
            <Card style={questionIdx === 0 ? { marginTop: 0 } : { marginTop: 10 }} key={questionIdx}>
                <CardContent>
                    <Box {...defaultBoxStyle}>
                        <Box style={{ flex: 1 }}>
                            <Grid container key={questionIdx} spacing={2}>
                                <Grid item xs={3} >
                                    <FormTextField {...formik}
                                        disabled={isLocked}
                                        propName={`questions[${questionIdx}].name`}
                                        label="Namn"
                                        valueGetter={() => question.name ?? ""} />
                                </Grid>
                                <Grid item xs={7} >
                                    <FormTextField {...formik}
                                        propName={`questions[${questionIdx}].description`}
                                        label="Beskrivning"
                                        valueGetter={() => question.description ?? ""} />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormCbxField {...formik}
                                        propName={`questions[${questionIdx}].required`}
                                        label="Tvingande"
                                        valueGetter={() => question.required} />
                                </Grid>
                                <Grid item xs={3}>
                                    <FormSelectField {...formik}
                                        disabled={isLocked}
                                        propName={`questions[${questionIdx}].type`}
                                        label="Typ"
                                        valueGetter={() => question.type ?? ""}
                                        onChange={() => (e: any) => {
                                            setFieldValue(`questions[${questionIdx}].type`, e.target.value);

                                            if (e.target.value !== "choice") {
                                                setFieldValue(`questions[${questionIdx}].validValues`, null)
                                            }
                                        }}
                                    >
                                        {this.fieldTypes.map((fieldType, key) => {
                                            return <MenuItem key={key} value={fieldType}>{fieldType}</MenuItem>;
                                        })}
                                    </FormSelectField>
                                </Grid>
                                {question.type === "choice" &&
                                    <Grid item xs={7} >
                                        <FormTextField {...formik}
                                            propName={`questions[${questionIdx}].validValues`}
                                            label="Värden"
                                            minRows={4}
                                            multiline={true}
                                            valueGetter={() => { return validValuesGetter(); }}
                                            onChange={() => (e: any) => {
                                                validValuesSetter(e.target.value);
                                            }} />
                                    </Grid>
                                }
                            </Grid>
                        </Box>
                        <Box>
                            {questionIdx > 0 &&
                                <ExpandLess style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        arrayHelpers.move(questionIdx, questionIdx - 1);
                                    }}
                                />
                            }
                            {questionIdx < formik.values.questions.length - 1 &&
                                <ExpandMore style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        arrayHelpers.move(questionIdx, questionIdx + 1);
                                    }}
                                />
                            }
                            <IconButton
                                disabled={isLocked}
                                onClick={() => { arrayHelpers.remove(questionIdx); }}>
                                <DeleteIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        )
    };

    private cardTitle = (): string => {
        const { survey } = this.props;

        let desc = survey.name ? survey.name : "Enkät";

        if (survey.description) {
            desc += " - ";
            desc += (survey.description.length > 40) ? survey.description.slice(0, 40 - 1) + '...' : survey.description;
        }

        return desc;
    };

    private addQuestion = (arrayHelpers: FieldArrayRenderProps) => {
        arrayHelpers.push({});
    };

    private addQuestionsFromCompanyProduct = (values: B2bCompanySurvey, arrayHelpers: FieldArrayRenderProps) => {
        const { companyProducts } = this.state;

        const productMatch = companyProducts?.find(x => x.id === values.companyProductId);
        if (!productMatch?.extraFields || productMatch.extraFields.length === 0) {
            return;
        }

        for (let i = 0; i < productMatch.extraFields.length; i++) {
            const item = productMatch.extraFields[i];
            arrayHelpers.push({
                name: item.name,
                description: item.description,
                type: item.type,
                required: item.required,
                validValues: item.validValues ? JSON.parse(item.validValues) : []
            } as B2bCompanySurveyQuestion);
        }
    };

    private handleOpenSurvey = async (values: B2bCompanySurvey) => {
        const { id: companyId } = this.props.values;
        window.open(`${CONFIG.minaSidorUrl}/survey/${companyId}/${values.id}`);
    };
}

export default EditB2bCompanySurveyDialog;
