/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import { Button, Card, CardActionArea, CardContent, CardHeader, FormControl, Grid, InputLabel, LinearProgress, TextField, Theme, WithStyles, createStyles, withStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import uuid from 'react-uuid';
import { B2bCompany } from '../../model/B2bCompany';
import { CodeHolder, isOrderCode } from '../../model/CodeHolder';
import { CodeProductImplementation } from '../../model/CodeProductImplementation';
import { Product } from '../../model/Product';
import { ApiBackend } from '../../providers/apibackend';
import CodeHolderCategoryPicker from '../Common/CodeHolderCategoryPicker';
import CompanySelect from '../Common/CompanySelect';
import CodeProducts from './CodeProducts';
import Codes from './Codes';
import { vasaloppetDateGetterFormatter, vasaloppetDateTimeGetterFormatter, vasaloppetIsoDateSetterFormatter } from '../../utilities/date';
import CodeEntries from './CodeEntries';
import CodeInvoice from './CodeInvoice';
import CodeHolderTypePicker from '../Common/CodeHolderTypePicker';
import withToaster, { IToasterContext } from '../Common/Toaster';

interface Props extends RouteChildrenProps<{ id: string }> {
}

interface State {
    loading: boolean;
    codeHolder: CodeHolder | null;
    haveChanges: boolean;
    products: Product[];
}

class EditCode extends React.Component<WithStyles & IToasterContext & Props, State> {
    state: State;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IToasterContext & Props) {
        super(props);

        this.api = new ApiBackend();

        this.state = {
            loading: true,
            products: [],
            codeHolder: null,
            haveChanges: false
        }
    }

    async componentDidMount(): Promise<void> {
        await this.loadData();
    }

    render() {
        return <div>
            {this.state.loading &&
                <LinearProgress />
            }
            {this.state.codeHolder &&
                this.renderCodeEdit()
            }
        </div>;
    }

    renderCodeEdit() {
        const { classes } = this.props;
        const { codeHolder } = this.state;

        const haveInvalidImplementations = isOrderCode(codeHolder) && codeHolder.Implementations.some(x => x.type === "pricegroup");
        const isSaveDisabled = !this.state.haveChanges || haveInvalidImplementations || (codeHolder.b2bInvoice && !codeHolder.b2bInvoiceDescription);

        return <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={"Hantera: " + codeHolder.name} />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }
                        <form className={classes.form} noValidate autoComplete="off">
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <Grid item xs={12} sm={6}>
                                    <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn" value={codeHolder.name} onChange={this.onChange('name')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink>Företagskoppling</InputLabel>
                                        <CompanySelect
                                            clearable={true}
                                            initialValue={codeHolder.b2bCompany ?? ""}
                                            includeArchived={false}
                                            onClear={() => {
                                                const { codeHolder } = this.state;
                                                codeHolder.b2bCompany = null;
                                                this.setState({ codeHolder: codeHolder, haveChanges: true });
                                            }}
                                            onChange={this.onChange("b2bCompany")}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <CodeHolderCategoryPicker
                                        initialValue={codeHolder.category}
                                        strict={false}
                                        onChange={(value: string) => {
                                            const { codeHolder } = this.state;
                                            codeHolder.category = value;
                                            this.setState({ codeHolder: codeHolder, haveChanges: true });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <TextField type="date"
                                        id="standard-basic"
                                        style={{ flex: 1, margin: '0 3px' }}
                                        value={vasaloppetDateGetterFormatter(codeHolder.ValidFrom)}
                                        onChange={this.onChange('ValidFrom')}
                                        label="Giltig från"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3} >
                                    <TextField type="date"
                                        id="standard-basic"
                                        style={{ flex: 1, margin: '0 3px' }}
                                        value={vasaloppetDateGetterFormatter(codeHolder.ValidTo)}
                                        onChange={this.onChange('ValidTo')}
                                        label="Giltig till"
                                        InputLabelProps={{ shrink: true }}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" minRows={4} multiline={true} label="Beskrivning" value={codeHolder.description ?? ""} onChange={this.onChange('description')} fullWidth />
                                </Grid>
                                <Grid item xs={2}>
                                    <CodeHolderTypePicker
                                        type={this.state.codeHolder.type}
                                        label="Typ"
                                        clearable={false}
                                        onChange={(nextValue) => {
                                            const { codeHolder } = this.state;
                                            codeHolder.type = nextValue;
                                            this.setState({ codeHolder: codeHolder, haveChanges: true });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        disabled={true}
                                        control={<Checkbox checked={!!codeHolder.reuseExistingEntries} name="reuseExistingEntries" />}
                                        label="Återanvänd tomma startplatser"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <FormControlLabel
                                        control={<Checkbox checked={!!codeHolder.b2bInvoice} onChange={this.onChangeChk('b2bInvoice')} name="b2bInvoice" />}
                                        label="Ska faktureras"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <FormControlLabel
                                        control={<Checkbox checked={!!codeHolder.IsArchived} onChange={this.onChangeChk('IsArchived')} name="Arkiverad" />}
                                        label="Arkiverad"
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={codeHolder.createdBy ?? "---"} label="Skapad av" fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={vasaloppetDateTimeGetterFormatter(codeHolder.created, "---")} label="Skapad" fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={codeHolder.updatedBy ?? "---"} label="Uppdaterad av" fullWidth />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField disabled style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={vasaloppetDateTimeGetterFormatter(codeHolder.updated, "---")} label="Uppdaterad" fullWidth />
                                </Grid>

                                {codeHolder.b2bInvoice &&
                                    <Grid item xs={12}>
                                        <CodeInvoice
                                            codeHolder={codeHolder}
                                            onDescriptionChange={this.onChange("b2bInvoiceDescription")}
                                        />
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <CodeProducts
                                        code={codeHolder}
                                        products={this.state.products}
                                        save={(impl: CodeProductImplementation) => {
                                            const c = codeHolder;
                                            if (!c.Implementations) {
                                                c.Implementations = [];
                                            }

                                            if (!impl.id) {
                                                //new
                                                impl.id = uuid();
                                                c.Implementations.push(impl);
                                            }
                                            else {
                                                let idx = c.Implementations.findIndex(x => x.id == impl.id);
                                                c.Implementations.splice(idx, 1, impl);
                                            }
                                            this.setState({ codeHolder: c, haveChanges: true });
                                        }}
                                        delete={(impl: CodeProductImplementation) => {
                                            const c = codeHolder;
                                            let idx = c.Implementations.findIndex(x => x.id == impl.id);
                                            c.Implementations.splice(idx, 1);
                                            this.setState({ codeHolder: c, haveChanges: true });
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {codeHolder &&
                                        <Codes code={codeHolder} codesChanged={this.updateCodesForHolder} />
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    {codeHolder &&
                                        <CodeEntries codeHolder={codeHolder}
                                            onChange={this.updateCodesForHolder}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <CardActionArea component="a">
                        <Button color="secondary" disabled={isSaveDisabled} onClick={this.saveCodeHolder}>Spara ändringar</Button>
                    </CardActionArea>
                </Card>
            </Grid>

        </Grid>;
    }

    private loadData = async () => {
        const work: (Promise<Product[]> | Promise<CodeHolder> | Promise<B2bCompany[]>)[] = [
            this.api.listProducts(true),
            this.api.getCode(this.props.match.params.id)
        ];

        const [products, codeHolder] = await Promise.all(work);

        this.setState({
            products: products as Product[],
            codeHolder: codeHolder as CodeHolder,
            loading: false
        });
    };

    private updateCodesForHolder = async (): Promise<void> => {
        const { codeHolder } = this.state;

        this.setState({ loading: true });
        const response = await this.api.getCode(this.props.match.params.id);

        codeHolder.Codes = response.Codes;
        this.setState({ codeHolder: { ...codeHolder }, loading: false });
    };

    private onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let value = ev.target.value;
        let e = this.state.codeHolder as any;

        if (prop === "ValidFrom") {
            value = vasaloppetIsoDateSetterFormatter(value);
        }

        if (prop === "ValidTo") {
            value = vasaloppetIsoDateSetterFormatter(value, true);
        }

        e[prop] = value;
        this.setState({ codeHolder: e, haveChanges: true });
    };

    private onChangeChk = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var e = this.state.codeHolder as any;
        e[prop] = ev.target.checked;
        this.setState({ codeHolder: e, haveChanges: true });
    };

    private saveCodeHolder = async () => {
        const { showToast } = this.props;

        this.setState({ loading: true });
        const saveResult = await this.api.createOrUpdateCode(this.state.codeHolder);
        if (!saveResult) {
            showToast("Någonting gick fel vid skapande av order", "error");
            this.setState({ loading: false });
            return;
        }

        const nextCodeHolder = await this.api.getCode(this.props.match.params.id);
        this.setState({ haveChanges: false, loading: false, codeHolder: nextCodeHolder });
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(withToaster(EditCode)));
