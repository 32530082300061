import { Product, ProductType } from "../../../model/Product"
import { Event } from "../../../model/Event"
import { groupBy } from "lodash";

type ChangeEventProduct = {
    id: string;
    variantIds?: string[];
};

export type ProductListChangeEvent = {
    products: ChangeEventProduct[];
    selectedIds: string[];
};

export type GroupedProducts = { name: string; products: Product[]; };

const ensureArray = (source: string | string[]) => {
    if (Array.isArray(source)) {
        return source;
    }

    return [source];
};

const getProductSelectionNames = (allProducts: Product[], changeEvent: ProductListChangeEvent): string => {
    const source = ensureArray(changeEvent.products.map(p => p.id));
    return allProducts.filter(p => source.some(x => x === p.id)).map(p => p.name).join(", ");
};

const getProductVariantSelectionNames = (allProducts: Product[], changeEvent: ProductListChangeEvent): string => {
    const ret: string[] = [];

    for (let i = 0; i < allProducts.length; i++) {
        const product = allProducts[i];

        const changeEventProduct = changeEvent.products.find(p => p.id === product.id);
        if (!changeEventProduct) {
            continue;
        }

        if (!product.variants) {
            continue;
        }

        const source = ensureArray(changeEventProduct.variantIds);
        ret.push(...product.variants.filter(pv => source.some(x => x === pv.Id)).map(pv => pv.Name));
    }

    return ret.join(", ");
};

export const buildPresentationValue = (useVariants: boolean, allProducts: Product[], changeEvent: ProductListChangeEvent): string => {
    if (!useVariants) {
        return getProductSelectionNames(allProducts, changeEvent);
    }

    return getProductVariantSelectionNames(allProducts, changeEvent);
};

export const getGroupedProducts = (products: Product[], events: Event[], productTypes?: ProductType[], filterEventId?: string,): GroupedProducts[] => {

    if (!!productTypes) {
        products = products.filter(x => productTypes.some(p => p === x.type));
    }

    if (!!filterEventId) {
        products = products.filter(x => x.EventId === filterEventId);
    }

    const map = new Map(events.map((x) => [x.id, x.name]));
    const groups = groupBy(products, (x) => (!!x.EventId && map.has(x.EventId)) ? map.get(x.EventId) : "Övriga");
    const subHeaders = Object.keys(groups).sort((a, b) => {
        if (b === "Övriga") {
            return -1;
        }

        return a.localeCompare(b, "sv");
    });

    return subHeaders.map(s => {
        return {
            name: s,
            products: groups[s].sort((a, b) => a.name.localeCompare(b.name, "sv"))
        }
    });
};

export const buildChangeEvent = (useVariants: boolean, products: Product[], selectedIds: string[]): ProductListChangeEvent => {
    let changeEventProducts: ChangeEventProduct[] = [];
    if (!useVariants) {
        changeEventProducts = selectedIds.map(x => {
            return { id: x };
        });
    } else {
        for (let i = 0; i < products.length; i++) {
            const p = products[i];
            if (!p.variants?.some(pv => selectedIds.some(v => v === pv.Id))) {
                continue;
            }

            changeEventProducts.push({
                id: p.id,
                variantIds: p.variants.filter(pv => selectedIds.some(v => v === pv.Id)).map(pv => pv.Id)
            });
        }
    }

    return {
        products: changeEventProducts,
        selectedIds: selectedIds
    };
};

export const getInitialSafeValue = (source: string | string[]): string[] => {
    let initialValue = source ?? [];
    if (!Array.isArray(initialValue)) {
        initialValue = [initialValue];
    }

    return initialValue;
};
