import { Entry } from "./Entry";
import { Product } from "./Product";

export class OrderItem {
    Id: string;
    numberOfProducts: number;
    productId: string;
    productVariantId?: string;
    productVariantPriceGroupId?: string;
    product?: Product;
    metadata?: { key: string, value: string }[];
    itemPrice: number;
    originalItemPrice?: number;
    productName: string;
    variantName: string;
    occationName: string;
    priceGroupName: string;
    productVariantOccationId?: string;
    entry: Entry;
    reverted: boolean;
    revertedWhen: Date;
    revertedBy: string;
    revertedReason: string;
    code?: string;
}