/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

 import React, { Fragment } from 'react';
 import { withRouter, RouteChildrenProps } from 'react-router-dom';
 import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Container, Link, Dialog, DialogContent } from '@material-ui/core';
 import DeleteIcon from '@material-ui/icons/Delete';
 import EditIcon from '@material-ui/icons/Edit';
 import AddIcon from '@material-ui/icons/AddBox';
 import { Reservation } from '../../model/Reservation';
import { cloneDeep } from 'lodash';
import { CodeHolder } from '../../model/CodeHolder';
import ReservationFormEditItem from './ReservationFormEditItem';
import { Product } from '../../model/Product';
import uuid from 'react-uuid';
 
 interface Props extends RouteChildrenProps<{id: string}> {
     reservation : Reservation;
     products: Product[];
     save : (reservation: Reservation) => void;
     abortEdit: () => void;
 }
 
 interface State {
     reservation : Reservation;
     editItem: CodeHolder;
     haveChanges: boolean;
 }
 
 class ReservationForm extends React.Component<WithStyles & Props, State> {
  
     constructor(props: WithStyles & Props) {
         super(props);
         this.state={reservation: cloneDeep(props.reservation), editItem:null, haveChanges:false};
     }
 
     onChange = (prop: string) => (ev : React.ChangeEvent<HTMLInputElement>) => 
     {
         var e = this.state.reservation as any;
         e[prop] = ev.target.value;
         this.setState({reservation: e, haveChanges: true});
     }
 
     render() {
              return <div>
                 {this.state.reservation &&
                     this.renderEditReservation()
                 }
             </div>;
     }

     save = () => {
        this.props.save(this.state.reservation);
     }

     getRaceName(id: string) {
        let p = this.props.products.filter(x=>x.id==id);
        if (p && p.length > 0)
        {
            return p[0].name;
        }
        return "";
    }

    getItemUsage(code: CodeHolder)
    {
        if (!code || !code.Codes || code.Codes.length==0)
        { return "0/0"}
        else 
        {
            let numAvailable=0;
            let numUsed=0;
            for (let i = 0; i < code.Codes.length;i++)
            {
                numAvailable += code.Codes[i].maxUsages;
                numUsed += code.Codes[i].numUsages;
            }
            return numUsed + "/" + numAvailable;
        }
    }

     renderEditReservation() {
         let { classes } = this.props;
         return <Card>
                 <CardHeader className={classes.cardHeader} title={"Reservation: " + this.state.reservation.name} />
                 <CardContent>
                     <form className={classes.form} noValidate autoComplete="off">
                         <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                             <Grid item xs={12}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Namn" value={this.state.reservation.name} onChange={this.onChange('name')} fullWidth />
                             </Grid>
                             <Grid item xs={12}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Ansvarig" value={this.state.reservation.responsible} onChange={this.onChange('responsible')} fullWidth />
                             </Grid>
                             <Grid item xs={12}>
                                 <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Beskrivning" value={this.state.reservation.description} onChange={this.onChange('description')} fullWidth />
                             </Grid>
                        
                        <Grid item xs={12}>
                        <Card style={{width:'100%'}}>
                            <CardHeader className={classes.cardHeader} title={<Fragment>{"Loppreservationer"}<AddIcon onClick={() => {
                                let ch = {name: ''} as CodeHolder;
                                this.setState({editItem: ch });
                            }}  style={{marginLeft:10, cursor:'pointer', verticalAlign:'middle'}}/></Fragment>} />
                            <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid className={classes.gridHeader} item xs={2}>
                                            <b>Lopp</b>
                                        </Grid>
                                        <Grid className={classes.gridHeader} item xs={2}>
                                            <b>Startled</b>
                                        </Grid>
                                        <Grid className={classes.gridHeader} item xs={2}>
                                            <b>Giltig till</b>
                                        </Grid>
                                        <Grid className={classes.gridHeader} item xs={2}>
                                            <b>Antal nyttjade</b>
                                        </Grid>
                                        <Grid className={classes.gridHeader} item xs={2}>
                                            <b>Prisgrupp</b>
                                        </Grid>
                                        <Grid className={classes.gridHeader} item xs={2}>
                                        </Grid>

                                        {this.state.reservation && this.state.reservation.items && this.state.reservation.items.map((item) => {
                                            return <Fragment>
                                                <Grid item xs={2}>
                                                    {this.getRaceName(item.name)}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    Alla
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {item.ValidTo}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {this.getItemUsage(item)}
                                                </Grid>
                                                <Grid item xs={2}>
                                                    
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <EditIcon style={{cursor:'pointer'}} onClick={() => {this.setState({editItem: item})}}/>
                                                    <DeleteIcon style={{cursor:'pointer'}} onClick={() => {}}/>
                                            </Grid>
                                            </Fragment>;
                                        })}

                                    </Grid>
                            </CardContent>
                         </Card>
                         </Grid>
                        </Grid>
                        {this.state && this.state.editItem &&
                    <Dialog
                    disableEscapeKeyDown
                    maxWidth="lg"
                    aria-labelledby="confirmation-dialog-title"
                    open={true}
                >
                    <DialogContent dividers>
                        <ReservationFormEditItem products={this.props.products} item={this.state.editItem} save={(item: CodeHolder)=>{
                            let res = this.state.reservation;
                            if (!res.items)
                            {
                                res.items =[];
                            }
                            if (!item.id)
                            {
                                item.id = uuid();
                            }
                            let existing = this.state.reservation.items.filter(x=>x.id == item.id);
                            if (existing && existing.length > 0)
                            {
                                existing[0].name = item.name;
                                existing[0].ValidTo = item.ValidTo;
                            }
                            else 
                            {
                                res.items.push(item);
                            }
                            this.setState({editItem:null, reservation: res, haveChanges:true});
                        }} abortEdit={() => {
                            this.setState({editItem:null});
                        }} />
                    </DialogContent>
                    </Dialog>
                }
                     </form>
                 </CardContent>
                 <CardActionArea component="a">
                     <Button color="secondary" disabled={!this.state.haveChanges || !this.state.reservation.name} variant="contained" onClick={this.save}>{this.state.reservation.id ? "Spara" : "Skapa"}</Button>
                     <Button style={{marginLeft: 10}} color="secondary" variant="contained" onClick={this.props.abortEdit}>Avbryt</Button>
                 </CardActionArea>
             </Card>;
     }
    }
 
 const useStyles = ({ palette, spacing }: Theme) => createStyles({
     cardHeader: {
         background: palette.secondary.main,
         color: palette.secondary.contrastText,
         padding: 3
     },
     photo: {
         height: '30px',
         verticalAlign: 'middle',
         borderRadius: '10px'
     },
     root: {
 
     },
     form: {
         '& > *': {
             margin: spacing(1),
             width: '25ch',
         },
         '& label.Mui-focused': {
             color: palette.secondary.main,
         },
         '& .MuiInput-underline:after': {
             borderBottomColor: palette.secondary.main,
         },
     }
 }
 );
 
 
 export default withStyles(useStyles)(withRouter(ReservationForm));