import React from 'react';
import { MenuItem } from '@material-ui/core';
import { FormikErrors, FormikProps, FormikTouched } from 'formik';
import FormSelectField from './FormSelectField';
import { Countries, ICountryCode } from '../../providers/countries';

interface IProps extends FormikProps<any> {
    propName: string;
    label: string;
    errors: FormikErrors<any>;
    touched: FormikTouched<any>;
    disabled?: boolean;
    values: any;
    handleBlur: {
        (e: React.FocusEvent<any>): void;
        <T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
    };
    handleChange: {
        (e: React.ChangeEvent<any>): void;
        <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    };
}

class FormCountryPickerDropdown extends React.Component<IProps> {
    private readonly allCountries: ICountryCode[];

    constructor(props: IProps) {
        super(props);
        
        this.allCountries = Countries.getCountries();
    }

    render() {
        const {propName, label} = this.props;

        return (
            <FormSelectField {...this.props}
                propName={propName}
                label={label}
                disabled={this.props.disabled}
            >
                <MenuItem key="-1" value="SWE">Sverige</MenuItem>
                <MenuItem key="-2" value="NOR">Norge</MenuItem>
                <MenuItem key="-3" value="FIN">Finland</MenuItem>
                <MenuItem key="-4" value="DNK">Danmark</MenuItem>
                <MenuItem value="">----</MenuItem>
                {this.allCountries.map((country, abbr) => (
                    <MenuItem key={abbr} value={country.abbr}>
                    {country.name}
                    </MenuItem>
                ))}
            </FormSelectField>
        )
    }
}

export default FormCountryPickerDropdown;
