import React from "react";
import { SupportTicketNote } from "../../../model/SupportTicket";
import { Card, CardHeader, CardContent, Grid, TextField, CardActions, Button } from "@material-ui/core";

interface IProps {
    onChange: (note: SupportTicketNote) => void;
}

interface IState {
    value: string;
}

class NotePopover extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            value: undefined
        };
    }

    render() {
        const { value } = this.state;
        const saveEnabled = !!value;

        return (
            <Card>
                <CardHeader title="Lägg till anteckning" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="off"
                                label="Anteckning"
                                style={{ flex: 1, margin: "0 3px" }}
                                InputLabelProps={{ shrink: true }}
                                value={value ?? ""}
                                fullWidth
                                onChange={this.onChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant="contained" disabled={!saveEnabled} onClick={this.handleSave}>Ok</Button>
                </CardActions>
            </Card>
        )
    }

    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ value: e.target.value });
    }

    private handleSave = () => {
        const { value } = this.state;

        const note: SupportTicketNote = {
            message: value
        } as any;

        this.props.onChange(note);
    }
}

export default NotePopover;
