import React, {Fragment, useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import { ExternalSeedingRace } from '../../model/ExternalSeedingRace';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Checkbox, FormControlLabel, LinearProgress } from '@material-ui/core';
import { ApiBackend } from '../../providers/apibackend';
import { ExternalSeedingResult } from '../../model/ExternalSeedingResult';
import { SeedingTable } from '../../model/SeedingTable';
import { Product } from '../../model/Product';

class Props {
    externalSeedingRace : ExternalSeedingRace;
    close: () => void;
}


const ExternalSeedingRaceShowResults = ({externalSeedingRace, close} : Props) => {

    const [results, setResults] = useState(null as ExternalSeedingResult[]);
    const [seedingTables, setSeedingTables] = useState(null as SeedingTable[]);
    const [compareSeedingTable, setCompareSeedingTable] = useState(null as string);

    useEffect(() => {
        let backend = new ApiBackend();
        Promise.all([backend.listProducts(),backend.listSeedingTables(), backend.getExternalSeedingResults(externalSeedingRace.year,externalSeedingRace.id)]).then((values: [Product[], SeedingTable[], ExternalSeedingResult[]]) => {
            let tablesToAdd = [] as SeedingTable[];
            const resTables = values[1];
            const resProducts = values[0];
            const results = values[2];
            setResults(results);
            for (let i = 0; i < resTables.length; i++)
            {
                if (resTables[i].races.some(x=>x.raceId == externalSeedingRace.id))
                {
                    //Does this table have this race?
                    tablesToAdd.push(resTables[i]);
                }
            }
            setSeedingTables(tablesToAdd);
            if (tablesToAdd.length > 0)
            {
                setCompareSeedingTable(tablesToAdd[0].id);
            }
        });

    },[]);


    const compareTable = compareSeedingTable ? seedingTables.find(x=>x.id == compareSeedingTable) : null;
        const compareRace = compareTable ? compareTable.races.find(x=>x.raceId == externalSeedingRace.id) : null;

        const getCalculatedGroup = (res : ExternalSeedingResult) => {

                if (!compareRace && res.resultMs > 0)
                {
                    return null;
                }

                let group = null;
                for (let i = compareRace.startGroupLimits.length-1; i >= 0; i--)
                {
                    if (res.resultMs < compareRace.startGroupLimits[i])
                    {
                        group = i;
                    }
                }

                return group;
        };

        return <Grid container spacing={2}>
                <Grid item xs={12}>Visa led i tabell <Select native value={compareSeedingTable} onChange={(ev) => {setCompareSeedingTable(ev.target.value as string)}}>
                    <option value=""></option>
                    {seedingTables && seedingTables.map((x) => {
                        return <option value={x.id}>{x.name}</option>
                    })}
                    </Select></Grid>
                        <Grid item xs={2}><b>VASA-ID</b></Grid>
                        <Grid item xs={3}><b>Namn</b></Grid>
                        <Grid item xs={3}><b>Resultat</b></Grid>
                        <Grid item xs={2}><b>Ger led enligt tabell</b></Grid>
                        <Grid item xs={2}></Grid>
                
                    {results && results.map((r,idx) => {
                        let bg = idx % 2 == 0 ? "" : "#EEEEEE";
                        
                        return <>
                        <Grid style={{background: bg}} item xs={2}>{r.vasaId}</Grid>
                        <Grid style={{background: bg}} item xs={3}>{r.firstName} {r.lastName}</Grid>
                        <Grid style={{background: bg}} item xs={3}>{r.result}</Grid>
                        <Grid style={{background: bg}} item xs={2}>{getCalculatedGroup(r)}</Grid>
                        <Grid style={{background: bg}} item xs={2}></Grid>
                        </>
                    })}
            </Grid>;


}
export default ExternalSeedingRaceShowResults;
