import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { MismatchingOrderPaymentSum } from "../../model/DashboardReports/MismatchingOrderPaymentSum";
import { ApiBackend } from "../../providers/apibackend";
import LinearProgress from "@material-ui/core/LinearProgress";
import ManageOrder from "../Orders/ManageOrder";
import Refresh from "@material-ui/icons/Refresh";
import { vasaloppetDateTimeGetterFormatter } from "../../utilities/date";

interface IProps extends WithStyles {
}
interface IState {
    resultingOrders: MismatchingOrderPaymentSum[];
    loading: boolean;
    editOrder: string;
}

class OrdersWithPaiedMismatchCard extends React.Component<IProps, IState> {
    state: IState;

    constructor(props: IProps) {
        super(props);

        this.state = {
            resultingOrders: null,
            loading: true,
            editOrder: null
        };
    }
    componentDidMount(): void {
        this.updateList();
    }

    updateList() {
        this.setState({ loading: true }, () => {
            let api = new ApiBackend();
            api.getOrdersWithWrongPaymentSum().then((data) => {
                this.setState({ resultingOrders: data, loading: false });
            });
        });
    }

    openOrder = (id: string) => {
        this.setState({ editOrder: id });
    }



    render(): JSX.Element {
        const { classes } = this.props;
        return (<>
            {this.state.editOrder &&
                <ManageOrder close={() => { this.setState({ editOrder: null }, () => { this.updateList(); }) }} orderId={this.state.editOrder} />
            }
            <Card>
                <CardHeader className={classes.cardHeader} title={<span>Orderavvikelser <Refresh onClick={() => { this.updateList() }} /></span>} />
                <CardContent>
                    Order där ordersumma inte matchar betalt belopp

                    {this.state.loading && <LinearProgress color="secondary" />
                    }
                    {this.state.resultingOrders &&
                        <Grid container>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>ID</Grid>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>Summa</Grid>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>Betalt</Grid>
                            <Grid item xs={3} style={{ fontWeight: 'bold' }}>Datum</Grid>
                            {this.state.resultingOrders.map((o, idx) => {
                                return <Fragment key={"item_" + idx}>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}><a style={{ textDecoration: 'underline' }} href="#" onClick={() => { this.openOrder(o.id) }}>{o.publicId}</a></Grid>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>{o.totalSum}</Grid>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>{o.paiedAmount}</Grid>
                                    <Grid item xs={3} style={{ fontSize: 12, background: idx % 2 == 0 ? '' : '#efefef' }}>{vasaloppetDateTimeGetterFormatter(o.orderDate)}</Grid>
                                </Fragment>
                            })}
                        </Grid>
                    }
                </CardContent>
            </Card>
        </>);
    }
}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(OrdersWithPaiedMismatchCard);
