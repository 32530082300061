import React, { useEffect, useState } from "react";
import { GroupedProducts, ProductListChangeEvent, buildChangeEvent } from "./utils";
import { List, ListSubheader, MenuItem, Typography } from "@material-ui/core";
import { Product } from "../../../model/Product";
import { ProductVariant } from "../../../model/ProductVariant";

interface ProductListPopoverItemsProps {
    groupedProducts: GroupedProducts[];
    useVariants: boolean;
    autoSelectFirst: boolean;
    multiple: boolean;
    initialValue?: string | string[];

    onChange: (evt: ProductListChangeEvent) => void;
}

const ProductListPopoverItems = (props: ProductListPopoverItemsProps) => {
    const [value, setValue] = useState([] as string[]);

    useEffect(() => {
        let nextValue = props.initialValue ?? [];

        if (!Array.isArray(nextValue)) {
            nextValue = [nextValue];
        }

        setValue(nextValue);
    }, [props.initialValue]);

    const toggleProducts = (s: { name: string; products: Product[]; }) => {
        if (!props.multiple) {
            return;
        }

        let nextValue: string[] = [];
        let ids: string[] = [];

        if (props.useVariants) {
            s.products.forEach(p => {
                const pVariantIds = p.variants?.map(v => v.Id);
                ids.push(...pVariantIds);
            });
        } else {
            ids = s.products.map(p => p.id);
        }

        if (ids.every(p => value.includes(p))) {
            nextValue = value.filter((p) => !ids.includes(p));
        } else {
            const toAdd = ids.filter((p) => !value.includes(p));
            nextValue = [...value, ...toAdd];
        }

        setValue(nextValue);
        notifyChange(nextValue);
    };

    const toggleVariants = (p: Product) => {
        if (!props.multiple) {
            return;
        }

        if (!p.variants) {
            return;
        }

        let nextValue: string[] = [];
        const variantIds = p.variants.map(v => v.Id);
        if (variantIds.every(v => value.includes(v))) {
            nextValue = value.filter((v) => !variantIds.includes(v));
        } else {
            const toAdd = variantIds.filter((v) => !value.includes(v));
            nextValue = [...value, ...toAdd];
        }

        setValue(nextValue);
        notifyChange(nextValue);
    };

    const toggleItem = (id: string) => {
        let nextValue: string[] = [];

        if (value.includes(id)) {
            nextValue = value.filter(v => v !== id);
        } else {
            nextValue = [...value, id];
        }

        setValue(nextValue);
        notifyChange(nextValue);
    };

    const notifyChange = (ids: string[]) => {
        const { useVariants, groupedProducts, onChange } = props;

        const products = groupedProducts.map(g => g.products).flat();
        onChange(buildChangeEvent(useVariants, products, ids));
    };

    const isItemSelected = (id: string) => {
        return value.some(x => x === id);
    };

    const buildItems = (): JSX.Element[] => {
        const { groupedProducts } = props;
        const defaultLeftMargin = 10;
        const defaultMargin = "0.2rem";

        const getVariantTypography = (product: Product, variant: ProductVariant): JSX.Element => {
            return (
                <Typography variant="body2"
                    style={{ display: "block", width: "100%" }}
                    onClick={() => {
                        toggleItem(variant.Id);
                    }}>
                    {!!variant.Name ? variant.Name : <em>{`${product.name} (Variant saknar namn)`}</em>}
                </Typography>
            );
        };

        const getOptions = (products: Product[]) => {
            let options: any[];

            if (props.useVariants) {
                options = products.map(p => {
                    if (!p.variants || p.variants.length === 0) {
                        return null;
                    }

                    if (p.variants?.length === 1) {
                        return (
                            <MenuItem id={`${p.id}_${p.variants[0].Id}`} key={p.variants[0].Id} value={p.variants[0].Id} style={{ marginTop: defaultMargin, marginBottom: defaultMargin, marginLeft: defaultLeftMargin }} selected={isItemSelected(p.variants[0].Id)}>
                                {getVariantTypography(p, p.variants[0])}
                            </MenuItem>
                        );
                    }

                    const innerOptions = p.variants?.map(v => {
                        return (
                            <MenuItem id={`${p.id}_${v.Id}`} key={v.Id} value={v.Id} style={{ marginTop: defaultMargin, marginBottom: defaultMargin, marginLeft: defaultLeftMargin + 15 }} selected={isItemSelected(v.Id)}>
                                {getVariantTypography(p, v)}
                            </MenuItem>
                        );
                    });

                    return [
                        <ListSubheader key={p.id} disableSticky style={{ marginBottom: defaultMargin, marginTop: "0.5rem", marginLeft: defaultLeftMargin, cursor: props.multiple ? "pointer" : "default" }}>
                            <Typography variant="body1"
                                style={{ display: "block", width: "100%" }}
                                onClick={(e) => {
                                    toggleVariants(p);
                                }}
                            >
                                {p.name}
                            </Typography>
                        </ListSubheader>,
                        innerOptions
                    ];
                });
            } else {
                options = products.map(p => {
                    return (
                        <MenuItem key={p.id} value={p.id} style={{ marginTop: defaultMargin, marginBottom: defaultMargin, marginLeft: defaultLeftMargin }} selected={isItemSelected(p.id)}>
                            <Typography variant="body2"
                                style={{ display: "block", width: "100%" }}
                                onClick={() => {
                                    toggleItem(p.id);
                                }}
                            >
                                {p.name}
                            </Typography>
                        </MenuItem>
                    );
                });
            }

            return options.filter(Boolean);
        };

        const listItems = groupedProducts.map((s, idx) => {
            const marginTyp = idx > 0 ? "0.5rem" : undefined;
            return [
                <ListSubheader key={s.name} disableSticky disableGutters style={{ marginTop: marginTyp, marginBottom: defaultMargin, cursor: props.multiple ? "pointer" : "default" }}>
                    <Typography variant="body1"
                        style={{ display: "block", width: "100%" }}
                        onClick={(e) => {
                            toggleProducts(s);
                        }}
                    >
                        {s.name}
                    </Typography>
                </ListSubheader>,
                getOptions(s.products)
            ];
        });

        return listItems as any;
    };

    const render = () => {
        return (
            <List disablePadding>
                {buildItems()}
            </List>

        );
    };

    return render();
};

export default ProductListPopoverItems;
