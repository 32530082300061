import React, { PropsWithChildren } from "react";
import { Box, FormControl, FormLabel } from "@material-ui/core";

type VasaloppetFieldsetProps = { title: string; style?: React.CSSProperties } & PropsWithChildren<{}>;

const VasaloppetFieldset = ({ title, style, children }: VasaloppetFieldsetProps) => {

    const render = () => {
        const defaultStyle: React.CSSProperties = { borderRadius: 2, padding: 5, border: "1px solid rgba(0, 0, 0, 0.12)" };

        return (
            <FormControl component="fieldset" style={{ ...defaultStyle, ...style }}>
                <FormLabel component="legend" style={{ marginLeft: 10, transform: "scale(0.75)" }}>
                    {title}
                </FormLabel>
                <Box style={{ marginLeft: 4 }}>
                    {children}
                </Box>
            </FormControl>
        );
    };

    return render();
};

export default VasaloppetFieldset;
