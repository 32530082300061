import { Entry } from "../../../../model/Entry";
import { Order } from "../../../../model/Order";
import { ProductVariant } from "../../../../model/ProductVariant";
import { ProductVariantPriceGroup } from "../../../../model/ProductVariantPriceGroup";
import { IValidateCodeResponse } from "../../../../providers/models";
import { isTodayOrAfter, isTodayOrBefore, vasaloppetMoment } from "../../../../utilities/date";

const awaiter = (ms: number): Promise<void> => {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, ms);
    });
};

const isSwishInputValid = (order: Order): boolean => {
    const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    return new RegExp(phoneRegExp).test(order.contact.phone);
};

const getInitPriceGroup = (variant: ProductVariant): ProductVariantPriceGroup | undefined => {
    return variant.priceGroups
        .find(x => isTodayOrBefore(x.validFrom) && isTodayOrAfter(x.validTo))
};

const priceGroupValidForEntryByAgeFilter = (pg: ProductVariantPriceGroup, raceStartDate: string | Date, entry: Entry) => {
    if (!isTodayOrBefore(pg.validFrom) || !isTodayOrAfter(pg.validTo)) {
        return false;
    }

    if (!pg.maxAge || pg.maxAge > 200) {
        return true;
    }

    const entryAge = vasaloppetMoment(raceStartDate).year() - entry.dateOfBirthYear;
    return entryAge <= pg.maxAge;
};

const getInitPriceGroupWithMaxAge = (raceStartDate: string | Date, variant: ProductVariant, entry: Entry): ProductVariantPriceGroup | undefined => {
    const maxAgePriceGroups = variant.priceGroups.filter(x => priceGroupValidForEntryByAgeFilter(x, raceStartDate, entry));

    return !!maxAgePriceGroups ? maxAgePriceGroups[0] : undefined;
};

const isValidCode = (validationResult: IValidateCodeResponse): false | "ORDER" | "ORDER_ITEM" => {
    if (!validationResult) {
        return false;
    }

    if (validationResult.valid) {
        return "ORDER_ITEM";
    }

    return validationResult.reason === "ORDER_ITEM" ? "ORDER" : false;
}

export { awaiter, isSwishInputValid, getInitPriceGroup, getInitPriceGroupWithMaxAge, isValidCode };
