import React, { useState } from "react";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Alert from "@material-ui/lab/Alert/Alert";

type Severity = "success" | "info" | "warning" | "error";

export interface IToasterContext {
    showToast: (message: string, severity?: Severity, duration?: number) => void;
}

const withToaster = <P extends {}>(
    WrappedComponent: React.ComponentType<P & IToasterContext>
) => {
    return (props: Omit<P, keyof IToasterContext>) => {
        const [message, setMessage] = useState("");
        const [severity, setSeverity] = useState("success" as Severity);
        const [duration, setDuration] = useState(5000);
        const [open, setOpen] = useState(false);

        const showToast = (message: string, severity?: Severity, duration?: number) => {
            setMessage(message);

            if (severity) {
                setSeverity(severity);
            }

            if (duration) {
                setDuration(duration);
            }

            setOpen(true);
        };

        const handleClose = (event: any) => {
            setOpen(false);
        };

        return (<>
            <WrappedComponent {...props as P} showToast={showToast} />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                autoHideDuration={duration}
                open={open}
                onClose={handleClose}
            >
                <Alert variant="filled" onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </>);
    };
};

export default withToaster;
