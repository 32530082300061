import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { ApiBackend } from "../../providers/apibackend";
import LinearProgress from "@material-ui/core/LinearProgress";
import Refresh from "@material-ui/icons/Refresh";
import EditEntryDialog from "../Entries/Entry/EditEntryDialog";
import { Entry } from "../../model/Entry";

interface IProps extends WithStyles {
}
interface IState {
    result: Entry[];
    loading: boolean;
    editEntry: string;
}

class EntriesWithWrongClub extends React.Component<IProps, IState> {
    state: IState;

    constructor(props: IProps) {
        super(props);
       
        this.state = {
            result: null,
            loading: true,
            editEntry: null
        };
    }
    componentDidMount(): void {
        this.updateList();
    }

    updateList() {
        this.setState({ loading: true }, () => {
            let api = new ApiBackend();
            api.getEntriesWithWrongClub().then((data) => {
                this.setState({ result: data, loading: false });
            });
        });
    }

    openEntry = (id: string) => {
        this.setState({editEntry: id});
    }



    render(): JSX.Element {
        const { classes } = this.props;
        return (<>
            {this.state.editEntry && 
            <EditEntryDialog  onAbortEdit={() => {this.setState({editEntry: null}, () => {this.updateList();})}} 
            onSave={() => {this.setState({editEntry: null}, () => {this.updateList();})}} 
             entryId={this.state.editEntry} />
            }
                <Card>
                    <CardHeader className={classes.cardHeader} title={<span>Felaktiga klubbar <Refresh onClick={() => {this.updateList()}}/></span>} />
                    <CardContent>
                        Anmälningar till tävlingslopp där klubben är felaktig
                    
                    {this.state.loading && <LinearProgress color="secondary"/>
                    }
                    {this.state.result &&
                    <Grid container>
                        <Grid item xs={4} style={{fontWeight:'bold'}}>Namn</Grid>
                        <Grid item xs={4} style={{fontWeight:'bold'}}>Lopp</Grid>
                        <Grid item xs={4} style={{fontWeight:'bold'}}>Angiven klubb</Grid>
                        {this.state.result.map((o, idx) => {
                            return <Fragment key={idx}>
                            <Grid item xs={4} style={{fontSize:12, background: idx%2==0 ? '' : '#efefef'}}><a style={{textDecoration:'underline'}} href="#" onClick={()=> {this.openEntry(o.id)}}>{o.firstName + " " + o.lastName}</a></Grid>
                            <Grid item xs={4} style={{fontSize:12, background: idx%2==0 ? '' : '#efefef'}}>{o.eventKey}</Grid>
                            <Grid item xs={4} style={{fontSize:12, background: idx%2==0 ? '' : '#efefef'}}>{o.clubName}</Grid>
                            </Fragment>
                        })}
                    </Grid>
                    }
                    </CardContent>
                </Card>
        </>);
    }
}


const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(EntriesWithWrongClub);
