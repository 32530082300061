import React, { useState } from "react";
import { Person } from "../../../../model/Person";
import PersonSearchDialog from "../../Person/PersonSearchDialog";
import AddEntryDialog from "../AddEntryDialog/AddEntryDialog";
import { Button } from "@material-ui/core";
import EditPersonDialog from "../../Person/EditPersonDialog";
import { RaceProductType } from "../../../../model/Product";

interface IProps {
    restrictedProductType?: RaceProductType;
    onComplete: () => void;
    onAbort: () => void;
}

const CreateEntryDialog = (props: IProps) => {
    const [showSearchChangePerson, setShowSearchChangePerson] = useState(true);
    const [showCreateChangePerson, setShowCreateChangePerson] = useState(false);
    const [targetPerson, setTargetPerson] = useState(null as Person);

    const handleOnPersonSelect = async (value: Person) => {
        setShowSearchChangePerson(false);
        setShowCreateChangePerson(false);
        setTargetPerson(value);
    };

    const handleOnSave = async () => {
        props.onComplete();
    };

    const render = () => {
        if (!targetPerson && showSearchChangePerson) {
            return (
                <PersonSearchDialog
                    title="Välj person att skapa anmälan för"
                    action={
                        <Button variant="text" color="secondary"
                            onClick={() => {
                                setShowSearchChangePerson(false);
                                setShowCreateChangePerson(true);
                            }}
                        >
                            Skapa ny person
                        </Button>
                    }
                    onAbort={props.onAbort}
                    onPersonSelect={handleOnPersonSelect}
                />
            );
        }

        if (!targetPerson && showCreateChangePerson) {
            return (
                <EditPersonDialog
                    onAbortEdit={() => {
                        setShowCreateChangePerson(false);
                        setShowSearchChangePerson(true);
                    }}
                    onSave={handleOnPersonSelect}
                />
            );
        }

        if (targetPerson) {
            return (
                <AddEntryDialog
                    personId={targetPerson.id}
                    restrictedProductType={props.restrictedProductType}
                    onAbort={props.onAbort}
                    onSave={handleOnSave}
                />
            )
        }

        return null;
    };

    return render();
};

export default CreateEntryDialog;
