import { Order } from "../../../model/Order";
import { OrderItem } from "../../../model/OrderItem";
import { ProductType } from "../../../model/Product";

export const canCreateEntryAllowanceOrderItem = (order: Order, orderItem: OrderItem): boolean => {
    if (!order) {
        return false;
    }

    if (!orderItem) {
        return false;
    }

    const allowedProductTypes: ProductType[] = ["race", "massage", "skipass"];
    if (!allowedProductTypes.some(x => x === orderItem.product.type)) {
        return false;
    }

    if (orderItem.product.type === "race" && (!orderItem.entry || orderItem.entry.status !== "ACTIVE")) {
        return false;
    }

    return order.Status === "Completed" && !orderItem.reverted;
};

export const canCreateEntryAllowanceOrder = (order: Order): boolean => {
    if (!order) {
        return false;
    }

    if (!order.items || order.items.length === 0) {
        return false;
    }

    return order.items.some((oi) => canCreateEntryAllowanceOrderItem(order, oi));
};

export const filterCreateableEntryAllowanceOrderItems = (order: Order): OrderItem[] => {
    if (!order) {
        return [];
    }

    if (!order.items || order.items.length === 0) {
        return [];
    }

    return order.items.filter((oi) => canCreateEntryAllowanceOrderItem(order, oi));
};
