import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import React, { useState } from "react";

export interface IAlertContext {
    showAlert: (title: string, message: string) => void;
}

const withAlert = <P extends {}>(
    WrappedComponent: React.ComponentType<P & IAlertContext>
) => {
    return (props: Omit<P, keyof IAlertContext>) => {
        const [title, setTitle] = useState("Fel");
        const [message, setMessage] = useState(null as string);
        const [open, setOpen] = useState(false);

        const showAlert = (newTitle: string, newMessage: string) => {
            setMessage(newMessage);
            if (newTitle) {
                setTitle(newTitle);
            }

            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        return (<>
            <WrappedComponent {...props as P} showAlert={showAlert} />
            <Dialog
                open={open}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained" color="primary" autoFocus>
                        Stäng
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    };
};

export default withAlert;
