import * as yup from "yup";

const mandatoryMessage = "Obligatoriskt";

const editB2bCompanyCustomProductsValidationSchema = yup
    .object()
    .shape(
        {
            "name": yup
                .string()
                .required(mandatoryMessage),
            "description": yup
                .string()
                .notRequired()
        }
    );

export default editB2bCompanyCustomProductsValidationSchema;
