export const SupportTicketTypes = ["company_account_request", "company_account_change_request"] as const;
export type SupportTicketType = typeof SupportTicketTypes[number];
export const SupportTicketStatuses = ["new", "started", "closed", "archived"];
export type SupportTicketStatus = typeof SupportTicketStatuses[number];
export const SupportTicketTypeLookup: {[key: string]: string} = {
    "company_account_request": "Skapa företagskonto", 
    "company_account_change_request": "Ändring av företagsuppgifter"
}

export const SupportTicketStatusLookup: {[key: string]: string} = {
    "new": "Ny",
    "started": "Påbörjad",
    "closed": "Stängd",
    "archived": "Arkiverad"
}

export class SupportTicket {
    id: string;
    type: SupportTicketType;
    b2bCompanyId?: string;
    b2bCompanyAdminId?: string;
    personId?: string;
    subject: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail?: string;
    contactPhone?: string;
    message: string;
    status: SupportTicketStatus;
    created?: Date;

    // Virtual-bag-ish
    notes?: SupportTicketNote[];
}

export class SupportTicketNote {
    id: string;
    supportTicketId: string;
    message: string;
    created: Date;
}

export interface IUpdateSupportTicketRequest {
    id: string;
    newStatus: SupportTicketStatus;
    notes?: string[];
}