/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import { Card, CardHeader, CardContent, Theme, withStyles, createStyles, Grid, LinearProgress, Typography, Button, FormControl, Select } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { DataGrid, GridColDef as ColDef } from '@material-ui/data-grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import { ReportTrippel, ReportTrippelParticipant } from '../../model/ReportTrippelParticipants';
import { DATE_FORMAT, vasaloppetMoment } from '../../utilities/date';

export type trippeltypes = 'trippel30' | 'trippel45' | 'trippel90' | 'noRunTrippel30' | 'noRunTrippel45' | 'noRunTrippel90' | 'noBikeTrippel30' | 'noBikeTrippel45' | 'noBikeTrippel90';

interface State {
    loading: boolean;
    trippelReport: ReportTrippel,
    view: trippeltypes;
}

class ListTrippelParticipants extends React.Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: false, trippelReport: { trippel30: [], trippel45: [], trippel90: [], noRunTrippel30: [], noBikeTrippel30: [], noBikeTrippel45: [], noRunTrippel45: [], noRunTrippel90: [], noBikeTrippel90: [] }, view: 'trippel90' };
    }

    componentDidMount() {
        this.refresh();
    }

    refresh = () => {
        let back = new ApiBackend();
        this.setState({ loading: true });
        back.listTrippelParticipants().then((trippelparticipants) => {
            this.setState({ trippelReport: trippelparticipants, loading: false });
        });
    };

    getReportItems(part: trippeltypes) {
        let ret = [];
        const items = this.state.trippelReport[part];
        for (let i = 0; i < items.length; i++) {
            const it = items[i];
            ret.push({
                id: i,
                firstName: it.firstName,
                lastName: it.lastName,
                email: it.email,
                tshirtSize: it.tshirtSize,
                birthDay: it.birthDay,
                vasaId: it.vasaId,
                nationality: it.nationality,
                language: it.language
            });
        }
        return ret;
    }

    render() {
        let { classes } = this.props as any;
        const columns: ColDef[] = [
            { field: 'Info.firstName', headerName: 'Förnamn', minWidth: 150, flex: 0.3, valueGetter: (params) => { return params.row.firstName } },
            { field: 'Info.lastName', headerName: 'Efternamn', minWidth: 200, flex: 0.3, valueGetter: (params) => { return params.row.lastName } },
            { field: 'Info.birthDay', headerName: 'Födelsedatum', width: 200, valueGetter: (params) => { return params.row.birthDay } },
            { field: 'Info.email', headerName: 'E-post', minWidth: 200, flex: 0.3, valueGetter: (params) => { return params.row.email } },
            { field: 'Info.tshirt', headerName: 'T-Shirt', width: 200, valueGetter: (params) => { return params.row.tshirtSize } },
            { field: 'Info.nationality', headerName: 'Nationalitet', width: 200, valueGetter: (params) => { return params.row.nationality } },
            { field: 'Info.language', headerName: 'Språk', width: 200, valueGetter: (params) => { return params.row.language } }
        ];

        return <Fragment><Grid container className={classes.root} spacing={2}>
            <Grid item xs={12}>
                <Card>
                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Inställningar</Typography></Fragment>} />
                    <CardContent>
                        <Grid container className={classes.root} spacing={2}>
                            <Grid item xs={12}><b>Visa</b></Grid>
                            <Grid item xs={12}><FormControl>
                                <Select native onChange={(ev) => { this.setState({ view: ev.target.value as 'trippel30' | 'trippel45' | 'trippel90' }); }}>
                                    <option value="trippel90">Trippel-90</option>
                                    <option value="trippel45">Trippel-45</option>
                                    <option value="trippel30">Trippel-30</option>
                                    <option value="noRunTrippel30">Trippel-30 utan löplopp</option>
                                    <option value="noBikeTrippel30">Trippel-30 utan cykellopp</option>
                                    <option value="noRunTrippel45">Trippel-45 utan löplopp</option>
                                    <option value="noBikeTrippel45">Trippel-45 utan cykellopp</option>
                                    <option value="noRunTrippel90">Trippel-90 utan löplopp</option>
                                    <option value="noBikeTrippel90">Trippel-90 utan cykellopp</option>
                                </Select>
                            </FormControl>
                            </Grid>
                            <Grid item xs={12}><Button variant="contained" disabled={this.state.loading} onClick={this.exportExcel}>Excelexportera</Button></Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>

            <Grid item xs={12}>

                <Card>

                    <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Potentiella trippeldeltagare</Typography>
                        <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} />
                    </Fragment>} />
                    <CardContent>
                        {this.state.loading &&
                            <LinearProgress color="secondary" />
                        }

                        <DataGrid
                            checkboxSelection autoHeight={true} loading={this.state.loading} rows={this.getReportItems(this.state.view)} columns={columns} pageSize={100} rowsPerPageOptions={[100]} />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
        </Fragment>;
    }

    exportExcel = () => {
        this.renderXLSX();
    };

    renderXLSX = async () => {
        var wb = XLSX.utils.book_new();
        wb.Props = {
            Title: "Potentiella trippeldeltagare",
            Subject: vasaloppetMoment().format(DATE_FORMAT),
            Author: "Vasaloppet",
            CreatedDate: new Date()
        };


        //let groupedPanels = await this.getGroupedPanels();
        let items90 = this.getReportItems('trippel90');
        let items45 = this.getReportItems('trippel45');
        let items30 = this.getReportItems('trippel30');

        this.renderSheet(wb, items90, "Trippel90");
        this.renderSheet(wb, items45, "Trippel45");
        this.renderSheet(wb, items30, "Trippel30");
        this.renderSheet(wb, this.getReportItems("noRunTrippel30"), "Trippel30-utan löp");
        this.renderSheet(wb, this.getReportItems("noBikeTrippel30"), "Trippel30-utan cykel");
        this.renderSheet(wb, this.getReportItems("noRunTrippel45"), "Trippel45-utan löp");
        this.renderSheet(wb, this.getReportItems("noBikeTrippel45"), "Trippel45-utan cykel");
        this.renderSheet(wb, this.getReportItems("noRunTrippel90"), "Trippel90-utan löp");
        this.renderSheet(wb, this.getReportItems("noBikeTrippel90"), "Trippel90-utan cykel");

        var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        saveAs(new Blob([this.s2ab(wbout)], { type: "application/octet-stream" }), 'trippeldeltagare.xlsx');
    };

    renderSheet(wb: XLSX.WorkBook, items: ReportTrippelParticipant[], header: string) {
        wb.SheetNames.push(header);
        let data = [["Förnamn", "Efternamn", "Epost", "vasa-id", "T-shirt", "Födelsedag", "Nationalitet", "Språk"]];
        for (let i = 0; i < items.length; i++) {
            const row = items[i];
            data.push(
                [
                    row.firstName,
                    row.lastName,
                    row.email,
                    row.vasaId,
                    row.tshirtSize,
                    row.birthDay,
                    row.nationality,
                    row.language
                ]
            );
        }

        var ws = XLSX.utils.aoa_to_sheet(data);

        wb.Sheets[header] = ws;
    }

    s2ab(s: any) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf);  //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
        return buf;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {

        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ListTrippelParticipants));
