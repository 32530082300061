export class OrderContact {
    loggedInUserId: string;
    firstName: string;
    lastName: string;    
    email: string;
    phone: string;
    address: string;
    postalcode: string;
    city: string;
    country: string;
    acceptterms: boolean;
    optinnews: boolean;
}