import Button from "@material-ui/core/Button/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener/ClickAwayListener";
import Grow from "@material-ui/core/Grow/Grow";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import MenuList from "@material-ui/core/MenuList/MenuList";
import Paper from "@material-ui/core/Paper/Paper";
import Popper from "@material-ui/core/Popper/Popper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import { SplitButtonProps, getPrimary, getSecondaries } from "./utils";

const SplitButton = ({ color, disabled, children }: SplitButtonProps) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const first = getPrimary(children);
    const secondaries = getSecondaries(children);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: any) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as HTMLElement)
        ) {
            return;
        }

        setOpen(false);
    };

    return (<>
        <ButtonGroup
            variant="contained"
            color={color ?? "secondary"}
            disabled={disabled}
            ref={anchorRef}
            aria-label="split button"
        >
            {first}
            <Button
                size="small"
                aria-controls={open ? "split-button-menu" : undefined}
                aria-expanded={open ? "true" : undefined}
                aria-label="split button toggle"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>
        <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            style={{ zIndex: 1000 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps}
                    style={{
                        transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu" autoFocusItem>
                                {secondaries.map((item, index) => (
                                    <MenuItem
                                        key={index}
                                        onClick={(event) => setOpen(false)}
                                    >
                                        {item}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>);
}
export default SplitButton;
