import { Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogTitle, Grid, LinearProgress, Theme, Typography, createStyles, withStyles } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import GetAppIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import { debounce } from 'lodash';
import React, { Fragment } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ListOrder, Order, initNewOrder } from '../../model/Order';
import { OrderContact } from '../../model/OrderContact';
import FirebaseContext from '../../providers/Firebase/context';
import { ApiBackend } from '../../providers/apibackend';
import SearchFilter, { Filters } from '../Common/SearchFilter/SearchFilter';
import AccountingExportDialog from './AccountingExportDialog';
import ManageOrder from './ManageOrder';
import ManageOrderContact from './ManageOrderContact';
import { vasaloppetDateGetterFormatter, vasaloppetDateTimeGetterFormatter } from '../../utilities/date';
import { IColumnDefinition } from '../Common/EnhancedTable/models';
import EnhancedTable from '../Common/EnhancedTable/EnhancedTable';
import { B2bCompany } from '../../model/B2bCompany';

interface ISearchFilter {
    fromDate: string; // Order
    toDate: string; // Order
    orderStatus: string[]; // Order (statuses)
    id?: string; // Order (id, publicId)
    firstName?: string; // OrderContact (name)
    lastName?: string; // OrderContact (lastName)
    email?: string; // OrderContact (email)
    phone?: string; // OrderContact (phone)
    code?: string; // OrderCodes (code)
    product?: string[]; // Product (ids)
    payeeRef?: string; // Payments (payeeRef)
    company?: string // Order (b2bIds)
}

interface State {
    loading: boolean;
    companies: B2bCompany[];
    orders: ListOrder[],
    deleteConfirmation: Order;
    showDeleteConfirmation: boolean;
    displayOrder: Order;
    showExport: boolean;
    newOrder: Order;
    searchFilter: ISearchFilter;
}

class ManageOrders extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;

    private readonly api: ApiBackend;

    constructor(props: RouteComponentProps) {
        super(props);

        this.api = new ApiBackend();

        const fromDate = new Date();
        fromDate.setDate(fromDate.getDate() - 365);

        const toDate = new Date(2030, 11, 31);

        this.state = {
            newOrder: null,
            loading: true,
            companies: [],
            orders: null,
            deleteConfirmation: null,
            showDeleteConfirmation: false,
            displayOrder: null,
            showExport: false,
            searchFilter: {
                fromDate: vasaloppetDateGetterFormatter(fromDate),
                toDate: vasaloppetDateGetterFormatter(toDate),
                orderStatus: ["Completed"]
            }
        };
    }

    async componentDidMount(): Promise<void> {
        await this.init();
        this.refresh();
    }

    editEvent = (order: Order) => () => {
        this.props.history.push("/orders/manage/" + order.id);
    }

    deleteEvent = (order: Order) => () => {
        this.setState({ deleteConfirmation: order, showDeleteConfirmation: true });
    }

    refresh = () => {
        this.setState({ loading: true });
        this.performSearch();
    }

    downloadResults = () => {
        this.setState({ showExport: true });
    }

    openOrder = (order: Order) => () => {
        this.setState({
            displayOrder: order
        })
    }

    newOrder = () => {
        let o = initNewOrder();
        o.contact = new OrderContact();
        o.items = [];
        o.Status = "NEW";
        this.setState({
            newOrder: o
        });
    }

    createNewOrder = () => {
        this.api.createOrder(this.state.newOrder).then((order: Order) => {
            this.setState({ newOrder: null, displayOrder: order });
        });
    }

    render() {
        const { classes } = this.props as any;
        const { searchFilter, companies } = this.state;

        const nameFormatter = (contact: OrderContact): string => {
            return `${contact.firstName} ${contact.lastName ?? ""}`;
        };

        const companyValueGetter = (row: ListOrder) => {
            const company = companies?.find(x => x.id == row.b2bId);
            return company?.name;
        };

        const columnDefinitions: IColumnDefinition<ListOrder>[] = [
            {
                propName: "publicId",
                label: "ID",
                renderCell: (row) => {
                    return <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }}
                        onClick={this.openOrder(row)}>{row.publicId}
                    </Typography>
                }
            },
            {
                propName: "OrderDate",
                label: "Skapad",
                valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.OrderDate)
            },
            {
                propName: "contact",
                label: "Namn",
                valueFormatter: (row) => nameFormatter(row.contact),
                sortValueGetter: (row) => nameFormatter(row.contact)
            },
            {
                propName: "contact",
                label: "Email",
                valueFormatter: (row) => row.contact.email,
                sortValueGetter: (row) => row.contact.email
            },
            {
                propName: "totalSum",
                label: "Ordersumma",
                valueFormatter: (row) => `${row.totalSum} kr`
            },
            {
                propName: "Status",
                label: "Status",
                valueFormatter: (row) => Order.translateStatus(row.Status),
                sortValueGetter: (row) => Order.translateStatus(row.Status)
            },
            {
                propName: "LastUpdate",
                label: "Ändrad",
                valueFormatter: (row) => vasaloppetDateTimeGetterFormatter(row.LastUpdate)
            },
            {
                propName: "b2bId",
                label: "Företag",
                valueFormatter: (row) => companyValueGetter(row),
                sortValueGetter: (row) => companyValueGetter(row)
            },
        ];

        return <>
            <SearchFilter
                id={"manage-orders-filter"}
                filters={{
                    "fromDate": {
                        id: "filter-fromdate",
                        type: "Date",
                        label: "Fråndatum",
                        size: 3,
                        defaultValue: searchFilter.fromDate
                    },
                    "toDate": {
                        id: "filter-todate",
                        type: "Date",
                        label: "Tilldatum",
                        size: 3,
                        defaultValue: searchFilter.toDate
                    },
                    "orderStatus": {
                        id: "filter-orderstatus",
                        type: "OrderStatus",
                        label: "Orderstatus",
                        size: 3,
                        defaultValue: searchFilter.orderStatus
                    },
                    "id": {
                        id: "filter-id",
                        type: "DebouncedText",
                        label: "Id",
                        size: 3
                    },
                    "firstName": {
                        id: "filter-first-name",
                        type: "DebouncedText",
                        label: "Förnamn",
                        size: 3
                    },
                    "lastName": {
                        id: "filter-last-name",
                        type: "DebouncedText",
                        label: "Efternamn",
                        size: 3
                    },
                    "email": {
                        id: "filter-email",
                        type: "DebouncedText",
                        label: "E-post",
                        size: 3
                    },
                    "phone": {
                        id: "filter-phone",
                        type: "DebouncedText",
                        label: "Telefon",
                        size: 3
                    },
                    "code": {
                        id: "filter-code",
                        type: "DebouncedText",
                        label: "Kod",
                        size: 3
                    },
                    "product": {
                        id: "filter-product",
                        type: "ProductList",
                        label: "Produkt",
                        size: 3
                    },
                    "payeeRef": {
                        id: "filter-payeeref",
                        type: "DebouncedText",
                        label: "Ref",
                        size: 3
                    },
                    "company": {
                        id: "filter-company",
                        type: "Company",
                        label: "Företag",
                        size: 3,
                        includeArchived: true
                    },
                }}
                persist={true}
                onInit={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
                onChange={async (filter: Filters<ISearchFilter>) => {
                    await this.handleSearchFilterChange(filter as unknown as ISearchFilter);
                }}
            />
            <Grid container className={classes.root} spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title={<Fragment><Typography variant="h5" style={{ display: 'inline' }}>Orders </Typography>
                            <RefreshIcon style={{ display: 'inline', verticalAlign: 'middle', cursor: 'pointer' }} onClick={this.refresh} />
                            <GetAppIcon style={{ display: 'inline', cursor: 'pointer', verticalAlign: 'middle' }} onClick={this.downloadResults} />
                            <AddCircleIcon style={{ display: 'inline', cursor: 'pointer', verticalAlign: 'middle' }} onClick={this.newOrder} /></Fragment>} />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <EnhancedTable<ListOrder>
                                columnDefinitions={columnDefinitions}
                                data={this.state.orders ?? []}
                                pageSize={10}
                                page={0}
                                sortModel={{
                                    sortBy: "OrderDate",
                                    sortOrder: "desc"
                                }}
                                loading={this.state.loading}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.showDeleteConfirmation && this.renderConfirmationDialog()}
            {this.state && this.state.displayOrder && this.renderOrderDialog()}
            {this.state && this.state.showExport && this.renderExportDialog()}
            {this.state && this.state.newOrder && this.renderNewOrderDialog()}
        </>;
    }

    renderExportDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Exportera</DialogTitle>
            <DialogContent>
                <AccountingExportDialog close={() => this.setState({ showExport: false })} />
            </DialogContent>
        </Dialog>;
    }

    renderNewOrderDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Ny order</DialogTitle>
            <DialogContent>
                <ManageOrderContact hideSave={true} editMode={true} order={this.state.newOrder} dataChanged={() => { }} />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={() => { this.setState({ newOrder: null }) }} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.createNewOrder} color="primary">
                    Skapa
                </Button>
            </DialogActions>
        </Dialog>;
    }

    renderOrderDialog() {
        return <ManageOrder
            orderId={this.state.displayOrder.id}
            close={this.closeOrderDialog}
        />
    }

    closeOrderDialog = () => {
        this.setState({ displayOrder: null });
    }

    renderConfirmationDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort ordern?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    handleDeleteCancel = () => {
        this.setState({ deleteConfirmation: null, showDeleteConfirmation: false });
    }

    handleDeleteOk = () => {
        this.setState({ loading: true });
    }

    private handleSearchFilterChange = async (searchFilter: ISearchFilter): Promise<void> => {
        this.setState({ searchFilter: searchFilter, loading: true }, async () => {
            await this.performSearch();
        });
    };

    private init = async (): Promise<void> => {
        const companies = await this.api.listB2bCompanies({ includeArchived: true });
        this.setState({ companies: companies });
    };

    private performSearch = debounce(async () => {
        const { searchFilter } = this.state;

        const orders = await this.api.listOrders(searchFilter);

        this.setState({
            orders: orders,
            loading: false
        });
    }, 500);
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(ManageOrders));
