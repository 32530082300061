import { Grid, Paper, Button, WithStyles, Card, CardHeader, Checkbox, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import React from "react";

interface IProps {
    items: any[];
    choicesTitle: string;
    choosenTitle: string;
    onChange: (items: any[]) => void;
    valueFormatter: (item: any) => string;
}

interface IState {
    left: any[];
    right: any[];
    checked: any[];
}

type Alignment = "left" | "right";

// Helpers
const not = (a: any[], b: any[]) => {
    return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a: any[], b: any[]) => {
    return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a: any[], b: any[]) => {
    return [...a, ...not(b, a)];
};

class TransferList extends React.Component<IProps & WithStyles> {
    state: IState;

    constructor(props: IProps & WithStyles) {
        super(props);
        this.state = { left: this.props.items, right: [], checked: [] };
    }

    render() {
        const { classes, choicesTitle, choosenTitle } = this.props;
        const { checked, left, right } = this.state;

        const leftChecked = intersection(checked, left);
        const rightChecked = intersection(checked, right);

        return (
            <>
                <Grid
                    container
                    spacing={2}
                    className={classes.root}
                >
                    <Grid item xs={5}>
                        <Paper elevation={3} className={classes.paper}>{this.renderCard(choicesTitle, left, "left")}</Paper>
                    </Grid>
                    <Grid item xs={1}>
                        <Grid container direction="column" alignItems="center">
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={this.handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label="move selected right"
                            >
                                &gt;
                            </Button>
                            <Button
                                variant="outlined"
                                size="small"
                                className={classes.button}
                                onClick={this.handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label="move selected left"
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <Paper elevation={3} className={classes.paper}>{this.renderCard(choosenTitle, right, "right")}</Paper>
                    </Grid>
                </Grid>
            </>
        );
    }

    private renderCard =(title: string, items: any[], alignment: Alignment) => {
        const { classes, valueFormatter } = this.props;
        const { checked } = this.state;

        return (
            <Card
                style={{ display: "flex", flexDirection: "column" }}
                className={classes.paper}
                component={Paper}
            >
                <CardHeader
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            onClick={this.handleToggleAll(items)}
                            checked={
                                this.numberOfChecked(items) === items.length && items.length !== 0
                            }
                            indeterminate={
                                this.numberOfChecked(items) !== items.length &&
                                this.numberOfChecked(items) !== 0
                            }
                            disabled={items.length === 0}
                            inputProps={{ "aria-label": "all items selected" }}
                        />
                    }
                    title={title}
                    subheader={`${this.numberOfChecked(items)}/${items.length} valda`}
                />
                <List className={classes.list} dense component="div" role="list">
                    {items.map((item: any, idx) => {
                        const labelId = `transfer-list-all-item-${idx}-label`;

                        return (
                            <ListItem
                                key={idx}
                                role="listitem"
                                button
                                onClick={this.handleToggle(item)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        checked={checked.indexOf(item) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={valueFormatter(item)}
                                />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Card>
        );
    };

    private handleCheckedRight = () => {
        const { left, right, checked } = this.state;

        const leftChecked = intersection(checked, left);

        const nextRight = right.concat(leftChecked);
        const nextLeft = not(left, leftChecked);
        const nextChecked = not(checked, leftChecked);
        this.setState({ left: nextLeft, right: nextRight, checked: nextChecked }, () => {
            this.props.onChange(this.state.right);
        });
    };

    private handleCheckedLeft = () => {
        const { left, right, checked } = this.state;
        const rightChecked = intersection(checked, right);

        const nextLeft = left.concat(rightChecked);
        const nextRight = not(right, rightChecked);
        const nextChecked = not(checked, rightChecked);
        this.setState({ left: nextLeft, right: nextRight, checked: nextChecked }, () => {
            this.props.onChange(this.state.right);
        });
    };

    private numberOfChecked = (items: any[]) => {
        const { checked } = this.state;
        return intersection(checked, items).length;
    };
 
    private handleToggle = (value: any) => () => {
        const { checked } = this.state;
        const currentIndex = checked.indexOf(value);
        const nextChecked = [...checked];

        if (currentIndex === -1) {
            nextChecked.push(value);
        } else {
            nextChecked.splice(currentIndex, 1);
        }

        this.setState({ checked: nextChecked });
    };

    private handleToggleAll = (items: any[]) => () => {
        const { checked } = this.state;
        let nextChecked: any[];
        if (this.numberOfChecked(items) === items.length) {
            nextChecked = not(checked, items);
        } else {
            nextChecked = union(checked, items);
        }

        this.setState({ checked: nextChecked });
    };

}

export default TransferList;
