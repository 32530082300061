import { Code } from "./Code";
import { CodeProductImplementation } from "./CodeProductImplementation";

const CodeHolderTypes = ["ORDER", "ORDER_ITEM"] as const;
export type CodeHolderType = typeof CodeHolderTypes[number];

export class CodeHolder {
    id: string;
    name: string;
    description: string;
    category: string;
    ValidFrom: Date;
    ValidTo: Date;
    Codes: Code[];
    Implementations: CodeProductImplementation[];
    FixedDiscount?: number;
    PercentageDiscount?: number;
    IsArchived: boolean;
    NumUsed?: number;
    NumAvailable?: number;
    b2bCompany: string;
    reuseExistingEntries: boolean;

    b2bInvoice: boolean;
    b2bInvoiceDescription?: string;
    b2bInvoiceCreated?: Date;
    b2bInvoiceCreatedBy?: string;
    b2bInvoiceExternalRef?: string;
    type?: CodeHolderType;

    created?: Date;
    updated?: Date;
    createdBy?: string;
    updatedBy?: string;
}

/**
 * Det är en Orderradskod om type är 'ORDER_ITEM'
 * @param codeHolder 
 * @returns 
 */
export const isOrderItemCode = (codeHolder: CodeHolder) => {
    if (!codeHolder) {
        return false;
    }

    return codeHolder.type === "ORDER_ITEM";
};

/**
 * Det är en Orderkod om type är 'ORDER' eller om typ är NULL
 * @param codeHolder 
 * @returns 
 */
export const isOrderCode = (codeHolder: CodeHolder) => {
    if (!codeHolder) {
        return false;
    }

    if (!codeHolder.type) {
        return true;
    }

    return codeHolder.type === "ORDER";
};
