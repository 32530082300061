import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import React, { useState } from "react";

export interface IConfirmContext {
    showConfirm: (message: string) => Promise<boolean>;
}

interface IState {
    message: string;
    open: boolean;
    resolve: (value?: any) => void;
    reject: (reason?: any) => void;
}

const withConfirm = <P extends {}>(
    WrappedComponent: React.ComponentType<P>
) => {
    return (props: Omit<P, keyof IConfirmContext>) => {
        const [state, setState] = useState<IState>({
            message: "",
            open: false,
            resolve: null,
            reject: null
        });

        const showConfirm = (message: string) => {
            const promise = new Promise((resolve, reject) => {
                setState({ message, open: true, resolve, reject });
            });

            const reset = () => {
                setState({ message: "", open: false, resolve: null, reject: null });
            };

            return promise.then(
                () => {
                    reset();
                    return true;
                },
                () => {
                    reset();
                    return false;
                }
            );
        };

        const handleConfirm = (event: any) => {
            const { resolve } = state;
            resolve();
        };

        const handleAbort = (event: any) => {
            const { reject } = state;
            reject();
        };

        return (<>
            <WrappedComponent {...props as P} showConfirm={showConfirm} />
            <Dialog
                open={state.open}
                aria-labelledby="confirm-dialog-title"
                aria-describedby="confirm-dialog-description"
            >
                <DialogTitle id="confirm-dialog-title">Bekräfta</DialogTitle>
                <DialogContent>
                    <DialogContentText id="confirm-dialog-description">
                        {state.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleAbort} variant="contained" color="secondary" autoFocus>
                        Nej
                    </Button>
                    <Button onClick={handleConfirm} variant="contained" color="primary" autoFocus>
                        ja
                    </Button>
                </DialogActions>
            </Dialog>
        </>);
    };
};

export default withConfirm;
