import { CustomField } from "./CustomField";
import { ProductVariantCustomField } from "./ProductVariantCustomField";

export type ProductVariantRaceDataDiscipline = "ski" | "bike" | "run" | "event";
export type VlDistance = "10" | "30" | "45" | "90";
export type ParticipationType = "in_arena" | "virtual";
export type RaceType = "individual" | "team" | "relay";
export type ResultPrincipal = "RankingAndTime" | "TimeOnly" | "NoTiming";

export class ProductVariantRaceData {
    type: RaceType;
    minAge: number;
    maxAge: number;
    gender: string;
    numberOfTeamMembers: number;
    entryOpenDate: Date;
    entryCloseDate: Date;

    entryFields: ProductVariantCustomField[];
    entryFieldDefinitions: CustomField[]; // Read only

    eventKey: string;
    discipline: ProductVariantRaceDataDiscipline;
    vlDistance: VlDistance;
    participationType: ParticipationType;
    isSSFCompetition: boolean;
    resultPrincipal?: ResultPrincipal;
}

export const hasEntryFields = (item: ProductVariantRaceData): boolean => {
    return !!item?.entryFields && item.entryFields.length > 0;
};

export const hasEntryFieldDefinitions = (item: ProductVariantRaceData): boolean => {
    return !!item?.entryFieldDefinitions && item.entryFieldDefinitions.length > 0;
};

export const mandatoryFieldDefinitions = (item: ProductVariantRaceData): CustomField[] => {
    if (!hasEntryFieldDefinitions(item) || !hasEntryFields(item)) {
        return [];
    }

    const mandatoryEntryFieldIds = item.entryFields.filter(x => x.mandatory).map(x => x.customFieldId);
    return item.entryFieldDefinitions.filter(x => mandatoryEntryFieldIds.some(y => y === x.id));
};

export const optionalFieldDefinitions = (item: ProductVariantRaceData): CustomField[] => {
    if (!hasEntryFieldDefinitions(item) || !hasEntryFields(item)) {
        return [];
    }

    const mandatoryEntryFieldIds = item.entryFields.filter(x => !x.mandatory).map(x => x.customFieldId);
    return item.entryFieldDefinitions.filter(x => mandatoryEntryFieldIds.some(y => y === x.id));
};
