import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { debounce } from "lodash";
import React from "react";
import { ApiBackend } from "../../providers/apibackend";

interface IProps {
    initialValue?: string | string[];
    strict: boolean;
    multiple?: boolean;
    label?: string;
    style?: React.CSSProperties
    onChange: (value: string | string[]) => void;
}

interface IState {
    loading: boolean;
    value: string | string[];
    inputValue: string;
    categories: string[];
}

class CodeHolderCategoryPicker extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;
    constructor(props: IProps) {
        super(props);

        if (props.multiple && !props.strict) {
            throw new Error("Must be 'strict' when 'multiple'");
        }

        this.api = new ApiBackend();

        let safeValue: string[] | string = props.multiple ? [] : "";
        let safeInputValue = "";
        if (this.props.initialValue) {
            safeValue = this.props.initialValue;

            if (!props.multiple) {
                safeInputValue = (Array.isArray(this.props.initialValue) && this.props.initialValue.length > 0) ?
                    this.props.initialValue[0] :
                    this.props.initialValue as string ?? ""
            }
        }

        this.state = {
            loading: true,
            value: safeValue,
            inputValue: safeInputValue,
            categories: []
        };
    }

    async componentDidMount(): Promise<void> {
        const { strict } = this.props;

        if (!strict) {
            this.notifyChange = debounce(this.notifyChange, 500);
        }

        const categories = await this.api.listCodeHolderCategories();
        this.setState({
            categories: categories,
            loading: false
        });
    }

    render(): JSX.Element {
        const { label, strict, multiple } = this.props;
        const { value, inputValue, categories } = this.state;

        const handleChangeImpl = strict ? null : this.handleInputChange;

        return (
            <FormControl fullWidth>
                <InputLabel shrink htmlFor="category-select">{label ? label : "Kategori"}</InputLabel>
                <Autocomplete id="category-select"
                    style={{ marginTop: 12 }}
                    defaultValue={value}
                    inputValue={inputValue}
                    onChange={this.handleChange}
                    onInputChange={handleChangeImpl}
                    freeSolo={!strict}
                    multiple={multiple}
                    openOnFocus
                    options={categories}
                    renderInput={(params) => (
                        <TextField {...params} value={value} />
                    )}
                />
            </FormControl>
        )
    }

    private handleChange = (event: any, newValue: string | string[]) => {
        const { strict } = this.props;
        this.setState({ value: newValue });
        if (strict) {
            this.notifyChange(newValue);
        }
    };

    private handleInputChange = (event: any, newValue: string) => {
        this.setState({ inputValue: newValue });
        this.notifyChange(newValue);
    };

    private notifyChange = (value: string | string[]) => {
        this.props.onChange(value ? value : null);
    };
}

export default CodeHolderCategoryPicker;
