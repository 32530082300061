import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { ApiBackend } from "../../providers/apibackend";
import { ProductType } from "../../model/Product";

export type ProductTypeSelectProductType = ProductType | "ALL";

type ProductTypeSelectProps = {
    useFormWrapper: boolean;
    wildCardSelect: string | false;
    native?: boolean;
    label?: string;
    defaultValue?: ProductTypeSelectProductType;
    restrictedTypes?: ProductType[];
    className?: string;
    style?: React.CSSProperties;
    onChange: (nextValue: ProductTypeSelectProductType) => void;
};

const ProductTypeSelect = ({ useFormWrapper, wildCardSelect, native, label, defaultValue, restrictedTypes, className, style, onChange }: ProductTypeSelectProps) => {
    const [value, setValue] = useState(defaultValue ?? "");

    const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setValue(ev.target.value);
        onChange(ev.target.value as ProductType);
    };

    const getTypes = () => {
        if (!restrictedTypes) {
            return ApiBackend.productTypes;
        }

        return ApiBackend.productTypes.filter(x => restrictedTypes.some(r => r === x.code));
    };

    const SelectComponent = () => {
        const labelValue = !!label ? label : "Produktkategori";

        if (native) {
            return (
                <Select native value={value} onChange={handleChange} label={labelValue}>
                    {!!wildCardSelect &&
                        <option key={wildCardSelect} value={wildCardSelect}>Alla</option>
                    }
                    {getTypes().map((item) => {
                        return <option key={item.code} value={item.code}>{item.name}</option>
                    })}
                </Select>
            );
        }

        return (
            <Select value={value} onChange={handleChange} label={labelValue}>
                {!!wildCardSelect &&
                    <MenuItem key={wildCardSelect} value={wildCardSelect}>Alla</MenuItem>
                }
                {getTypes().map((item) => {
                    return <MenuItem key={item.code} value={item.code}>{item.name}</MenuItem>
                })}
            </Select>
        );
    };

    const render = () => {
        const labelValue = !!label ? label : "Produktkategori";

        if (useFormWrapper) {
            return (
                <FormControl className={className} style={style}>
                    <InputLabel shrink>
                        {labelValue}
                    </InputLabel>
                    <SelectComponent />
                </FormControl>
            );
        } else {
            return <SelectComponent />;
        }
    };

    return render();
};

export default ProductTypeSelect;
