/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Theme,
    WithStyles,
    createStyles,
    withStyles
} from '@material-ui/core';
import { Formik, FormikProps, FormikState } from 'formik';
import React from 'react';
import uuidv4 from "react-uuid";
import { Club } from '../../../model/Club';
import { Entry } from '../../../model/Entry';
import { Person } from '../../../model/Person';
import { Product } from '../../../model/Product';
import { ApiBackend } from '../../../providers/apibackend';
import SplitButton from '../../Common/SplitButton/SplitButton';
import EditPersonDialog from '../Person/EditPersonDialog';
import EditEntryFormContent from './EditEntryFormContent';
import validationSchema from './EntryValidationSchema';
import SetEntryB2b from './SetEntryB2b';
import EditEntryActions from './EditEntryActions/EditEntryActions';
import PersonSearchDialog from '../Person/PersonSearchDialog';
import { copyValuesFromPersonToEntry } from '../../../utilities/EntryHelpers';
import withConfirm, { IConfirmContext } from '../../Common/dialog/Confirm';
import withToaster, { IToasterContext } from '../../Common/Toaster';

interface Props {
    entryId: string;
    onSave?: (entry: Entry) => void;
    onAbortEdit: () => void;
}

interface IState {
    loading: boolean;
    entry: Entry;
    person: Person;
    validClubs: Club[];
    entryProduct: Product;
    showPerson: boolean;
    showSearchChangePerson: boolean;
    showCreateChangePerson: boolean;
}

class EditEntryDialog extends React.Component<WithStyles & IConfirmContext & Props & IToasterContext, IState> {
    private readonly api: ApiBackend;
    private readonly formId = `entry-form-${uuidv4()}`;

    constructor(props: WithStyles & IConfirmContext & Props & IToasterContext) {
        super(props);
        this.state = { entry: null, person: null, validClubs: null, loading: true, entryProduct: null, showPerson: false, showSearchChangePerson: false, showCreateChangePerson: false };
        this.api = new ApiBackend();
    }

    async componentDidMount() {
        await this.loadEntry(this.props.entryId);
    }

    render(): JSX.Element {
        return <div>
            {this.renderEditEntry()}
        </div>;
    }

    renderEditEntry(): JSX.Element {
        const { loading, entry, showPerson, showSearchChangePerson, showCreateChangePerson, person, entryProduct, validClubs } = this.state;
        const { entryId, classes, onAbortEdit, onSave } = this.props;

        if (loading || !entry) {
            return (<Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="edit-entry-title"
                open={true}
            >
                <DialogTitle id="edit-entry-title">
                    Laddar
                </DialogTitle>
                <DialogContent dividers>
                    <CircularProgress color="secondary" />
                </DialogContent>
            </Dialog>);
        }

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return <Formik
            initialValues={entry}
            validationSchema={validationSchema}
            onSubmit={async (values, { resetForm }) => {
                const saveResult = await this.save(values);
                if (saveResult) {
                    resetForm({ values: saveResult.entry });

                    if (onSave) {
                        onSave(saveResult.entry);
                    }
                }
            }}
        >
            {formik => {
                const { isValid, dirty, handleSubmit, resetForm, isSubmitting } = formik;
                const isSaveDisabled = !dirty || !isValid || isSubmitting;

                return (
                    <Dialog
                        disableEscapeKeyDown
                        fullWidth={true}
                        maxWidth="lg"
                        aria-labelledby="edit-entry-title"
                        open={true}
                    >
                        <DialogTitle id="edit-entry-title">
                            <Box {...defaultBoxStyle}>
                                <a href="#" style={{cursor:'default'}} onClick={() => {
                                    navigator.clipboard.writeText(this.state.entry.id);
                                    this.props.showToast("AnmälningsID kopierat till urklipp"); 
                                }}>
                                {this.cardHeader()}
                                </a>
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                    <SetEntryB2b
                                        entry={this.state.entry}
                                        disabled={dirty}
                                        onChange={async () => {
                                            const nextValue = await this.loadEntry(entryId);
                                            resetForm({ values: nextValue });
                                        }}
                                    />
                                </Box>
                            </Box>
                        </DialogTitle>
                        <DialogContent dividers >
                            <Box style={{ paddingBottom: 16, display: "flex", flexDirection: "column", width: "100%" }}>
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                                    <EditEntryActions {...formik}
                                        entryProduct={entryProduct}
                                        isParentDialog={true}
                                        onChange={async () => {
                                            const nextValue = await this.loadEntry(entryId);
                                            resetForm({ values: nextValue });
                                        }}
                                    />
                                </Box>
                            </Box>
                            <form id={this.formId} autoComplete="off" onSubmit={handleSubmit}>
                                <EditEntryFormContent {...formik}
                                    classes={classes}
                                    person={person}
                                    validClubs={validClubs}
                                    entryProduct={entryProduct}
                                    onShowPerson={() => {
                                        this.setState({ showPerson: true });
                                    }}
                                    onRequestChangePerson={() => {
                                        this.setState({ showSearchChangePerson: true });
                                    }}
                                    onRequestReloadEntry={
                                        async () => {
                                            const nextValue = await this.loadEntry(entryId);
                                            resetForm({ values: nextValue });
                                        }
                                    }
                                />
                            </form>
                        </DialogContent>
                        <DialogActions>
                            <SplitButton>
                                <Button form={this.formId} type="submit" color="secondary" disabled={isSaveDisabled} variant="contained">Spara</Button>
                                <Button form={this.formId} type="submit" color="secondary" variant="text">Forcera Spara</Button>
                            </SplitButton>
                            <Button style={{ marginLeft: 10 }} color="secondary" variant="contained" onClick={onAbortEdit}>
                                {dirty ? "Avbryt" : "Stäng"}
                            </Button>
                        </DialogActions>
                        {showPerson &&
                            <EditPersonDialog
                                personId={entry.personId}
                                onAbortEdit={async () => {
                                    const nextValue = await this.loadEntry(entryId);
                                    resetForm({ values: nextValue });
                                    this.setState({ showPerson: false });
                                }}
                                onSave={async () => {
                                    const nextValue = await this.loadEntry(entryId);
                                    resetForm({ values: nextValue });
                                    this.setState({ showPerson: false });
                                }}
                            />
                        }
                        {showSearchChangePerson &&
                            <PersonSearchDialog
                                title="Välj person att byta till"
                                action={
                                    <Button variant="text" color="secondary"
                                        onClick={() => {
                                            this.setState({ showSearchChangePerson: false, showCreateChangePerson: true });
                                        }}
                                    >
                                        Skapa ny person
                                    </Button>
                                }
                                onAbort={() => {
                                    this.setState({ showSearchChangePerson: false });
                                }}
                                onPersonSelect={async (value: Person) => {
                                    await this.handleChangePerson(value, formik, resetForm);
                                }}
                            />
                        }
                        {showCreateChangePerson &&
                            <EditPersonDialog
                                onAbortEdit={() => {
                                    this.setState({ showCreateChangePerson: false });
                                }}
                                onSave={async (value: Person) => {
                                    await this.handleChangePerson(value, formik, resetForm);
                                }}
                            />
                        }
                    </Dialog>
                );
            }}
        </Formik>
    }

    private handleChangePerson = async (nextValue: Person, formik: FormikProps<Entry>, resetForm: (nextState?: Partial<FormikState<Entry>>) => void) => {
        const { person } = this.state;

        let nextEntry = { personId: nextValue.id } as Entry;
        copyValuesFromPersonToEntry(nextValue, nextEntry);

        const from = `${formik.values.firstName} ${formik.values.lastName} (${person.vasaId})`;
        const to = `${nextEntry.firstName} ${nextEntry.lastName} (${nextValue.vasaId})`;
        const result = await this.props.showConfirm(`Är du säker på att du vill byta person från ${from} till ${to}?`);

        if (result) {
            const nextValues: Entry = {
                ...formik.values,
                ...nextEntry
            };
            formik.setValues(nextValues);

            this.setState({ showSearchChangePerson: false, showCreateChangePerson: false });
            const saveResult = await this.save(nextValues);
            if (saveResult) {
                resetForm({ values: saveResult.entry });
                this.setState({ person: saveResult.person });
            }
        }
    };

    private cardHeader = (): string => {
        const { entry, entryProduct } = this.state;

        return `Hantera anmälan: ${entry.firstName} ${entry.lastName} - ${entryProduct.name} / ${entryProduct.variants?.find(x => x.Id === entry.variantId)?.Name}`;
        

    };

    private loadEntry = async (id: string): Promise<Entry> => {
        return new Promise<Entry>(async (resolve) => {
            const entry = await this.api.getEntry(id);
            const work = [
                this.api.getPerson(entry.personId),
                this.api.getProduct(entry.productId),
                this.api.listClubs(false)
            ];

            const [person, entryProduct, clubs] = await Promise.all(work);

            this.setState({
                loading: false,
                entry: entry,
                person: person as Person,
                validClubs: clubs as Club[],
                entryProduct: entryProduct as Product
            }, () => {
                resolve(entry);
            });
        });
    };

    private save = async (values: Entry): Promise<{ entry: Entry, person: Person } | false> => {
        const saveRes = await this.api.saveEntry(values);
        if (!saveRes) {
            return false;
        }

        const entry = await this.api.getEntry(values.id);
        const person = await this.api.getPerson(entry.personId);

        return {
            entry: entry,
            person: person
        }
    };
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withToaster(withConfirm(withStyles(useStyles)(EditEntryDialog)));
