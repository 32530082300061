import { Product } from "../../../model/Product";

type Props = "productRequiresSocialSecurityNumber" | "preRaceEntryOpen" | "raceEntryOpen" | "raceEntriesCanBeChanged" | "raceStartCertificateAvailable" | "physicalProduct" | "participantCanChangeStartGroup" |
    "requiresSex" | "requiresDateOfBirth" | "requiresNationality" | "requiresAddress" | "requiresCountry" | "personalBib";
export type ProductSettings = Pick<Product, Props>;

export const getInitialValue = (source: ProductSettings): ProductSettings => {
    return {
        productRequiresSocialSecurityNumber: !!source?.productRequiresSocialSecurityNumber,
        preRaceEntryOpen: !!source?.preRaceEntryOpen,
        raceEntryOpen: !!source?.raceEntryOpen,
        raceEntriesCanBeChanged: !!source?.raceEntriesCanBeChanged,
        raceStartCertificateAvailable: !!source?.raceStartCertificateAvailable,
        physicalProduct: !!source?.physicalProduct,
        participantCanChangeStartGroup: !!source?.participantCanChangeStartGroup,
        requiresSex: !!source?.requiresSex,
        requiresDateOfBirth: !!source?.requiresDateOfBirth,
        requiresNationality: !!source?.requiresNationality,
        requiresAddress: !!source?.requiresAddress,
        requiresCountry: !!source?.requiresCountry,
        personalBib: !!source?.personalBib
    };
};
