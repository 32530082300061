import React, { useContext } from "react";
import { Card, CardContent, CardHeader, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, Theme, Typography, WithStyles, createStyles, withStyles } from "@material-ui/core";
import SelectPosSetting from "./Orders/SelectPosSetting";
import { POSSetting } from "../model/POSSetting";
import { Alert } from "@material-ui/lab";
import ExpoContext from "../contexts/ExpoContext";

const AccountSettings = ({ classes }: WithStyles) => {
    const expoContext = useContext(ExpoContext);

    const setPosLayout = (value: boolean) => {
        expoContext.setPosLayout(value);
    };

    const setPosSetting = (value?: POSSetting) => {
        expoContext.setUserPosSetting(value);
    };

    const getInitialValue = (): string => {
        return expoContext.userPosSettings?.Id ?? "";
    };

    return (
        <div>
            <Typography variant="h4">
                Inställningar
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader className={classes.cardHeader} title="Kontrollen som POS-klient" />
                        <CardContent>
                            <Alert severity="info">
                                <strong>
                                    Kontrollen kan agera POS-klient i EXPO-läge. Välj en POS ur listan. Val av POS kommer endast att gälla för dig i den här webbläsaren. <br />
                                    Kontrollen kommer att gå in i ett nedskalat läge med endast ett fåtal funktioner tillgängliga. Återgå till normalläge via inställningar.
                                </strong>
                            </Alert>
                            <FormControl fullWidth>
                                <InputLabel shrink>POS</InputLabel>
                                <SelectPosSetting
                                    initialValue={getInitialValue()}
                                    clearable={true}
                                    posSettingSelected={setPosSetting}
                                />
                            </FormControl>
                            <FormControlLabel
                                label="Använd expo-layout"
                                control={
                                    <Checkbox
                                        checked={expoContext.isExpoLayoutMode}
                                        onChange={(ev) => {
                                            setPosLayout(ev.target.checked);
                                        }}
                                    />
                                } />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

const useStyles = ({ palette }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
});

export default withStyles(useStyles)(AccountSettings);
