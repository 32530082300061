import Grid from "@material-ui/core/Grid/Grid";
import useTheme from "@material-ui/core/styles/useTheme";
import React, { useEffect, useRef, useState } from "react";
import { Entry, EntryAddonProduct } from "../../../../model/Entry";
import { Product } from "../../../../model/Product";
import { ApiBackend } from "../../../../providers/apibackend";
import EntryAddon from "./EntryAddon";

interface IProps {
    entry: Entry;
    raceProduct: Product;
    updateEntry: (entry: Entry) => void;
}

const AddEntryAddons = ({ entry, raceProduct, updateEntry }: IProps) => {
    const [addonProducts, setAddonProducts] = useState(null as Product[]);
    const didMount = useRef<boolean>(false);
    const theme = useTheme();

    const backend = new ApiBackend();

    useEffect(() => {
        didMount.current = true;

        const init = async (): Promise<void> => {
            const work = raceProduct.addOnProducts?.map(p => backend.getProduct(p));
            if (!work || work.length === 0) {
                return;
            }

            const addons = await Promise.all(work);

            if (didMount.current) {
                setAddonProducts(addons);
            }
        };

        init();

        // unmount
        return () => { didMount.current = false };
    }, []);

    const getEntryAddon = (addon: Product): EntryAddonProduct | undefined => {
        return entry.addOns?.find(x => x.productId === addon.id);
    };

    const handleAddonChange = (productId: string, e: any) => {
        if (!entry.addOns) {
            entry.addOns = [];
        }

        const idx = entry.addOns?.findIndex(x => x.productId === productId);
        if (idx > -1) {
            entry.addOns.splice(idx, 1, e);
        } else {
            entry.addOns.push(e);
        }

        updateEntry(entry);
    };

    const handleAddonRemove = (productId: string) => {
        const idx = entry.addOns?.findIndex(x => x.productId === productId);
        if (idx > -1) {
            entry.addOns.splice(idx, 1);
            updateEntry(entry);
        }
    };

    const render = () => {
        if (!raceProduct.addOnProducts) {
            return null;
        }

        const gridStyle = { background: theme.palette.primary.dark, color: theme.palette.primary.main };

        return (<>
            <Grid item xs={12} key="addons" style={gridStyle}>
                Tillägg
            </Grid>

            {addonProducts && addonProducts.map((p, idx) => {
                return (
                    <EntryAddon
                        key={idx}
                        entryAddon={getEntryAddon(p)}
                        addon={p}
                        onChange={handleAddonChange}
                        onRemove={handleAddonRemove}
                    />
                )
            })}
        </>);
    };

    return render();
};

export default AddEntryAddons;
