import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import IconButton from "@material-ui/core/IconButton/IconButton";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import Typography from "@material-ui/core/Typography/Typography";
import { Theme } from "@material-ui/core/styles/createTheme";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, { WithStyles } from "@material-ui/core/styles/withStyles";
import { ArrowForward, OpenInBrowser } from "@material-ui/icons";
import Edit from "@material-ui/icons/Edit";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Entry } from "../../../model/Entry";
import { ApiBackend } from "../../../providers/apibackend";
import withAlert, { IAlertContext } from "../../Common/dialog/Alert";
import withConfirm, { IConfirmContext } from "../../Common/dialog/Confirm";
import AddEntryDialog from "../Entry/AddEntryDialog/AddEntryDialog";
import EditEntryDialog from "../Entry/EditEntryDialog";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import { IColumnDefinition } from "../../Common/EnhancedTable/models";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";

interface IProps extends RouteComponentProps {
    isModalMode: boolean;
    isFormDisabled: boolean;
    isPersonDirty: boolean;
    personId?: string;
    personEmail?: string;
}

interface IState {
    loading: boolean;
    entries: Entry[];
    editEntry: Entry;
    addEntry: boolean;
}

type editEntryMode = "dialog" | "in-app" | "new-tab";

class PersonEntries extends React.Component<WithStyles & IProps & IConfirmContext & IAlertContext, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: WithStyles & IProps & IConfirmContext & IAlertContext) {
        super(props);

        this.api = new ApiBackend();
        this.state = {
            loading: true,
            entries: [],
            editEntry: undefined,
            addEntry: false
        };
    }

    async componentDidMount() {
        await this.fetchEntries();
    }

    render(): JSX.Element {
        const { isModalMode, isFormDisabled, isPersonDirty, personEmail, classes } = this.props;

        const columnDefinitions: IColumnDefinition<Entry>[] = [
            {
                renderCell: (row: Entry) => {
                    return <>
                        <IconButton color="default" onClick={() => { this.editEntry(row, "dialog") }} size="small">
                            <Edit fontSize="medium" />
                        </IconButton>
                        <IconButton color="default" onClick={() => { this.editEntry(row, "new-tab") }} size="small">
                            <OpenInBrowser fontSize="medium" />
                        </IconButton>
                        {!isModalMode &&
                            <IconButton color="default" onClick={() => { this.editEntry(row, "in-app") }} size="small">
                                <ArrowForward fontSize="medium" />
                            </IconButton>
                        }
                    </>;
                }
            },
            { propName: 'firstName', label: 'Förnamn' },
            { propName: 'lastName', label: 'Efternamn' },
            { propName: 'clubName', label: 'Klubb' },
            { propName: 'eventKey', label: 'Evenemang' },
            { propName: 'status', label: 'Status' },
            {
                propName: 'created',
                label: 'Skapad',
                valueFormatter: (row: Entry) => vasaloppetDateTimeGetterFormatter(row.created, "---")
            },
            {
                propName: 'updated',
                label: 'Uppdaterad',
                valueFormatter: (row: Entry) => vasaloppetDateTimeGetterFormatter(row.updated, "---")
            }
        ];

        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (<>
            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader
                            className={classes.cardHeader}
                            title={
                                <Typography variant="h5" style={{ display: 'inline' }}>
                                    Anmälningar
                                </Typography>
                            }
                            action={
                                <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto", marginTop: 8, marginRight: 8 }}>
                                    {!isFormDisabled &&
                                        <Button color="primary" variant="text"
                                            onClick={async () => {
                                                if (isPersonDirty) {
                                                    this.props.showAlert("Personen har osparade ändringar!", "Spara för att lägga till en anmälan.");
                                                    return;
                                                } else if (!personEmail) {
                                                    const result = await this.props.showConfirm("Personen saknar epost. Vill du ändå lägga till en anmälan?");
                                                    if (!result) {
                                                        return;
                                                    }
                                                }

                                                this.setState({ addEntry: true })
                                            }}>Lägg till anmälan
                                        </Button>
                                    }
                                </Box>
                            }
                        />
                        <CardContent>
                            {this.state.loading &&
                                <LinearProgress color="secondary" />
                            }
                            <EnhancedTable<Entry>
                                columnDefinitions={columnDefinitions}
                                data={this.state.entries ?? []}
                                pageSize={10}
                                page={0}
                                loading={this.state.loading}
                                dense
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {this.state && this.state.editEntry &&
                <EditEntryDialog
                    entryId={this.state.editEntry.id}
                    onAbortEdit={() => {
                        this.setState({ editEntry: null });
                    }}
                    onSave={() => {
                        this.setState({ editEntry: null });
                        this.fetchEntries();
                    }}
                />

            }
            {this.state && this.state.addEntry &&
                <AddEntryDialog
                    personId={this.props.personId}
                    onAbort={() => {
                        this.setState({ addEntry: false });
                    }}
                    onSave={() => {
                        this.setState({ addEntry: false }, () => { this.fetchEntries() });
                    }}
                />
            }
        </>);
    }

    private editEntry = (entry: Entry, mode: editEntryMode): void => {
        switch (mode) {
            case "dialog":
                this.setState({ editEntry: entry });
                break;
            case "in-app":
                this.props.history.push(`/entries/manage/${entry.id}`);
                break;
            case "new-tab":
                window.open(`/entries/manage/${entry.id}`);
                break;
        }
    };

    private fetchEntries = async (): Promise<void> => {
        this.setState({ loading: true });
        const entries = await this.api.getEntriesForPerson(this.props.personId);
        this.setState({ entries: entries, loading: false });
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withAlert(withConfirm(withStyles(useStyles)(PersonEntries))));
