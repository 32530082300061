import * as yup from "yup";
import { CompanySurveyType, CompanySurveyTypes, CompanySurveyTypesLookup } from "../../../../model/B2bCompany";

const mandatoryMessage = "Obligatoriskt";

const editB2bCompanySurveysValidationSchema = yup
    .object()
    .shape(
        {
            "name": yup
                .string()
                .required(mandatoryMessage),
            "description": yup
                .string()
                .notRequired(),
            "type": yup
                .string()
                .oneOf(CompanySurveyTypes)
                .notRequired(),
            "companyProductId": yup
                .string()
                .notRequired()
                .nullable()
                .when(["type"], {
                    is: (type: CompanySurveyType) => type === "RELAY_POOL",
                    then: () => yup.string().required(`Obligatoriskt för typ ${CompanySurveyTypesLookup["RELAY_POOL"]}`).nullable(),
                }),
            "requiresLogin": yup
                .bool()
                .notRequired()
                .when(["type"], {
                    is: (type: CompanySurveyType) => type === "RELAY_POOL",
                    then: () => yup.bool().oneOf([true], `Obligatoriskt för typ ${CompanySurveyTypesLookup["RELAY_POOL"]}`),
                })
        }
    );

export default editB2bCompanySurveysValidationSchema;
