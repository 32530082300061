import React, { useEffect, useRef, useState } from "react";
import { Person } from "../../../model/Person";
import { ApiBackend } from "../../../providers/apibackend";
import { IColumnDefinition } from "../../Common/EnhancedTable/models";
import { debounce } from "lodash";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, LinearProgress } from "@material-ui/core";
import SearchFilter, { Filters } from "../../Common/SearchFilter/SearchFilter";
import EnhancedTable from "../../Common/EnhancedTable/EnhancedTable";

interface IProps {
    title: string;
    action?: JSX.Element & React.ReactNode;
    onPersonSelect: (value: Person) => void;
    onAbort: () => void;
}

interface ISearchFilter {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    vasaId?: string;
    birthDate?: string;
}

const initSearchFilter = () => {
    return {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        vasaId: "",
    };
};

const PersonSearchDialog = ({ title, action, onPersonSelect, onAbort }: IProps) => {
    const [loading, setLoading] = useState(false);
    const [persons, setPersons] = useState(null as Person[]);
    const [searchFilter, setSearchFilter] = useState(initSearchFilter() as ISearchFilter);

    const searchFilterInitialized = useRef<boolean>(false);
    const busyLoading = useRef<boolean>(false);
    const didMount = useRef<boolean>(false);

    const backend = new ApiBackend();
    const maxRows = 100;

    const setBusyLoading = (value: boolean): void => {
        busyLoading.current = value;
        setLoading(value);
    };

    useEffect(() => {
        didMount.current = true;

        // unmount
        return () => { didMount.current = false };
    }, []);

    useEffect(() => {
        if (!didMount.current || !searchFilterInitialized.current || busyLoading.current) {
            return;
        }
        performSearch();
    }, [searchFilter]);


    const performSearch = debounce(async () => {
        if (!didMount.current || !searchFilterInitialized.current || busyLoading.current) {
            return;
        }

        const { firstName, lastName, email, phone, vasaId, birthDate } = searchFilter;

        setBusyLoading(true);

        const response = await backend.searchPersons({
            firstName: firstName,
            lastName: lastName,
            email: email,
            phone: phone,
            vasaid: vasaId,
            birthDate: birthDate,
            noCount: true,
            begin: 0,
            limit: maxRows
        });

        if (didMount.current) {
            setPersons(response.persons);
            setBusyLoading(false);
        }
    }, 500);

    const handleRequestSearchFilterInit = (nextValue: ISearchFilter): void => {
        searchFilterInitialized.current = true;
        handleRequestSearchFilterChange(nextValue);
    };

    const handleRequestSearchFilterChange = (nextValue: ISearchFilter): void => {
        setSearchFilter(nextValue);
    };

    const columnDefinitions: IColumnDefinition<Person>[] = [
        {
            renderCell: (row) => {
                return (
                    <Button
                        variant="text"
                        color="secondary"
                        onClick={() => {
                            onPersonSelect(row);
                        }}
                    >
                        Välj
                    </Button>
                );
            }
        },
        { propName: "lastName", label: "Efternamn" },
        { propName: "firstName", label: "Förnamn" },
        { propName: "vasaId", label: "VASA-ID" },
        { propName: "birthDay", label: "Födelsedatum" },
        { propName: "email", label: "E-post" },
        { propName: "mobileNumber", label: "Telefon" },
    ];

    const render = () => {
        const defaultBoxStyle = {
            display: "flex",
            flexDirection: "row",
        };

        return (
            <Dialog
                disableEscapeKeyDown
                fullWidth={true}
                maxWidth="lg"
                aria-labelledby="search-person-dialog-title"
                open={true}
            >
                <DialogTitle id="search-person-dialog-title">
                    <Box {...defaultBoxStyle}>
                        {title}
                        <Box {...defaultBoxStyle} style={{ gap: "16px", marginLeft: "auto" }}>
                            {action}
                        </Box>
                    </Box>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <SearchFilter
                        id={"search-person-dialog-filter"}
                        filters={{
                            "lastName": {
                                id: "filter-last-name",
                                type: "DebouncedText",
                                label: "Efternamn",
                                size: 4
                            },
                            "firstName": {
                                id: "filter-first-name",
                                type: "DebouncedText",
                                label: "Förnamn",
                                size: 4
                            },
                            "vasaId": {
                                id: "filter-vasaId",
                                type: "DebouncedText",
                                label: "VASA-ID",
                                size: 4
                            },
                            "birthDate": {
                                id: "filter-birthDate",
                                type: "BirthDate",
                                label: "Födelsedatum",
                                size: 4
                            },
                            "email": {
                                id: "filter-email",
                                type: "DebouncedText",
                                label: "E-post",
                                size: 4
                            },
                            "phone": {
                                id: "filter-phone",
                                type: "DebouncedText",
                                label: "Telefon",
                                size: 4
                            },
                        }}
                        persist={true}
                        onInit={(filter: Filters<ISearchFilter>) => {
                            handleRequestSearchFilterInit(filter as unknown as ISearchFilter);
                        }}
                        onChange={(filter: Filters<ISearchFilter>) => {
                            handleRequestSearchFilterChange(filter as unknown as ISearchFilter);
                        }}
                    />
                    <Grid container spacing={2}>
                        {loading &&
                            <Grid item xs={12}>
                                <LinearProgress color="secondary" />
                            </Grid>
                        }

                        <EnhancedTable<Person>
                            columnDefinitions={columnDefinitions}
                            data={persons ?? []}
                            pageSize={10}
                            maxRows={maxRows}
                            paginationMode="client"
                            sortingMode="client"
                            loading={loading}
                            dense
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onAbort}>Stäng</Button>
                </DialogActions>
            </Dialog>
        )
    };

    return render();
};

export default PersonSearchDialog;
