import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { createStyles, Grid, Theme, withStyles, WithStyles } from "@material-ui/core";
import FirebaseContext from "../../../providers/Firebase/context";
import CompanyFilterForm from "./CompanyFilterForm";
import CompanyEntriesForm from "./CompanyEntriesForm";
import { B2bCompany, B2bCompanyProduct } from "../../../model/B2bCompany";

interface IState {
    companyFilter: {company: B2bCompany; companyProduct: B2bCompanyProduct; } | undefined;
}

class CompanyEntryOverview extends React.Component<RouteComponentProps & WithStyles, IState> {
    static contextType = FirebaseContext;
    
    state: IState;

    constructor(props: RouteComponentProps & WithStyles) {
        super(props);

        this.state = { companyFilter: undefined };
    }

    render(): JSX.Element {
        const { companyFilter } = this.state;

        return (<>
            <CompanyFilterForm
                onCompanyChange={(companyFilter: { company: B2bCompany; companyProduct: B2bCompanyProduct; }): void => {
                    this.setState({ companyFilter: companyFilter });
                }}
            />
            <Grid container spacing={2} style={{ width: '100%' }}>
                <Grid item xs={12}>
                    <CompanyEntriesForm
                        companyFilter={companyFilter}
                    />
                </Grid>
            </Grid>
        </>);
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    gridheader: {
        background: '#eee',
        padding: 10
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withRouter(withStyles(useStyles)(CompanyEntryOverview));
