import { Subset } from "../typings/GlobalExtensions";
import { ParticipationType, ProductVariantRaceDataDiscipline, RaceType } from "./ProductVariantRaceData";

export class ExternalSeedingRace {
    eventKey: string;
    name: string;
    date: Date;
    year: number;
    id: string;
    raceDiscipline: Subset<ProductVariantRaceDataDiscipline, 'ski' | 'run' | 'bike'>;
    raceType: RaceType;
    raceParticipationType: ParticipationType;
    numResults: number;
}