import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import LinearProgress from "@material-ui/core/LinearProgress";
import React, { useState } from "react";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { ApiBackend } from "../../providers/apibackend";
import { OrderTransactionRow } from "../../model/OrderTransactionRow";
import { vasaloppetDateGetterFormatter } from "../../utilities/date";
import { AccountingAccountItem } from "../../model/IGetAccountingAccountsResponse";
import ManageOrder from "./ManageOrder";

interface IProps {
    account: AccountingAccountItem;
    fromDateFilter : string;
    toDateFilter: string;
}
const OrderTransactionOverViewItem = ({ account, fromDateFilter, toDateFilter}: IProps) => {

    const [loading, setLoading] = useState(false);
    const [viewOrder, setViewOrder] = useState(null as string);
    const [rows, setRows] = useState(null as OrderTransactionRow[]);

    const fetchData = async () => {
        setLoading(true);
        let api = new ApiBackend();
        api.getOrderTransactionsForAccount(account.accountid, account.type, fromDateFilter, toDateFilter).then((res) => {
            setRows(res);
            setLoading(false);
        })
    };

    const render = () => {
        return <><Accordion 
        onChange={(ev, expanded) => {
            console.log(ev);
            console.log(expanded);
            if (expanded && !rows)
            {
                fetchData();
            }
        }}>
        <AccordionSummary 
        expandIcon={<ExpandMoreIcon />}
        >
            <b>{account.account}</b> - Totalt period: {account.totalsum}kr
        </AccordionSummary>
        <AccordionDetails>
        {loading &&
            <LinearProgress color="secondary" />
        }
        <table style={{width: '100%',fontSize:12}} cellSpacing={0} cellPadding={2}>
            <thead>
            <tr style={{background:'#efefef'}}><td style={{width:100}}><b>Datum</b></td>
            <td style={{width:60}}><b>Order</b></td>
            <td style={{width:300}}><b>Produkt</b></td>
            <td style={{width:100}}><b>Belopp</b></td>
            
            <td style={{width:100}}><b>Betalkanal</b></td>
            
            <td ><b>Kommentar</b></td>
            </tr>
            </thead>
            <tbody>
                {rows && rows.map((x) => {
                        const border = {borderTop: '1px solid #dfdfdf'}
                        return <tr>
                            <td style={border}>{vasaloppetDateGetterFormatter(x.created)}</td>
                            <td style={border}><a href="#" style={{textDecoration: 'underline', color:'blue'}} onClick={() => {setViewOrder(x.orderId);}}>{x.publicId}</a></td>
                            <td style={border}>{x.productName}</td>
                            <td style={border}>{x.amount}</td>
                            <td style={border}></td>
                            <td style={border}>{x.reason}</td>
                        </tr>
                })}
            </tbody>
        </table>
        </AccordionDetails>
    </Accordion>
    {viewOrder && <ManageOrder
            orderId={viewOrder}
            close={() => {setViewOrder(null)}}/>
    }
    </>;
    };

    return render();
};

export default OrderTransactionOverViewItem;
