import React, { Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { ExternalSeedingRace } from '../../model/ExternalSeedingRace';
import { vasaloppetDateGetterFormatter, vasaloppetMoment } from '../../utilities/date';
import Select from '@material-ui/core/Select';
import { ParticipationType, ProductVariantRaceDataDiscipline, RaceType } from '../../model/ProductVariantRaceData';
import { Subset } from '../../typings/GlobalExtensions';

class Props {
    externalSeedingRace: ExternalSeedingRace;
    onChanged: (externalSeedingRace: ExternalSeedingRace) => void;
}

const ExternalSeedingRacesAddRace = ({ externalSeedingRace, onChanged }: Props) => {

    return <Fragment>
        <Grid container spacing={2}>
            <Grid item xs={6}>
                Datum
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth type='date' value={vasaloppetDateGetterFormatter(externalSeedingRace.date)} onChange={(ev) => {
                    externalSeedingRace.date = vasaloppetMoment(ev.target.value).toDate();
                    externalSeedingRace.year = externalSeedingRace.date.getFullYear();
                    onChanged(externalSeedingRace);
                }} />
            </Grid>

            <Grid item xs={6}>
                År
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth value={externalSeedingRace.year} disabled />
            </Grid>

            <Grid item xs={6}>
                Namn
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth value={externalSeedingRace.name} onChange={(ev) => {
                    externalSeedingRace.name = ev.target.value;
                    externalSeedingRace.eventKey = externalSeedingRace.name.replace(/ /g, "").substring(0, 10).toUpperCase() + "-" + externalSeedingRace.year;
                    onChanged(externalSeedingRace);
                }} />
            </Grid>

            <Grid item xs={6}>
                EventKey
            </Grid>
            <Grid item xs={6}>
                <TextField fullWidth value={externalSeedingRace.eventKey} disabled />
            </Grid>

            <Grid item xs={6}>
                Typ av lopp
            </Grid>
            <Grid item xs={6}>
                <Select native value={externalSeedingRace.raceParticipationType} onChange={(ev) => {
                    externalSeedingRace.raceParticipationType = ev.target.value as ParticipationType;
                    onChanged(externalSeedingRace);
                }}>
                    <option value="in_arena">På plats</option>
                    <option value="virtual">Virtuellt</option>
                </Select>
            </Grid>
            <Grid item xs={6}>
                Dicipline
            </Grid>
            <Grid item xs={6}>

                <Select native value={externalSeedingRace.raceDiscipline} onChange={(ev) => {
                    externalSeedingRace.raceDiscipline = ev.target.value as Subset<ProductVariantRaceDataDiscipline, 'ski' | 'run' | 'bike'>;
                    onChanged(externalSeedingRace);
                }}>
                    <option value="ski">Skidor</option>
                    <option value="bike">Cykel</option>
                    <option value="run">Löpning</option>
                </Select>
            </Grid>
            <Grid item xs={6}>
                Typ av lopp
            </Grid>
            <Grid item xs={6}>

                <Select native value={externalSeedingRace.raceType} onChange={(ev) => {
                    externalSeedingRace.raceType = ev.target.value as RaceType;
                    onChanged(externalSeedingRace);
                }}>
                    <option value="individual">Individuell</option>
                    <option value="team">Lag</option>
                    <option value="relay">Stafett</option>
                </Select>
            </Grid>
        </Grid>
    </Fragment>;

}
export default ExternalSeedingRacesAddRace;
