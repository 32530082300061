import React from "react";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { ApiBackend } from "../../providers/apibackend";
import { Product } from "../../model/Product";
import ClearableSelect from "./ClearableSelect";
import ListSubheader from "@material-ui/core/ListSubheader/ListSubheader";
import { StartGroup } from "../../model/StartGroup";

interface IProps {
    productIds: string | string[];
    id?: string;
    variant?: "filled" | "outlined" | "standard";
    initialValue?: string | string[];
    autoSelectFirst?: boolean;
    clearable?: boolean;
    multiple?: boolean;
    valueFormatter?: (startGroup: StartGroup) => string;
    onInit?: (value: string | string[]) => void;
    onClear?: () => void;
    onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IState {
    value: string | string[];
    listItems: (JSX.Element | JSX.Element[])[][] | JSX.Element[];
}

class StartGroupSelector extends React.Component<IProps, IState> {
    state: IState;

    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.api = new ApiBackend();

        this.state = {
            value: this.props.initialValue ?? "",
            listItems: null
        };
    }

    async componentDidMount(): Promise<void> {
        const { multiple, autoSelectFirst, onInit } = this.props;

        const products = await this.getProducts();

        let value = autoSelectFirst ? products[0].startGroups[0].id : this.props.initialValue;
        if (multiple && !Array.isArray(value)) {
            if (value) {
                value = [value];
            }
            value = [];
        }

        this.setState({
            listItems: this.generateItemsFromProducts(products),
            value: value
        }, () => {
            if (onInit) {
                this.props.onInit(this.state.value);
            }
        });
    }

    render(): JSX.Element {
        const { productIds, variant, clearable, multiple } = this.props;
        const { value, listItems } = this.state;

        const safeValue = !!listItems ? value : "";
        const disabled = !productIds;

        return (
            <ClearableSelect
                disabled={disabled}
                clearable={clearable}
                multiple={multiple}
                variant={variant}
                value={safeValue}
                onClear={this.handleClear}
                onChange={this.handleChange}
            >
                {listItems}
            </ClearableSelect>
        );
    }

    private generateItemsFromProducts = (products: Product[]): (JSX.Element | JSX.Element[])[][] | JSX.Element[] => {
        const { valueFormatter } = this.props;

        if (!products || products.length === 0) {
            return null;
        }

        if (products.length === 1) {
            const product = products[0];
            if (!product?.startGroups) {
                return null;
            }

            const listItems = product.startGroups.map(s => {
                return (
                    <MenuItem key={s.id} value={s.id} style={{ marginLeft: 18 }}>
                        {!!valueFormatter ? valueFormatter(s) : s.name}
                    </MenuItem>
                );
            });

            return listItems;
        }

        const listItems = products.map(p => {
            if (!p?.startGroups) {
                return null;
            }

            const options = p.startGroups.map(s => {
                return (
                    <MenuItem key={s.id} value={s.id} style={{ marginLeft: 18 }}>
                        {!!valueFormatter ? valueFormatter(s) : s.name}
                    </MenuItem>
                );
            });
            return [<ListSubheader disableSticky>{p.name}</ListSubheader>, options];
        });

        return listItems;
    };

    private handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const value = event.target.value;
        this.setState({ value: value });
        this.props.onChange(event);
    };

    private handleClear = (): void => {
        const { multiple } = this.props;

        this.setState({ value: multiple ? [] : "" });

        if (this.props.onClear) {
            this.props.onClear();
        }
    };

    private getProducts = async (): Promise<Product[]> => {
        const { productIds } = this.props;

        if (!productIds) {
            return [];
        }

        let ids = Array.isArray(productIds) ? productIds : [productIds];

        const work = ids.map((id) => {
            return this.api.getProduct(id);
        });

        const result = await Promise.all(work);
        return [].concat(...result);
    };
}

export default StartGroupSelector;
