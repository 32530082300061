import React from "react";
import { Product } from "../../../model/Product";
import { ProductVariantAgeCategory } from "../../../model/ProductVariantAgeCategory";
import { ApiBackend } from "../../../providers/apibackend";
import ProductVariantPriceGroupSelector, { IProductVariantPriceGroupSelectorResult } from "../../Common/ProductVariantPriceGroupSelector";

interface IProps {
    onChange: (ageCategories: ProductVariantAgeCategory[]) => void;
}

interface IState {
    products?: Product[];
}

class CopyCard extends React.Component<IProps, IState> {
    private readonly api: ApiBackend;

    constructor(props: IProps) {
        super(props);

        this.state = {
            products: undefined
        };
        this.api = new ApiBackend();
    }

    async componentDidMount(): Promise<void> {
        const products = await this.api.listProducts();
        this.setState({
            products: products.filter(x => x.type === "race")
                .sort((a, b) => a.name.localeCompare(b.name, "sv"))
        });
    }

    render() {
        const { products } = this.state;

        if (!products) {
            return (<></>);
        }

        return (
            <ProductVariantPriceGroupSelector
                headerText="Kopiera ålderskategorier från tidigare lopp"
                buttonText="Kopiera"
                products={products}
                includePriceGroups={false}
                onChange={this.onChange} 
            />
        )
    }

    private onChange = (res: IProductVariantPriceGroupSelectorResult) => {
        const { onChange } = this.props;

        onChange(res.productVariant.ageCategories.map(x => {
            return {
                id: undefined,
                gender: x.gender,
                category: x.category,
                fromAge: x.fromAge,
                toAge: x.toAge
            };
        }));
    };
}

export default CopyCard;
