/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React from 'react';
import { withRouter, RouteChildrenProps } from 'react-router-dom';
import { Theme, createStyles, Grid, WithStyles, Card, CardHeader, CardContent, TextField, Button, withStyles, CardActionArea, LinearProgress, Container, Dialog, DialogContent } from '@material-ui/core';
import {Event} from '../../model/Event';
import { ApiBackend } from '../../providers/apibackend';
import CopyEventDialog from './CopyEvent/CopyEventDialog';

interface Props extends RouteChildrenProps<{id: string}> {
}

interface State {
    event : Event;
    copyEvent: boolean;
    loading : boolean;
    haveChanges:boolean;
}

/** Edit  -- Edits an existing a new event
 * - Also keep the instance of firebase (Provider Context)
 * - Keep the route mapping to components
 */
class EditEvent extends React.Component<WithStyles & Props, State> {

    state = { event: null as Event, copyEvent: false, loading: true, haveChanges: false };

    private readonly api: ApiBackend;

    constructor(props: WithStyles & Props) {
        super(props);
        this.api = new ApiBackend();
        this.api.getEvent(props.match.params.id).then((event) => {
            this.setState({event: event, loading: false});
        });
    }

    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        var e = this.state.event as any;
        e[prop] = ev.target.value;
        this.setState({ event: e, haveChanges: true });
    };

    saveEvent = () => {
        this.setState({ loading: true });
        this.api.createOrUpdateEvent(this.state.event).then((res) => {
            if (res) {
                this.setState({ haveChanges: false, loading: false });
            }
            else {
                alert("Kunde inte spara evenemang!");
                this.setState({ loading: false });
            }
        });
    };

    copyEvent = () => {
        this.setState({ copyEvent: true });
    };
   
    copyEventCancelled = () => {
        this.setState({ copyEvent: false });
    };

    copyEventSaved = () => {
        this.setState({ copyEvent: false });
    };

    render() {
        return <div>
            {this.state.loading &&
                <LinearProgress />
            }
            {this.state && this.state.event && this.state.copyEvent &&
                <CopyEventDialog {...this.props}
                    event={this.state.event}
                    cancel={this.copyEventCancelled}
                    onSave={this.copyEventSaved}
                />
                }
            {this.state.event &&
                this.renderEventEdit()
            }
        </div>;
    }

    renderEventEdit() {
        let { classes } = this.props;
        return <Grid container className={classes.root} spacing={0}>
            <Grid item xs={12}>
                {this.state && this.state.loading &&
                    <LinearProgress color="secondary" style={{ marginBottom: 3 }} />
                }

                <Card>
                    <CardHeader className={classes.cardHeader} title={"Hantera: " + this.state.event.name} />
                    <CardContent>
                        <form className={classes.form} noValidate autoComplete="off">
                            <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                <Grid item xs={12}>
                                    <TextField style={{ flex: 1, margin: '0 3px' }} id="standard-basic" label="Arrangemangsnamn" value={this.state.event.name} onChange={this.onChange('name')} fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={this.state.event.startDate} onChange={this.onChange('startDate')} label="Startdatum" fullWidth />
                                </Grid>
                                <Grid item xs={12} sm={6} >
                                    <TextField type="date" style={{ flex: 1, margin: '0 3px' }} id="standard-basic" value={this.state.event.endDate} onChange={this.onChange('endDate')} label="Slutdatum" fullWidth />
                                </Grid>
                                <Grid item xs={12}>

                                </Grid>
                            </Grid>
                        </form>
                    </CardContent>
                    <CardActionArea component="a">
                        <Button color="secondary" disabled={!this.state.haveChanges} onClick={this.saveEvent}>Spara ändringar</Button>
                        <Button color="secondary" disabled={this.state.haveChanges} onClick={this.copyEvent}>Kopiera event</Button>
                    </CardActionArea>
                </Card>
            </Grid>

        </Grid>;
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {

    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withStyles(useStyles)(withRouter(EditEvent));
