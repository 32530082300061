import React, { useState } from "react";
import { FormControl, Grid, InputLabel, MenuItem } from "@material-ui/core";
import ClearableSelect from "../../../../Common/ClearableSelect";
import { EntryStatus, EntryStatusTypes } from "../../../../../model/CommonTypes";
import useDidMountEffect from "../../../../Common/UseDidMountEffect";

interface IProps {
    restrictedStatuses?: EntryStatus[];
    defaultValues?: EntryStatus[];
    onChange: (nextValue: EntryStatus) => void;
}

const initialValue = (statuses?: EntryStatus[]): EntryStatus | "" => {
    if (!statuses) {
        return "";
    }

    return statuses.every(x => x === statuses[0]) ? statuses[0] : "";
};

const StatusRenderer = (props: IProps) => {
    const [value, setValue] = useState(initialValue(props.defaultValues));

    useDidMountEffect(() => {
        props.onChange(value as EntryStatus);
    }, [value]);

    const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
        setValue(evt.target.value as EntryStatus);
    };

    const render = () => {
        const statuses = props.restrictedStatuses ? props.restrictedStatuses : EntryStatusTypes;

        return (
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel shrink>Status</InputLabel>
                    <ClearableSelect
                        value={value}
                        clearable={false}
                        onChange={handleChange}
                    >
                        {statuses.map((x, idx) => {
                            return <MenuItem key={idx} value={x}>{x}</MenuItem>;
                        })}
                    </ClearableSelect>
                </FormControl>
            </Grid>
        );
    }

    return render();
}

export default StatusRenderer;
