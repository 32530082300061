import uuid from "react-uuid";

export class ProductVariantPriceGroup {
    id: string;
    Name: string;
    Name_En: string;
    price: number;
    LongName: string;
    Description: string;
    LongName_En: string;
    Description_En: string;
    type: string;
    validOnlineOnly: boolean;
    validPOSOnly: boolean;
    validFrom: string;
    validTo: string;
    maxAge: number;
    isPublic: boolean;
    enabledForAddons: boolean;
    enabledForAddonsNewRegistration: boolean;

    /**
     * 
     * @param name Defaults to ''
     * @param nameEn Defaults to ''
     * @param type Defaults to null
     * @param validFrom Defaults to null
     * @param isPublic Defaults to true
     * @returns 
     */
    static createNew(name = "", nameEn = "", type: string = null, validFrom: string = null, isPublic: boolean = true): ProductVariantPriceGroup {
        return {
            id: uuid(),
            Name: name,
            LongName_En: "",
            Name_En: nameEn,
            Description_En: "",
            price: 0,
            LongName: "",
            Description: "",
            type: type,
            validFrom: validFrom,
            validOnlineOnly: false,
            validPOSOnly: false,
            validTo: null,
            maxAge: 999,
            isPublic: isPublic,
            enabledForAddons: false,
            enabledForAddonsNewRegistration: false
        }
    }
}

type GetSkiPassTypeResponse = {
    day: boolean;
    threeDay: boolean;
    week: boolean;
    season: boolean;
    isRange: boolean;
}

export const getSkiPassType = (priceGroup: ProductVariantPriceGroup, productType?: string): GetSkiPassTypeResponse => {
    let obj = {
        day: false,
        threeDay: false,
        week: false,
        season: false,
        isRange: false
    };

    if (productType && productType !== "skipass") {
        return obj;
    }

    if (!priceGroup || !priceGroup.Name) {
        return obj;
    }

    obj.day = priceGroup.Name == "Dagkort";
    obj.threeDay = priceGroup.Name == "3-dagarskort";
    obj.week = priceGroup.Name == "Veckokort";
    obj.season = priceGroup.Name == "Säsongskort";
    obj.isRange = obj.threeDay || obj.week;

    return obj;
};
