import React from "react";
import { Box, Card, CardActions, CardContent, CardHeader, WithStyles, Grid, IconButton, TextField } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { FieldArray, FieldArrayRenderProps, FormikProps } from "formik";
import { ApiBackend } from "../../providers/apibackend";
import { Product } from "../../model/Product";
import { ProductCatalogue } from "../../model/ProductCatalogue";
import ProductMenu from "./Menus/ProductMenu";
import { ProductCatalogueProduct } from "../../model/ProductCatalogueProduct";

interface IState {
	sourceProducts?: Product[];
}

class EditCatalogueProducts extends React.Component<WithStyles & FormikProps<ProductCatalogue>, IState> {
	private readonly api: ApiBackend;

	constructor(props: WithStyles & FormikProps<ProductCatalogue>) {
		super(props);

		this.api = new ApiBackend();

		this.state = {};
	}

	async componentDidMount(): Promise<void> {
		await this.updateAllSourceProducts();
	}

	render(): JSX.Element {
		const { sourceProducts } = this.state;
		const { classes } = this.props;
		const products = this.props.values.products;

		if (!sourceProducts) {
			return null;
		}

		return (this.state.sourceProducts &&
			<Card>
				<FieldArray
					name="products"
					render={arrayHelpers => (
						<>
							<CardHeader
								className={classes.cardHeader}
								title="Hantera produkter"
							/>
							<CardContent>
								{products.map((product, idx) => {
									return (
										<Grid container key={idx} spacing={2}>
											<Grid item xs={11}>
												<TextField {...this.props}
													fullWidth
													disabled={true}
													label="Namn"
													value={sourceProducts.find(x => x.id === product.productId)?.name}
												/>
											</Grid>
											<Grid item xs={1}>
												<Box display="flex" justifyContent="flex-end">
													<IconButton
														onClick={() => { arrayHelpers.remove(idx); }}>
														<DeleteIcon />
													</IconButton>
												</Box>
											</Grid>
										</Grid>
									)
								})}
							</CardContent>
							<CardActions>
								<Box mb={1}>
									<ProductMenu
										buttonText="Lägg till produkt"
										products={sourceProducts}
										disabledItems={products?.map(x => x.productId)}
										onChange={(product) => {
											this.handleOnPopoverChange(product, arrayHelpers);
										}}
									/>
								</Box>
							</CardActions>
						</>
					)} />
			</Card>
		)
	}

	private handleOnPopoverChange = async (product: Product, arrayHelpers: FieldArrayRenderProps) => {
		const p = new ProductCatalogueProduct();
		p.productId = product.id;
		arrayHelpers.push(p);
	};

	private updateAllSourceProducts = async (): Promise<void> => {
		const products = await this.api.listProducts();
		this.setState({
			sourceProducts: products
		});
	}
}

export default EditCatalogueProducts;