import React from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";

const unsavedMessage = "Du har osparade ändringar. Är du säker på att du vill gå härifrån?";

interface IProps extends RouteComponentProps {
	isDirty: boolean;
	getTitle?: () => string | undefined;
}

interface IState {
	isDirty: boolean;
}

class DirtyPageChecker extends React.Component<IProps, IState> {
	private readonly favicon: HTMLLinkElement;
	private readonly initialHref: string;
	private readonly initialPageTitle: string;

	constructor(props: IProps) {
		super(props);

		this.state = { isDirty: false };
		this.favicon = document.getElementById("favicon") as HTMLLinkElement;
		this.initialHref = this.favicon.href;
		this.initialPageTitle = document.title;
	}

	componentDidMount(): void {
		window.addEventListener("beforeunload", this.dirtyCheckListener);
	}

	componentWillUnmount(): void {
		window.removeEventListener("beforeunload", this.dirtyCheckListener);
		this.unsetFav();
		this.unsetTitle();
	}

	componentDidUpdate(prevProps: IProps): void {
		if (this.props.isDirty !== prevProps.isDirty) {
			this.props.isDirty ? this.setDirtyFav() : this.unsetFav();

			if (this.props.isDirty) {
				window.addEventListener("beforeunload", this.dirtyCheckListener);
			} else {
				window.removeEventListener("beforeunload", this.dirtyCheckListener);
			}
		}
	}

	render(): JSX.Element {
		const { children, isDirty, getTitle } = this.props;

		if (getTitle) {
			this.setPageTitle(getTitle());
		}

		return (
			<div>
				<Prompt when={isDirty} message={unsavedMessage} />
				{children}
			</div>
		);
	}

	private dirtyCheckListener = (event: BeforeUnloadEvent) => {
		event.preventDefault();

		if (this.props.isDirty) {
			return (event.returnValue = unsavedMessage);
		}
	};

	private setDirtyFav = (): void => {
		const size = 16;
		const canvas = document.createElement("canvas");
		canvas.width = size;
		canvas.height = size;

		const context = canvas.getContext("2d");
		const img = document.createElement("img");
		img.src = this.favicon.href;

		img.onload = () => {
			context.drawImage(img, 0, 0, size, size);
			context.beginPath();
			context.arc(canvas.width - size / 2, size / 3, size / 3, 0, 2 * Math.PI);
			context.fillStyle = "#FF0000";
			context.fill();
			this.favicon.href = canvas.toDataURL("image/png");
		};
	};

	private unsetFav = (): void => {
		this.favicon.href = this.initialHref;
	};

	private setPageTitle = (title: string | undefined): void => {
		document.title = title || this.initialPageTitle;
	};

	private unsetTitle = (): void => {
		document.title = this.initialPageTitle;
	};
}

export default DirtyPageChecker;
