import React from "react";
import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem } from "@material-ui/core";
import { Settings } from "@material-ui/icons";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Firebase from "../providers/Firebase/firebase";
import { RouteComponentProps, withRouter } from "react-router-dom";
import VasaloppetTooltip from "./Common/VasaloppetTooltip";

export interface IProps {
    firebase: Firebase;
}

const AccountMenu = ({ firebase, history }: IProps & RouteComponentProps) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const currentUser = firebase.auth().currentUser;

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogOut = () => {
        setAnchorEl(null);
        firebase.doSignOut();
    };

    const handleGoToProfile = () => {
        setAnchorEl(null);
        history.push("/profile");
    };

    const handleGoToSettings = () => {
        setAnchorEl(null);
        history.push("/accountsettings");
    };

    const getInitials = () => {
        const parts = currentUser.displayName.split(" ");
        return parts.map(x => x.charAt(0));
    };

    return (
        <>
            <Box style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
                <VasaloppetTooltip title="Min profil">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <Avatar style={{ width: 40, height: 40 }}>{getInitials()}</Avatar>
                    </IconButton>
                </VasaloppetTooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    style: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    },
                }}
            >
                <MenuItem onClick={handleGoToProfile}>
                    <ListItemIcon>
                        <Avatar style={{ width: 32, height: 32 }} />
                    </ListItemIcon>
                    Min profil
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleGoToSettings}>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Inställningar
                </MenuItem>
                <MenuItem onClick={handleLogOut}>
                    <ListItemIcon>
                        <ExitToAppIcon fontSize="small" />
                    </ListItemIcon>
                    Logga ut
                </MenuItem>
            </Menu>
        </>
    );
};

export default withRouter(AccountMenu);
