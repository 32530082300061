import { IconButton, TextField } from "@material-ui/core";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { ClassNameMap, WithStyles } from "@material-ui/core/styles/withStyles";
import ClearIcon from "@material-ui/icons/Clear";
import { FormikProps } from "formik";
import _ from "lodash";
import React from "react";
import { CustomField, CustomFieldValidValue, hasCustomFieldStringValidValues, isChoiceType, toCustomFieldValidValues } from "../../../model/CustomField";
import { CustomFieldValue, Entry } from "../../../model/Entry";
import { Product } from "../../../model/Product";
import { hasEntryFields } from "../../../model/ProductVariantRaceData";
import { ApiBackend } from "../../../providers/apibackend";
import MultiChoiceField from "../../Common/MultiChoiceField";

interface IProps extends FormikProps<Entry> {
	entryProduct: Product;
}

interface IState {
	mounted: boolean;
	expanded: boolean;
	customFields: CustomField[];
	mappedValues: CustomFieldValue[]; // Values for custom fields that exist. Might have empty values.
	reservedValues: CustomFieldValue[]; // Values that for some reason exist on entry, but the custom field doesn’t exist. Should never be removed. Merge with mappedValues on save.
}

class EntryCustomFieldsCard extends React.Component<WithStyles & IProps, IState> {
	state: IState;

	private readonly api: ApiBackend;

	constructor(props: WithStyles & IProps) {
		super(props);

		this.api = new ApiBackend();

		this.state = {
			mounted: false,
			expanded: true,
			customFields: [],
			mappedValues: [],
			reservedValues: [],
		};
	}

	async componentDidMount(): Promise<void> {
		const customFields = await this.getCustomFields();
		const mappedValues = this.getMappedValues(customFields);
		const reservedValues = this.getReservedValues(customFields);

		this.setState({
			mounted: true,
			customFields,
			mappedValues,
			reservedValues,
		});
	}

	componentDidUpdate(prevProps: Readonly<{ classes: ClassNameMap<string> } & IProps>): void {
		// Handles updating props, for example during save (submit), when we may receive new values (e.g., from the backend).
		if (!_.isEqual(prevProps.values.customFieldValues, this.props.values.customFieldValues)) {
			const { customFields } = this.state;
			const mappedValues = this.getMappedValues(customFields);
			const reservedValues = this.getReservedValues(customFields);

			this.setState({
				mappedValues,
				reservedValues,
			});
		}
	}

	private async getCustomFields(): Promise<CustomField[]> {
		const { entryProduct, values } = this.props;

		const variant = entryProduct.variants?.find(x => x.Id === values.variantId);
		if (!hasEntryFields(variant.productVariantRaceData)) {
			return [];
		}

		const fields = await this.api.listCustomFields();
		const applicableFields = fields.filter((f) => !f.handleAsClubName && f.type !== "genderpicker");

		return variant.productVariantRaceData.entryFields
			.map(x => applicableFields.find(f => f.id === x.customFieldId))
			.filter(Boolean);
	}

	private getMappedValues = (customFields: CustomField[]): CustomFieldValue[] => {
		const { customFieldValues } = this.props.values;

		return customFields.map(
			(field) => customFieldValues?.find((x) => x.key === field.id) || { key: field.id, value: null }
		);
	};

	private getMappedValue = (key: string): CustomFieldValue | undefined => {
		return this.state.mappedValues.find((cfv) => cfv.key === key);
	};

	private setMappedValue = (customField: CustomFieldValue) => {
		this.setState((state) => {
			const mappedValues = state.mappedValues.map((cfv) => (cfv.key === customField.key ? customField : cfv));

			return { mappedValues };
		}, this.save);
	};

	private getReservedValues = (customFields: CustomField[]): CustomFieldValue[] => {
		const { customFieldValues } = this.props.values;

		return customFieldValues?.filter((cfv) => !customFields.some((cf) => cf.id === cfv.key)) ?? [];
	};

	private save = () => {
		const { setFieldValue } = this.props;
		const { mappedValues, reservedValues } = this.state;

		setFieldValue("customFieldValues", [...mappedValues, ...reservedValues]);
	};

	private renderCustomField = (field: CustomField): JSX.Element => {

		const customFieldValue = this.getMappedValue(field.id);
		const commonProps = {
			key: field.id,
			id: field.id,
			name: field.id,
			label: field.name,
			InputLabelProps: { shrink: true },
			fullWidth: true,
			value: customFieldValue?.value || "",
			onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) =>
				this.setMappedValue({ key: field.id, value: e.target.value }),
		};

		let validValues = field.validvalues as CustomFieldValidValue[];
		if (isChoiceType(field) && hasCustomFieldStringValidValues(field.validvalues)) {
			validValues = toCustomFieldValidValues(field.validvalues);
		}

		switch (field.type) {
			case "ssn":
			case "text":
				return <TextField type="text" {...commonProps} />;
			case "number":
				return <TextField type="number" {...commonProps} />;
			case "choice":
				return (
					<TextField
						{...commonProps}
						select
						InputProps={{
							endAdornment: customFieldValue?.value ? (
								<IconButton
									style={{ padding: 0, marginRight: "1em" }}
									onClick={() => this.setMappedValue({ key: field.id, value: null })}
								>
									<ClearIcon />
								</IconButton>
							) : null,
						}}
					>
						{validValues.map((x, idx) => (
							<MenuItem key={idx} value={x.value}>
								{x.label}
							</MenuItem>
						))}
					</TextField>
				);
			case "multichoice":
				return (
					<MultiChoiceField
						key={field.id}
						label={field.name}
						valueGetter={() => customFieldValue.value}
						validValues={validValues}
						onChange={(value: string) => {
							this.setMappedValue({
								key: field.id,
								value: value,
							});
						}}
					/>
				);
			default:
				return null;
		}
	};

	render(): JSX.Element {
		const { classes } = this.props;
		const { mounted, expanded, customFields } = this.state;

		if (!mounted) {
			return null;
		}

		return (
			<Card>
				<CardHeader title="Anmälningsfält" />
				{expanded && (
					<CardContent>
						<Grid container className={classes.root} spacing={2} style={{ width: "100%", gap: "16px" }}>
							{customFields.map((field) => {
								return this.renderCustomField(field);
							})}
						</Grid>
					</CardContent>
				)}
			</Card>
		);
	}
}

export default EntryCustomFieldsCard;
