import { EntryPersonalBibHandoutLocation, EntryPersonalBibSize, EntryStatus, RaceStatus, Sex } from "./CommonTypes";

export const hasPermanentBib = ({ bibtext, personalBib }: Pick<Entry, "bibtext" | "personalBib">) => {
    return personalBib || (bibtext && (bibtext.startsWith("P") || bibtext.startsWith("V")));
};

export class Entry {
    id: string;
    externalId: string;
    personId: string;

    firstName: string;
    lastName: string;
    clubName: string;
    team: string;
    nationality: string;
    sex: Sex;
    email: string;
    phone: string;
    address: string;
    postalCode: string;
    city: string;
    county: string;
    country: string;
    socialSecurityNumber: string;

    status: EntryStatus;
    raceStatus: RaceStatus;
    isPayedInFull: boolean;

    startgroup: string;
    ageClassKey: string;

    bib: number;
    bibtext: string;

    dateOfBirthYear: number;
    dateOfBirthMonth: number;
    dateOfBirthDay: number;

    addOns: EntryAddonProduct[];
    customFieldValues: CustomFieldValue[];

    productId: string;
    variantId: string;
    priceGroupId: string;
    orderId: string;
    orderItemId: string;

    dsq: boolean;
    dsqReason: string;

    MT_MTQRcontent: string;
    MT_regId: string;

    prePackingGroup: string;
    eventKey: string;

    created: Date;
    updated: Date;

    relayMembers: RelayPerson[];
    b2bId: string;
    usedEntryCode?: string;
    startCertificateSent?: Date;
    entryTransfer?: EntryTransfer;
    chip?: string;

    personalBib: boolean;
    handoutLocation?: EntryPersonalBibHandoutLocation;
    bibSize?: EntryPersonalBibSize;
}

export class EntryTransfer {
    id: string;
    entryId: string;
    receiverEmail: string;
    entryCodeCreated: string;
    productId: string;
    actionInitiatedBy: string;
    status: string;
    created: Date;
};

/**
 * Creates a new empty Entry
 * @param Entry Data
 * @returns Empty Entry or with set data
 */
export const newEmptyEntry = (entryData?: Partial<Entry>): Entry => {
    return {
        firstName: "",
        nationality: "",
        lastName: "",
        email: "",
        phone: "",
        clubName: "",
        address: "",
        city: "",
        postalCode: "",
        country: "",
        socialSecurityNumber: "",
        dateOfBirthYear: null,
        dateOfBirthMonth: null,
        dateOfBirthDay: null,
        startgroup: "",
        usedEntryCode: "",
        relayMembers: [],
        ...entryData
    } as Entry;
};

export class ListEntry {
    id: string;
    productId: string;
    variantId: string;
    priceGroupId: string;
    eventName: string;
    raceName: string;
    orderDate: Date;
    firstName: string;
    lastName: string;
    clubName: string;
    email: string;
    phone: string;
    status: EntryStatus;
    bib: number;
    bibtext: string;
    startgroupId: string;
    startgroupName: string;
    vasaId: string;
    birthDay: string;
    b2bId: string;
    raceStatus: string;
    itemPrice: number;
    usedEntryCode: string;
    prePackingGroup: string;
    fisCode: string;
    team: string;
    customFieldValues: CustomFieldValue[];
    nationality: string;
}

export class CompanyEntry {
    id: string;
    personId?: string;
    firstName: string;
    lastName: string;
    sex: Sex;
    startGroupId?: string;
    startGroupName?: string;
    startTime?: Date;
    addOns: EntryAddonProduct[];
    customValues: { key: string; value: string; }[];
    relayMembers?: RelayPerson[];
}

export class CustomFieldValue {
    key: string;
    value: string | number | boolean;
}

export class RelayPerson {
    personId: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    address: string;
    postalCode: string;
    city: string;
    country: string;
    nationality: string;
    dateOfBirthYear: number;
    dateOfBirthMonth: number;
    dateOfBirthDay: number;
    sex: string;
}

export class EntryAddonProduct {
    productId: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
    productName: string;
    productVariantName: string;
    productVariantPrice: number;
}

export interface IListEntriesResponse {
    entries: ListEntry[];
    count: number;
}
