import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, CardContent, CardHeader, Grid, IconButton } from "@material-ui/core";
import { Entry, ListEntry } from "../../../model/Entry";
import { vasaloppetDateTimeGetterFormatter } from "../../../utilities/date";
import { Alert } from "@material-ui/lab";
import { ApiBackend } from "../../../providers/apibackend";
import EditEntryDialog from "./EditEntryDialog";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";

interface IProps {
    entry: Entry;
}

const EntryTransferCard = ({ entry }: IProps) => {
    const [targetEntry, setTargetEntry] = useState(null as Entry | ListEntry);
    const [expanded, setExpanded] = useState(true);
    const [showDialog, setShowDialog] = useState(false);

    const isSeller = entry.entryTransfer.entryId === entry.id;
    const isBuyer = !isSeller && entry.entryTransfer.entryCodeCreated === entry.usedEntryCode;
    const backend = new ApiBackend();

    useEffect(() => {
        fetchTargetEntry();
    }, []);

    const fetchTargetEntry = async () => {
        let entryMatch: Entry | ListEntry;

        if (isSeller && !!entry.entryTransfer.entryCodeCreated) {
            const entriesResult = await backend.listEntries({ code: entry.entryTransfer.entryCodeCreated, noCount: true });
            if (entriesResult?.entries && entriesResult.entries.length > 0) {
                entryMatch = entriesResult.entries[0];
            }
        } else if (isBuyer) {
            entryMatch = await backend.getEntry(entry.entryTransfer.entryId);
        }

        if (!!entryMatch) {
            setTargetEntry(entryMatch);
        }
    };

    const formatSeller = () => {
        return isSeller ? <strong>{entry.entryTransfer.actionInitiatedBy}</strong> : entry.entryTransfer.actionInitiatedBy;
    };

    const formatBuyer = () => {
        return isBuyer ? <strong>{entry.entryTransfer.receiverEmail}</strong> : entry.entryTransfer.receiverEmail;
    };

    const renderAlertContent = () => {
        if (isSeller) {
            return (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <strong>
                            Den här anmälan är såld till {entry.entryTransfer.receiverEmail}
                        </strong>
                    </Alert>
                </Grid>
            );
        }

        if (isBuyer) {
            return (
                <Grid item xs={12}>
                    <Alert severity="info">
                        <strong>
                            Den här anmälan är såld av {entry.entryTransfer.actionInitiatedBy}
                        </strong>
                    </Alert>
                </Grid>
            );
        }

        return null;
    };

    const renderCardAction = () => {
        if (targetEntry) {
            return (<>
                <ButtonGroup color="secondary">
                    <Button
                        onClick={() => {
                            setShowDialog(true);
                        }}
                    >
                        Visa kopplad anmälan
                    </Button>
                </ButtonGroup>
                <IconButton
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    {expanded ? (
                        <KeyboardArrowUp />
                    ) : (
                        <KeyboardArrowDown />
                    )}
                </IconButton>
            </>);
        }

        return null;
    };

    const render = () => {
        const { entryTransfer } = entry;
        return (
            <Card>
                <CardHeader
                    title="Försäljning"
                    action={renderCardAction()}
                />
                {expanded &&
                    <CardContent>
                        <Grid container spacing={2} style={{ width: '100%' }}>
                            {renderAlertContent()}
                            <Grid item xs={3}>Säljare: </Grid>
                            <Grid item xs={9}>{formatSeller()}</Grid>
                            <Grid item xs={3}>Köpare: </Grid>
                            <Grid item xs={9}>{formatBuyer()}</Grid>
                            <Grid item xs={3}>Kod: </Grid>
                            <Grid item xs={9}>{entryTransfer.entryCodeCreated}</Grid>
                            <Grid item xs={3}>Status: </Grid>
                            <Grid item xs={9}>{entryTransfer.status}</Grid>
                            <Grid item xs={3}>Skapad: </Grid>
                            <Grid item xs={9}>{vasaloppetDateTimeGetterFormatter(entryTransfer.created)}</Grid>
                        </Grid>

                    </CardContent>
                }
                {showDialog &&
                    <EditEntryDialog
                        entryId={targetEntry.id}
                        onAbortEdit={() => {
                            setShowDialog(false);
                        }}
                    />
                }
            </Card>
        )
    };

    return render();
};

export default EntryTransferCard;
