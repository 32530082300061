import React from "react";
import TextField from "@material-ui/core/TextField";

type ReadonlyTextFieldProps = { value: string; label: string; };
const ReadonlyTextField = ({ value, label }: ReadonlyTextFieldProps): JSX.Element => {
    return (
        <TextField
            disabled
            InputLabelProps={{ shrink: true }}
            value={value}
            label={label}
            fullWidth
        />
    );
};

export default ReadonlyTextField;
