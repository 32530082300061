export enum CampaignType {
    Code4Product = "code4product"
}

export class Campaign {
    id: string;
    name: string;
    type: string;
    description?: string;
    description_en?: string;
    isActive: boolean;
    campaignStart: string;
    campaignEnd: string;
    products?: CampaignProduct[]
    codes?: CampaignCode[]
    totalNumCodes?: number;
    usedNumCodes?: number;
}

export class CampaignProduct {
    campaignId?: string;
    productId: string;
    productVariantId: string;
    productVariantPriceGroupId: string;
}

export class CampaignCode {
    campaignId?: string;
    code: string;
}