import { Card, CardHeader, CardContent, Grid, TextField, CardActions, Button, MenuItem } from "@material-ui/core";
import React from "react";
import { ProductVariantAgeCategory } from "../../../model/ProductVariantAgeCategory";

interface IProps {
    onChange: (ageCategories: ProductVariantAgeCategory[]) => void;
}

interface IState {
    gender: "male" | "female";
    from: number;
    to: number;
    delta: number;
}

class YearTemplateCard extends React.Component<IProps, IState> {
    state: IState;

    constructor(props: IProps) {
        super(props);

        this.state = {
            gender: undefined, from: 35, to: 84, delta: 5
        };
    }

    render() {
        const { gender, from, to, delta } = this.state;
        const calculateEnabled = gender && from && to && delta;
        const genderDefault = gender ?? "";

        return (
            <Card>
                <CardHeader title="Skapa ålderskategorier för intervall" />
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <TextField
                                select
                                style={{ flex: 1, margin: '0 3px' }}
                                label="Kön"
                                InputLabelProps={{ shrink: true }}
                                value={genderDefault}
                                fullWidth
                                onChange={this.genderChanged}
                            >
                                <MenuItem value="male">Man</MenuItem>
                                <MenuItem value="female">Kvinna</MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={from} inputProps={{ min: 1 }} label="Min-ålder" onChange={this.fromChanged} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={to} inputProps={{ min: 1 }} label="Max-ålder" onChange={this.toChanged} fullWidth />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField type="number" value={delta} inputProps={{ min: 1, max: 30 }} label="Intervall" onChange={this.deltaChanged} fullWidth />
                        </Grid>
                    </Grid>
                </CardContent>
                <CardActions>
                    <Button variant="contained" disabled={!calculateEnabled} onClick={this.calculate}>Generera</Button>
                </CardActions>
            </Card>
        )
    }

    private genderChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ gender:  ev.target.value as any});
    };

    private fromChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ from:  +ev.target.value});
    };

    private toChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ to:  +ev.target.value});
    };

    private deltaChanged = (ev: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ delta:  +ev.target.value});
    };
    
    private calculate = () => {
        const { gender, from, to, delta } = this.state;
        const { onChange } = this.props;

        const iterations = Math.max((to - from) / delta);
        const newItems: ProductVariantAgeCategory[] = [];
        for (let i = 0; i < iterations; i++) {
            const categoryPrefix = gender === "male" ? "M" : "W";
            const newFrom = from + (delta * i);
            const newTo = Math.min(newFrom + delta - 1, to);
            newItems.push({
                id: undefined,
                gender: gender,
                category: `${categoryPrefix}${newFrom}`,
                fromAge: newFrom,
                toAge: newTo
            });
        }

        onChange(newItems);
    };
}

export default YearTemplateCard;
