/**
 * Vasaloppet Mina Sidor
 * Author: Peter Löfås, peter@lofas.se
 */

import React, { Fragment } from 'react'
import FirebaseContext from '../../providers/Firebase/context';
import { Card, CardHeader, CardContent, Theme, withStyles, Button, createStyles, Grid, Dialog, DialogTitle, DialogActions, LinearProgress, TextField, Typography, IconButton } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApiBackend } from '../../providers/apibackend';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { BeloppsregleringName, KortterminalVlName, PointOfSale } from '../../model/PointOfSale';

interface State {
    loading: boolean;
    addPlace: boolean;
    posName: string;
    posId: string;
    posAccount: string;
    pointOfSales: PointOfSale[];
    confirmDelete: PointOfSale;
}

const isPosReadOnly = (posName: string): boolean => {
    return [BeloppsregleringName, KortterminalVlName].some(x => x === posName);
};

class ManagePaymentChannels extends React.Component<RouteComponentProps, State> {
    static contextType = FirebaseContext;
    constructor(props: RouteComponentProps) {
        super(props);
        this.state = { loading: true, pointOfSales: null, posName: null, posId: null, addPlace: false, confirmDelete: null, posAccount: null };
    }

    componentDidMount() {
        this.refresh();

    }

    refresh = () => {
        let back = new ApiBackend();
        back.listPointOfSales().then(res => {
            this.setState({ pointOfSales: res, loading: false });
        })
    }

    savePos = async () => {
        let back = new ApiBackend();
        this.setState({ loading: true });
        await back.addOrUpdatePointOfSales({
            id: this.state.posId,
            name: this.state.posName,
            account: this.state.posAccount
        }).then((res) => {
            this.setState({ loading: false, addPlace: false, posName: null, posId: null, posAccount: null });
            if (res == null) {
                alert("Kunde inte spara!");
            }
            else {
                this.refresh();
            }
        })
    }

    render() {
        const { classes } = this.props as any;

        return <Card>
            <CardHeader className={classes.cardHeader}
                title={<>
                    <Typography variant="h5" style={{ display: 'inline' }}>
                        Betalsätt
                    </Typography>
                </>}
            />
            <CardContent>
                {this.state.loading &&
                    <LinearProgress color="secondary" />
                }
                <Grid container spacing={2}>
                    {this.state && !this.state.addPlace &&
                        this.renderList()
                    }

                    {this.state.addPlace &&
                        this.renderEdit()
                    }
                </Grid>
                {this.state.confirmDelete && this.renderConfirmationDialog()}
            </CardContent>
        </Card>;
    }

    private renderList = () => {
        const { pointOfSales } = this.state;

        return (<>
            {pointOfSales && pointOfSales.map((pos) => {
                const isDeleteDisabled = isPosReadOnly(pos.name);

                return <Fragment key={pos.id}>
                    <Grid item xs={12}>
                        <IconButton
                            onClick={() => {
                                this.setState({ addPlace: true, posName: pos.name, posId: pos.id, posAccount: pos.account });
                            }}>
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            disabled={isDeleteDisabled}
                            onClick={() => {
                                this.setState({ confirmDelete: pos });
                            }}>
                            <DeleteIcon />
                        </IconButton>
                        {pos.name}
                    </Grid>
                </Fragment>;
            })}

            <Button variant="contained"
                onClick={() => {
                    this.setState({ addPlace: true, posId: null, posName: '', posAccount: '' })
                }}
            >
                Lägg till
            </Button>
        </>);
    }

    private renderEdit = () => {
        const { posName, posAccount } = this.state;
        const isNameDisabled = isPosReadOnly(posName);

        return (<>
            <Grid item xs={12}>
                <TextField placeholder="Namn"
                    disabled={isNameDisabled}
                    value={posName}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => { this.setState({ posName: ev.target.value }); }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField placeholder="Konto"
                    value={posAccount}
                    onChange={(ev: React.ChangeEvent<HTMLInputElement>) => { this.setState({ posAccount: ev.target.value }); }}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained"
                    onClick={this.savePos}>
                    Spara
                </Button>
                <Button variant="contained"
                    onClick={() => {
                        this.setState({
                            addPlace: false,
                            posId: null,
                            posName: null,
                            posAccount: null
                        })
                    }}
                >
                    Avbryt
                </Button>
            </Grid>
        </>);
    };

    renderConfirmationDialog() {
        return <Dialog
            disableEscapeKeyDown
            maxWidth="xs"
            aria-labelledby="confirmation-dialog-title"
            open={true}
        >
            <DialogTitle id="confirmation-dialog-title">Är du säker på att du vill ta bort {this.state.confirmDelete.name}?</DialogTitle>
            <DialogActions>
                <Button variant="contained" autoFocus onClick={this.handleDeleteCancel} color="primary">
                    Avbryt
                </Button>
                <Button variant="contained" onClick={this.handleDeleteOk} color="primary">
                    Ja, ta bort
                </Button>
            </DialogActions>
        </Dialog>;
    }

    handleDeleteCancel = () => {
        this.setState({ confirmDelete: null });
    }

    handleDeleteOk = () => {
        this.setState({ loading: true });
        let back = new ApiBackend();
        back.deletePointOfSale(this.state.confirmDelete).then((res) => {
            this.setState({ loading: false, confirmDelete: null }, () => {
                this.refresh();
            });
        })
    }
}

const useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
}
);

export default withRouter(withStyles(useStyles)(ManagePaymentChannels));