import { IInvoice, IInvoicePayment, IInvoiceB2bCustomProduct, IInvoiceCodeHolder } from "../../model/Invoice";
import { ListInvoicesParameters } from "../../providers/models";
import { vasaloppetDateGetterFormatter } from "../../utilities/date";
import { isConcreteType } from "../../utilities/generics";

const empty = "---";

export type SearchFilterType = Omit<ListInvoicesParameters, "begin" | "limit">;

export const getDefaultSearchFilter = (): SearchFilterType => {
    const fromDate = new Date();
    fromDate.setDate(fromDate.getDate() - 365);

    const toDate = new Date(2030, 11, 31);

    return {
        entityType: "ALL",
        fromDate: vasaloppetDateGetterFormatter(fromDate),
        toDate: vasaloppetDateGetterFormatter(toDate),
        invoiceStatus: "UNMANAGED"
    }
};

export const isPayment = (item: IInvoice): item is IInvoicePayment => {
    return isConcreteType<IInvoicePayment>(item, "pointOfSale");
};

export const isCodeHolder = (item: IInvoice): item is IInvoiceCodeHolder => {
    return isConcreteType<IInvoiceCodeHolder>(item, "createdBy");
};

export const isCustomProduct = (item: IInvoice): item is IInvoiceB2bCustomProduct => {
    return isConcreteType<IInvoiceB2bCustomProduct>(item, "numItems");
};

export const hasAmount = (item: IInvoice): item is IInvoicePayment | IInvoiceB2bCustomProduct => {
    return isConcreteType<IInvoicePayment | IInvoiceB2bCustomProduct>(item, "amount");
};

export const hasNumItems = (item: IInvoice): item is IInvoiceB2bCustomProduct => {
    return isConcreteType<IInvoiceB2bCustomProduct>(item, "numItems");
};

export const entityTypeFormatter = (item: IInvoice): string => {
    switch (item.entityType) {
        case "Payment":
            return "Order"
        case "CodeHolder":
            return "Kod"
        case "B2bCustomProduct":
            return "Anpassad produkt"
        default:
            throw new Error("Invalid entityType provided")
    }
};

export const amountFormatter = (item: IInvoice): string => {
    switch (item.entityType) {
        case "Payment":
        case "B2bCustomProduct":
            return `${(item as IInvoicePayment | IInvoiceB2bCustomProduct).amount} kr`
        default:
            return empty;
    }
};

export const numItemsFormatter = (item: IInvoice): string => {
    switch (item.entityType) {
        case "B2bCustomProduct":
            return `${(item as IInvoiceB2bCustomProduct).numItems}`
        default:
            return empty;
    }
};