import React from "react";
import ExpoContext from "./ExpoContext";

const withExpoContext = (Component: any) => {
    const WrapperComponent = (props: any) => {
        return (
            <ExpoContext.Consumer>
                {state => <Component {...props} expoContext={state} />}
            </ExpoContext.Consumer>
        );
    };

    return WrapperComponent;
}

export default withExpoContext;
