import React from 'react';
import { withStyles, Typography, Grid, Card, CardHeader, CardContent, TextField, Button, createStyles, Theme, Select, Checkbox, ThemeProvider, FormControlLabel, FormGroup, FormHelperText } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import FirebaseContext from '../providers/Firebase/context';
import Firebase from '../providers/Firebase/firebase';
import { MainStateValue } from './MainPage';
import countrycodes from '../constants/countrycodes.json';
import { ApiBackend } from '../providers/apibackend';
import { Profile } from '../model/Profile';

class FinishProfile extends React.Component<RouteComponentProps, any>
{
    static contextType = FirebaseContext;

    constructor(props: any) {
        super(props);
        this.state = {
            email: '', firstName: '', lastName: '', phoneNumber: '', addressLine1: '', postalCode: '', city: '',
            country: countrycodes.filter((val) => val.code == 'SE')[0],
            errors: {},
            consent:false,
            optin:true
        };
    }

    componentWillMount() {
        let ctx: MainStateValue = this.context;

        if (!ctx.profile.isComplete) {
            let displayName = ctx.firebase.auth().currentUser.displayName;
            let firstName = displayName;
            let lastName = '';
            if (displayName.length > 0 && displayName.indexOf(' ') > 0) {
                firstName = displayName.split(' ', 2)[0];
                lastName = displayName.split(' ', 2)[1];
            }
            this.setState({
                email: ctx.firebase.auth().currentUser.email,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: ctx.firebase.auth().currentUser.phoneNumber
            });
        }
        else 
        {
            this.setState({
                email: ctx.firebase.auth().currentUser.email,
                firstName: ctx.profile.firstName,
                lastName: ctx.profile.lastName,
                phoneNumber: ctx.profile.phone, addressLine1: ctx.profile.addressLine1, postalCode: ctx.profile.postalCode, city: ctx.profile.city,
                country: countrycodes.filter((x) => x.code==ctx.profile.country)[0],
                optin: ctx.profile.optInMarketing,
                consent: true
            });
        }
    }
    isFormValid = (state: any) => {
        let o = {} as any;
        let isValid = true;
        if (state.firstName.length <= 0) {
            o["firstName"] = "Du måste ange förnamn";
            isValid = false;
        }
        if (state.lastName.length <= 0) {
            o["lastName"] = "Du måste ange efternamn";
            isValid = false;
        }
        /*if (state.addressLine1.length <= 0) {
            o["addressLine1"] = "Du måste ange adress";
            isValid = false;
        }
        if (state.postalCode.length <= 0) {
            o["postalCode"] = "Du måste ange postnummer";
            isValid = false;
        }
        if (state.city.length <= 0) {
            o["city"] = "Du måste ange ort";
            isValid = false;
        }
        if (!state.phoneNumber || state.phoneNumber.length <= 0) {
            o["phoneNumber"] = "Du måste ange telefonnummer";
            isValid = false;
        }*/
        if (!state.consent) {
            o["consent"] = "Du måste acceptera villkoren";
            isValid = false;
        }
        return { valid: isValid, errors: o };
    }
    onChange = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>) => {
        let s = this.state as any;
        s[prop] = ev.target.value;
        let valid = this.isFormValid(s).valid;
        this.setState({ s, isValid: valid });
    }
    onChangeCheckbox = (prop: string) => (ev: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        let s = this.state as any;
        s[prop] = checked
        this.setState({ s });
    }

    saveProfile = async () => {
        let valid = this.isFormValid(this.state);
        if (!valid.valid) {
            this.setState({ errors: valid.errors });
        }
        else {
            let profile = {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                phone: this.state.phoneNumber,
                addressLine1: this.state.addressLine1,
                postalCode: this.state.postalCode,
                city: this.state.city,
                country: this.state.country.code,
                optInMarketing: this.state.optin,
                isComplete: true,
                id: null,
                accessRoles: null
            } as Profile;
            let backend = new ApiBackend();
            let res = await backend.updateProfile(profile);
            if (res) {
                let ctx: MainStateValue = this.context;
                ctx.onUpdateProfile(new Profile(profile))
                this.props.history.push('/');
            }
        }
    }

    render() {
        let { classes } = this.props as any;
        return <FirebaseContext.Consumer>
            {(value: { firebase: Firebase, isSignedIn: boolean, profile: Profile }) => {
                return <div>
                    <Typography variant="h4">
                        {value.profile.isComplete ? "Uppdatera din profil" : "Slutför registrering av din profil"}
                    </Typography>

                    <Grid container className={classes.root} spacing={2}>
                        <Grid item xs={12}>
                            <Card>
                                <CardHeader className={classes.cardHeader} title="Min Profil" />
                                <CardContent>
                                    <form className={classes.form} noValidate autoComplete="off">
                                        <Grid container className={classes.root} spacing={2} style={{ width: '100%' }}>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField style={{ flex: 1, margin: '0 3px' }} value={this.state.firstName} 
                                                helperText={this.state.errors.firstName} 
                                                error={this.state.errors.firstName} 
                                                onChange={this.onChange("firstName")} id="standard-basic" label="Förnamn" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField style={{ flex: 1, margin: '0 3px' }} value={this.state.lastName} 
                                                helperText={this.state.errors.lastName} 
                                                error={this.state.errors.lastName} 
                                                onChange={this.onChange("lastName")} id="standard-basic" label="Efternamn" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} sm={6} md={4}>
                                                <TextField id="standard-basic" value={this.state.email} disabled label="E-post" fullWidth />
                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FormGroup>
                                                    <FormControlLabel
                                                        control={<Checkbox  checked={this.state.consent} onChange={this.onChangeCheckbox('consent')}
                                                        name="consent" />}
                                                        label="Jag godkänner de allmänna villkoren"
                                                    /> 
                                                    <FormControlLabel
                                                        control={<Checkbox checked={this.state.optin} onChange={this.onChangeCheckbox('optin')} name="optin" />}
                                                        label="Jag vill gärna ta emot kommunikation med information och erbjudanden från Vasaloppet"
                                                    /></FormGroup>
                                            </Grid>
                                            <Grid item xs={12} sm={12}>

                                            </Grid>
                                            <Grid item xs={12} sm={12}>
                                                <Button variant="contained" color="secondary" onClick={this.saveProfile} disabled={!this.state.consent} >Spara</Button>
                                            </Grid>
                                        </Grid>
                                        <div id='recaptcha-container'></div>
                                    </form>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </div>;
            }}
        </FirebaseContext.Consumer>;
    }
}

let useStyles = ({ palette, spacing }: Theme) => createStyles({
    cardHeader: {
        background: palette.secondary.main,
        color: palette.secondary.contrastText,
        padding: 3
    },
    photo: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '10px'
    },
    root: {
    },
    form: {
        '& > *': {
            margin: spacing(1),
            width: '25ch',
        },
        '& label.Mui-focused': {
            color: palette.secondary.main,
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: palette.secondary.main,
        },
    }
});

export default withStyles(useStyles)(withRouter(FinishProfile));