import React from "react";
import uuid from "react-uuid";
import { FormControl, InputLabel, TextField } from "@material-ui/core";
import { FormikProps } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { debounce } from "lodash";

interface IProps<T> extends FormikProps<T> {
    propName: keyof T;
    autoCompleteValues: string[];
    label?: string;
}

interface IState {
    inputValue: string;
}

class FormAutoCompleteField<T> extends React.Component<IProps<T>, IState> {
    state: IState;

    constructor(props: IProps<T>) {
        super(props);
        
        const value = props.values[props.propName] as string ?? "";
        this.state = {
            inputValue: value
        };
    }

    render(): JSX.Element {
        const { propName, label, autoCompleteValues, values } = this.props;
        const { inputValue } = this.state;
        const id = `form-autocomplete-${uuid()}`;
        let value = "";
        if (!!values[propName]) {
            value = values[propName] as string;
        }

        return (
            <FormControl fullWidth>
                <InputLabel shrink htmlFor={id}>{label}</InputLabel>
                <Autocomplete id={id}
                    style={{ marginTop: 16 }}
                    value={value}
                    inputValue={inputValue}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    freeSolo
                    openOnFocus
                    options={autoCompleteValues}
                    renderInput={(params) => (
                        <TextField {...params} value={value} />
                    )}
                />
            </FormControl>
        )
    }

    private handleChange = (event: any, newValue: string) => {
        this.notifyChange(newValue);
    };

    private handleInputChange = (event: any, newValue: string) => {
        this.setState({ inputValue: newValue });
        this.notifyChange(newValue);
    };

    private notifyChange = debounce((value: string) => {
        const { propName, setFieldValue } = this.props;
        setFieldValue(propName as string, value);
	}, 500); 
}

export default FormAutoCompleteField;
